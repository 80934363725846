import React, { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { ConfigProvider } from 'antd';

import PublicLayout, { ApplicationFormLayout, PopupLayout, LoginLayout } from './layouts/PublicLayout';
import DefaultLayout from 'src/layouts/DefaultLayout';
import PhonicsLayout from 'src/layouts/PhonicsLayout';
import Administrator from './routes/Administrator';
import HeadManager from './routes/HeadManager';
import BranchManager from './routes/BranchManager';
import CampusManager from './routes/CampusManager';
import Teacher from './routes/Teacher';
import Student from './routes/Student';
import Login from './pages/Login';
import LevelTest from './pages/LevelTest';
import LevelTestMain from './pages/LevelTest/LevelTestMain';
import EvineLevelTestAppForm from './popups/EvineLevelTestAppForm';
import LevelTestAppForm from './popups/LevelTestAppForm';
import OnlineStudyResult from './popups/OnlineStudyResult';
import ElibraryStudyResult from './popups/ElibraryStudyResult';
import PayLetterCancel from './popups/PayLetterCancel';

import { useDispatch, useSelector } from 'react-redux';
import 'moment/locale/ko';
import 'antd/dist/antd.css';
import { getCurrentToken } from 'src/utils';
import './App.scss';
import koKR from 'antd/lib/locale/ko_KR';
import enUS from 'antd/lib/locale/en_US';
import { updateClassSlice, classStoreData } from 'src/operations/store';
import { useLazyQuery } from '@apollo/client';
import { USER_ME } from 'src/operations/queries/getUser';
import Privacy from './pages/Privacy';
import Policy from './pages/Policy';

import MetaUserInfo from './pages/MetaExam/MetaUserInfo';
import UserInfoNew from './pages/MetaExam/UserInfoNew';
import Survey from './pages/MetaExam/Survey';
import MetaLogin from './pages/MetaExam/MetaLogin';
import MetaExamType from './pages/MetaExam/MetaExamType';
import ReadingInfo from './pages/MetaExam/ReadingInfo';
import ReadingTest from './pages/MetaExam/ReadingTest';
import MetaFinished from './pages/MetaExam/MetaFinished';
import MetaSampleQuestion from './pages/MetaExam/MetaSampleQuestion';
import ImageTest from './pages/MetaExam/ImageTest';
import MetaReport from './pages/MetaExam/MetaReport';
import MetaTestStart from './pages/MetaExam/MetaTestStart';
import InstructionalGuideMeta from './pages/MetaExam/InstructionalGuideMeta';
import InstructionalMetaExamType from './pages/MetaExam/InstructionalMetaExamType';
import VocaTestSample from './pages/MetaExam/VocaTestSample';
import ReadingTestSample from './pages/MetaExam/ReadingTestSample';
import ListeningTestSample from './pages/MetaExam/ListeningTestSample';
import ExternalTool from './pages/ExternalTool';
import ToolLayout from 'src/layouts/ToolLayout';
import LevelUpLayout from 'src/layouts/LevelUpLayout';
import AICoachLayout from 'src/layouts/AICoachLayout';
import AICoachNewLayout from 'src/layouts/AICoachNewLayout';
import AITalkingTutorLayout from 'src/layouts/AITalkingTutorLayout';
import BookrTool from './pages/Bookr/Recording';
import BookrRedirect from 'src/pages/Bookr/Redirect';
import BlankAppPage from 'src/pages/BlankAppPage/index';
import Cmaker from './pages/Cmaker';
import FAQ from './components/common/FAQ';
import AiEssayCorrection from './pages/LevelUp/BookReport';
import VocaTool from './pages/VocaTool';
import VocaCheck from './pages/VocaTool/VocaCheck';
import VocaFullWidth from './pages/VocaTool/VocaFullWidth';
import VocaDictation from './pages/VocaTool/VocaDictation';
import VocaFlashcard from './pages/VocaTool/VocaFlashcard';
import VocaFlashCardTyping from './pages/VocaTool/VocaFlashCardTyping';
import VocaUnscramble from './pages/VocaTool/VocaUnscramble';
import VocaQuizFrame from './pages/VocaTool/VocaQuizFrame';
import LevelUpPortfolio from './pages/LevelUp/Library/Portfolio';
import LevelUpFavorite from './pages/LevelUp/Library/Favorite';
import LevelUpInProgress from './pages/LevelUp/Library/InProgress';
import LevelUpPointsAndCalendarPage from './pages/LevelUp/Library/PtsAndCalendar';
import LevelUpHome from './pages/LevelUp';
// import LevelUpResultPage from './pages/LevelUp/Results';
import LevelUpLibrary from './pages/LevelUp/Library';
import LevelUpSearch from './pages/LevelUp/Library/Search';
import LevelUpManage from 'src/pages/LevelUp/Report';
import LevelUpDetailsByDate from 'src/pages/LevelUp/Report/detailsByDate';
import LevelUpBookReportStudy from 'src/pages/LevelUp/Report/study';
import LevelUpVocaCard from 'src/pages/LevelUp/Library/Study/Voca/Flashcard';
import LevelUpVocaType from 'src/pages/LevelUp/Library/Study/Voca/Type';
import LevelUpVocaMatch from 'src/pages/LevelUp/Library/Study/Voca/Match';
import LevelUpVocaQuiz from 'src/pages/LevelUp/Library/Study/Voca/Quiz';
import LevelUpSentenceCard from 'src/pages/LevelUp/Library/Study/Sentence/Flashcard';
import LevelUpSentenceRepeat from 'src/pages/LevelUp/Library/Study/Sentence/Repeat';
import LevelUpSentenceDnD from 'src/pages/LevelUp/Library/Study/Sentence/DnD';
import LevelUpSentenceDictation from 'src/pages/LevelUp/Library/Study/Sentence/Dictation';
import LevelUpQuiz1 from 'src/pages/LevelUp/Quiz/Quiz1';
import LevelUpQuiz2 from 'src/pages/LevelUp/Quiz/Quiz2';
import LevelUpQuiz3 from 'src/pages/LevelUp/Quiz/Quiz3';
import LevelUpBookStudyPage from 'src/pages/LevelUp/Library/Study';

import LevelUpReportandWPMResultPage from './pages/LevelUp/Results/ReportandWPM';
import LevelUpWPM from './pages/LevelUp/WPM';
import LevelUpWPMResult from './pages/LevelUp/WPM/Result';
import CardMatchingGame from './pages/LevelUp/Game/CardMatch';
import CrossPuzzleGame from './pages/LevelUp/Game/CrossWord';
import RubricPage from './pages/LevelUp/Results/Rubric';
import RubricAiCoachPage from 'src/pages/AiCoach/Result/RubricAiCoach';
import LevelUpReportGenerate from './pages/LevelUp/Report/generate';
import LevelUpReader from './pages/LevelUp/Reader';
import LevelUpTool from './pages/LevelUp/Recording';
import EpubReader from './pages/LevelUp/Reader/EpubReader';

import PhonicsMonsterStageList from './pages/PhonicsMonster/stagelist';
import PhonicsMonsterStage from './pages/PhonicsMonster/stage';

import AiWritingCoach from './pages/AiCoach/Writing';
import Demo from './pages/AITalkingTutor/Writing/demo';
import Demo2 from './pages/AITalkingTutor/Writing/demo2';
import AICoachReportGenerate from './pages/AiCoach/Generate';
import AiTalkingStudy from './pages/AITalkingTutor/Writing';

import DashBoard from 'src/pages/DashBoard';

function App() {
  const defaultClass = useSelector(classStoreData);

  const locales = (company) => {
    switch (company) {
      case 'creo':
        return enUS;
      default:
        return koKR;
    }
  };

  const [getUserData, { data: userMeData }] = useLazyQuery(USER_ME);
  const token = getCurrentToken();
  useEffect(() => {
    if (token && token !== null) {
      getUserData({
        variables: { token: token },
      });
    }
  }, [token, getUserData]);
  return (
    <ConfigProvider locale={locales(defaultClass)}>
      <div className={defaultClass}>
        <Switch>
          <DefaultLayout exact path="/" component={DashBoard} />
          <PublicLayout exact path="/bookr/redirect/:user_idx/:bookId/:assigned_idx/:path" component={BookrRedirect} />
          <PublicLayout exact path="/oauth/app" component={BlankAppPage} />
          <PublicLayout exact path="/privacy" component={Privacy} />
          <PublicLayout exact path="/policy" component={Policy} />
          <LoginLayout exact path="/login" component={Login} />
          <PublicLayout exact path="/level-test" component={LevelTest} />
          <PublicLayout exact path="/level-test-main" component={LevelTestMain} />
          <PublicLayout exact path="/MetaExam/MetaUserInfo" component={MetaUserInfo} />
          <PublicLayout exact path="/meta-user-info" component={UserInfoNew} />
          <PublicLayout exact path="/meta-login" component={MetaLogin} />
          <PublicLayout exact path="/MetaExam/Survey" component={Survey} />
          <PublicLayout exact path="/MetaExam/MetaExamType" component={MetaExamType} />
          <PublicLayout exact path="/MetaExam/ReadingInfo" component={ReadingInfo} />
          <PublicLayout exact path="/MetaExam/ReadingTest" component={ReadingTest} />
          <PublicLayout exact path="/MetaExam/MetaFinished" component={MetaFinished} />
          <PublicLayout exact path="/MetaExam/MetaSampleQuestion" component={MetaSampleQuestion} />
          <PublicLayout exact path="/MetaExam/ImageTest" component={ImageTest} />
          <PublicLayout exact path="/MetaExam/MetaReport" component={MetaReport} />
          <PublicLayout exact path="/MetaExam/MetaTestStart/:id" component={MetaTestStart} />
          <PublicLayout exact path="/MetaExam/InstructionalGuideMeta" component={InstructionalGuideMeta} />
          <PublicLayout exact path="/MetaExam/InstructionalGuideMeta/complete" component={InstructionalGuideMeta} />
          <PublicLayout exact path="/MetaExam/InstructionalMetaExamType" component={InstructionalMetaExamType} />
          <PublicLayout exact path="/MetaExam/VocaTestSample" component={VocaTestSample} />
          <PublicLayout exact path="/MetaExam/ReadingTestSample" component={ReadingTestSample} />
          <PublicLayout exact path="/MetaExam/ListeningTestSample" component={ListeningTestSample} />
          <ApplicationFormLayout exact path="/placement-test-app" component={EvineLevelTestAppForm} />
          <ApplicationFormLayout exact path="/application" component={LevelTestAppForm} />
          <PopupLayout exact path="/payletter/cancel" component={PayLetterCancel} />
          <PopupLayout exact path="/online-study-result/:user_idx" component={OnlineStudyResult} />
          <PopupLayout exact path="/elibrary-study-result/:user_idx" component={ElibraryStudyResult} />
          <PublicLayout exact path="/cmaker" component={Cmaker} />
          <DefaultLayout exact path="/faq" component={FAQ} />
          <LevelUpLayout exact path="/level-up" component={LevelUpHome} removeMargin={true} isHeaderStatic={true} />
          <LevelUpLayout exact path="/level-up/search" component={LevelUpSearch} removeMargin={true} isHeaderStatic={true} />

          <LevelUpLayout
            exact
            path="/level-up/results/reportandwpm"
            component={LevelUpReportandWPMResultPage}
            removeMargin={true}
            isHeaderStatic={false}
          />

          <LevelUpLayout exact path="/level-up/results/rubric/:id" component={RubricPage} removeMargin={true} isHeaderStatic={false} />
          <LevelUpLayout exact path="/level-up/bookreport" component={AiEssayCorrection} removeMargin={true} isHeaderStatic={false} />
          <LevelUpLayout exact path="/level-up/portfolio" component={LevelUpPortfolio} removeMargin={true} isHeaderStatic={false} />
          <LevelUpLayout exact path="/level-up/favorite" component={LevelUpFavorite} removeMargin={true} isHeaderStatic={false} />
          <LevelUpLayout exact path="/level-up/inprogress" component={LevelUpInProgress} removeMargin={true} isHeaderStatic={false} />
          <LevelUpLayout
            exact
            path="/level-up/calendar"
            component={LevelUpPointsAndCalendarPage}
            removeMargin={true}
            isHeaderStatic={false}
          />
          <LevelUpLayout exact path="/level-up/library" component={LevelUpLibrary} removeMargin={true} isHeaderStatic={true} />
          <LevelUpLayout exact path="/level-up/wpm" component={LevelUpWPM} removeMargin={true} isHeaderStatic={true} />
          <LevelUpLayout exact path="/level-up/wpmresult" component={LevelUpWPMResult} removeMargin={true} isHeaderStatic={true} />

          <LevelUpLayout exact path="/level-up/:idx/matchinggame" component={CardMatchingGame} removeMargin={true} />
          <LevelUpLayout exact path="/level-up/:idx/crosspuzzlegame" component={CrossPuzzleGame} removeMargin={true} />
          <LevelUpLayout exact path="/level-up/reader/:id" component={LevelUpReader} removeMargin={true} />
          <LevelUpLayout exact path="/level-up/epub-reader/:id" component={EpubReader} removeMargin={true} />

          <LevelUpLayout exact path="/level-up/study/:idx" removeMargin={true} component={LevelUpBookStudyPage} />
          <LevelUpLayout exact path="/level-up/:idx/voca/card" removeMargin={true} component={LevelUpVocaCard} />
          <LevelUpLayout exact path="/level-up/:idx/voca/type" removeMargin={true} component={LevelUpVocaType} />
          <LevelUpLayout exact path="/level-up/:idx/voca/match" removeMargin={true} component={LevelUpVocaMatch} />
          <LevelUpLayout exact path="/level-up/:idx/voca/quiz" removeMargin={true} component={LevelUpVocaQuiz} />
          <LevelUpLayout exact path="/level-up/:idx/sentence/card" removeMargin={true} component={LevelUpSentenceCard} />
          <LevelUpLayout exact path="/level-up/:idx/sentence/repeat" removeMargin={true} component={LevelUpSentenceRepeat} />
          <LevelUpLayout exact path="/level-up/:idx/sentence/dnd" removeMargin={true} component={LevelUpSentenceDnD} />
          <LevelUpLayout exact path="/level-up/:idx/sentence/dictation" removeMargin={true} component={LevelUpSentenceDictation} />
          <LevelUpLayout exact path="/level-up/:idx/quiz1" removeMargin={true} component={LevelUpQuiz1} />
          <LevelUpLayout exact path="/level-up/:idx/quiz2" removeMargin={true} component={LevelUpQuiz2} />
          <LevelUpLayout exact path="/level-up/:idx/quiz3" removeMargin={true} component={LevelUpQuiz3} />
          <LevelUpLayout exact path="/level-up/report" removeMargin={true} component={LevelUpManage} />
          <PublicLayout
            exact
            path="/level-up/report/generate/:id"
            isHeaderStatic={false}
            removeMargin={true}
            component={LevelUpReportGenerate}
          />
          <LevelUpLayout exact path="/level-up/report/:type" removeMargin={true} component={LevelUpManage} />
          <LevelUpLayout exact path="/level-up/report/details-by-date/:idx" removeMargin={true} component={LevelUpDetailsByDate} />
          <LevelUpLayout
            exact
            path="/level-up/report/study/:idx"
            component={LevelUpBookReportStudy}
            removeMargin={true}
            isHeaderStatic={true}
          />
          <LevelUpLayout exact path="/level-up/recording/:id" removeMargin={true} component={LevelUpTool} />

          <ToolLayout exact path="/bookr/:type/:id" component={BookrTool} />
          <ToolLayout exact path="/external-tool/:type" component={ExternalTool} />

          <PhonicsLayout exact isHeaderStatic={true} path="/pm/:level/unit/:unit" component={PhonicsMonsterStageList} />
          <PhonicsLayout exact isHeaderStatic={true} path="/pm/:level/unit/:unit/stage/:stage" component={PhonicsMonsterStage} />

          {/* <AICoachLayout exact path="/aicoach/report/details-by-date/:idx" removeMargin={true} component={LevelUpDetailsByDate} /> */}
          <AICoachNewLayout exact path="/aicoach/writing/:idx" removeMargin={true} component={AiWritingCoach} />
          <AITalkingTutorLayout exact path="/ai-talking-tutor/demo" removeMargin={true} component={Demo} />
          <AITalkingTutorLayout exact path="/ai-talking-tutor/demo2" removeMargin={true} component={Demo2} />
          <AITalkingTutorLayout exact path="/ai-talking-tutor/writing/:idx" removeMargin={true} component={AiTalkingStudy} />
          <PublicLayout
            exact
            path="/aicoach/report/generate/:id"
            isHeaderStatic={false}
            removeMargin={true}
            component={AICoachReportGenerate}
          />
          <AICoachLayout
            exact
            path="/aicoach/results/rubric/:id"
            component={RubricAiCoachPage}
            removeMargin={true}
            isHeaderStatic={false}
          />
          <PublicLayout exact path="/vocatool" component={VocaTool} />
          <PublicLayout exact path="/vocaQuizFrame" component={VocaQuizFrame} />
          <PublicLayout exact path="/vocaFullWidth" component={VocaFullWidth} />
          {/*  */}
          <PublicLayout exact path="/VocaFlashCardTyping" component={VocaFlashCardTyping} />
          <PublicLayout exact path="/VocaFlashcard" component={VocaFlashcard} />
          <PublicLayout exact path="/VocaUnscramble" component={VocaUnscramble} />
          <PublicLayout exact path="/vocacheck" component={VocaCheck} />
          <PublicLayout exact path="/vocadictation" component={VocaDictation} />
          {/*  */}
          {token && userMeData?.me?.type === 5 ? (
            <Administrator />
          ) : userMeData?.me?.type === 4 ? (
            <HeadManager />
          ) : userMeData?.me?.type === 3 ? (
            <BranchManager />
          ) : userMeData?.me?.type === 2 ? (
            <CampusManager />
          ) : userMeData?.me?.type === 1 ? (
            <Teacher />
          ) : (
            <Student />
          )}
        </Switch>
      </div>
    </ConfigProvider>
  );
}

export default App;
