import React from 'react';
import styled, { css } from 'styled-components';
import { Image } from 'antd';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

/*rgba(255, 234, 188, .5)   ECF1F3*/
const MessageBlockWrapper = styled.p`
  padding: 10px;
  border-radius: 10px;
  background: ${(props) => (props.isMe ? `#cbc9c9` : `rgba(255, 234, 188, .5)`)};

  ${(props) =>
    props.isMe
      ? css`
          color: #fff;
          margin-left: 10px;
        `
      : `margin-right:10px;`}

  .msg_image {
    display: block;
  }
`;

const MarkdownComponents = {
  img: (image) => {
    return <Image rootClassName="msg_image" src={image.src} style={{ width: '100%', padding: '2%', maxWidth: '200px' }} />;
  },
  a: (link) => {
    let href = link.href;
    if (href.endsWith('.mp4')) {
      return <video controls src={href} style={{ width: '100%', padding: '2%' }} />;
    }
    if (href.endsWith('.mp3')) {
      return <audio controls src={href} style={{ width: '100%', padding: '2%' }} />;
    }

    return <a href={link.href}>{link.children}</a>;
  },
};

const MessageBlock = ({ isMe, message, urls = [], type = '', currentFileList = [] }) => {
  // let capitalizedMessage = message?.charAt(0).toUpperCase() + message?.slice(1);
  // check if single \n withing words and replace it with double \n
  let newLineMessage = message.replace(/\*\*\n(?=\w)/g, '**\n\n');

  return (
    <>
      <MessageBlockWrapper className={type === 'ai-talking-tutor' ? 'msg_block' : ''} isMe={isMe}>
        {message?.length > 0 && (
          <ReactMarkdown
            rehypePlugins={[rehypeRaw]}
            remarkPlugins={[remarkGfm]}
            children={newLineMessage}
            components={MarkdownComponents}
            transformLinkUri={(url) => {
              if (url.startsWith('http')) {
                return url;
              }
              let findIndx = currentFileList.findIndex((path) => path.endsWith(url));
              if (findIndx !== -1) {
                return currentFileList[findIndx];
              } else if ((url.endsWith('.mp4') || url.endsWith('.mp3')) && url.startsWith('audio_')) {
                let audioUrl = url.replace('audio_', '');
                let findIndx = currentFileList.findIndex((path) => path.endsWith(audioUrl));
                if (findIndx !== -1) {
                  return currentFileList[findIndx];
                }
              }
              return url;
            }}
            transformImageUri={(url) => {
              if (url.startsWith('http')) {
                return url;
              }

              let findIndx = currentFileList.findIndex((path) => path.endsWith(url));
              if (findIndx !== -1) {
                return currentFileList[findIndx];
              }
              return url;
            }}
          />
        )}
        {urls.map((url, index) => {
          if (url.type === 'image') return <Image rootClassName="msg_image" key={index} src={url.url} width={200} />;
          else if (url.type === 'video') return <video controls key={index} src={url.url} width={200} />;
          else if (url.type === 'audio') return <audio controls key={index} src={url.url} width={200} />;
          else return <Image rootClassName="msg_image" key={index} src={url.url} width={200} />;
        })}
      </MessageBlockWrapper>
    </>
  );
};

export default MessageBlock;
