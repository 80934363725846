import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Col, Row, Typography, Button, Radio } from 'antd';
import moment from 'moment';
import CustomTable from 'src/components/common/CustomTable';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import { customDayOfWeek, getCurrentToken } from 'src/utils';
import { useQueryProxy } from 'src/operations/proxy/user';
import * as queries from 'src/operations/queries';
import StudentHomeworkMenuGroup from 'src/components/common/StudentHomeworkMenuGroup';

const { Title } = Typography;

const plusUrl = [
  ['xfcb', 'lxgt', 'wycn', 'hwsk', 'obet', 'tphy'],
  ['kyrc', 'hbag', 'tnac', 'jndw', 'dmvl', 'ypor'],
  ['irsb', 'zapo', 'wqbo', 'crzm', 'ewqh', 'ojux'],
  ['hdso', 'qhug', 'gwfo', 'gdtj', 'wnay', 'mkcf'],
  ['nrea', 'njht', 'jwxm', 'akxg', 'cunt', 'edyw'],
  ['hilj', 'jhqr', 'qmth', 'skjn', 'lims', 'empz'],
];

const KTPURLS = ['brna', 'xlow', 'btkc', 'bsoq', 'yokj', 'aqry'];
const HPHURLS = ['cewf', 'rqpf', 'ctxb', 'zegi'];
const VTURLS = [["yrao",	"crnm"],	["aftn",	"tcew"],	["goqa",	"unyf"],	["brel",	"afks"],	["ldtg",	"xgqt"],	["vewz",	"alxk"],]
const options = [
  { label: '진행중', value: true },
  { label: '종료', value: false },
];

function ClassRoomStudent({ isAfterSchool,companyName, gotoPhonics = false }) {
  const token = getCurrentToken();
  const [inProgress, setInProgress] = useState(true);
  const { data, loading: getClassLoading } = useQuery(queries.getClass.GET_TYPE_CLASS, {
    fetchPolicy: 'no-cache',
  });
  const { data: userData } = useQueryProxy(queries.getUser.USER_ME, { variables: { token } });
  console.log('userData', userData);

  const columns = [
    {
      title: 'No',
      dataIndex: 'No',
      key: 'No',
      align: 'center',
      width: '100px',
    },
    {
      title: 'Class(반명)',
      dataIndex: 'className',
      key: 'className',
      align: 'center',
      sorter: (a, b) => a.className.localeCompare(b.className),
      render: (text, record) => {
        return <Link to={`/study-result/${record.key}`}>{text} </Link>;
      },
    },
    {
      title: '강사',
      dataIndex: 'teacher',
      key: 'teacher',
      align: 'center',
      sorter: (a, b) => a.teacher && a.teacher.localeCompare(b.teacher),
      render: (text, record) => {
        return <Link to={`/study-result/${record.key}`}>{text} </Link>;
      },
    },
    {
      title: '요일',
      dataIndex: 'yoil',
      key: 'yoil',
      align: 'center',
    },
    {
      title: '시간',
      dataIndex: 'time',
      key: 'time',
      align: 'center',
    },
    {
      title: '시작일',
      dataIndex: 'start_date',
      key: 'start_date',
      align: 'center',
      sorter: (a, b) => a.start_date && a.start_date.localeCompare(b.start_date),
    },
    {
      title: '종료일',
      dataIndex: 'end_date',
      key: 'end_date',
      align: 'center',
      sorter: (a, b) => a.end_date && a.end_date.localeCompare(b.end_date),
    },
    {
      title: userData?.me?.company_name === 'creo' ? 'CREO BOT' : '학습결과보기',
      key: 'action',
      align: 'center',
      render: (text, record) => (
        <>
          {' '}
          {record.book_code && (
            <Button>
              <Link to={`/study-result/${text.key}`}>View </Link>
            </Button>
          )}
        </>
      ),
    },
  ];

  const realColumns = useMemo(() => {
    return !isAfterSchool
      ? !gotoPhonics
        ? columns
        : [
            ...columns,
            {
              title: 'Phonics Result',
              key: 'phonics_result',
              align: 'center',
              render: (text, record) => (
                <Button>
                  <Link to={`/mypage/results-class/${text.key}/${userData?.me?.idx}`}>View </Link>
                </Button>
              ),
              //
            },
          ]
      : [
          ...columns,
          {
            title: 'ebook보기',
            key: 'ebook',
            align: 'center',
            render: (text, { lv, vol, book_code }) => {
              let url = '';
              if (!book_code) {
                url = '';
              } else {
                if (book_code.includes('LTR')) {
                  url = `https://online.flipbuilder.com/sangbong91/${plusUrl[lv - 1][vol - 1]}/`;
                } else if (book_code.includes('KTP')) {
                  url = `https://online.flipbuilder.com/sangbong91/${KTPURLS[vol - 1]}/`;
                } else if (book_code.includes('HPH')) {
                  url = `https://online.flipbuilder.com/sangbong91/${HPHURLS[vol - 1]}/`;
                } else if (book_code.includes('VT')) {
                  url = `https://online.flipbuilder.com/sangbong91/${VTURLS[lv - 1][vol - 1]}/`;
                } else if (book_code.includes('TT')) {
                  url = `http://ebook.theclassenglish.com/SB/TalkingTap/TalkingTap_L${lv}_V${vol}_STUDENT/`;
                } else if (parseInt(lv) === 0) {
                  if (parseInt(vol) < 7) {
                    url = `http://coco-phonics.p-turn.com/v${vol}/`;
                  } else {
                    url = `http://ebook.theclassenglish.com/SB/SuperCocoPhonics/SCP_Vol${parseInt(vol) - 6}/`;
                  }
                } else {
                  url = `http://ebook.theclassenglish.com/SB/Sneakers/Sneakers_SB_L${lv}_V${vol}/html5forpc.html`;
                }
              }
              return (
                <>
                  {book_code && (
                    <Button
                      onClick={() => {
                        window.open(url, '_blank', 'width=1400, height=900');
                      }}
                    >
                      ebook
                    </Button>
                  )}
                </>
              );
            },
            //
          },
        ];
  }, [isAfterSchool]);

  const classList = useMemo(() => {
    if (data) {
      return data.classList
        .reduce((acc, cur, index) => {
          const dayOfWeek = customDayOfWeek(cur);

          const obj = {
            key: cur.idx,
            No: index + 1,
            className: cur.name,
            teacher: cur.class_teacher[0]?.user?.name,
            yoil: dayOfWeek,
            time: !cur.begin_time || !cur.end_time ? '종일' : `${cur.begin_time} ~ ${cur.end_time}`,
            start_date: cur?.begin_date && moment(cur?.begin_date).format('YYYY-MM-DD'),
            end_date: cur?.end_date && moment(cur?.end_date).format('YYYY-MM-DD'),
            lv: cur?.book?.level_no,
            vol: cur?.book?.volume_no,
            book_code: cur?.book?.code || cur?.book_code,
          };

          return [...acc, obj];
        }, [])
        .filter((item) => {
          if (!inProgress) {
            if (item.end_date && moment().isAfter(item.end_date)) {
              return true;
            }
            return false;
          } else {
            if (!item.end_date || !moment().isAfter(item.end_date)) {
              return true;
            }
            return false;
          }
        });
    }
    return [];
  }, [data, inProgress]);
  return (
    <>
      {/* <HeaderTitle level={4}>My Class</HeaderTitle> */}
      {companyName==='intoreading'?<div style={{height:'30px'}}></div>:<StudentHomeworkMenuGroup currentMenu="homework" />}
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper className="tblclass-list-height">
            <div
              className="header-classroom"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 10,
              }}
            >
              <Title className="header-classroom-title" level={5}>
                Homework Result
              </Title>
              <Radio.Group
                options={options}
                optionType="button"
                value={inProgress}
                buttonStyle="solid"
                onChange={(e) => {
                  setInProgress(e.target.value);
                }}
              />
            </div>

            <CustomTable
              className="classroom-table-table"
              loading={getClassLoading}
              dataSource={classList}
              columns={realColumns}
              pagination={{ pageSize: 12, position: ['bottomCenter'] }}
              size="small"
              scroll={{ y: 'calc(100vh - 254px)' }}
              color="#edf3fb"
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    //console.log('record.key', record.key);
                  }, // click row
                };
              }}
            />
          </HalfWrapper>
        </Col>
      </Row>
    </>
  );
}

export default ClassRoomStudent;
