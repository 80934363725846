const bookInfo = [
  {
    bookName: '1a',
    bookCode: 'PM-L1V1',
    validation: {
      purchaseCode: 'someRandomCode',
      accessToken: 'short-lived-access-token',
    },
    unit: {
      1: {
        title: 'Single Letters - Aa • Bb • Cc',
        stage: {
          1: {
            title: 'Sound Animation',
            shortDescption: 'Sound Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S1_Dir.mp3',
            url: 'https://player.vimeo.com/video/409617405',
            type: 'video',
          },
          2: {
            title: 'Drag and drop.',
            shortDescption: 'Learn the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S2_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imageDndAlphabet',
              problem: [
                {
                  imageAlphabet: 'https://cdn.cloubot.com/PM/image/level1/Aa.png',
                  imageWord: 'https://cdn.cloubot.com/PM/image/level1/image_ant.png',
                  mp3: 'https://cdn.cloubot.com/PM/audio/sounds/a_ant.mp3',
                  alphabet: 'a',
                  word: 'ant',
                },
                {
                  imageAlphabet: 'https://cdn.cloubot.com/PM/image/level1/Bb.png',
                  imageWord: 'https://cdn.cloubot.com/PM/image/level1/image_ball.png',
                  mp3: 'https://cdn.cloubot.com/PM/audio/sounds/b_ball.mp3',
                  alphabet: 'b',
                  word: 'ball',
                },
                {
                  imageAlphabet: 'https://cdn.cloubot.com/PM/image/level1/Cc.png',
                  imageWord: 'https://cdn.cloubot.com/PM/image/level1/image_car.png',
                  mp3: 'https://cdn.cloubot.com/PM/audio/sounds/c_car.mp3',
                  alphabet: 'c',
                  word: 'car',
                },
              ],
            },
          },
          3: {
            title: 'Listen and choose.',
            shortDescption: 'Practice the Sounds I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S3_Dir.mp3',
            type: 'imgSelect',
            customData: {
              type: 'imgAlphabetSelectSound',
              problem: [
                {
                  qSound: 'ant',
                  exImage: [
                    {
                      image: 'https://cdn.cloubot.com/PM/image/level1/Aa.png',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level1/image_ant.png',
                      alphabet: 'a',
                      isAnswer: true,
                    },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Bb.png', alphabet: 'b', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Cc.png', alphabet: 'c', isAnswer: false },
                  ],
                },
                {
                  qSound: 'ball',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Aa.png', alphabet: 'a', isAnswer: false },
                    {
                      image: 'https://cdn.cloubot.com/PM/image/level1/Bb.png',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level1/image_ball.png',
                      alphabet: 'b',
                      isAnswer: true,
                    },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Cc.png', alphabet: 'c', isAnswer: false },
                  ],
                },
                {
                  qSound: 'car',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Aa.png', alphabet: 'a', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Bb.png', alphabet: 'b', isAnswer: false },
                    {
                      image: 'https://cdn.cloubot.com/PM/image/level1/Cc.png',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level1/image_car.png',
                      alphabet: 'c',
                      isAnswer: true,
                    },
                  ],
                },
              ],
            },
          },
          4: {
            title: 'Word Animation',
            shortDescption: 'Word Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S4_Dir.mp3',
            url: 'https://player.vimeo.com/video/409618311',
            type: 'video',
          },
          5: {
            title: 'Drag and drop the word.',
            shortDescption: 'Practice the Words I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S5_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'multiWordDndWrite',
              problem: [
                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_ant.png', word: 'ant' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_alligator.png', word: 'alligator' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_apple.png', word: 'apple' },
                  ],
                  words: ['ant', 'alligator', 'apple'],
                },
                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_ball.png', word: 'ball' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_book.png', word: 'book' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_bear.png', word: 'bear' },
                  ],
                  words: ['ball', 'book', 'bear'],
                },
                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_car.png', word: 'car' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_cow.png', word: 'cow' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_cat.png', word: 'cat' },
                  ],
                  words: ['car', 'cow', 'cat'],
                },
              ],
            },
          },
          6: {
            title: 'Listen and choose the picture.',
            shortDescption: 'Practice the Sounds II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S6_Dir.mp3',
            type: 'imgSelect',
            customData: {
              type: 'imgSelectSound',
              problem: [
                {
                  qSound: 'alligator',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_alligator.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_ant.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_apple.png', isAnswer: false },
                  ],
                },
                {
                  qSound: 'ball',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_bear.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_book.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_ball.png', isAnswer: true },
                  ],
                },
                {
                  qSound: 'cow',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_car.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_cow.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_cat.png', isAnswer: false },
                  ],
                },
              ],
            },
          },
          7: {
            title: 'Listen and choose. Then type the letter.',
            shortDescption: 'Practice the Words II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S7_Dir.mp3',
            type: 'write',
            customData: {
              type: 'imgSelectWrite',
              problem: [
                {
                  letter: 'a',
                  question:'nt',
                  rightSound: 'ant.mp3',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_ant.png', sound: 'ant.mp3', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_book.png', sound: 'book.mp3', isAnswer: false },
                  ],
                },
                {
                  letter: 'c',
                  rightSound: 'cow.mp3',
                  question:'ow',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_bear.png', sound: 'bear.mp3', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_cow.png', sound: 'cow.mp3', isAnswer: true },
                  ],
                },
                {
                  letter: 'b',
                  rightSound: 'book.mp3',
                  question:'ook',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_car.png', sound: 'car.mp3', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_book.png', sound: 'book.mp3', isAnswer: true },
                  ],
                },
                {
                  letter: 'c',
                  rightSound: 'cat.mp3',
                  question:'at',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_cat.png', sound: 'cat.mp3', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_apple.png', sound: 'apple.mp3', isAnswer: false },
                  ],
                },
                {
                  letter: 'a',
                  rightSound: 'alligator.mp3',
                  question:'lligator',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_alligator.png', sound: 'alligator.mp3', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_ball.png', sound: 'ball.mp3', isAnswer: false },
                  ],
                },
                {
                  letter: 'b',
                  rightSound: 'bear.mp3',
                  question:'ear',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_ant.png', sound: 'ant.mp3', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_bear.png', sound: 'bear.mp3', isAnswer: true },
                  ],
                },

                {
                  letter: 'a',
                  rightSound: 'apple.mp3',
                  question:'pple',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_book.png', sound: 'book.mp3', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_apple.png', sound: 'apple.mp3', isAnswer: true },
                  ],
                },
                {
                  letter: 'c',
                  rightSound: 'car.mp3',
                  question:'ar',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_car.png', sound: 'car.mp3', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_alligator.png', sound: 'alligator.mp3', isAnswer: false },
                  ],
                },
                {
                  letter: 'b',
                  rightSound: 'ball.mp3',
                  question:'all',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_cow.png', sound: 'cow.mp3', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_ball.png', sound: 'ball.mp3', isAnswer: true },
                  ],
                },
              ],
            },
          },
          8: {
            title: 'Listen and repeat. Then record your voice.',
            shortDescption: 'Story',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S8_Dir.mp3',
            type: 'record',
            customData: {
              bgImage: 'https://cdn.cloubot.com/PM/image/l1_u1_bg_wide.png',
              sound: 'Lv1_U1_reading',
              sentences: ['An ##alligator## and an ##ant##.', 'A ##bear## and a ##ball##.', 'A ##cow## and a ##car##.'],
            },
          },
          9: {
            title: 'Story Chant',
            shortDescption: 'Story Chant',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S9_Dir.mp3',
            type: 'video',
            url: 'https://player.vimeo.com/video/409618137',
          },
          10: {
            title: 'Read. Then drag and drop.',
            shortDescption: 'Story Activity',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S10_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imgDnd3',
              problem: {
                question: [
                  { image: 'https://cdn.cloubot.com/PM/image/level1_unit01_SB_01.png', sentence: ['An ##alligator## is for the ##ant##.'] },
                  { image: 'https://cdn.cloubot.com/PM/image/level1_unit01_SB_02.png', sentence: ['A ##ball## is for the ##bear##.'] },
                  { image: 'https://cdn.cloubot.com/PM/image/level1_unit01_SB_03.png', sentence: ['A ##car## is for the ##cow##.'] },
                  { image: 'https://cdn.cloubot.com/PM/image/level1_unit01_SB_04.png', sentence: ['What is for me?'] },
                ],
              },
              totalCount: 4,
            },
          },
          11: {
            title: 'Find the hidden picture. Then, drag and drop.',
            shortDescption: 'Picture Activity',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S11_Dir.mp3',
            type: 'hidden',
            bgHiddenImage1: 'https://cdn.cloubot.com/PM/image/L1_U1_hidden_picture_1.png',
            bgHiddenImage2: 'https://cdn.cloubot.com/PM/image/L1_U1_hidden_picture_2.png',
            problem: [
              {
                isFrontLetter: true,
                letter: 'a',
                word: 'apple',
                image: 'https://cdn.cloubot.com/PM/image/level1/apple_h.png',
                coords: { top: '38%', left: '11%' },
              },
              {
                isFrontLetter: true,
                letter: 'c',
                word: 'cat',
                image: 'https://cdn.cloubot.com/PM/image/level1/cat_h.png',
                coords: { top: '62%', left: '46%' },
              },
              {
                isFrontLetter: true,
                letter: 'b',
                word: 'ball',
                image: 'https://cdn.cloubot.com/PM/image/level1/ball_h.png',
                coords: { top: '74%', left: '91%' },
              },
              {
                isFrontLetter: true,
                letter: 'a',
                word: 'ant',
                image: 'https://cdn.cloubot.com/PM/image/level1/ant_h.png',
                coords: { top: '24%', left: '25%' },
              },
              {
                isFrontLetter: true,
                letter: 'b',
                word: 'book',
                image: 'https://cdn.cloubot.com/PM/image/level1/book_h.png',
                coords: { top: '11%', left: '81%' },
              },
              {
                isFrontLetter: true,
                letter: 'c',
                word: 'car',
                image: 'https://cdn.cloubot.com/PM/image/level1/car_h.png',
                coords: { top: '92%', left: '71%' },
              },
            ],
          },
          12: {
            title: 'Listen and type the word.',
            shortDescption: 'Quiz',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S12_Dir.mp3',
            type: 'unitTest',
            problem: [
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_ant.png', word: 'ant' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_alligator.png', word: 'alligator' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_apple.png', word: 'apple' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_ball.png', word: 'ball' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_book.png', word: 'book' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_bear.png', word: 'bear' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_car.png', word: 'car' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_cow.png', word: 'cow' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_cat.png', word: 'cat' },
            ],
          },
        },
      },
      2: {
        title: 'Single Letters - Dd • Ee • Ff',
        stage: {
          1: {
            title: 'Sound Animation',
            shortDescption: 'Sound Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S1_Dir.mp3',
            url: 'https://player.vimeo.com/video/409617434',
            type: 'video',
          },
          2: {
            title: 'Drag and drop.',
            shortDescption: 'Learn the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S2_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imageDndAlphabet',
              problem: [
                {
                  imageAlphabet: 'https://cdn.cloubot.com/PM/image/level1/Dd.png',
                  imageWord: 'https://cdn.cloubot.com/PM/image/level1/image_door.png',
                  mp3: 'https://cdn.cloubot.com/PM/audio/sounds/d_door.mp3',
                  alphabet: 'd',
                  word: 'door',
                },
                {
                  imageAlphabet: 'https://cdn.cloubot.com/PM/image/level1/Ee.png',
                  imageWord: 'https://cdn.cloubot.com/PM/image/level1/image_egg.png',
                  mp3: 'https://cdn.cloubot.com/PM/audio/sounds/e_egg.mp3',
                  alphabet: 'e',
                  word: 'egg',
                },
                {
                  imageAlphabet: 'https://cdn.cloubot.com/PM/image/level1/Ff.png',
                  imageWord: 'https://cdn.cloubot.com/PM/image/level1/image_fish.png',
                  mp3: 'https://cdn.cloubot.com/PM/audio/sounds/f_fish.mp3',
                  alphabet: 'f',
                  word: 'fish',
                },
              ],
            },
          },
          3: {
            title: 'Listen and choose.',
            shortDescption: 'Practice the Sounds I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S3_Dir.mp3',
            type: 'imgSelect',
            customData: {
              type: 'imgAlphabetSelectSound',
              problem: [
                {
                  qSound: 'door',
                  exImage: [
                    {
                      image: 'https://cdn.cloubot.com/PM/image/level1/Dd.png',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level1/image_door.png',
                      alphabet: 'd',
                      isAnswer: true,
                    },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Ee.png', alphabet: 'e', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Ff.png', alphabet: 'f', isAnswer: false },
                  ],
                },
                {
                  qSound: 'egg',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Dd.png', alphabet: 'd', isAnswer: false },
                    {
                      image: 'https://cdn.cloubot.com/PM/image/level1/Ee.png',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level1/image_egg.png',
                      alphabet: 'e',
                      isAnswer: true,
                    },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Ff.png', alphabet: 'f', isAnswer: false },
                  ],
                },
                {
                  qSound: 'fish',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Dd.png', alphabet: 'd', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Ee.png', alphabet: 'e', isAnswer: false },
                    {
                      image: 'https://cdn.cloubot.com/PM/image/level1/Ff.png',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level1/image_fish.png',
                      alphabet: 'f',
                      isAnswer: true,
                    },
                  ],
                },
              ],
            },
          },
          4: {
            title: 'Word Animation',
            shortDescption: 'Word Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S4_Dir.mp3',
            url: 'https://player.vimeo.com/video/409618339',
            type: 'video',
          },
          5: {
            title: 'Drag and drop the word.',
            shortDescption: 'Practice the Words I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S5_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'multiWordDndWrite',
              problem: [
                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_door.png', word: 'door' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_dog.png', word: 'dog' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_desk.png', word: 'desk' },
                  ],
                  words: ['door', 'dog', 'desk'],
                },
                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_egg.png', word: 'egg' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_elephant.png', word: 'elephant' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_elbow.png', word: 'elbow' },
                  ],
                  words: ['egg', 'elephant', 'elbow'],
                },
                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_fish.png', word: 'fish' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_five.png', word: 'five' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_fox.png', word: 'fox' },
                  ],
                  words: ['fish', 'five', 'fox'],
                },
              ],
            },
          },
          6: {
            title: 'Listen and choose the picture.',
            shortDescption: 'Practice the Sounds II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S6_Dir.mp3',
            type: 'imgSelect',
            customData: {
              type: 'imgSelectSound',
              problem: [
                {
                  qSound: 'door',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_door.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_egg.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_five.png', isAnswer: false },
                  ],
                },
                {
                  qSound: 'elbow',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_desk.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_fish.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_elbow.png', isAnswer: true },
                  ],
                },
                {
                  qSound: 'fox',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_elephant.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_fox.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_dog.png', isAnswer: false },
                  ],
                },
              ],
            },
          },
          7: {
            title: 'Listen and choose. Then type the letter.',
            shortDescption: 'Practice the Words II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S7_Dir.mp3',
            type: 'write',
            customData: {
              type: 'imgSelectWrite',
              problem: [
                {
                  letter: 'd',
                  rightSound: 'desk.mp3',
                  question:'esk',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_desk.png', sound: 'desk.mp3', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_elephant.png', sound: 'elephant.mp3', isAnswer: false },
                  ],
                },
                {
                  letter: 'e',
                  rightSound: 'elbow.mp3',
                  question:'lbow',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_five.png', sound: 'five.mp3', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_elbow.png', sound: 'elbow.mp3', isAnswer: true },
                  ],
                },
                {
                  letter: 'f',
                  rightSound: 'fish.mp3',
                  question:'ish',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_dog.png', sound: 'dog.mp3', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_fish.png', sound: 'fish.mp3', isAnswer: true },
                  ],
                },
                {
                  letter: 'd',
                  rightSound: 'door.mp3',
                  question:'oor',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_door.png', sound: 'door.mp3', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_egg.png', sound: 'egg.mp3', isAnswer: false },
                  ],
                },
                {
                  letter: 'f',
                  rightSound: 'fox.mp3',
                  question:'ox',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_fox.png', sound: 'fox.mp3', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_desk.png', sound: 'desk.mp3', isAnswer: false },
                  ],
                },
                {
                  letter: 'd',
                  rightSound: 'dog.mp3',
                  question:'og',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_elbow.png', sound: 'elbow.mp3', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_dog.png', sound: 'dog.mp3', isAnswer: true },
                  ],
                },
                {
                  letter: 'e',
                  rightSound: 'egg.mp3',
                  question:'gg',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_fish.png', sound: 'fish.mp3', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_egg.png', sound: 'egg.mp3', isAnswer: true },
                  ],
                },
                {
                  letter: 'f',
                  rightSound: 'five.mp3',
                  question:'ive',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_five.png', sound: 'five.mp3', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_door.png', sound: 'door.mp3', isAnswer: false },
                  ],
                },
                {
                  letter: 'e',
                  rightSound: 'elephant.mp3',
                  question:'lephant',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_door.png', sound: 'door.mp3', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_elephant.png', sound: 'elephant.mp3', isAnswer: true },
                  ],
                },
              ],
            },
          },
          8: {
            title: 'Listen and repeat. Then record your voice.',
            shortDescption: 'Story',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S8_Dir.mp3',
            type: 'record',
            customData: {
              bgImage: 'https://cdn.cloubot.com/PM/image/l1_u2_bg_wide.png',
              sound: 'Lv1_U2_reading',
              sentences: ['##Dog## to the ##desk##.', '##Elephant## to the ##egg##.', '##Fox## to the ##five## ##fish##.'],
            },
          },
          9: {
            title: 'Story Chant',
            shortDescption: 'Story Chant',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S9_Dir.mp3',
            type: 'video',
            url: 'https://player.vimeo.com/video/409618160',
          },
          10: {
            title: 'Read. Then drag and drop.',
            shortDescption: 'Story Activity',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S10_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imgDnd3',
              problem: {
                question: [
                  { image: 'https://cdn.cloubot.com/PM/image/level1_unit02_SB_01.png', sentence: ['A ##dog## is after a ##duck##.'] },
                  { image: 'https://cdn.cloubot.com/PM/image/level1_unit02_SB_02.png', sentence: ['An ##elephant## is after an ##egg##.'] },
                  { image: 'https://cdn.cloubot.com/PM/image/level1_unit02_SB_03.png', sentence: ['A ##fox## is after a ##fish##.'] },
                  { image: 'https://cdn.cloubot.com/PM/image/level1_unit02_SB_04.png', sentence: ['I am hungry.'] },
                ],
              },
              totalCount: 4,
            },
          },
          11: {
            title: 'Find the hidden picture. Then, drag and drop.',
            shortDescption: 'Picture Activity',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S11_Dir.mp3',
            type: 'hidden',
            bgHiddenImage1: 'https://cdn.cloubot.com/PM/image/L1_U2_hidden_picture_1.png',
            bgHiddenImage2: 'https://cdn.cloubot.com/PM/image/L1_U2_hidden_picture_2.png',
            problem: [
              {
                isFrontLetter: true,
                letter: 'd',
                word: 'desk',
                image: 'https://cdn.cloubot.com/PM/image/level1/desk_h.png',
                coords: { top: '12%', left: '79%' },
              },
              {
                isFrontLetter: true,
                letter: 'f',
                word: 'fish',
                image: 'https://cdn.cloubot.com/PM/image/level1/fish_h.png',
                coords: { top: '39%', left: '89%' },
              },
              {
                isFrontLetter: true,
                letter: 'd',
                word: 'door',
                image: 'https://cdn.cloubot.com/PM/image/level1/door_h.png',
                coords: { top: '66%', left: '87%' },
              },
              {
                isFrontLetter: true,
                letter: 'd',
                word: 'dog',
                image: 'https://cdn.cloubot.com/PM/image/level1/dog_h.png',
                coords: { top: '43%', left: '13%' },
              },
              {
                isFrontLetter: true,
                letter: 'f',
                word: 'five',
                image: 'https://cdn.cloubot.com/PM/image/level1/five_h.png',
                coords: { top: '61%', left: '65%' },
              },
              {
                isFrontLetter: true,
                letter: 'e',
                word: 'egg',
                image: 'https://cdn.cloubot.com/PM/image/level1/egg_h.png',
                coords: { top: '22%', left: '31%' },
              },
            ],
          },
          12: {
            title: 'Listen and type the word.',
            shortDescption: 'Quiz',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S12_Dir.mp3',
            type: 'unitTest',
            problem: [
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_door.png', word: 'door' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_dog.png', word: 'dog' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_desk.png', word: 'desk' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_egg.png', word: 'egg' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_elephant.png', word: 'elephant' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_elbow.png', word: 'elbow' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_fish.png', word: 'fish' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_five.png', word: 'five' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_fox.png', word: 'fox' },
            ],
          },
        },
      },
      3: {
        title: 'Single Letters - Gg • Hh • Ii',
        stage: {
          1: {
            title: 'Sound Animation',
            shortDescption: 'Sound Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S1_Dir.mp3',
            url: 'https://player.vimeo.com/video/409617454',
            type: 'video',
          },
          2: {
            title: 'Drag and drop.',
            shortDescption: 'Learn the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S2_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imageDndAlphabet',
              problem: [
                {
                  imageAlphabet: 'https://cdn.cloubot.com/PM/image/level1/Gg.png',
                  imageWord: 'https://cdn.cloubot.com/PM/image/level1/image_green.png',
                  mp3: 'https://cdn.cloubot.com/PM/audio/sounds/g_green.mp3',
                  alphabet: 'g',
                  word: 'green',
                },
                {
                  imageAlphabet: 'https://cdn.cloubot.com/PM/image/level1/Hh.png',
                  imageWord: 'https://cdn.cloubot.com/PM/image/level1/image_hat.png',
                  mp3: 'https://cdn.cloubot.com/PM/audio/sounds/h_hat.mp3',
                  alphabet: 'h',
                  word: 'hat',
                },
                {
                  imageAlphabet: 'https://cdn.cloubot.com/PM/image/level1/Ii.png',
                  imageWord: 'https://cdn.cloubot.com/PM/image/level1/image_igloo.png',
                  mp3: 'https://cdn.cloubot.com/PM/audio/sounds/i_igloo.mp3',
                  alphabet: 'i',
                  word: 'igloo',
                },
              ],
            },
          },
          3: {
            title: 'Listen and choose.',
            shortDescption: 'Practice the Sounds I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S3_Dir.mp3',
            type: 'imgSelect',
            customData: {
              type: 'imgAlphabetSelectSound',
              problem: [
                {
                  qSound: 'green',
                  exImage: [
                    {
                      image: 'https://cdn.cloubot.com/PM/image/level1/Gg.png',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level1/image_green.png',
                      alphabet: 'g',
                      isAnswer: true,
                    },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Hh.png', alphabet: 'h', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Ii.png', alphabet: 'i', isAnswer: false },
                  ],
                },
                {
                  qSound: 'hat',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Gg.png', alphabet: 'g', isAnswer: false },
                    {
                      image: 'https://cdn.cloubot.com/PM/image/level1/Hh.png',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level1/image_hat.png',
                      alphabet: 'h',
                      isAnswer: true,
                    },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Ii.png', alphabet: 'i', isAnswer: false },
                  ],
                },
                {
                  qSound: 'igloo',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Gg.png', alphabet: 'g', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Hh.png', alphabet: 'h', isAnswer: false },
                    {
                      image: 'https://cdn.cloubot.com/PM/image/level1/Ii.png',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level1/image_igloo.png',
                      alphabet: 'i',
                      isAnswer: true,
                    },
                  ],
                },
              ],
            },
          },
          4: {
            title: 'Word Animation',
            shortDescption: 'Word Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S4_Dir.mp3',
            url: 'https://player.vimeo.com/video/409618357',
            type: 'video',
          },
          5: {
            title: 'Drag and drop the word.',
            shortDescption: 'Practice the Words I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S5_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'multiWordDndWrite',
              problem: [
                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_green.png', word: 'green' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_girl.png', word: 'girl' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_gorilla.png', word: 'gorilla' },
                  ],
                  words: ['green', 'girl', 'gorilla'],
                },
                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_hat.png', word: 'hat' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_hand.png', word: 'hand' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_house.png', word: 'house' },
                  ],
                  words: ['hat', 'hand', 'house'],
                },
                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_igloo.png', word: 'igloo' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_insect.png', word: 'insect' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_iguana.png', word: 'iguana' },
                  ],
                  words: ['igloo', 'insect', 'iguana'],
                },
              ],
            },
          },
          6: {
            title: 'Listen and choose the picture.',
            shortDescption: 'Practice the Sounds II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S6_Dir.mp3',
            type: 'imgSelect',
            customData: {
              type: 'imgSelectSound',
              problem: [
                {
                  qSound: 'green',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_green.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_hat.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_igloo.png', isAnswer: false },
                  ],
                },
                {
                  qSound: 'hand',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_girl.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_iguana.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_hand.png', isAnswer: true },
                  ],
                },
                {
                  qSound: 'insect',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_gorilla.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_insect.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_house.png', isAnswer: false },
                  ],
                },
              ],
            },
          },
          7: {
            title: 'Listen and choose. Then type the letter.',
            shortDescption: 'Practice the Words II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S7_Dir.mp3',
            type: 'write',
            customData: {
              type: 'imgSelectWrite',
              problem: [
                {
                  letter: 'h',
                  rightSound: 'hat.mp3',
                  question:'at',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_girl.png', sound: 'girl.mp3', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_hat.png', sound: 'hat.mp3', isAnswer: true },
                  ],
                },
                {
                  letter: 'g',
                  rightSound: 'girl.mp3',
                  question:'irl',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_house.png', sound: 'house.mp3', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_girl.png', sound: 'girl.mp3', isAnswer: true },
                  ],
                },
                {
                  letter: 'i',
                  rightSound: 'insect.mp3',
                  question:'nsect',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_insect.png', sound: 'insect.mp3', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_green.png', sound: 'green.mp3', isAnswer: false },
                  ],
                },
                {
                  letter: 'h',
                  rightSound: 'house.mp3',
                  question:'ouse',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_house.png', sound: 'house.mp3', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_igloo.png', sound: 'igloo.mp3', isAnswer: false },
                  ],
                },
                {
                  letter: 'g',
                  rightSound: 'gorilla.mp3',
                  question:'orilla',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_iguana.png', sound: 'iguana.mp3', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_gorilla.png', sound: 'gorilla.mp3', isAnswer: true },
                  ],
                },
                {
                  letter: 'i',
                  rightSound: 'igloo.mp3',
                  question:'gloo',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_igloo.png', sound: 'igloo.mp3', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_house.png', sound: 'house.mp3', isAnswer: false },
                  ],
                },
                {
                  letter: 'h',
                  rightSound: 'hand.mp3',
                  question:'and',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_green.png', sound: 'green.mp3', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_hand.png', sound: 'hand.mp3', isAnswer: true },
                  ],
                },
                {
                  letter: 'g',
                  rightSound: 'green.mp3',
                  question:'reen',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_hand.png', sound: 'hand.mp3', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_green.png', sound: 'green.mp3', isAnswer: true },
                  ],
                },
                {
                  letter: 'i',
                  rightSound: 'iguana.mp3',
                  question:'guana',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_iguana.png', sound: 'iguana.mp3', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_girl.png', sound: 'girl.mp3', isAnswer: false },
                  ],
                },
              ],
            },
          },
          8: {
            title: 'Listen and repeat. Then record your voice.',
            shortDescption: 'Story',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S8_Dir.mp3',
            type: 'record',
            customData: {
              bgImage: 'https://cdn.cloubot.com/PM/image/l1_u3_bg_wide.png',
              sound: 'Lv1_U3_reading',
              sentences: ['##Green## ##girl##? No!', '##Green## ##gorilla##? No!', '##Green## ##hat##? No!', '##Green## ##iguana##? Yes!'],
            },
          },
          9: {
            title: 'Story Chant',
            shortDescption: 'Story Chant',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S9_Dir.mp3',
            type: 'video',
            url: 'https://player.vimeo.com/video/409618175',
          },
          10: {
            title: 'Read. Then drag and drop.',
            shortDescption: 'Story Activity',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S10_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imgDnd3',
              problem: {
                question: [
                  { image: 'https://cdn.cloubot.com/PM/image/level1_unit03_SB_01.png', sentence: ['##Gorilla## is in the ##garden##.'] },
                  { image: 'https://cdn.cloubot.com/PM/image/level1_unit03_SB_02.png', sentence: ['##Hippo## is in the ##hut##.'] },
                  { image: 'https://cdn.cloubot.com/PM/image/level1_unit03_SB_03.png', sentence: ['##Indian## is in the ##igloo##.'] },
                  { image: 'https://cdn.cloubot.com/PM/image/level1_unit03_SB_04.png', sentence: ['Where am I?'] },
                ],
              },
              totalCount: 4,
            },
          },
          11: {
            title: 'Find the hidden picture. Then, drag and drop.',
            shortDescption: 'Picture Activity',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S11_Dir.mp3',
            type: 'hidden',
            bgHiddenImage1: 'https://cdn.cloubot.com/PM/image/L1_U3_hidden_picture_1.png',
            bgHiddenImage2: 'https://cdn.cloubot.com/PM/image/L1_U3_hidden_picture_2.png',
            problem: [
              {
                isFrontLetter: true,
                letter: 'i',
                word: 'igloo',
                image: 'https://cdn.cloubot.com/PM/image/level1/igloo_h.png',
                coords: { top: '21%', left: '61%' },
              },
              {
                isFrontLetter: true,
                letter: 'g',
                word: 'gorilla',
                image: 'https://cdn.cloubot.com/PM/image/level1/gorilla_h.png',
                coords: { top: '15%', left: '93%' },
              },
              {
                isFrontLetter: true,
                letter: 'h',
                word: 'hand',
                image: 'https://cdn.cloubot.com/PM/image/level1/hand_h.png',
                coords: { top: '85%', left: '4%' },
              },
              {
                isFrontLetter: true,
                letter: 'i',
                word: 'insect',
                image: 'https://cdn.cloubot.com/PM/image/level1/insect_h.png',
                coords: { top: '53%', left: '19%' },
              },
              {
                isFrontLetter: true,
                letter: 'h',
                word: 'hat',
                image: 'https://cdn.cloubot.com/PM/image/level1/hat_h.png',
                coords: { top: '81%', left: '84%' },
              },
              {
                isFrontLetter: true,
                letter: 'g',
                word: 'green',
                image: 'https://cdn.cloubot.com/PM/image/level1/green_h.png',
                coords: { top: '70%', left: '93%' },
              },
            ],
          },
          12: {
            title: 'Listen and type the word.',
            shortDescption: 'Quiz',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S12_Dir.mp3',
            type: 'unitTest',
            problem: [
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_green.png', word: 'green' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_girl.png', word: 'girl' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_gorilla.png', word: 'gorilla' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_hat.png', word: 'hat' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_hand.png', word: 'hand' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_house.png', word: 'house' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_igloo.png', word: 'igloo' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_insect.png', word: 'insect' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_iguana.png', word: 'iguana' },
            ],
          },
        },
      },
      4: {
        title: 'Single Letters - Jj • Kk • Ll',
        stage: {
          1: {
            title: 'Sound Animation',
            shortDescption: 'Sound Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S1_Dir.mp3',
            url: 'https://player.vimeo.com/video/409617479',
            type: 'video',
          },
          2: {
            title: 'Drag and drop.',
            shortDescption: 'Learn the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S2_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imageDndAlphabet',
              problem: [
                {
                  imageAlphabet: 'https://cdn.cloubot.com/PM/image/level1/Jj.png',
                  imageWord: 'https://cdn.cloubot.com/PM/image/level1/image_jet.png',
                  mp3: 'https://cdn.cloubot.com/PM/audio/sounds/j_jet.mp3',
                  alphabet: 'j',
                  word: 'jet',
                },
                {
                  imageAlphabet: 'https://cdn.cloubot.com/PM/image/level1/Kk.png',
                  imageWord: 'https://cdn.cloubot.com/PM/image/level1/image_kangaroo.png',
                  mp3: 'https://cdn.cloubot.com/PM/audio/sounds/k_kangaroo.mp3',
                  alphabet: 'k',
                  word: 'kangaroo',
                },
                {
                  imageAlphabet: 'https://cdn.cloubot.com/PM/image/level1/Ll.png',
                  imageWord: 'https://cdn.cloubot.com/PM/image/level1/image_lamp.png',
                  mp3: 'https://cdn.cloubot.com/PM/audio/sounds/l_lamp.mp3',
                  alphabet: 'l',
                  word: 'lamp',
                },
              ],
            },
          },
          3: {
            title: 'Listen and choose.',
            shortDescption: 'Practice the Sounds I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S3_Dir.mp3',
            type: 'imgSelect',
            customData: {
              type: 'imgAlphabetSelectSound',
              problem: [
                {
                  qSound: 'jet',
                  exImage: [
                    {
                      image: 'https://cdn.cloubot.com/PM/image/level1/Jj.png',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level1/image_jet.png',
                      alphabet: 'j',
                      isAnswer: true,
                    },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Kk.png', alphabet: 'k', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Ll.png', alphabet: 'l', isAnswer: false },
                  ],
                },
                {
                  qSound: 'kangaroo',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Jj.png', alphabet: 'j', isAnswer: false },
                    {
                      image: 'https://cdn.cloubot.com/PM/image/level1/Kk.png',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level1/image_kangaroo.png',
                      alphabet: 'k',
                      isAnswer: true,
                    },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Ll.png', alphabet: 'l', isAnswer: false },
                  ],
                },
                {
                  qSound: 'lamp',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Jj.png', alphabet: 'j', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Kk.png', alphabet: 'k', isAnswer: false },
                    {
                      image: 'https://cdn.cloubot.com/PM/image/level1/Ll.png',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level1/image_lamp.png',
                      alphabet: 'l',
                      isAnswer: true,
                    },
                  ],
                },
              ],
            },
          },
          4: {
            title: 'Word Animation',
            shortDescption: 'Word Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S4_Dir.mp3',
            url: 'https://player.vimeo.com/video/409618386',
            type: 'video',
          },
          5: {
            title: 'Drag and drop the word.',
            shortDescption: 'Practice the Words I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S5_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'multiWordDndWrite',
              problem: [
                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_jet.png', word: 'jet' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_jam.png', word: 'jam' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_jacket.png', word: 'jacket' },
                  ],
                  words: ['jet', 'jam', 'jacket'],
                },
                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_kangaroo.png', word: 'kangaroo' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_kid.png', word: 'kid' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_king.png', word: 'king' },
                  ],
                  words: ['kangaroo', 'kid', 'king'],
                },
                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_lamp.png', word: 'lamp' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_lion.png', word: 'lion' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_leaf.png', word: 'leaf' },
                  ],
                  words: ['lamp', 'lion', 'leaf'],
                },
              ],
            },
          },
          6: {
            title: 'Listen and choose the picture.',
            shortDescption: 'Practice the Sounds II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S6_Dir.mp3',
            type: 'imgSelect',
            customData: {
              type: 'imgSelectSound',
              problem: [
                {
                  qSound: 'jacket',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_jacket.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_kid.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_lamp.png', isAnswer: false },
                  ],
                },
                {
                  qSound: 'king',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_jet.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_leaf.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_king.png', isAnswer: true },
                  ],
                },
                {
                  qSound: 'lion',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_jam.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_lion.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_kangaroo.png', isAnswer: false },
                  ],
                },
              ],
            },
          },
          7: {
            title: 'Listen and choose. Then type the letter.',
            shortDescption: 'Practice the Words II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S7_Dir.mp3',
            type: 'write',
            customData: {
              type: 'imgSelectWrite',
              problem: [
                {
                  letter: 'j',
                  rightSound: 'jet.mp3',
                  question:'et',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_jet.png', sound: 'jet.mp3', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_king.png', sound: 'king.mp3', isAnswer: false },
                  ],
                },
                {
                  letter: 'k',
                  rightSound: 'kid.mp3',
                  question:'id',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_lion.png', sound: 'lion.mp3', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_kid.png', sound: 'kid.mp3', isAnswer: true },
                  ],
                },
                {
                  letter: 'l',
                  rightSound: 'lamp.mp3',
                  question:'amp',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_lamp.png', sound: 'lamp.mp3', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_jam.png', sound: 'jam.mp3', isAnswer: false },
                  ],
                },
                {
                  letter: 'j',
                  rightSound: 'jacket.mp3',
                  question:'acket',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_kangaroo.png', sound: 'kangaroo.mp3', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_jacket.png', sound: 'jacket.mp3', isAnswer: true },
                  ],
                },
                {
                  letter: 'l',
                  rightSound: 'lion.mp3',
                  question:'ion',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_lion.png', sound: 'lion.mp3', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_kid.png', sound: 'kid.mp3', isAnswer: false },
                  ],
                },
                {
                  letter: 'l',
                  rightSound: 'leaf.mp3',
                  question:'eaf',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_jet.png', sound: 'jet.mp3', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_leaf.png', sound: 'leaf.mp3', isAnswer: true },
                  ],
                },
                {
                  letter: 'j',
                  rightSound: 'jam.mp3',
                  question:'am',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_jam.png', sound: 'jam.mp3', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_lion.png', sound: 'lion.mp3', isAnswer: false },
                  ],
                },
                {
                  letter: 'k',
                  rightSound: 'king.mp3',
                  question:'ing',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_jacket.png', sound: 'jacket.mp3', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_king.png', sound: 'king.mp3', isAnswer: true },
                  ],
                },
                {
                  letter: 'k',
                  rightSound: 'kangaroo.mp3',
                  question:'angaroo',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_kangaroo.png', sound: 'kangaroo.mp3', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_lamp.png', sound: 'lamp.mp3', isAnswer: false },
                  ],
                },
              ],
            },
          },
          8: {
            title: 'Listen and repeat. Then record your voice.',
            shortDescption: 'Story',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S8_Dir.mp3',
            type: 'record',
            customData: {
              bgImage: 'https://cdn.cloubot.com/PM/image/l1_u4_bg_wide.png',
              sound: 'Lv1_U4_reading',
              sentences: ['Ride the ##jet##, ##kid##.', 'Ride the ##kangaroo##, ##kid##.', 'Ride the ##lion##, ##kid##.'],
            },
          },
          9: {
            title: 'Story Chant',
            shortDescption: 'Story Chant',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S9_Dir.mp3',
            type: 'video',
            url: 'https://player.vimeo.com/video/409618197',
          },
          10: {
            title: 'Read. Then drag and drop.',
            shortDescption: 'Story Activity',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S10_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imgDnd3',
              problem: {
                question: [
                  { image: 'https://cdn.cloubot.com/PM/image/level1_unit04_SB_01.png', sentence: ['##Jack## is on the ##jet##.'] },
                  { image: 'https://cdn.cloubot.com/PM/image/level1_unit04_SB_02.png', sentence: ['##Ken## is on the ##kangaroo##.'] },
                  { image: 'https://cdn.cloubot.com/PM/image/level1_unit04_SB_03.png', sentence: ['##Lisa## is on the ##lion##.'] },
                  { image: 'https://cdn.cloubot.com/PM/image/level1_unit04_SB_04.png', sentence: ['They are in the park.'] },
                ],
              },
              totalCount: 4,
            },
          },
          11: {
            title: 'Find the hidden picture. Then, drag and drop.',
            shortDescption: 'Picture Activity',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S11_Dir.mp3',
            type: 'hidden',
            bgHiddenImage1: 'https://cdn.cloubot.com/PM/image/L1_U4_hidden_picture_1.png',
            bgHiddenImage2: 'https://cdn.cloubot.com/PM/image/L1_U4_hidden_picture_2.png',
            problem: [
              {
                isFrontLetter: true,
                letter: 'j',
                word: 'jet',
                image: 'https://cdn.cloubot.com/PM/image/level1/jet_h.png',
                coords: { top: '69%', left: '82%' },
              },
              {
                isFrontLetter: true,
                letter: 'k',
                word: 'king',
                image: 'https://cdn.cloubot.com/PM/image/level1/king_h.png',
                coords: { top: '76%', left: '21%' },
              },
              {
                isFrontLetter: true,
                letter: 'l',
                word: 'lamp',
                image: 'https://cdn.cloubot.com/PM/image/level1/lamp_h.png',
                coords: { top: '84%', left: '71%' },
              },
              {
                isFrontLetter: true,
                letter: 'l',
                word: 'lion',
                image: 'https://cdn.cloubot.com/PM/image/level1/lion_h.png',
                coords: { top: '87%', left: '43%' },
              },
              {
                isFrontLetter: true,
                letter: 'j',
                word: 'jam',
                image: 'https://cdn.cloubot.com/PM/image/level1/jam_h.png',
                coords: { top: '11%', left: '34%' },
              },
              {
                isFrontLetter: true,
                letter: 'l',
                word: 'leaf',
                image: 'https://cdn.cloubot.com/PM/image/level1/leaf_h.png',
                coords: { top: '45%', left: '10%' },
              },
            ],
          },
          12: {
            title: 'Listen and type the word.',
            shortDescption: 'Quiz',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S12_Dir.mp3',
            type: 'unitTest',
            problem: [
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_jet.png', word: 'jet' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_jam.png', word: 'jam' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_jacket.png', word: 'jacket' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_kangaroo.png', word: 'kangaroo' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_kid.png', word: 'kid' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_king.png', word: 'king' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_lamp.png', word: 'lamp' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_lion.png', word: 'lion' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_leaf.png', word: 'leaf' },
            ],
          },
        },
      },
      5: {
        title: 'Review - Unit 1~Unit 4',
        stage: {
          1: {
            title: 'Listen and repeat.',
            shortDescption: 'Review the Sounds I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_Review_S1_Dir.mp3',
            type: 'imageSound',
            problem: [
              {
                image: 'https://cdn.cloubot.com/PM/image/level1/Review_1_1.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/Lv1_Unit5_Stage1_1.mp3',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level1/Review_1_2.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/Lv1_Unit5_Stage1_2.mp3',
              },
            ],
          },
          2: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Review the Words I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_Review_S2_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imgDnd4',
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level1/image_apple.png',
                  rightWord: 'apple',
                  alphabet: 'Aa',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level1/image_cow.png',
                    'https://cdn.cloubot.com/PM/image/level1/image_apple.png',
                    'https://cdn.cloubot.com/PM/image/level1/image_book.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level1/image_fish.png',
                  rightWord: 'fish',
                  alphabet: 'Ff',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level1/image_fish.png',
                    'https://cdn.cloubot.com/PM/image/level1/image_cow.png',
                    'https://cdn.cloubot.com/PM/image/level1/image_elbow.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level1/image_girl.png',
                  rightWord: 'girl',
                  alphabet: 'Gg',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level1/image_hand.png',
                    'https://cdn.cloubot.com/PM/image/level1/image_elbow.png',
                    'https://cdn.cloubot.com/PM/image/level1/image_girl.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level1/image_desk.png',
                  rightWord: 'desk',
                  alphabet: 'Dd',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level1/image_bear.png',
                    'https://cdn.cloubot.com/PM/image/level1/image_desk.png',
                    'https://cdn.cloubot.com/PM/image/level1/image_green.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level1/image_elephant.png',
                  rightWord: 'elephant',
                  alphabet: 'Ee',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level1/image_alligator.png',
                    'https://cdn.cloubot.com/PM/image/level1/image_iguana.png',
                    'https://cdn.cloubot.com/PM/image/level1/image_elephant.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level1/image_ball.png',
                  rightWord: 'ball',
                  alphabet: 'Bb',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level1/image_ball.png',
                    'https://cdn.cloubot.com/PM/image/level1/image_door.png',
                    'https://cdn.cloubot.com/PM/image/level1/image_gorilla.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level1/image_cat.png',
                  rightWord: 'cat',
                  alphabet: 'Cc',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level1/image_girl.png',
                    'https://cdn.cloubot.com/PM/image/level1/image_cat.png',
                    'https://cdn.cloubot.com/PM/image/level1/image_hat.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level1/image_house.png',
                  rightWord: 'house',
                  alphabet: 'Hh',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level1/image_house.png',
                    'https://cdn.cloubot.com/PM/image/level1/image_bear.png',
                    'https://cdn.cloubot.com/PM/image/level1/image_cow.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level1/image_igloo.png',
                  rightWord: 'igloo',
                  alphabet: 'Ii',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level1/image_igloo.png',
                    'https://cdn.cloubot.com/PM/image/level1/image_egg.png',
                    'https://cdn.cloubot.com/PM/image/level1/image_five.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level1/image_lion.png',
                  rightWord: 'lion',
                  alphabet: 'Ll',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level1/image_jam.png',
                    'https://cdn.cloubot.com/PM/image/level1/image_insect.png',
                    'https://cdn.cloubot.com/PM/image/level1/image_lion.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level1/image_king.png',
                  rightWord: 'king',
                  alphabet: 'Kk',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level1/image_apple.png',
                    'https://cdn.cloubot.com/PM/image/level1/image_king.png',
                    'https://cdn.cloubot.com/PM/image/level1/image_cow.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level1/image_jacket.png',
                  rightWord: 'jacket',
                  alphabet: 'Jj',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level1/image_lamp.png',
                    'https://cdn.cloubot.com/PM/image/level1/image_dog.png',
                    'https://cdn.cloubot.com/PM/image/level1/image_jacket.png',
                  ],
                },
              ],
            },
          },
          3: {
            title: 'Look and listen. Then choose.',
            shortDescption: 'Review the Words II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_Review_S3_Dir.mp3',
            type: 'imgSelect',
            customData: {
              type: 'imgSelectImg', //imgSelectImg, imgSelectWord

              problem: [
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level1/image_elephant.png',
                  qWord: 'elephant',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_igloo.png', word: 'igloo', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_elbow.png', word: 'elbow', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_alligator.png', word: 'alligator', isAnswer: false },
                  ],
                },
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level1/image_five.png',
                  qWord: 'five',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_fish.png', word: 'fish', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_green.png', word: 'green', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_book.png', word: 'book', isAnswer: false },
                  ],
                },
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level1/image_book.png',
                  qWord: 'book',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_hand.png', word: 'hand', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_elbow.png', word: 'elbow', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_bear.png', word: 'bear', isAnswer: true },
                  ],
                },
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level1/image_ant.png',
                  qWord: 'ant',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_insect.png', word: 'insect', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_apple.png', word: 'apple', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_lion.png', word: 'lion', isAnswer: false },
                  ],
                },
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level1/image_door.png',
                  qWord: 'door',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_house.png', word: 'house', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_green.png', word: 'green', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_dog.png', word: 'dog', isAnswer: true },
                  ],
                },
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level1/image_gorilla.png',
                  qWord: 'gorilla',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_ball.png', word: 'ball', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_jam.png', word: 'jam', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_girl.png', word: 'girl', isAnswer: true },
                  ],
                },
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level1/image_iguana.png',
                  qWord: 'iguana',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_lion.png', word: 'lion', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_insect.png', word: 'insect', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_kid.png', word: 'kid', isAnswer: false },
                  ],
                },
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level1/image_hand.png',
                  qWord: 'hand',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_house.png', word: 'house', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_cow.png', word: 'cow', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_fox.png', word: 'fox', isAnswer: false },
                  ],
                },
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level1/image_car.png',
                  qWord: 'car',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_cat.png', word: 'cat', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_egg.png', word: 'egg', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_lamp.png', word: 'lamp', isAnswer: false },
                  ],
                },
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level1/image_leaf.png',
                  qWord: 'leaf',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_kangaroo.png', word: 'kangaroo', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_insect.png', word: 'insect', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_lion.png', word: 'lion', isAnswer: true },
                  ],
                },
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level1/image_jet.png',
                  qWord: 'jet',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_desk.png', word: 'desk', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_jam.png', word: 'jam', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_cow.png', word: 'cow', isAnswer: false },
                  ],
                },
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level1/image_kangaroo.png',
                  qWord: 'kangaroo',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_lion.png', word: 'lion', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_fox.png', word: 'fox', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_kid.png', word: 'kid', isAnswer: true },
                  ],
                },
              ],
            },
          },
          4: {
            title: 'Look and color.',
            shortDescption: 'Coloring Activity',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_Review_S4_Dir.mp3',
            type: 'color',
            problem: ['aA', 'Db', 'Kk', 'Jj', 'dB', 'Ii', 'Hh', 'gL', 'Ej', 'Ff'],
            answer: ['aA', 'Kk', 'Jj', 'Ii', 'Hh', 'Ff'],
          },
          5: {
            title: 'Look and choose the picture that has the same beginning sound.',
            shortDescption: 'Review the Sounds II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_Review_S5_Dir.mp3',
            type: 'imgSelect',
            customData: {
              type: 'imgSelectWord', //imgSelectImg, imgSelectWord
              problem: [
                {
                  qWord: 'Ee',
                  direction: 'none',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_ant.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_kid.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_egg.png', isAnswer: true },
                  ],
                },
                {
                  qWord: 'Ff',
                  direction: 'none',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_lion.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_fox.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_bear.png', isAnswer: false },
                  ],
                },
                {
                  qWord: 'Hh',
                  direction: 'none',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_cow.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_ball.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_hand.png', isAnswer: true },
                  ],
                },
                {
                  qWord: 'Dd',
                  direction: 'none',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_door.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_book.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_girl.png', isAnswer: false },
                  ],
                },
                {
                  qWord: 'Aa',
                  direction: 'none',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_desk.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_egg.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_alligator.png', isAnswer: true },
                  ],
                },
                {
                  qWord: 'Bb',
                  direction: 'none',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_bear.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_dog.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_gorilla.png', isAnswer: false },
                  ],
                },
                {
                  qWord: 'Cc',
                  direction: 'none',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_elbow.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_cow.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_hat.png', isAnswer: false },
                  ],
                },
                {
                  qWord: 'Gg',
                  direction: 'none',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_king.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_house.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_girl.png', isAnswer: true },
                  ],
                },
                {
                  qWord: 'Ii',
                  direction: 'none',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_insect.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_leaf.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_kid.png', isAnswer: false },
                  ],
                },

                {
                  qWord: 'Ll',
                  direction: 'none',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_girl.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_lion.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_desk.png', isAnswer: false },
                  ],
                },
                {
                  qWord: 'Kk',
                  direction: 'none',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_bear.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_five.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_king.png', isAnswer: true },
                  ],
                },
                {
                  qWord: 'Jj',
                  direction: 'none',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_jam.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_green.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_cat.png', isAnswer: false },
                  ],
                },
              ],
            },
          },
          6: {
            title: 'Look and choose the beginning sound.',
            shortDescption: 'Review the Sounds III',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_Review_S6_Dir.mp3',
            type: 'imgSelect',
            customData: {
              type: 'selectWord',
              problem: [
                {
                  image: 'https://cdn.cloubot.com/PM/image/level1/image_jam.png',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/jam.mp3',
                  words: [
                    { name: 'a', isAnswer: false },
                    { name: 'j', isAnswer: true },
                    { name: 'k', isAnswer: false },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level1/image_ant.png',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/ant.mp3',
                  words: [
                    { name: 'A', isAnswer: true },
                    { name: 'E', isAnswer: false },
                    { name: 'L', isAnswer: false },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level1/image_house.png',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/house.mp3',
                  words: [
                    { name: 'c', isAnswer: false },
                    { name: 'd', isAnswer: false },
                    { name: 'h', isAnswer: true },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level1/image_bear.png',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/bear.mp3',
                  words: [
                    { name: 'G', isAnswer: false },
                    { name: 'B', isAnswer: true },
                    { name: 'D', isAnswer: false },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level1/image_dog.png',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/dog.mp3',
                  words: [
                    { name: 'e', isAnswer: false },
                    { name: 'f', isAnswer: false },
                    { name: 'd', isAnswer: true },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level1/image_green.png',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/green.mp3',
                  words: [
                    { name: 'K', isAnswer: false },
                    { name: 'G', isAnswer: true },
                    { name: 'C', isAnswer: false },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level1/image_elbow.png',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/elbow.mp3',
                  words: [
                    { name: 'E', isAnswer: true },
                    { name: 'F', isAnswer: false },
                    { name: 'G', isAnswer: false },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level1/image_hand.png',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/hand.mp3',
                  words: [
                    { name: 'L', isAnswer: false },
                    { name: 'H', isAnswer: true },
                    { name: 'K', isAnswer: false },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level1/image_car.png',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/car.mp3',
                  words: [
                    { name: 'D', isAnswer: false },
                    { name: 'A', isAnswer: false },
                    { name: 'C', isAnswer: true },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level1/image_leaf.png',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/leaf.mp3',
                  words: [
                    { name: 'g', isAnswer: false },
                    { name: 'l', isAnswer: true },
                    { name: 'b', isAnswer: false },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level1/image_jet.png',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/jet.mp3',
                  words: [
                    { name: 'j', isAnswer: true },
                    { name: 'k', isAnswer: false },
                    { name: 'c', isAnswer: false },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level1/image_kangaroo.png',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/kangaroo.mp3',
                  words: [
                    { name: 'h', isAnswer: false },
                    { name: 'a', isAnswer: false },
                    { name: 'k', isAnswer: true },
                  ],
                },
              ],
            },
          },
          7: {
            title: 'Look, choose, and type.',
            shortDescption: 'Review the Words III',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_Review_S7_Dir.mp3',
            type: 'write',
            customData: {
              type: 'imageWordSelectWrite',
              problem: [
                {
                  image: 'https://cdn.cloubot.com/PM/image/level1/image_lion.png',
                  answer: 'Ll',
                  exWords: ['Jj', 'Ll'],
                  isForceLowerCase: false,
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level1/image_hat.png',
                  answer: 'Hh',
                  exWords: ['Hh', 'Ee'],
                  isForceLowerCase: false,
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level1/image_apple.png',
                  answer: 'Aa',
                  exWords: ['Aa', 'Ii'],
                  isForceLowerCase: false,
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level1/image_book.png',
                  answer: 'Bb',
                  exWords: ['Dd', 'Bb'],
                  isForceLowerCase: false,
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level1/image_jacket.png',
                  answer: 'Jj',
                  exWords: ['Jj', 'Hh'],
                  isForceLowerCase: false,
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level1/image_green.png',
                  answer: 'Gg',
                  exWords: ['Cc', 'Gg'],
                  isForceLowerCase: false,
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level1/image_elbow.png',
                  answer: 'Ee',
                  exWords: ['Ee', 'Ff'],
                  isForceLowerCase: false,
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level1/image_door.png',
                  answer: 'Dd',
                  exWords: ['Ff', 'Dd'],
                  isForceLowerCase: false,
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level1/image_car.png',
                  answer: 'Cc',
                  exWords: ['Jj', 'Cc'],
                  isForceLowerCase: false,
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level1/image_five.png',
                  answer: 'Ff',
                  exWords: ['Bb', 'Ff'],
                  isForceLowerCase: false,
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level1/image_insect.png',
                  answer: 'Ii',
                  exWords: ['Ii', 'Ll'],
                  isForceLowerCase: false,
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level1/image_kid.png',
                  answer: 'Kk',
                  exWords: ['Kk', 'Ee'],
                  isForceLowerCase: false,
                },
              ],
            },
          },
          8: {
            title: 'Look and type the letters.',
            shortDescption: 'Review the Sounds IV',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_Review_S8_Dir.mp3',
            type: 'write',
            customData: {
              type: 'imageAlphabetWrite',
              problem: [
                { image: 'https://cdn.cloubot.com/PM/image/level1/image_king.png', answer: 'Kk' },
                { image: 'https://cdn.cloubot.com/PM/image/level1/image_bear.png', answer: 'Bb' },
                { image: 'https://cdn.cloubot.com/PM/image/level1/image_lamp.png', answer: 'Ll' },
                { image: 'https://cdn.cloubot.com/PM/image/level1/image_cat.png', answer: 'Cc' },
                { image: 'https://cdn.cloubot.com/PM/image/level1/image_ant.png', answer: 'Aa' },
                { image: 'https://cdn.cloubot.com/PM/image/level1/image_jet.png', answer: 'Jj' },
                { image: 'https://cdn.cloubot.com/PM/image/level1/image_desk.png', answer: 'Dd' },
                { image: 'https://cdn.cloubot.com/PM/image/level1/image_egg.png', answer: 'Ee' },
                { image: 'https://cdn.cloubot.com/PM/image/level1/image_car.png', answer: 'Cc' },
                { image: 'https://cdn.cloubot.com/PM/image/level1/image_fish.png', answer: 'Ff' },
                { image: 'https://cdn.cloubot.com/PM/image/level1/image_hand.png', answer: 'Hh' },
                { image: 'https://cdn.cloubot.com/PM/image/level1/image_iguana.png', answer: 'Ii' },
              ],
            },
          },
        },
      },
    },
  },
  {
    bookName: '1b',
    bookCode: 'PM-L2V1',
    validation: {
      purchaseCode: 'someRandomCode',
      accessToken: 'short-lived-access-token',
    },

    unit: {
      6: {
        title: 'Single Letters - Mm • Nn • Oo',
        stage: {
          1: {
            title: 'Sound Animation',
            shortDescption: 'Sound Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S1_Dir.mp3',
            url: 'https://player.vimeo.com/video/409617495',
            type: 'video',
          },
          2: {
            title: 'Drag and drop.',
            shortDescption: 'Learn the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S2_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imageDndAlphabet',
              problem: [
                {
                  imageAlphabet: 'https://cdn.cloubot.com/PM/image/level1/Mm.png',
                  imageWord: 'https://cdn.cloubot.com/PM/image/level1/image_monster.png',
                  mp3: 'https://cdn.cloubot.com/PM/audio/sounds/m_monster.mp3',
                  alphabet: 'm',
                  word: 'monster',
                },
                {
                  imageAlphabet: 'https://cdn.cloubot.com/PM/image/level1/Nn.png',
                  imageWord: 'https://cdn.cloubot.com/PM/image/level1/image_nest.png',
                  mp3: 'https://cdn.cloubot.com/PM/audio/sounds/n_nest.mp3',
                  alphabet: 'n',
                  word: 'nest',
                },
                {
                  imageAlphabet: 'https://cdn.cloubot.com/PM/image/level1/Oo.png',
                  imageWord: 'https://cdn.cloubot.com/PM/image/level1/image_ox.png',
                  mp3: 'https://cdn.cloubot.com/PM/audio/sounds/o_ox.mp3',
                  alphabet: 'o',
                  word: 'ox',
                },
              ],
            },
          },
          3: {
            title: 'Listen and choose.',
            shortDescption: 'Practice the Sounds I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S3_Dir.mp3',
            type: 'imgSelect',
            customData: {
              type: 'imgAlphabetSelectSound',
              problem: [
                {
                  qSound: 'monster',
                  exImage: [
                    {
                      image: 'https://cdn.cloubot.com/PM/image/level1/Mm.png',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level1/image_monster.png',
                      alphabet: 'm',
                      isAnswer: true,
                    },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Nn.png', alphabet: 'n', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Oo.png', alphabet: 'o', isAnswer: false },
                  ],
                },
                {
                  qSound: 'nest',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Mm.png', alphabet: 'm', isAnswer: false },
                    {
                      image: 'https://cdn.cloubot.com/PM/image/level1/Nn.png',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level1/image_nest.png',
                      alphabet: 'n',
                      isAnswer: true,
                    },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Oo.png', alphabet: 'o', isAnswer: false },
                  ],
                },
                {
                  qSound: 'ox',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Mm.png', alphabet: 'm', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Nn.png', alphabet: 'n', isAnswer: false },
                    {
                      image: 'https://cdn.cloubot.com/PM/image/level1/Oo.png',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level1/image_ox.png',
                      alphabet: 'o',
                      isAnswer: true,
                    },
                  ],
                },
              ],
            },
          },
          4: {
            title: 'Word Animation',
            shortDescption: 'Word Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S4_Dir.mp3',
            url: 'https://player.vimeo.com/video/409618405',
            type: 'video',
          },
          5: {
            title: 'Drag and drop the word.',
            shortDescption: 'Practice the Words I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S5_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'multiWordDndWrite',
              problem: [
                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_monster.png', word: 'monster' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_mouse.png', word: 'mouse' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_map.png', word: 'map' },
                  ],
                  words: ['monster', 'mouse', 'map'],
                },
                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_nest.png', word: 'nest' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_nut.png', word: 'nut' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_neck.png', word: 'neck' },
                  ],
                  words: ['nest', 'nut', 'neck'],
                },
                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_ox.png', word: 'ox' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_octopus.png', word: 'octopus' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_ostrich.png', word: 'ostrich' },
                  ],
                  words: ['ox', 'octopus', 'ostrich'],
                },
              ],
            },
          },
          6: {
            title: 'Listen and choose the picture.',
            shortDescption: 'Practice the Sounds II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S6_Dir.mp3',
            type: 'imgSelect',
            customData: {
              type: 'imgSelectSound',
              problem: [
                {
                  qSound: 'mouse',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_mouse.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_nest.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_octopus.png', isAnswer: false },
                  ],
                },
                {
                  qSound: 'nut',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_map.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_ox.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_nut.png', isAnswer: true },
                  ],
                },
                {
                  qSound: 'ostrich',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_monster.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_ostrich.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_neck.png', isAnswer: false },
                  ],
                },
              ],
            },
          },
          7: {
            title: 'Listen and choose. Then type the letter.',
            shortDescption: 'Practice the Words II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S7_Dir.mp3',
            type: 'write',
            customData: {
              type: 'imgSelectWrite',
              problem: [
                {
                  letter: 'n',
                  rightSound: 'neck.mp3',
                  question:'eck',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_ox.png', sound: 'ox.mp3', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_neck.png', sound: 'neck.mp3', isAnswer: true },
                  ],
                },
                {
                  letter: 'o',
                  rightSound: 'octopus.mp3',
                  question:'ctopus',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_octopus.png', sound: 'octopus.mp3', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_nut.png', sound: 'nut.mp3', isAnswer: false },
                  ],
                },
                {
                  letter: 'm',
                  rightSound: 'monster.mp3',
                  question:'onster',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_ostrich.png', sound: 'ostrich.mp3', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_monster.png', sound: 'monster.mp3', isAnswer: true },
                  ],
                },
                {
                  letter: 'n',
                  rightSound: 'nut.mp3',
                  question:'ut',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_nut.png', sound: 'nut.mp3', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_monster.png', sound: 'monster.mp3', isAnswer: false },
                  ],
                },
                {
                  letter: 'o',
                  rightSound: 'ox.mp3',
                  question:'x',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_ox.png', sound: 'ox.mp3', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_map.png', sound: 'map.mp3', isAnswer: false },
                  ],
                },
                {
                  letter: 'm',
                  rightSound: 'mouse.mp3',
                  question:'ouse',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_nut.png', sound: 'nut.mp3', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_mouse.png', sound: 'mouse.mp3', isAnswer: true },
                  ],
                },
                {
                  letter: 'o',
                  rightSound: 'ostrich.mp3',
                  question:'strich',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_ostrich.png', sound: 'ostrich.mp3', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_nut.png', sound: 'nut.mp3', isAnswer: false },
                  ],
                },
                {
                  letter: 'n',
                  rightSound: 'nest.mp3',
                  question:'est',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_monster.png', sound: 'monster.mp3', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_nest.png', sound: 'nest.mp3', isAnswer: true },
                  ],
                },
                {
                  letter: 'm',
                  rightSound: 'map.mp3',
                  question:'ap',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_map.png', sound: 'map.mp3', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_neck.png', sound: 'neck.mp3', isAnswer: false },
                  ],
                },
              ],
            },
          },
          8: {
            title: 'Listen and repeat. Then record your voice.',
            shortDescption: 'Story',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S8_Dir.mp3',
            type: 'record',
            customData: {
              bgImage: 'https://cdn.cloubot.com/PM/image/l1_u6_bg_wide.png',
              sound: 'Lv1_U6_reading',
              sentences: ['##Octopus## wants the eggs.', '##Ox## wants the eggs.', '##Mouse## wants the ##nuts##.'],
            },
          },
          9: {
            title: 'Story Chant',
            shortDescption: 'Story Chant',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S9_Dir.mp3',
            type: 'video',
            url: 'https://player.vimeo.com/video/409682664',
          },
          10: {
            title: 'Read. Then drag and drop.',
            shortDescption: 'Story Activity',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S10_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imgDnd3',
              problem: {
                question: [
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level1_unit06_SB_01.png',
                    sentence: ['Here is my ##monster##.', 'Its face looks like a ##mouse##.'],
                  },
                  { image: 'https://cdn.cloubot.com/PM/image/level1_unit06_SB_02.png', sentence: ['Its neck looks like ##noodles##.'] },
                  { image: 'https://cdn.cloubot.com/PM/image/level1_unit06_SB_03.png', sentence: ['Its body looks like an ##octopus##.'] },
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level1_unit06_SB_04.png',
                    sentence: ['Here is my ##monster##.', 'I like my ##monster##.'],
                  },
                ],
              },
              totalCount: 4,
            },
          },
          11: {
            title: 'Find the hidden picture. Then, drag and drop.',
            shortDescption: 'Picture Activity',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S11_Dir.mp3',
            type: 'hidden',
            bgHiddenImage1: 'https://cdn.cloubot.com/PM/image/L1_U6_hidden_picture_1.png',
            bgHiddenImage2: 'https://cdn.cloubot.com/PM/image/L1_U6_hidden_picture_2.png',
            problem: [
              {
                isFrontLetter: true,
                letter: 'n',
                word: 'neck',
                image: 'https://cdn.cloubot.com/PM/image/level1/neck_h.png',
                coords: { top: '7%', left: '90%' },
              },
              {
                isFrontLetter: true,
                letter: 'n',
                word: 'nest',
                image: 'https://cdn.cloubot.com/PM/image/level1/nest_h.png',
                coords: { top: '57%', left: '96%' },
              },
              {
                isFrontLetter: true,
                letter: 'n',
                word: 'nut',
                image: 'https://cdn.cloubot.com/PM/image/level1/nut_h.png',
                coords: { top: '73%', left: '30%' },
              },
              {
                isFrontLetter: true,
                letter: 'm',
                word: 'mouse',
                image: 'https://cdn.cloubot.com/PM/image/level1/mouse_h.png',
                coords: { top: '88%', left: '71%' },
              },
              {
                isFrontLetter: true,
                letter: 'o',
                word: 'ox',
                image: 'https://cdn.cloubot.com/PM/image/level1/ox_h.png',
                coords: { top: '20%', left: '5%' },
              },
              {
                isFrontLetter: true,
                letter: 'o',
                word: 'ostrich',
                image: 'https://cdn.cloubot.com/PM/image/level1/ostrich_h.png',
                coords: { top: '67%', left: '13%' },
              },
            ],
          },
          12: {
            title: 'Listen and type the word.',
            shortDescption: 'Quiz',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S12_Dir.mp3',
            type: 'unitTest',
            problem: [
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_monster.png', word: 'monster' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_mouse.png', word: 'mouse' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_map.png', word: 'map' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_nest.png', word: 'nest' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_nut.png', word: 'nut' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_neck.png', word: 'neck' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_ox.png', word: 'ox' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_octopus.png', word: 'octopus' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_ostrich.png', word: 'ostrich' },
            ],
          },
        },
      },
      7: {
        title: 'Single Letters - Pp • Qq • Rr',
        stage: {
          1: {
            title: 'Sound Animation',
            shortDescption: 'Sound Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S1_Dir.mp3',
            url: 'https://player.vimeo.com/video/409617523',
            type: 'video',
          },
          2: {
            title: 'Drag and drop.',
            shortDescption: 'Learn the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S2_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imageDndAlphabet',
              problem: [
                {
                  imageAlphabet: 'https://cdn.cloubot.com/PM/image/level1/Pp.png',
                  imageWord: 'https://cdn.cloubot.com/PM/image/level1/image_pen.png',
                  mp3: 'https://cdn.cloubot.com/PM/audio/sounds/p_pen.mp3',
                  alphabet: 'p',
                  word: 'pen',
                },
                {
                  imageAlphabet: 'https://cdn.cloubot.com/PM/image/level1/Qq.png',
                  imageWord: 'https://cdn.cloubot.com/PM/image/level1/image_question.png',
                  mp3: 'https://cdn.cloubot.com/PM/audio/sounds/q_question.mp3',
                  alphabet: 'q',
                  word: 'question',
                },
                {
                  imageAlphabet: 'https://cdn.cloubot.com/PM/image/level1/Rr.png',
                  imageWord: 'https://cdn.cloubot.com/PM/image/level1/image_robot.png',
                  mp3: 'https://cdn.cloubot.com/PM/audio/sounds/r_robot.mp3',
                  alphabet: 'r',
                  word: 'robot',
                },
              ],
            },
          },
          3: {
            title: 'Listen and choose.',
            shortDescption: 'Practice the Sounds I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S3_Dir.mp3',
            type: 'imgSelect',
            customData: {
              type: 'imgAlphabetSelectSound',
              problem: [
                {
                  qSound: 'pen',
                  exImage: [
                    {
                      image: 'https://cdn.cloubot.com/PM/image/level1/Pp.png',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level1/image_pen.png',
                      alphabet: 'p',
                      isAnswer: true,
                    },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Qq.png', alphabet: 'q', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Rr.png', alphabet: 'r', isAnswer: false },
                  ],
                },
                {
                  qSound: 'question',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Pp.png', alphabet: 'p', isAnswer: false },
                    {
                      image: 'https://cdn.cloubot.com/PM/image/level1/Qq.png',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level1/image_question.png',
                      alphabet: 'q',
                      isAnswer: true,
                    },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Rr.png', alphabet: 'r', isAnswer: false },
                  ],
                },
                {
                  qSound: 'robot',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Pp.png', alphabet: 'p', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Qq.png', alphabet: 'q', isAnswer: false },
                    {
                      image: 'https://cdn.cloubot.com/PM/image/level1/Rr.png',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level1/image_robot.png',
                      alphabet: 'r',
                      isAnswer: true,
                    },
                  ],
                },
              ],
            },
          },
          4: {
            title: 'Word Animation',
            shortDescption: 'Word Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S4_Dir.mp3',
            url: 'https://player.vimeo.com/video/409618433',
            type: 'video',
          },
          5: {
            title: 'Drag and drop the word.',
            shortDescption: 'Practice the Words I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S5_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'multiWordDndWrite',
              problem: [
                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_pen.png', word: 'pen' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_pig.png', word: 'pig' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_pizza.png', word: 'pizza' },
                  ],
                  words: ['pen', 'pig', 'pizza'],
                },
                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_question.png', word: 'question' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_queen.png', word: 'queen' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_quiet.png', word: 'quiet' },
                  ],
                  words: ['question', 'queen', 'quiet'],
                },
                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_robot.png', word: 'robot' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_ring.png', word: 'ring' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_rabbit.png', word: 'rabbit' },
                  ],
                  words: ['robot', 'ring', 'rabbit'],
                },
              ],
            },
          },
          6: {
            title: 'Listen and choose the picture.',
            shortDescption: 'Practice the Sounds II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S6_Dir.mp3',
            type: 'imgSelect',
            customData: {
              type: 'imgSelectSound',
              problem: [
                {
                  qSound: 'pen',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_pen.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_question.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_robot.png', isAnswer: false },
                  ],
                },
                {
                  qSound: 'quiet',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_ring.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_pig.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_quiet.png', isAnswer: true },
                  ],
                },
                {
                  qSound: 'rabbit',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_pizza.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_rabbit.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_queen.png', isAnswer: false },
                  ],
                },
              ],
            },
          },
          7: {
            title: 'Listen and choose. Then type the letter.',
            shortDescption: 'Practice the Words II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S7_Dir.mp3',
            type: 'write',
            customData: {
              type: 'imgSelectWrite',
              problem: [
                {
                  letter: 'p',
                  rightSound: 'pen.mp3',
                  question:'en',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_pen.png', sound: 'pen.mp3', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_question.png', sound: 'question.mp3', isAnswer: false },
                  ],
                },
                {
                  letter: 'r',
                  rightSound: 'rabbit.mp3',
                  question:'abbit',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_rabbit.png', sound: 'rabbit.mp3', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_pizza.png', sound: 'pizza.mp3', isAnswer: false },
                  ],
                },

                {
                  letter: 'q',
                  rightSound: 'question.mp3',
                  question:'uestion',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_pizza.png', sound: 'pizza.mp3', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_question.png', sound: 'question.mp3', isAnswer: true },
                  ],
                },
                {
                  letter: 'r',
                  rightSound: 'ring.mp3',
                  question:'ing',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_ring.png', sound: 'ring.mp3', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_quiet.png', sound: 'quiet.mp3', isAnswer: false },
                  ],
                },
                {
                  letter: 'q',
                  rightSound: 'queen.mp3',
                  question:'ueen',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_queen.png', sound: 'queen.mp3', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_rabbit.png', sound: 'rabbit.mp3', isAnswer: false },
                  ],
                },
                {
                  letter: 'p',
                  rightSound: 'pizza.mp3',
                  question:'izza',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_robot.png', sound: 'robot.mp3', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_pizza.png', sound: 'pizza.mp3', isAnswer: true },
                  ],
                },
                {
                  letter: 'r',
                  rightSound: 'robot.mp3',
                  question:'obot',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_robot.png', sound: 'robot.mp3', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_question.png', sound: 'question.mp3', isAnswer: false },
                  ],
                },
                {
                  letter: 'q',
                  rightSound: 'quiet.mp3',
                  question:'uiet',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_pen.png', sound: 'pen.mp3', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_quiet.png', sound: 'quiet.mp3', isAnswer: true },
                  ],
                },
                {
                  letter: 'p',
                  rightSound: 'pig.mp3',
                  question:'ig',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_pig.png', sound: 'pig.mp3', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_ring.png', sound: 'ring.mp3', isAnswer: false },
                  ],
                },
              ],
            },
          },
          8: {
            title: 'Listen and repeat. Then record your voice.',
            shortDescption: 'Story',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S8_Dir.mp3',
            type: 'record',
            customData: {
              bgImage: 'https://cdn.cloubot.com/PM/image/l1_u7_bg_wide.png',
              sound: 'Lv1_U7_reading',
              sentences: ['A ##pen## for ##Pig##? Yes.', 'A ##ring## for ##Robot##? Yes.', 'A ##question## for ##Queen##?'],
            },
          },
          9: {
            title: 'Story Chant',
            shortDescption: 'Story Chant',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S9_Dir.mp3',
            type: 'video',
            url: 'https://player.vimeo.com/video/409682627',
          },
          10: {
            title: 'Read. Then drag and drop.',
            shortDescption: 'Story Activity',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S10_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imgDnd3',
              problem: {
                question: [
                  { image: 'https://cdn.cloubot.com/PM/image/level1_unit07_SB_01.png', sentence: ['Christmas time has come.'] },
                  { image: 'https://cdn.cloubot.com/PM/image/level1_unit07_SB_02.png', sentence: ['A ##pig## wants a ##pen##.'] },
                  { image: 'https://cdn.cloubot.com/PM/image/level1_unit07_SB_03.png', sentence: ['A ##queen## wants a ##quilt##.'] },
                  { image: 'https://cdn.cloubot.com/PM/image/level1_unit07_SB_04.png', sentence: ['A ##robot## wants a ##ring##.'] },
                ],
              },
              totalCount: 4,
            },
          },
          11: {
            title: 'Find the hidden picture. Then, drag and drop.',
            shortDescption: 'Picture Activity',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S11_Dir.mp3',
            type: 'hidden',
            bgHiddenImage1: 'https://cdn.cloubot.com/PM/image/L1_U7_hidden_picture_1.png',
            bgHiddenImage2: 'https://cdn.cloubot.com/PM/image/L1_U7_hidden_picture_2.png',
            problem: [
              {
                isFrontLetter: true,
                letter: 'p',
                word: 'pizza',
                image: 'https://cdn.cloubot.com/PM/image/level1/pizza_h.png',
                coords: { top: '50%', left: '89%' },
              },
              {
                isFrontLetter: true,
                letter: 'r',
                word: 'ring',
                image: 'https://cdn.cloubot.com/PM/image/level1/ring_h.png',
                coords: { top: '50%', left: '68%' },
              },
              {
                isFrontLetter: true,
                letter: 'r',
                word: 'robot',
                image: 'https://cdn.cloubot.com/PM/image/level1/robot_h.png',
                coords: { top: '92%', left: '13%' },
              },
              {
                isFrontLetter: true,
                letter: 'p',
                word: 'pig',
                image: 'https://cdn.cloubot.com/PM/image/level1/pig_h.png',
                coords: { top: '68%', left: '12%' },
              },
              {
                isFrontLetter: true,
                letter: 'p',
                word: 'pen',
                image: 'https://cdn.cloubot.com/PM/image/level1/pen_h.png',
                coords: { top: '31%', left: '25%' },
              },
              {
                isFrontLetter: true,
                letter: 'q',
                word: 'queen',
                image: 'https://cdn.cloubot.com/PM/image/level1/queen_h.png',
                coords: { top: '78%', left: '80%' },
              },
            ],
          },
          12: {
            title: 'Listen and type the word.',
            shortDescption: 'Quiz',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S12_Dir.mp3',
            type: 'unitTest',
            problem: [
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_pen.png', word: 'pen' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_pig.png', word: 'pig' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_pizza.png', word: 'pizza' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_question.png', word: 'question' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_queen.png', word: 'queen' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_quiet.png', word: 'quiet' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_robot.png', word: 'robot' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_ring.png', word: 'ring' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_rabbit.png', word: 'rabbit' },
            ],
          },
        },
      },
      8: {
        title: 'Single Letters - Ss • Tt • Uu • Vv',
        stage: {
          1: {
            title: 'Sound Animation',
            shortDescption: 'Sound Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S1_Dir.mp3',
            url: 'https://player.vimeo.com/video/409617539',
            type: 'video',
          },
          2: {
            title: 'Drag and drop.',
            shortDescption: 'Learn the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S2_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imageDndAlphabet',
              problem: [
                {
                  imageAlphabet: 'https://cdn.cloubot.com/PM/image/level1/Ss.png',
                  imageWord: 'https://cdn.cloubot.com/PM/image/level1/image_sun.png',
                  mp3: 'https://cdn.cloubot.com/PM/audio/sounds/s_sun.mp3',
                  alphabet: 's',
                  word: 'sun',
                },
                {
                  imageAlphabet: 'https://cdn.cloubot.com/PM/image/level1/Tt.png',
                  imageWord: 'https://cdn.cloubot.com/PM/image/level1/image_tiger.png',
                  mp3: 'https://cdn.cloubot.com/PM/audio/sounds/t_tiger.mp3',
                  alphabet: 't',
                  word: 'tiger',
                },
                {
                  imageAlphabet: 'https://cdn.cloubot.com/PM/image/level1/Uu.png',
                  imageWord: 'https://cdn.cloubot.com/PM/image/level1/image_up.png',
                  mp3: 'https://cdn.cloubot.com/PM/audio/sounds/u_up.mp3',
                  alphabet: 'u',
                  word: 'up',
                },
                {
                  imageAlphabet: 'https://cdn.cloubot.com/PM/image/level1/Vv.png',
                  imageWord: 'https://cdn.cloubot.com/PM/image/level1/image_van.png',
                  mp3: 'https://cdn.cloubot.com/PM/audio/sounds/v_van.mp3',
                  alphabet: 'v',
                  word: 'van',
                },
              ],
            },
          },
          3: {
            title: 'Listen and choose.',
            shortDescption: 'Practice the Sounds I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S3_Dir.mp3',
            type: 'imgSelect',
            customData: {
              type: 'imgAlphabetSelectSound',
              problem: [
                {
                  qSound: 'sun',
                  exImage: [
                    {
                      image: 'https://cdn.cloubot.com/PM/image/level1/Ss.png',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level1/image_sun.png',
                      alphabet: 's',
                      isAnswer: true,
                    },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Tt.png', alphabet: 't', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Uu.png', alphabet: 'u', isAnswer: false },
                  ],
                },
                {
                  qSound: 'tiger',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Ss.png', alphabet: 's', isAnswer: false },
                    {
                      image: 'https://cdn.cloubot.com/PM/image/level1/Tt.png',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level1/image_tiger.png',
                      alphabet: 't',
                      isAnswer: true,
                    },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Uu.png', alphabet: 'u', isAnswer: false },
                  ],
                },
                {
                  qSound: 'up',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Tt.png', alphabet: 't', isAnswer: false },
                    {
                      image: 'https://cdn.cloubot.com/PM/image/level1/Uu.png',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level1/image_up.png',
                      alphabet: 'u',
                      isAnswer: true,
                    },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Vv.png', alphabet: 'v', isAnswer: false },
                  ],
                },
                {
                  qSound: 'van',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Tt.png', alphabet: 't', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Uu.png', alphabet: 'u', isAnswer: false },
                    {
                      image: 'https://cdn.cloubot.com/PM/image/level1/Vv.png',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level1/image_van.png',
                      alphabet: 'v',
                      isAnswer: true,
                    },
                  ],
                },
              ],
            },
          },
          4: {
            title: 'Word Animation',
            shortDescption: 'Word Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S4_Dir.mp3',
            url: 'https://player.vimeo.com/video/409618461',
            type: 'video',
          },
          5: {
            title: 'Drag and drop the word.',
            shortDescption: 'Practice the Words I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S5_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'multiWordDndWrite',
              problem: [
                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_sun.png', word: 'sun' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_sock.png', word: 'sock' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_six.png', word: 'six' },
                  ],
                  words: ['sun', 'sock', 'six'],
                },

                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_tiger.png', word: 'tiger' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_tape.png', word: 'tape' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_tent.png', word: 'tent' },
                  ],
                  words: ['tiger', 'tape', 'tent'],
                },
                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_up.png', word: 'up' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_under.png', word: 'under' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_umbrella.png', word: 'umbrella' },
                  ],
                  words: ['up', 'under', 'umbrella'],
                },
                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_van.png', word: 'van' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_vase.png', word: 'vase' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_violin.png', word: 'violin' },
                  ],
                  words: ['van', 'vase', 'violin'],
                },
              ],
            },
          },
          6: {
            title: 'Listen and choose the picture.',
            shortDescption: 'Practice the Sounds II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S6_Dir.mp3',
            type: 'imgSelect',
            customData: {
              type: 'imgSelectSound',
              problem: [
                {
                  qSound: 'sock',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_sock.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_tape.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_under.png', isAnswer: false },
                  ],
                },
                {
                  qSound: 'tiger',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_sun.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_vase.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_tiger.png', isAnswer: true },
                  ],
                },
                {
                  qSound: 'umbrella',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_six.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_umbrella.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_tent.png', isAnswer: false },
                  ],
                },
                {
                  qSound: 'violin',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_up.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_violin.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_van.png', isAnswer: false },
                  ],
                },
              ],
            },
          },
          7: {
            title: 'Listen and choose. Then type the letter.',
            shortDescption: 'Practice the Words II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S7_Dir.mp3',
            type: 'write',
            customData: {
              type: 'imgSelectWrite',
              problem: [
                {
                  letter: 't',
                  rightSound: 'tent.mp3',
                  question:'ent',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_sun.png', sound: 'sun.mp3', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_tent.png', sound: 'tent.mp3', isAnswer: true },
                  ],
                },
                {
                  letter: 'v',
                  rightSound: 'vase.mp3',
                  question:'ase',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_vase.png', sound: 'vase.mp3', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_up.png', sound: 'up.mp3', isAnswer: false },
                  ],
                },
                {
                  letter: 't',
                  rightSound: 'tiger.mp3',
                  question:'iger',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_violin.png', sound: 'violin.mp3', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_tiger.png', sound: 'tiger.mp3', isAnswer: true },
                  ],
                },
                {
                  letter: 's',
                  rightSound: 'sock.mp3',
                  question:'ock',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_sock.png', sound: 'sock.mp3', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_tape.png', sound: 'tape.mp3', isAnswer: false },
                  ],
                },
                {
                  letter: 's',
                  rightSound: 'six.mp3',
                  question:'ix',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_vase.png', sound: 'vase.mp3', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_six.png', sound: 'six.mp3', isAnswer: true },
                  ],
                },
                {
                  letter: 'v',
                  rightSound: 'van.mp3',
                  question:'an',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_tent.png', sound: 'tent.mp3', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_van.png', sound: 'van.mp3', isAnswer: true },
                  ],
                },
                {
                  letter: 'u',
                  rightSound: 'under.mp3',
                  question:'nder',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_under.png', sound: 'under.mp3', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_sock.png', sound: 'sock.mp3', isAnswer: false },
                  ],
                },
                {
                  letter: 't',
                  rightSound: 'tape.mp3',
                  question:'ape',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_up.png', sound: 'up.mp3', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_tape.png', sound: 'tape.mp3', isAnswer: true },
                  ],
                },
                {
                  letter: 'u',
                  rightSound: 'umbrella.mp3',
                  question:'mbrella',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_umbrella.png', sound: 'umbrella.mp3', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_tent.png', sound: 'tent.mp3', isAnswer: false },
                  ],
                },
              ],
            },
          },
          8: {
            title: 'Listen and repeat. Then record your voice.',
            shortDescption: 'Story',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S8_Dir.mp3',
            type: 'record',
            customData: {
              bgImage: 'https://cdn.cloubot.com/PM/image/l1_u8_bg_wide.png',
              sound: 'Lv1_U8_reading',
              sentences: ['##Six## ##socks##.', 'One ##tent##.', 'One ##violin##.', 'One ##umbrella##.', 'One ##tiger##. ##Up##, ##up##, ##up##!'],
            },
          },
          9: {
            title: 'Story Chant',
            shortDescption: 'Story Chant',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S9_Dir.mp3',
            type: 'video',
            url: 'https://player.vimeo.com/video/409682645',
          },
          10: {
            title: 'Read. Then drag and drop.',
            shortDescption: 'Story Activity',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S10_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imgDnd3',
              problem: {
                question: [
                  { image: 'https://cdn.cloubot.com/PM/image/level1_unit08_SB_01.png', sentence: ['I have ##six## ##socks##.'] },
                  { image: 'https://cdn.cloubot.com/PM/image/level1_unit08_SB_02.png', sentence: ['I have a ##tiny## ##tent##.'] },
                  { image: 'https://cdn.cloubot.com/PM/image/level1_unit08_SB_03.png', sentence: ['I have a ##violin##.'] },
                  { image: 'https://cdn.cloubot.com/PM/image/level1_unit08_SB_04.png', sentence: ['They are all for you!'] },
                ],
              },
              totalCount: 4,
            },
          },
          11: {
            title: 'Find the hidden picture. Then, drag and drop.',
            shortDescption: 'Picture Activity',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S11_Dir.mp3',
            type: 'hidden',
            bgHiddenImage1: 'https://cdn.cloubot.com/PM/image/L1_U8_hidden_picture_1.png',
            bgHiddenImage2: 'https://cdn.cloubot.com/PM/image/L1_U8_hidden_picture_2.png',
            problem: [
              {
                isFrontLetter: true,
                letter: 's',
                word: 'six',
                image: 'https://cdn.cloubot.com/PM/image/level1/six_h.png',
                coords: { top: '29%', left: '94%' },
              },
              {
                isFrontLetter: true,
                letter: 't',
                word: 'tent',
                image: 'https://cdn.cloubot.com/PM/image/level1/tent_h.png',
                coords: { top: '22%', left: '80%' },
              },
              {
                isFrontLetter: true,
                letter: 's',
                word: 'sock',
                image: 'https://cdn.cloubot.com/PM/image/level1/sock_h.png',
                coords: { top: '13%', left: '37%' },
              },
              {
                isFrontLetter: true,
                letter: 't',
                word: 'tiger',
                image: 'https://cdn.cloubot.com/PM/image/level1/tiger_h.png',
                coords: { top: '54%', left: '28%' },
              },
              {
                isFrontLetter: true,
                letter: 'v',
                word: 'van',
                image: 'https://cdn.cloubot.com/PM/image/level1/van_h.png',
                coords: { top: '51%', left: '53%' },
              },
              {
                isFrontLetter: true,
                letter: 's',
                word: 'sun',
                image: 'https://cdn.cloubot.com/PM/image/level1/sun_h.png',
                coords: { top: '92%', left: '83%' },
              },
            ],
          },
          12: {
            title: 'Listen and type the word.',
            shortDescption: 'Quiz',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S12_Dir.mp3',
            type: 'unitTest',
            problem: [
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_sun.png', word: 'sun' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_sock.png', word: 'sock' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_six.png', word: 'six' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_tiger.png', word: 'tiger' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_tape.png', word: 'tape' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_tent.png', word: 'tent' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_up.png', word: 'up' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_under.png', word: 'under' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_umbrella.png', word: 'umbrella' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_van.png', word: 'van' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_vase.png', word: 'vase' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_violin.png', word: 'violin' },
            ],
          },
        },
      },
      9: {
        title: 'Single Letters - Ww • Xx • Yy • Zz',
        stage: {
          1: {
            title: 'Sound Animation',
            shortDescption: 'Sound Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S1_Dir.mp3',
            url: 'https://player.vimeo.com/video/409617562',
            type: 'video',
          },
          2: {
            title: 'Drag and drop.',
            shortDescption: 'Learn the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S2_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imageDndAlphabet',
              problem: [
                {
                  imageAlphabet: 'https://cdn.cloubot.com/PM/image/level1/Ww.png',
                  imageWord: 'https://cdn.cloubot.com/PM/image/level1/image_web.png',
                  mp3: 'https://cdn.cloubot.com/PM/audio/sounds/w_web.mp3',
                  alphabet: 'w',
                  word: 'web',
                },
                {
                  imageAlphabet: 'https://cdn.cloubot.com/PM/image/level1/Xx.png',
                  imageWord: 'https://cdn.cloubot.com/PM/image/level1/image_box.png',
                  mp3: 'https://cdn.cloubot.com/PM/audio/sounds/x_box.mp3',
                  alphabet: 'x',
                  word: 'box',
                },
                {
                  imageAlphabet: 'https://cdn.cloubot.com/PM/image/level1/Yy.png',
                  imageWord: 'https://cdn.cloubot.com/PM/image/level1/image_yo-yo.png',
                  mp3: 'https://cdn.cloubot.com/PM/audio/sounds/y_yo-yo.mp3',
                  alphabet: 'y',
                  word: 'yo-yo',
                },
                {
                  imageAlphabet: 'https://cdn.cloubot.com/PM/image/level1/Zz.png',
                  imageWord: 'https://cdn.cloubot.com/PM/image/level1/image_zebra.png',
                  mp3: 'https://cdn.cloubot.com/PM/audio/sounds/z_zebra.mp3',
                  alphabet: 'z',
                  word: 'zebra',
                },
              ],
            },
          },
          3: {
            title: 'Listen and choose.',
            shortDescption: 'Practice the Sounds I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S3_Dir.mp3',
            type: 'imgSelect',
            customData: {
              type: 'imgAlphabetSelectSound',
              problem: [
                {
                  qSound: 'web',
                  exImage: [
                    {
                      image: 'https://cdn.cloubot.com/PM/image/level1/Ww.png',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level1/image_web.png',
                      alphabet: 'w',
                      isAnswer: true,
                    },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Xx.png', alphabet: 'x', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Yy.png', alphabet: 'y', isAnswer: false },
                  ],
                },
                {
                  qSound: 'box',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Ww.png', alphabet: 'w', isAnswer: false },
                    {
                      image: 'https://cdn.cloubot.com/PM/image/level1/Xx.png',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level1/image_box.png',
                      alphabet: 'x',
                      isAnswer: true,
                    },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Yy.png', alphabet: 'y', isAnswer: false },
                  ],
                },
                {
                  qSound: 'yo-yo',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Xx.png', alphabet: 'x', isAnswer: false },
                    {
                      image: 'https://cdn.cloubot.com/PM/image/level1/Yy.png',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level1/image_yo-yo.png',
                      alphabet: 'y',
                      isAnswer: true,
                    },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Zz.png', alphabet: 'z', isAnswer: false },
                  ],
                },
                {
                  qSound: 'zebra',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Xx.png', alphabet: 'x', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/Yy.png', alphabet: 'y', isAnswer: false },
                    {
                      image: 'https://cdn.cloubot.com/PM/image/level1/Zz.png',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level1/image_zebra.png',
                      alphabet: 'z',
                      isAnswer: true,
                    },
                  ],
                },
              ],
            },
          },
          4: {
            title: 'Word Animation',
            shortDescption: 'Word Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S4_Dir.mp3',
            url: 'https://player.vimeo.com/video/409618490',
            type: 'video',
          },
          5: {
            title: 'Drag and drop the word.',
            shortDescption: 'Practice the Words I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S5_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'multiWordDndWrite',
              problem: [
                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_box.png', word: 'box', isEnd: true },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_fox.png', word: 'fox', isEnd: true },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_ax.png', word: 'ax', isEnd: true },
                  ],
                  words: ['box', 'fox', 'ax'],
                },
                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_web.png', word: 'web' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_wolf.png', word: 'wolf' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_watch.png', word: 'watch' },
                  ],
                  words: ['web', 'wolf', 'watch'],
                },

                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_yo-yo.png', word: 'yo-yo' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_yacht.png', word: 'yacht' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_yellow.png', word: 'yellow' },
                  ],
                  words: ['yo-yo', 'yacht', 'yellow'],
                },
                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_zebra.png', word: 'zebra' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_zero.png', word: 'zero' },
                    { url: 'https://cdn.cloubot.com/PM/image/level1/image_zoo.png', word: 'zoo' },
                  ],
                  words: ['zebra', 'zero', 'zoo'],
                },
              ],
            },
          },
          6: {
            title: 'Listen and choose the picture.',
            shortDescption: 'Practice the Sounds II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S6_Dir.mp3',
            type: 'imgSelect',
            customData: {
              type: 'imgSelectSound',
              problem: [
                {
                  qSound: 'web',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_web.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_fox.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_yo-yo.png', isAnswer: false },
                  ],
                },
                {
                  qSound: 'box',
                  isEnd: true,
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_wolf.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_yellow.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_box.png', isAnswer: true },
                  ],
                },
                {
                  qSound: 'yacht',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_watch.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_yacht.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_zoo.png', isAnswer: false },
                  ],
                },
                {
                  qSound: 'zebra',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_ax.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_zebra.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_zero.png', isAnswer: false },
                  ],
                },
              ],
            },
          },
          7: {
            title: 'Listen and choose. Then type the letter.',
            shortDescption: 'Practice the Words II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S7_Dir.mp3',
            type: 'write',
            customData: {
              type: 'imgSelectWrite',
              problem: [
                {
                  letter: 'w',
                  rightSound: 'wolf.mp3',
                  question:'olf',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_wolf.png', sound: 'wolf.mp3', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_zoo.png', sound: 'zoo.mp3', isAnswer: false },
                  ],
                },
                {
                  letter: 'x',
                  rightSound: 'fox.mp3',
                  question:'fo',
                  isEndSound: true,
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_yacht.png', sound: 'yacht.mp3', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_fox.png', sound: 'fox.mp3', isAnswer: true },
                  ],
                },
                {
                  letter: 'y',
                  rightSound: 'yo-yo.mp3',
                  question:'o-yo',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_yo-yo.png', sound: 'yo-yo.mp3', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_ax.png', sound: 'ax.mp3', isAnswer: false },
                  ],
                },
                {
                  letter: 'z',
                  rightSound: 'zebra.mp3',
                  question:'ebra',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_box.png', sound: 'box.mp3', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_zebra.png', sound: 'zebra.mp3', isAnswer: true },
                  ],
                },
                {
                  letter: 'x',
                  rightSound: 'ax.mp3',
                  question:'a',
                  isEndSound: true,
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_ax.png', sound: 'ax.mp3', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_yo-yo.png', sound: 'yo-yo.mp3', isAnswer: false },
                  ],
                },
                {
                  letter: 'w',
                  rightSound: 'watch.mp3',
                  question:'atch',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_watch.png', sound: 'watch.mp3', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_zebra.png', sound: 'zebra.mp3', isAnswer: false },
                  ],
                },
                {
                  letter: 'y',
                  rightSound: 'yacht.mp3',
                  question:'acht',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_zero.png', sound: 'zero.mp3', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_yacht.png', sound: 'yacht.mp3', isAnswer: true },
                  ],
                },
                {
                  letter: 'w',
                  rightSound: 'web.mp3',
                  question:'eb',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_web.png', sound: 'web.mp3', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_zebra.png', sound: 'zebra.mp3', isAnswer: false },
                  ],
                },
                {
                  letter: 'y',
                  rightSound: 'yellow.mp3',
                  question:'ellow',
                  examples: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_fox.png', sound: 'fox.mp3', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_yellow.png', sound: 'yellow.mp3', isAnswer: true },
                  ],
                },
              ],
            },
          },
          8: {
            title: 'Listen and repeat. Then record your voice.',
            shortDescption: 'Story',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S8_Dir.mp3',
            type: 'record',
            customData: {
              bgImage: 'https://cdn.cloubot.com/PM/image/l1_u9_bg_wide.png',
              sound: 'Lv1_U9_reading',
              sentences: ['##Wolf## has my ##watch##.', '##Fox## has my ##yacht##.', '##Zebra## has my ##zero##.', 'Who has my ##yo##-##yo##?'],
            },
          },
          9: {
            title: 'Story Chant',
            shortDescption: 'Story Chant',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S9_Dir.mp3',
            type: 'video',
            url: 'https://player.vimeo.com/video/409618288',
          },
          10: {
            title: 'Read. Then drag and drop.',
            shortDescption: 'Story Activity',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S10_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imgDnd3',
              problem: {
                question: [
                  { image: 'https://cdn.cloubot.com/PM/image/level1_unit09_SB_01.png', sentence: ['The ##wolf## has a ##watch##.'] },
                  { image: 'https://cdn.cloubot.com/PM/image/level1_unit09_SB_02.png', sentence: ['The ##fox## has an ##ax##.'] },
                  { image: 'https://cdn.cloubot.com/PM/image/level1_unit09_SB_03.png', sentence: ['The ##yak## has a ##yo##-##yo##.'] },
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level1_unit09_SB_04.png',
                    sentence: ['The ##zebra## has a ##zipper##.', 'What do you have in your tummy?'],
                  },
                ],
              },
              totalCount: 4,
            },
          },
          11: {
            title: 'Find the hidden picture. Then, drag and drop.',
            shortDescption: 'Picture Activity',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S11_Dir.mp3',
            type: 'hidden',
            bgHiddenImage1: 'https://cdn.cloubot.com/PM/image/L1_U9_hidden_picture_1.png',
            bgHiddenImage2: 'https://cdn.cloubot.com/PM/image/L1_U9_hidden_picture_2.png',
            problem: [
              {
                isFrontLetter: true,
                letter: 'y',
                word: 'yo-yo',
                image: 'https://cdn.cloubot.com/PM/image/level1/yo-yo_h.png',
                coords: { top: '24%', left: '29%' },
              },
              {
                isFrontLetter: true,
                letter: 'w',
                word: 'watch',
                image: 'https://cdn.cloubot.com/PM/image/level1/watch_h.png',
                coords: { top: '79%', left: '63%' },
              },
              {
                isFrontLetter: false,
                letter: 'x',
                word: 'box',
                image: 'https://cdn.cloubot.com/PM/image/level1/box_h.png',
                coords: { top: '18%', left: '19%' },
              },
              {
                isFrontLetter: true,
                letter: 'z',
                word: 'zero',
                image: 'https://cdn.cloubot.com/PM/image/level1/zero_h.png',
                coords: { top: '94%', left: '29%' },
              },
              {
                isFrontLetter: true,
                letter: 'y',
                word: 'yacht',
                image: 'https://cdn.cloubot.com/PM/image/level1/yacht_h.png',
                coords: { top: '32%', left: '91%' },
              },
              {
                isFrontLetter: false,
                letter: 'x',
                word: 'fox',
                image: 'https://cdn.cloubot.com/PM/image/level1/fox_h.png',
                coords: { top: '74%', left: '6%' },
              },
            ],
          },
          12: {
            title: 'Listen and type the word.',
            shortDescption: 'Quiz',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_S12_Dir.mp3',
            type: 'unitTest',
            problem: [
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_web.png', word: 'web' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_wolf.png', word: 'wolf' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_watch.png', word: 'watch' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_box.png', word: 'box' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_fox.png', word: 'fox' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_ax.png', word: 'ax' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_yo-yo.png', word: 'yo-yo' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_yacht.png', word: 'yacht' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_yellow.png', word: 'yellow' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_zebra.png', word: 'zebra' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_zero.png', word: 'zero' },
              { image: 'https://cdn.cloubot.com/PM/image/level1/image_zoo.png', word: 'zoo' },
            ],
          },
        },
      },

      10: {
        title: 'Review - Unit 6~Unit 9',
        stage: {
          1: {
            title: 'Listen and repeat.',
            shortDescption: 'Review the Sounds I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_Review_S1_Dir.mp3',
            type: 'imageSound',
            problem: [
              {
                image: 'https://cdn.cloubot.com/PM/image/level1/Review_2_1.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/Lv1_Unit10_Stage1_1.mp3',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level1/Review_2_2.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/Lv1_Unit10_Stage1_2.mp3',
              },
            ],
          },
          2: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Review the Words I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_Review_S2_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imgDnd4',
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level1/image_monster.png',
                  rightWord: 'monster',
                  alphabet: 'Mm',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level1/image_nut.png',
                    'https://cdn.cloubot.com/PM/image/level1/image_monster.png',
                    'https://cdn.cloubot.com/PM/image/level1/image_ostrich.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level1/image_octopus.png',
                  rightWord: 'octopus',
                  alphabet: 'Oo',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level1/image_yellow.png',
                    'https://cdn.cloubot.com/PM/image/level1/image_ring.png',
                    'https://cdn.cloubot.com/PM/image/level1/image_octopus.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level1/image_nest.png',
                  rightWord: 'nest',
                  alphabet: 'Nn',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level1/image_nest.png',
                    'https://cdn.cloubot.com/PM/image/level1/image_mouse.png',
                    'https://cdn.cloubot.com/PM/image/level1/image_quiet.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level1/image_pizza.png',
                  rightWord: 'pizza',
                  alphabet: 'Pp',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level1/image_quiet.png',
                    'https://cdn.cloubot.com/PM/image/level1/image_pizza.png',
                    'https://cdn.cloubot.com/PM/image/level1/image_box.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level1/image_queen.png',
                  rightWord: 'queen',
                  alphabet: 'Qq',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level1/image_queen.png',
                    'https://cdn.cloubot.com/PM/image/level1/image_umbrella.png',
                    'https://cdn.cloubot.com/PM/image/level1/image_six.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level1/image_vase.png',
                  rightWord: 'vase',
                  alphabet: 'Vv',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level1/image_pen.png',
                    'https://cdn.cloubot.com/PM/image/level1/image_ox.png',
                    'https://cdn.cloubot.com/PM/image/level1/image_vase.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level1/image_web.png',
                  rightWord: 'web',
                  alphabet: 'Ww',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level1/image_zoo.png',
                    'https://cdn.cloubot.com/PM/image/level1/image_map.png',
                    'https://cdn.cloubot.com/PM/image/level1/image_web.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level1/image_tent.png',
                  rightWord: 'tent',
                  alphabet: 'Tt',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level1/image_pig.png',
                    'https://cdn.cloubot.com/PM/image/level1/image_tent.png',
                    'https://cdn.cloubot.com/PM/image/level1/image_sun.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level1/image_under.png',
                  rightWord: 'under',
                  alphabet: 'Uu',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level1/image_under.png',
                    'https://cdn.cloubot.com/PM/image/level1/image_yo-yo.png',
                    'https://cdn.cloubot.com/PM/image/level1/image_wolf.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level1/image_rabbit.png',
                  rightWord: 'rabbit',
                  alphabet: 'Rr',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level1/image_tape.png',
                    'https://cdn.cloubot.com/PM/image/level1/image_rabbit.png',
                    'https://cdn.cloubot.com/PM/image/level1/image_neck.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level1/image_sock.png',
                  rightWord: 'sock',
                  alphabet: 'Ss',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level1/image_robot.png',
                    'https://cdn.cloubot.com/PM/image/level1/image_zebra.png',
                    'https://cdn.cloubot.com/PM/image/level1/image_sock.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level1/image_zero.png',
                  rightWord: 'zero',
                  alphabet: 'Zz',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level1/image_watch.png',
                    'https://cdn.cloubot.com/PM/image/level1/image_zero.png',
                    'https://cdn.cloubot.com/PM/image/level1/image_pen.png',
                  ],
                },
              ],
            },
          },
          3: {
            title: 'Look and listen. Then choose.',
            shortDescption: 'Review the Words II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_Review_S3_Dir.mp3',
            type: 'imgSelect',
            customData: {
              type: 'imgSelectImg', //imgSelectImg, imgSelectWord

              problem: [
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level1/image_pig.png',
                  qWord: 'pig',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_nut.png', word: 'nut', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_rabbit.png', word: 'rabbit', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_pen.png', word: 'pen', isAnswer: true },
                  ],
                },
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level1/image_tiger.png',
                  qWord: 'tiger',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_wolf.png', word: 'wolf', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_ring.png', word: 'ring', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_tape.png', word: 'tape', isAnswer: true },
                  ],
                },
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level1/image_mouse.png',
                  qWord: 'mouse',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_map.png', word: 'map', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_neck.png', word: 'neck', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_quiet.png', word: 'quiet', isAnswer: false },
                  ],
                },
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level1/image_ostrich.png',
                  qWord: 'ostrich',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_pizza.png', word: 'pizza', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_ox.png', word: 'ox', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_yacht.png', word: 'yacht', isAnswer: false },
                  ],
                },
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level1/image_yellow.png',
                  qWord: 'yellow',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_yo-yo.png', word: 'yo-yo', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_umbrella.png', word: 'umbrella', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_robot.png', word: 'robot', isAnswer: false },
                  ],
                },
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level1/image_zero.png',
                  qWord: 'zero',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_queen.png', word: 'queen', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_ox.png', word: 'ox', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_zoo.png', word: 'zoo', isAnswer: true },
                  ],
                },
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level1/image_question.png',
                  qWord: 'question',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_watch.png', word: 'watch', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_mouse.png', word: 'mouse', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_quiet.png', word: 'quiet', isAnswer: true },
                  ],
                },
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level1/image_ring.png',
                  qWord: 'ring',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_violin.png', word: 'violin', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_rabbit.png', word: 'rabbit', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_tent.png', word: 'tent', isAnswer: false },
                  ],
                },
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level1/image_nut.png',
                  qWord: 'nut',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_neck.png', word: 'neck', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_zero.png', word: 'zero', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_wolf.png', word: 'wolf', isAnswer: false },
                  ],
                },

                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level1/image_sun.png',
                  qWord: 'sun',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_tape.png', word: 'tape', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_six.png', word: 'six', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_ox.png', word: 'ox', isAnswer: false },
                  ],
                },
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level1/image_up.png',
                  qWord: 'up',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_ring.png', word: 'ring', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_zebra.png', word: 'zebra', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_umbrella.png', word: 'umbrella', isAnswer: true },
                  ],
                },
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level1/image_van.png',
                  qWord: 'van',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_web.png', word: 'web', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_vase.png', word: 'vase', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_pen.png', word: 'pen', isAnswer: false },
                  ],
                },
              ],
            },
          },
          4: {
            title: 'Look and color.',
            shortDescption: 'Coloring Activity',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_Review_S4_Dir.mp3',
            type: 'color',
            problem: ['Tt', 'Vu', 'Pq', 'Qq', 'Wv', 'Mn', 'Yy', 'Vu', 'Nn', 'Sz'],
            answer: ['Tt', 'Qq', 'Yy', 'Nn'],
          },
          5: {
            title: 'Look and choose the picture that has the same beginning sound.',
            shortDescption: 'Review the Sounds II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_Review_S5_Dir.mp3',
            type: 'imgSelect',
            customData: {
              type: 'imgSelectWord', //imgSelectImg, imgSelectWord
              problem: [
                {
                  qWord: 'Nn',
                  direction: 'none',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_vase.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_nut.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_monster.png', isAnswer: false },
                  ],
                },
                {
                  qWord: 'Qq',
                  direction: 'none',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_rabbit.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_ox.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_question.png', isAnswer: true },
                  ],
                },
                {
                  qWord: 'Mm',
                  direction: 'none',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_map.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_neck.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_sun.png', isAnswer: false },
                  ],
                },
                {
                  qWord: 'Pp',
                  direction: 'none',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_ring.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_pen.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_zoo.png', isAnswer: false },
                  ],
                },
                {
                  qWord: 'Oo',
                  direction: 'none',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_octopus.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_sun.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_queen.png', isAnswer: false },
                  ],
                },
                {
                  qWord: 'Vv',
                  direction: 'none',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_web.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_van.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_zoo.png', isAnswer: false },
                  ],
                },
                {
                  qWord: 'Yy',
                  direction: 'none',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_zero.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_pen.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_yellow.png', isAnswer: true },
                  ],
                },
                {
                  qWord: 'Tt',
                  direction: 'none',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_zebra.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_map.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_tiger.png', isAnswer: true },
                  ],
                },
                {
                  qWord: 'Uu',
                  direction: 'none',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_ostrich.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_under.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_nest.png', isAnswer: false },
                  ],
                },
                {
                  qWord: 'Rr',
                  direction: 'none',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_robot.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_six.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_quiet.png', isAnswer: false },
                  ],
                },
                {
                  qWord: 'Ss',
                  direction: 'none',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_zero.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_sock.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_wolf.png', isAnswer: false },
                  ],
                },
                {
                  qWord: 'Zz',
                  direction: 'none',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_nut.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_pizza.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level1/image_zebra.png', isAnswer: true },
                  ],
                },
              ],
            },
          },
          6: {
            title: 'Look and choose the beginning sound.',
            shortDescption: 'Review the Sounds III',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_Review_S6_Dir.mp3',
            type: 'imgSelect',
            customData: {
              type: 'selectWord',
              problem: [
                {
                  image: 'https://cdn.cloubot.com/PM/image/level1/image_pig.png',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/pig.mp3',
                  words: [
                    { name: 'r', isAnswer: false },
                    { name: 'p', isAnswer: true },
                    { name: 'o', isAnswer: false },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level1/image_tiger.png',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/tiger.mp3',
                  words: [
                    { name: 'K', isAnswer: false },
                    { name: 'S', isAnswer: false },
                    { name: 'T', isAnswer: true },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level1/image_mouse.png',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/mouse.mp3',
                  words: [
                    { name: 'm', isAnswer: true },
                    { name: 'n', isAnswer: false },
                    { name: 'y', isAnswer: false },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level1/image_ostrich.png',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/ostrich.mp3',
                  words: [
                    { name: 'O', isAnswer: true },
                    { name: 'P', isAnswer: false },
                    { name: 'Q', isAnswer: false },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level1/image_yellow.png',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/yellow.mp3',
                  words: [
                    { name: 's', isAnswer: false },
                    { name: 'y', isAnswer: true },
                    { name: 't', isAnswer: false },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level1/image_zero.png',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/zero.mp3',
                  words: [
                    { name: 'y', isAnswer: false },
                    { name: 'r', isAnswer: false },
                    { name: 'z', isAnswer: true },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level1/image_question.png',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/question.mp3',
                  words: [
                    { name: 'R', isAnswer: false },
                    { name: 'Q', isAnswer: true },
                    { name: 'M', isAnswer: false },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level1/image_ring.png',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/ring.mp3',
                  words: [
                    { name: 'o', isAnswer: false },
                    { name: 'r', isAnswer: true },
                    { name: 'v', isAnswer: false },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level1/image_nut.png',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/nut.mp3',
                  words: [
                    { name: 'N', isAnswer: true },
                    { name: 'M', isAnswer: false },
                    { name: 'R', isAnswer: false },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level1/image_sun.png',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/sun.mp3',
                  words: [
                    { name: 'z', isAnswer: false },
                    { name: 'x', isAnswer: false },
                    { name: 's', isAnswer: true },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level1/image_up.png',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/up.mp3',
                  words: [
                    { name: 'V', isAnswer: false },
                    { name: 'U', isAnswer: true },
                    { name: 'W', isAnswer: false },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level1/image_van.png',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/van.mp3',
                  words: [
                    { name: 'q', isAnswer: false },
                    { name: 'v', isAnswer: true },
                    { name: 'r', isAnswer: false },
                  ],
                },
              ],
            },
          },
          7: {
            title: 'Look, choose, and type.',
            shortDescption: 'Review the Words III',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_Review_S7_Dir.mp3',
            type: 'write',
            customData: {
              type: 'imageWordSelectWrite',
              problem: [
                {
                  image: 'https://cdn.cloubot.com/PM/image/level1/image_pig.png',
                  answer: 'Pp',
                  exWords: ['Pp', 'Kk'],
                  isForceLowerCase: false,
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level1/image_tiger.png',
                  answer: 'Tt',
                  exWords: ['Tt', 'Rr'],
                  isForceLowerCase: false,
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level1/image_mouse.png',
                  answer: 'Mm',
                  exWords: ['Qq', 'Mm'],
                  isForceLowerCase: false,
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level1/image_ostrich.png',
                  answer: 'Oo',
                  exWords: ['Oo', 'Pp'],
                  isForceLowerCase: false,
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level1/image_yellow.png',
                  answer: 'Yy',
                  exWords: ['Vv', 'Yy'],
                  isForceLowerCase: false,
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level1/image_zero.png',
                  answer: 'Zz',
                  exWords: ['Zz', 'Ss'],
                  isForceLowerCase: false,
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level1/image_question.png',
                  answer: 'Qq',
                  exWords: ['Qq', 'Oo'],
                  isForceLowerCase: false,
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level1/image_ring.png',
                  answer: 'Rr',
                  exWords: ['Pp', 'Rr'],
                  isForceLowerCase: false,
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level1/image_nut.png',
                  answer: 'Nn',
                  exWords: ['Xx', 'Nn'],
                  isForceLowerCase: false,
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level1/image_sun.png',
                  answer: 'Ss',
                  exWords: ['Tt', 'Ss'],
                  isForceLowerCase: false,
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level1/image_up.png',
                  answer: 'Uu',
                  exWords: ['Uu', 'Ww'],
                  isForceLowerCase: false,
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level1/image_van.png',
                  answer: 'Vv',
                  exWords: ['Ww', 'Vv'],
                  isForceLowerCase: false,
                },
              ],
            },
          },
          8: {
            title: 'Look and type the letters.',
            shortDescption: 'Review the Sounds IV',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L1_Review_S8_Dir.mp3',
            type: 'write',
            customData: {
              type: 'imageAlphabetWrite',
              problem: [
                { image: 'https://cdn.cloubot.com/PM/image/level1/image_pen.png', answer: 'Pp' },
                { image: 'https://cdn.cloubot.com/PM/image/level1/image_yellow.png', answer: 'Yy' },
                { image: 'https://cdn.cloubot.com/PM/image/level1/image_nut.png', answer: 'Nn' },
                { image: 'https://cdn.cloubot.com/PM/image/level1/image_vase.png', answer: 'Vv' },
                { image: 'https://cdn.cloubot.com/PM/image/level1/image_zebra.png', answer: 'Zz' },
                { image: 'https://cdn.cloubot.com/PM/image/level1/image_rabbit.png', answer: 'Rr' },
                { image: 'https://cdn.cloubot.com/PM/image/level1/image_map.png', answer: 'Mm' },
                { image: 'https://cdn.cloubot.com/PM/image/level1/image_ox.png', answer: 'Oo' },
                { image: 'https://cdn.cloubot.com/PM/image/level1/image_up.png', answer: 'Uu' },
                { image: 'https://cdn.cloubot.com/PM/image/level1/image_six.png', answer: 'Ss' },
                { image: 'https://cdn.cloubot.com/PM/image/level1/image_wolf.png', answer: 'Ww' },
                { image: 'https://cdn.cloubot.com/PM/image/level1/image_queen.png', answer: 'Qq' },
              ],
            },
          },
        },
      },
    },
  },
  {
    bookName: '2a',
    bookCode: 'PM-L3V1',
    validation: {
      purchaseCode: 'someRandomCode',
      accessToken: 'short-lived-access-token',
    },
    unit: {
      1: {
        title: 'Short Vowel - ad · ag · am',
        stage: {
          1: {
            title: 'Sound Animation',
            shortDescption: 'Sound Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S1_Dir.mp3',
            url: 'https://player.vimeo.com/video/381457973',
            type: 'video',
          },
          2: {
            title: 'Drag and drop.',
            shortDescption: 'Learn the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S2_Dir.mp3',
            type: 'dnd',
            hasStep: false,
            customData: {
              isShortVowel: true,
              soundBaseUrl: 'https://cdn.cloubot.com/PM/audio/sounds/level2/',
              problem: [
                { 1: ['a'], 2: ['a', 'd'], type: 'dndType1', exampleWord: 'dad', exSound: 'd_ad_dad' },
                { 1: ['a'], 2: ['a', 'g'], type: 'dndType1', exampleWord: 'bag', exSound: 'b_ag_bag' },
                { 1: ['a'], 2: ['a', 'm'], type: 'dndType1', exampleWord: 'jam', exSound: 'j_am_jam' },
              ],
              totalCount: 3,
              type: 'alphabetDnd',
            },
          },
          3: {
            title: ['Listen and choose.', 'Drag and drop. Then type.'],
            shortDescption: 'Practice the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S3_Dir.mp3',
            type: 'phonemeSelectAndDnd',
            hasStep: true,
            problem: [
              {
                type: 'select',
                answer: { name: 'ad', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/ad.mp3' },
                word: {
                  name: 'dad',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/dad.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_dad.png',
                },
                candidate: ['ad', 'am'],
              },
              {
                type: 'select',
                answer: { name: 'ag', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/ag.mp3' },
                word: {
                  name: 'bag',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/bag.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_bag.png',
                },
                candidate: ['ag', 'ad'],
              },
              {
                type: 'select',
                answer: { name: 'am', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/am.mp3' },
                word: {
                  name: 'jam',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/jam.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_jam.png',
                },
                candidate: ['ag', 'am'],
              },
              {
                type: 'dnd',
                answer: { name: 'ad', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/ad.mp3' },
                word: {
                  name: 'dad',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/dad.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_dad.png',
                },
                candidate: ['ad', 'ag', 'am'],
              },
              {
                type: 'dnd',
                answer: { name: 'ag', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/ag.mp3' },
                word: {
                  name: 'bag',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/bag.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_bag.png',
                },
                candidate: ['ad', 'ag', 'am'],
              },
              {
                type: 'dnd',
                answer: { name: 'am', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/am.mp3' },
                word: {
                  name: 'jam',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/jam.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_jam.png',
                },
                candidate: ['ad', 'ag', 'am'],
              },
            ],
          },
          4: {
            title: 'Word Animation',
            shortDescption: 'Word Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S4_Dir.mp3',
            url: 'https://player.vimeo.com/video/381458332',
            type: 'video',
          },
          5: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Practice the WordsⅠ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S5_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_dad.png',
                  question: 'd',
                  answer: 'ad',
                  candidate: ['ad', 'ag', 'am'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_bad.png',
                  question: 'b',
                  answer: 'ad',
                  candidate: ['ad', 'ag', 'am'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_sad.png',
                  question: 's',
                  answer: 'ad',
                  candidate: ['ad', 'ag', 'am'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_bag.png',
                  question: 'b',
                  answer: 'ag',
                  candidate: ['ad', 'ag', 'am'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_tag.png',
                  question: 't',
                  answer: 'ag',
                  candidate: ['ad', 'ag', 'am'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_wag.png',
                  question: 'w',
                  answer: 'ag',
                  candidate: ['ad', 'ag', 'am'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_jam.png',
                  question: 'j',
                  answer: 'am',
                  candidate: ['ad', 'ag', 'am'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_ram.png',
                  question: 'r',
                  answer: 'am',
                  candidate: ['ad', 'ag', 'am'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_ham.png',
                  question: 'h',
                  answer: 'am',
                  candidate: ['ad', 'ag', 'am'],
                },
              ],
              type: 'imgDnd',
              totalCount: 1,
            },
          },
          6: {
            title: 'Look and choose.',
            shortDescption: 'Practice the Words Ⅱ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S6_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'multiWordDnd',
              problem: [
                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_dad.png', word: 'dad' },
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_bad.png', word: 'bad' },
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_sad.png', word: 'sad' },
                  ],
                  words: ['dad', 'sad', 'bad'],
                },
                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_bag.png', word: 'bag' },
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_tag.png', word: 'tag' },
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_wag.png', word: 'wag' },
                  ],
                  words: ['bag', 'tag', 'wag'],
                },
                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_jam.png', word: 'jam' },
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_ram.png', word: 'ram' },
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_ham.png', word: 'ham' },
                  ],
                  words: ['jam', 'ram', 'ham'],
                },
              ],
            },
          },
          7: {
            title: 'Choose and type the word.',
            shortDescption: 'Practice the Words Ⅲ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S7_Dir.mp3',
            type: 'lineWord',
            problem: [
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_dad.png',
                spellings: [
                  { alphabet: 'm', isAnswer: false },
                  { alphabet: 'r', isAnswer: false },
                  { alphabet: 'd', isAnswer: true },
                  { alphabet: 'a', isAnswer: true ,isPink:true},
                  { alphabet: 'd', isAnswer: true ,isPink:true},
                  { alphabet: 's', isAnswer: false },
                ],
                answer: 'dad',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_bad.png',
                spellings: [
                  { alphabet: 'p', isAnswer: false },
                  { alphabet: 'b', isAnswer: true },
                  { alphabet: 'a', isAnswer: true ,isPink:true},
                  { alphabet: 'd', isAnswer: true ,isPink:true},
                  { alphabet: 'c', isAnswer: false },
                  { alphabet: 'q', isAnswer: false },
                ],
                answer: 'bad',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_sad.png',
                spellings: [
                  { alphabet: 'o', isAnswer: false },
                  { alphabet: 'y', isAnswer: false },
                  { alphabet: 'j', isAnswer: false },
                  { alphabet: 's', isAnswer: true },
                  { alphabet: 'a', isAnswer: true ,isPink:true},
                  { alphabet: 'd', isAnswer: true ,isPink:true},
                ],
                answer: 'sad',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_bag.png',
                spellings: [
                  { alphabet: 'd', isAnswer: false },
                  { alphabet: 'b', isAnswer: true },
                  { alphabet: 'a', isAnswer: true ,isPink:true},
                  { alphabet: 'g', isAnswer: true ,isPink:true},
                  { alphabet: 't', isAnswer: false },
                  { alphabet: 'w', isAnswer: false },
                ],
                answer: 'bag',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_tag.png',
                spellings: [
                  { alphabet: 'u', isAnswer: false },
                  { alphabet: 'r', isAnswer: false },
                  { alphabet: 't', isAnswer: true },
                  { alphabet: 'a', isAnswer: true ,isPink:true},
                  { alphabet: 'g', isAnswer: true ,isPink:true},
                  { alphabet: 'v', isAnswer: false },
                ],
                answer: 'tag',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_wag.png',
                spellings: [
                  { alphabet: 'w', isAnswer: true },
                  { alphabet: 'a', isAnswer: true ,isPink:true},
                  { alphabet: 'g', isAnswer: true ,isPink:true},
                  { alphabet: 'c', isAnswer: false },
                  { alphabet: 'e', isAnswer: false },
                  { alphabet: 'f', isAnswer: false },
                ],
                answer: 'wag',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_jam.png',
                spellings: [
                  { alphabet: 'o', isAnswer: false },
                  { alphabet: 'u', isAnswer: false },
                  { alphabet: 'j', isAnswer: true },
                  { alphabet: 'a', isAnswer: true ,isPink:true},
                  { alphabet: 'm', isAnswer: true ,isPink:true},
                  { alphabet: 'g', isAnswer: false },
                ],
                answer: 'jam',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_ram.png',
                spellings: [
                  { alphabet: 'd', isAnswer: false },
                  { alphabet: 'h', isAnswer: false },
                  { alphabet: 'i', isAnswer: false },
                  { alphabet: 'r', isAnswer: true },
                  { alphabet: 'a', isAnswer: true ,isPink:true},
                  { alphabet: 'm', isAnswer: true ,isPink:true},
                ],
                answer: 'ram',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_ham.png',
                spellings: [
                  { alphabet: 'j', isAnswer: false },
                  { alphabet: 'h', isAnswer: true },
                  { alphabet: 'a', isAnswer: true ,isPink:true},
                  { alphabet: 'm', isAnswer: true ,isPink:true},
                  { alphabet: 'k', isAnswer: false },
                  { alphabet: 'l', isAnswer: false },
                ],
                answer: 'ham',
              },
            ],
          },
          8: {
            title: 'Listen and repeat. Then record your voice.',
            shortDescption: 'Story',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S8_Dir.mp3',
            type: 'record',
            customData: {
              bgImage: 'https://cdn.cloubot.com/PM/image/l2_u1_bg_wide.jpg',
              sound: 'Lv2_U1_reading',
              sentences: ['##Dad## has a ##ram##.', '##Dad## has a ##bag##.', '##Ham##!', '##Dad## has ##ham##!', '##Jam##!', '##Dad## has ##jam##!', '##Bad## ##ram##!', '##Bad## ##ram##!', '##Dad## is ##sad##.'],
            },
          },
          9: {
            title: 'Choose and type the word.',
            shortDescption: 'Words in the Story',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S9_Dir.mp3',
            type: 'write',
            customData: {
              type: 'imgSoundWrite',
              problem: [
                {
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_dad_has_a_ram.png',
                  sentenceFormat: 'Dad has a ##word##.',
                  correctWord: 'ram',
                  wrongWord: 'wag',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_dad_has_a_bag.png',
                  sentenceFormat: 'Dad has a ##word##.',
                  correctWord: 'bag',
                  wrongWord: 'jam',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_dad_has_a_ham.png',
                  sentenceFormat: 'Dad has ##word##!',
                  correctWord: 'ham',
                  wrongWord: 'tag',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_dad_is_sad.png',
                  sentenceFormat: 'Dad is ##word##.',
                  correctWord: 'sad',
                  wrongWord: 'bad',
                },
              ],
            },
          },
          10: {
            title: 'Story Chant',
            shortDescption: 'Story Chant',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S10_Dir.mp3',
            url: 'https://player.vimeo.com/video/381458531',
            type: 'video',
          },
          11: {
            title: 'Read. Then drag and drop.',
            shortDescption: 'Story Activity',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S11_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imgDnd3',
              problem: {
                question: [
                  { image: 'https://cdn.cloubot.com/PM/image/level2_unit01_SB_01.png', sentence: ['##Dad## wants ##ham##.'] },
                  { image: 'https://cdn.cloubot.com/PM/image/level2_unit01_SB_02.png', sentence: ['##Dad## wants a ##yam##.'] },
                  { image: 'https://cdn.cloubot.com/PM/image/level2_unit01_SB_03.png', sentence: ['But ##Dad## is ##sad##.'] },
                  { image: 'https://cdn.cloubot.com/PM/image/level2_unit01_SB_04.png', sentence: ['There is only ##jam##!'] },
                ],
              },
              totalCount: 4,
            },
          },
          12: {
            title: 'Listen and type the word.',
            shortDescption: 'Quiz',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S12_Dir.mp3',
            type: 'unitTest',
            problem: [
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_bad.png', word: 'bad' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_dad.png', word: 'dad' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_sad.png', word: 'sad' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_tag.png', word: 'tag' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_wag.png', word: 'wag' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_bag.png', word: 'bag' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_jam.png', word: 'jam' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_ram.png', word: 'ram' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_ham.png', word: 'ham' },
            ],
          },
        },
      },
      2: {
        title: 'Short Vowel - an · ap · at',
        stage: {
          1: {
            title: 'Sound Animation',
            shortDescption: 'Sound Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S1_Dir.mp3',
            url: 'https://player.vimeo.com/video/381457977',
            type: 'video',
          },
          2: {
            title: 'Drag and drop.',
            shortDescption: 'Learn the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S2_Dir.mp3',
            type: 'dnd',
            hasStep: false,
            customData: {
              isShortVowel: true,
              soundBaseUrl: 'https://cdn.cloubot.com/PM/audio/sounds/level2/',
              problem: [
                { 1: ['a'], 2: ['a', 'n'], type: 'dndType1', exampleWord: 'fan', exSound: 'f_an_fan' },
                { 1: ['a'], 2: ['a', 'p'], type: 'dndType1', exampleWord: 'map', exSound: 'm_ap_map' },
                { 1: ['a'], 2: ['a', 't'], type: 'dndType1', exampleWord: 'cat', exSound: 'c_at_cat' },
              ],
              totalCount: 3,
              type: 'alphabetDnd',
            },
          },
          3: {
            title: ['Listen and choose.', 'Drag and drop. Then type.'],
            shortDescption: 'Practice the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S3_Dir.mp3',
            type: 'phonemeSelectAndDnd',
            hasStep: true,
            problem: [
              {
                type: 'select',
                answer: { name: 'an', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/an.mp3' },
                word: {
                  name: 'fan',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/fan.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_fan.png',
                },
                candidate: ['an', 'ap'],
              },
              {
                type: 'select',
                answer: { name: 'ap', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/ap.mp3' },
                word: {
                  name: 'map',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/map.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_map.png',
                },
                candidate: ['ap', 'at'],
              },
              {
                type: 'select',
                answer: { name: 'at', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/at.mp3' },
                word: {
                  name: 'cat',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/cat.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_cat.png',
                },
                candidate: ['at', 'an'],
              },
              {
                type: 'dnd',
                answer: { name: 'an', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/an.mp3' },
                word: {
                  name: 'fan',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/fan.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_fan.png',
                },
                candidate: ['an', 'ap', 'at'],
              },
              {
                type: 'dnd',
                answer: { name: 'ap', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/ap.mp3' },
                word: {
                  name: 'map',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/map.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_map.png',
                },
                candidate: ['an', 'ap', 'at'],
              },
              {
                type: 'dnd',
                answer: { name: 'at', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/at.mp3' },
                word: {
                  name: 'cat',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/cat.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_cat.png',
                },
                candidate: ['an', 'ap', 'at'],
              },
            ],
          },
          4: {
            title: 'Word Animation',
            shortDescption: 'Word Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S4_Dir.mp3',
            url: 'https://player.vimeo.com/video/381458335',
            type: 'video',
          },
          5: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Practice the Words Ⅰ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S5_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_fan.png',
                  question: 'f',
                  answer: 'an',
                  candidate: ['an', 'ap', 'at'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_can.png',
                  question: 'c',
                  answer: 'an',
                  candidate: ['an', 'ap', 'at'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_man.png',
                  question: 'm',
                  answer: 'an',
                  candidate: ['an', 'ap', 'at'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_map.png',
                  question: 'm',
                  answer: 'ap',
                  candidate: ['an', 'ap', 'at'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_cap.png',
                  question: 'c',
                  answer: 'ap',
                  candidate: ['an', 'ap', 'at'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_tap.png',
                  question: 't',
                  answer: 'ap',
                  candidate: ['an', 'ap', 'at'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_cat.png',
                  question: 'c',
                  answer: 'at',
                  candidate: ['an', 'ap', 'at'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_fat.png',
                  question: 'f',
                  answer: 'at',
                  candidate: ['an', 'ap', 'at'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_hat.png',
                  question: 'h',
                  answer: 'at',
                  candidate: ['an', 'ap', 'at'],
                },
              ],
              type: 'imgDnd',
              totalCount: 1,
            },
          },
          6: {
            title: 'Look and choose.',
            shortDescption: 'Practice the Words Ⅱ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S6_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'multiWordDnd',
              problem: [
                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_fan.png', word: 'fan' },
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_can.png', word: 'can' },
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_man.png', word: 'man' },
                  ],
                  words: ['fan', 'can', 'man'],
                },
                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_map.png', word: 'map' },
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_cap.png', word: 'cap' },
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_tap.png', word: 'tap' },
                  ],
                  words: ['map', 'cap', 'tap'],
                },
                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_cat.png', word: 'cat' },
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_fat.png', word: 'fat' },
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_hat.png', word: 'hat' },
                  ],
                  words: ['cat', 'fat', 'hat'],
                },
              ],
              totalCount: 0, //사용하지 않음
            },
          },
          7: {
            title: 'Choose and type the word.',
            shortDescption: 'Practice the Words Ⅲ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S7_Dir.mp3',
            type: 'lineWord',
            problem: [
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_man.png',
                spellings: [
                  { alphabet: 'b', isAnswer: false },
                  { alphabet: 'm', isAnswer: false },
                  { alphabet: 'a', isAnswer: true ,isPink:true},
                  { alphabet: 'n', isAnswer: true ,isPink:true},
                  { alphabet: 'p', isAnswer: true },
                  { alphabet: 'p', isAnswer: false },
                ],
                answer: 'man',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_cat.png',
                spellings: [
                  { alphabet: 'c', isAnswer: false },
                  { alphabet: 'a', isAnswer: false ,isPink:true},
                  { alphabet: 't', isAnswer: true ,isPink:true},
                  { alphabet: 'm', isAnswer: true },
                  { alphabet: 'a', isAnswer: true },
                  { alphabet: 'g', isAnswer: false },
                ],
                answer: 'cat',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_can.png',
                spellings: [
                  { alphabet: 's', isAnswer: false },
                  { alphabet: 'c', isAnswer: false },
                  { alphabet: 'a', isAnswer: true ,isPink:true},
                  { alphabet: 'n', isAnswer: true ,isPink:true},
                  { alphabet: 'i', isAnswer: true },
                  { alphabet: 'g', isAnswer: false },
                ],
                answer: 'can',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_hat.png',
                spellings: [
                  { alphabet: 'd', isAnswer: false },
                  { alphabet: 's', isAnswer: false },
                  { alphabet: 'i', isAnswer: true },
                  { alphabet: 'h', isAnswer: true },
                  { alphabet: 'a', isAnswer: true ,isPink:true},
                  { alphabet: 't', isAnswer: false ,isPink:true},
                ],
                answer: 'hat',
              },
            ],
          },
          8: {
            title: 'Listen and repeat. Then record your voice.',
            shortDescption: 'Story',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S8_Dir.mp3',
            type: 'record',
            customData: {
              bgImage: 'https://cdn.cloubot.com/PM/image/l2_u2_bg_wide.jpg',
              sound: 'Lv2_U2_reading',
              sentences: ['A ##man##.', 'A ##fat## ##man##.', 'A ##fat## ##man## in a ##hat##.', 'A ##cat##.', 'A ##cat## in a ##can##.', '“##Tap##! ##Tap##! ##Tap##!”', 'A ##fat## ##man## in a ##can##.', 'A ##cat## in a ##hat##.'],
            },
          },
          9: {
            title: 'Choose and type the word.',
            shortDescption: 'Words in the Story',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S9_Dir.mp3',
            type: 'write',
            customData: {
              type: 'imgSoundWrite',
              problem: [
                {
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_a_man_in_a_hat.png',
                  sentenceFormat: 'A ##word## in a hat.',
                  correctWord: 'man',
                  wrongWord: 'cat',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_a_cat_in_a_can.png',
                  sentenceFormat: 'A ##word## in a can.',
                  correctWord: 'cat',
                  wrongWord: 'man',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_a_fat_man_in_a_can.png',
                  sentenceFormat: 'A fat man in a ##word##.',
                  correctWord: 'can',
                  wrongWord: 'hat',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_a_cat_in_a_hat.png',
                  sentenceFormat: 'A cat in a ##word##.',
                  correctWord: 'hat',
                  wrongWord: 'can',
                },
              ],
            },
          },
          10: {
            title: 'Story Chant',
            shortDescption: 'Story Chant',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S10_Dir.mp3',
            url: 'https://player.vimeo.com/video/381458540',
            type: 'video',
          },
          11: {
            title: 'Read. Then drag and drop.',
            shortDescption: 'Story Activity',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S11_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imgDnd3',
              problem: {
                question: [
                  { image: 'https://cdn.cloubot.com/PM/image/level2_unit02_SB_01.png', sentence: ['The ##man## sees the ##fan##.'] },
                  { image: 'https://cdn.cloubot.com/PM/image/level2_unit02_SB_02.png', sentence: ['The ##man## sees the ##can##.'] },
                  { image: 'https://cdn.cloubot.com/PM/image/level2_unit02_SB_03.png', sentence: ['The ##man## is so ##fat##.'] },
                  { image: 'https://cdn.cloubot.com/PM/image/level2_unit02_SB_04.png', sentence: ['The ##man## cannot see the ##map##.'] },
                ],
              },
              totalCount: 4,
            },
          },
          12: {
            title: 'Listen and type the word.',
            shortDescption: 'Quiz',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S12_Dir.mp3',
            type: 'unitTest',
            problem: [
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_fan.png', word: 'fan' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_can.png', word: 'can' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_man.png', word: 'man' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_map.png', word: 'map' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_cap.png', word: 'cap' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_tap.png', word: 'tap' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_cat.png', word: 'cat' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_fat.png', word: 'fat' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_hat.png', word: 'hat' },
            ],
          },
        },
      },
      3: {
        title: 'Short Vowel - ig · in · ip',
        stage: {
          1: {
            title: 'Sound Animation',
            shortDescption: 'Sound Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S1_Dir.mp3',
            url: 'https://player.vimeo.com/video/381457984',
            type: 'video',
          },
          2: {
            title: 'Drag and drop.',
            shortDescption: 'Learn the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S2_Dir.mp3',
            type: 'dnd',
            hasStep: false,
            customData: {
              isShortVowel: true,
              soundBaseUrl: 'https://cdn.cloubot.com/PM/audio/sounds/level2/',
              problem: [
                { 1: ['i'], 2: ['i', 'g'], type: 'dndType1', exampleWord: 'pig', exSound: 'p_ig_pig' },
                { 1: ['i'], 2: ['i', 'n'], type: 'dndType1', exampleWord: 'bin', exSound: 'b_in_bin' },
                { 1: ['i'], 2: ['i', 'p'], type: 'dndType1', exampleWord: 'lip', exSound: 'l_ip_lip' },
              ],
              totalCount: 3,
              type: 'alphabetDnd',
            },
          },
          3: {
            title: ['Listen and choose.', 'Drag and drop. Then type.'],
            shortDescption: 'Practice the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S3_Dir.mp3',
            type: 'phonemeSelectAndDnd',
            hasStep: true,
            problem: [
              {
                type: 'select',
                answer: { name: 'ig', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/ig.mp3' },
                word: {
                  name: 'pig',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/pig.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_pig.png',
                },
                candidate: ['ig', 'in'],
              },
              {
                type: 'select',
                answer: { name: 'in', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/in.mp3' },
                word: {
                  name: 'bin',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/bin.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_bin.png',
                },
                candidate: ['in', 'ip'],
              },
              {
                type: 'select',
                answer: { name: 'ip', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/ip.mp3' },
                word: {
                  name: 'lip',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/lip.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_lip.png',
                },
                candidate: ['ip', 'ig'],
              },
              {
                type: 'dnd',
                answer: { name: 'ig', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/ig.mp3' },
                word: {
                  name: 'pig',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/pig.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_pig.png',
                },
                candidate: ['ig', 'in', 'ip'],
              },
              {
                type: 'dnd',
                answer: { name: 'in', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/in.mp3' },
                word: {
                  name: 'bin',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/bin.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_bin.png',
                },
                candidate: ['ig', 'in', 'ip'],
              },
              {
                type: 'dnd',
                answer: { name: 'ip', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/ip.mp3' },
                word: {
                  name: 'lip',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/lip.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_lip.png',
                },
                candidate: ['ig', 'in', 'ip'],
              },
            ],
          },
          4: {
            title: 'Word Animation',
            shortDescption: 'Word Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S4_Dir.mp3',
            url: 'https://player.vimeo.com/video/381458345',
            type: 'video',
          },
          5: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Practice the Words Ⅰ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S5_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_pig.png',
                  question: 'p',
                  answer: 'ig',
                  candidate: ['ig', 'in', 'ip'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_big.png',
                  question: 'b',
                  answer: 'ig',
                  candidate: ['ig', 'in', 'ip'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_wig.png',
                  question: 'w',
                  answer: 'ig',
                  candidate: ['ig', 'in', 'ip'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_bin.png',
                  question: 'b',
                  answer: 'in',
                  candidate: ['ig', 'in', 'ip'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_win.png',
                  question: 'w',
                  answer: 'in',
                  candidate: ['ig', 'in', 'ip'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_pin.png',
                  question: 'p',
                  answer: 'in',
                  candidate: ['ig', 'in', 'ip'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_lip.png',
                  question: 'l',
                  answer: 'ip',
                  candidate: ['ig', 'in', 'ip'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_hip.png',
                  question: 'h',
                  answer: 'ip',
                  candidate: ['ig', 'in', 'ip'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_rip.png',
                  question: 'r',
                  answer: 'ip',
                  candidate: ['ig', 'in', 'ip'],
                },
              ],
              type: 'imgDnd',
              totalCount: 1,
            },
          },
          6: {
            title: 'Look and choose.',
            shortDescption: 'Practice the Words Ⅱ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S6_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'multiWordDnd',
              problem: [
                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_pig.png', word: 'pig' },
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_big.png', word: 'big' },
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_wig.png', word: 'wig' },
                  ],
                  words: ['pig', 'big', 'wig'],
                },
                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_bin.png', word: 'bin' },
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_win.png', word: 'win' },
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_pin.png', word: 'pin' },
                  ],
                  words: ['bin', 'win', 'pin'],
                },
                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_lip.png', word: 'lip' },
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_hip.png', word: 'hip' },
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_rip.png', word: 'rip' },
                  ],
                  words: ['lip', 'hip', 'rip'],
                },
              ],
              totalCount: 0, //사용하지 않음
            },
          },
          7: {
            title: 'Choose and type the word.',
            shortDescption: 'Practice the Words Ⅲ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S7_Dir.mp3',
            type: 'lineWord',
            problem: [
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_pig.png',
                spellings: [
                  { alphabet: 'a', isAnswer: false },
                  { alphabet: 'd', isAnswer: false },
                  { alphabet: 'p', isAnswer: true },
                  { alphabet: 'i', isAnswer: true ,isPink:true},
                  { alphabet: 'g', isAnswer: true ,isPink:true},
                  { alphabet: 'm', isAnswer: false },
                ],
                answer: 'pig',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_big.png',
                spellings: [
                  { alphabet: 'c', isAnswer: false },
                  { alphabet: 'a', isAnswer: false },
                  { alphabet: 'n', isAnswer: false },
                  { alphabet: 'b', isAnswer: true },
                  { alphabet: 'i', isAnswer: true ,isPink:true},
                  { alphabet: 'g', isAnswer: true ,isPink:true},
                ],
                answer: 'big',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_wig.png',
                spellings: [
                  { alphabet: 'i', isAnswer: false },
                  { alphabet: 'x', isAnswer: false },
                  { alphabet: 'a', isAnswer: false },
                  { alphabet: 'w', isAnswer: true },
                  { alphabet: 'i', isAnswer: true ,isPink:true},
                  { alphabet: 'g', isAnswer: true ,isPink:true},
                ],
                answer: 'wig',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_bin.png',
                spellings: [
                  { alphabet: 'b', isAnswer: true },
                  { alphabet: 'i', isAnswer: true ,isPink:true},
                  { alphabet: 'n', isAnswer: true ,isPink:true},
                  { alphabet: 's', isAnswer: false },
                  { alphabet: 'a', isAnswer: false },
                  { alphabet: 'm', isAnswer: false },
                ],
                answer: 'bin',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_win.png',
                spellings: [
                  { alphabet: 'a', isAnswer: false },
                  { alphabet: 'p', isAnswer: false },
                  { alphabet: 'w', isAnswer: true },
                  { alphabet: 'i', isAnswer: true ,isPink:true},
                  { alphabet: 'n', isAnswer: true ,isPink:true},
                  { alphabet: 'm', isAnswer: false },
                ],
                answer: 'win',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_pin.png',
                spellings: [
                  { alphabet: 'h', isAnswer: false },
                  { alphabet: 'a', isAnswer: false },
                  { alphabet: 'g', isAnswer: false },
                  { alphabet: 'p', isAnswer: true },
                  { alphabet: 'i', isAnswer: true ,isPink:true},
                  { alphabet: 'n', isAnswer: true ,isPink:true},
                ],
                answer: 'pin',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_lip.png',
                spellings: [
                  { alphabet: 'f', isAnswer: false },
                  { alphabet: 'l', isAnswer: true },
                  { alphabet: 'i', isAnswer: true ,isPink:true},
                  { alphabet: 'p', isAnswer: true ,isPink:true},
                  { alphabet: 'a', isAnswer: false },
                  { alphabet: 'n', isAnswer: false },
                ],
                answer: 'lip',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_hip.png',
                spellings: [
                  { alphabet: 'j', isAnswer: false },
                  { alphabet: 'a', isAnswer: false },
                  { alphabet: 'h', isAnswer: true },
                  { alphabet: 'i', isAnswer: true ,isPink:true},
                  { alphabet: 'p', isAnswer: true ,isPink:true},
                  { alphabet: 'm', isAnswer: false },
                ],
                answer: 'hip',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_rip.png',
                spellings: [
                  { alphabet: 'd', isAnswer: false },
                  { alphabet: 'r', isAnswer: true },
                  { alphabet: 'i', isAnswer: true ,isPink:true},
                  { alphabet: 'p', isAnswer: true ,isPink:true},
                  { alphabet: 'a', isAnswer: false },
                  { alphabet: 'c', isAnswer: false },
                ],
                answer: 'rip',
              },
            ],
          },
          8: {
            title: 'Listen and repeat. Then record your voice.',
            shortDescption: 'Story',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S8_Dir.mp3',
            type: 'record',
            customData: {
              bgImage: 'https://cdn.cloubot.com/PM/image/l2_u3_bg_wide.jpg',
              sound: 'Lv2_U3_reading',
              sentences: ['A ##big## ##pig##.', 'A ##wig##.', 'A ##big## ##pig## in a ##wig##.', '##Rip##!', 'The ##wig## ##rips##.', 'The ##wig## in the ##bin##.', 'A ##big## ##wig##.', 'The ##big## ##pig## in a ##big## ##wig##.', 'The ##pig## ##wins##!'],
            },
          },
          9: {
            title: 'Choose and type the word.',
            shortDescption: 'Words in the Story',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S9_Dir.mp3',
            type: 'write',
            customData: {
              type: 'imgSoundWrite',
              problem: [
                {
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_a_pig_in_a_wig.png',
                  sentenceFormat: 'A pig in a ##word##.',
                  correctWord: 'wig',
                  wrongWord: 'pin',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_a_wig_rips.png',
                  sentenceFormat: 'The wig ##word##s.',
                  correctWord: 'rip',
                  wrongWord: 'win',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_a_wig_in_a_bin.png',
                  sentenceFormat: 'The wig in the ##word##.',
                  correctWord: 'bin',
                  wrongWord: 'hip',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_the_pig_wins.png',
                  sentenceFormat: 'The pig ##word##s!',
                  correctWord: 'win',
                  wrongWord: 'rip',
                },
              ],
            },
          },
          10: {
            title: 'Story Chant',
            shortDescption: 'Story Chant',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S10_Dir.mp3',
            url: 'https://player.vimeo.com/video/381458555',
            type: 'video',
          },
          11: {
            title: 'Read. Then drag and drop.',
            shortDescption: 'Story Activity',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S11_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imgDnd3',
              problem: {
                question: [
                  { image: 'https://cdn.cloubot.com/PM/image/level2_unit03_SB_01.png', sentence: ['The ##pig## wears a ##wig##.'] },
                  { image: 'https://cdn.cloubot.com/PM/image/level2_unit03_SB_02.png', sentence: ['The ##pig## wears a ##fin##.'] },
                  { image: 'https://cdn.cloubot.com/PM/image/level2_unit03_SB_03.png', sentence: ["The ##pig##'##s## pants ##rip##."] },
                  { image: 'https://cdn.cloubot.com/PM/image/level2_unit03_SB_04.png', sentence: ['The ##pig## needs a ##big## ##pin##.'] },
                ],
              },
              totalCount: 4,
            },
          },
          12: {
            title: 'Listen and type the word.',
            shortDescption: 'Quiz',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S12_Dir.mp3',
            type: 'unitTest',
            problem: [
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_pig.png', word: 'pig' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_big.png', word: 'big' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_wig.png', word: 'wig' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_bin.png', word: 'bin' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_win.png', word: 'win' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_pin.png', word: 'pin' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_lip.png', word: 'lip' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_hip.png', word: 'hip' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_rip.png', word: 'rip' },
            ],
          },
        },
      },
      4: {
        title: 'Short Vowel - it · ix · id',
        stage: {
          1: {
            title: 'Sound Animation',
            shortDescption: 'Sound Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S1_Dir.mp3',
            url: 'https://player.vimeo.com/video/381457992',
            type: 'video',
          },
          2: {
            title: 'Drag and drop.',
            shortDescption: 'Learn the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S2_Dir.mp3',
            type: 'dnd',
            hasStep: false,
            customData: {
              isShortVowel: true,
              soundBaseUrl: 'https://cdn.cloubot.com/PM/audio/sounds/level2/',
              problem: [
                { 1: ['i'], 2: ['i', 't'], type: 'dndType1', exampleWord: 'kit', exSound: 'k_it_kit' },
                { 1: ['i'], 2: ['i', 'x'], type: 'dndType1', exampleWord: 'mix', exSound: 'm_ix_mix' },
                { 1: ['i'], 2: ['i', 'd'], type: 'dndType1', exampleWord: 'lid', exSound: 'l_id_lid' },
              ],
              totalCount: 3,
              type: 'alphabetDnd',
            },
          },
          3: {
            title: ['Listen and choose.', 'Drag and drop. Then type.'],
            shortDescption: 'Practice the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S3_Dir.mp3',
            type: 'phonemeSelectAndDnd',
            hasStep: true,
            problem: [
              {
                type: 'select',
                answer: { name: 'it', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/it.mp3' },
                word: {
                  name: 'kit',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/kit.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_kit.png',
                },
                candidate: ['it', 'ix'],
              },
              {
                type: 'select',
                answer: { name: 'ix', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/ix.mp3' },
                word: {
                  name: 'mix',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/mix.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_mix.png',
                },
                candidate: ['ix', 'id'],
              },
              {
                type: 'select',
                answer: { name: 'id', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/id.mp3' },
                word: {
                  name: 'lid',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/lid.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_lid.png',
                },
                candidate: ['id', 'it'],
              },
              {
                type: 'dnd',
                answer: { name: 'it', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/it.mp3' },
                word: {
                  name: 'kit',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/kit.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_kit.png',
                },
                candidate: ['it', 'ix', 'id'],
              },
              {
                type: 'dnd',
                answer: { name: 'ix', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/ix.mp3' },
                word: {
                  name: 'mix',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/mix.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_mix.png',
                },
                candidate: ['it', 'ix', 'id'],
              },
              {
                type: 'dnd',
                answer: { name: 'id', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/id.mp3' },
                word: {
                  name: 'lid',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/lid.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_lid.png',
                },
                candidate: ['it', 'ix', 'id'],
              },
            ],
          },
          4: {
            title: 'Word Animation',
            shortDescption: 'Word Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S4_Dir.mp3',
            url: 'https://player.vimeo.com/video/381458356',
            type: 'video',
          },
          5: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Practice the Words Ⅰ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S5_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_kit.png',
                  question: 'k',
                  answer: 'it',
                  candidate: ['it', 'ix', 'id'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_sit.png',
                  question: 's',
                  answer: 'it',
                  candidate: ['it', 'ix', 'id'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_hit.png',
                  question: 'h',
                  answer: 'it',
                  candidate: ['it', 'ix', 'id'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_mix.png',
                  question: 'm',
                  answer: 'ix',
                  candidate: ['it', 'ix', 'id'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_fix.png',
                  question: 'f',
                  answer: 'ix',
                  candidate: ['it', 'ix', 'id'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_six.png',
                  question: 's',
                  answer: 'ix',
                  candidate: ['it', 'ix', 'id'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_lid.png',
                  question: 'l',
                  answer: 'id',
                  candidate: ['it', 'ix', 'id'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_kid.png',
                  question: 'k',
                  answer: 'id',
                  candidate: ['it', 'ix', 'id'],
                },
              ],
              type: 'imgDnd',
              totalCount: 1,
            },
          },
          6: {
            title: 'Look and choose.',
            shortDescption: 'Practice the Words Ⅱ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S6_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'multiWordDnd',
              problem: [
                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_kit.png', word: 'kit' },
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_sit.png', word: 'sit' },
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_hit.png', word: 'hit' },
                  ],
                  words: ['kit', 'sit', 'hit'],
                },
                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_mix.png', word: 'mix' },
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_fix.png', word: 'fix' },
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_six.png', word: 'six' },
                  ],
                  words: ['mix', 'fix', 'six'],
                },
                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_lid.png', word: 'lid' },
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_kid.png', word: 'kid' },
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_fix.png', word: 'fix' },
                  ],
                  words: ['lid', 'kid', 'fix'],
                },
              ],
              totalCount: 0, //사용하지 않음
            },
          },
          7: {
            title: 'Choose and type the word.',
            shortDescption: 'Practice the Words Ⅲ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S7_Dir.mp3',
            type: 'lineWord',
            problem: [
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_kit.png',
                spellings: [
                  { alphabet: 'a', isAnswer: false },
                  { alphabet: 'd', isAnswer: false },
                  { alphabet: 'k', isAnswer: true },
                  { alphabet: 'i', isAnswer: true ,isPink:true},
                  { alphabet: 't', isAnswer: true ,isPink:true},
                  { alphabet: 'm', isAnswer: false },
                ],
                answer: 'kit',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_sit.png',
                spellings: [
                  { alphabet: 'c', isAnswer: false },
                  { alphabet: 'n', isAnswer: false },
                  { alphabet: 'a', isAnswer: false },
                  { alphabet: 's', isAnswer: true },
                  { alphabet: 'i', isAnswer: true ,isPink:true},
                  { alphabet: 't', isAnswer: true ,isPink:true},
                ],
                answer: 'sit',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_hit.png',
                spellings: [
                  { alphabet: 'o', isAnswer: false },
                  { alphabet: 'x', isAnswer: false },
                  { alphabet: 'a', isAnswer: false },
                  { alphabet: 'h', isAnswer: true },
                  { alphabet: 'i', isAnswer: true ,isPink:true},
                  { alphabet: 't', isAnswer: true ,isPink:true},
                ],
                answer: 'hit',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_mix.png',
                spellings: [
                  { alphabet: 'm', isAnswer: true },
                  { alphabet: 'i', isAnswer: true ,isPink:true},
                  { alphabet: 'x', isAnswer: true ,isPink:true},
                  { alphabet: 's', isAnswer: false },
                  { alphabet: 'a', isAnswer: false },
                  { alphabet: 'm', isAnswer: false },
                ],
                answer: 'mix',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_fix.png',
                spellings: [
                  { alphabet: 'a', isAnswer: false },
                  { alphabet: 'p', isAnswer: false },
                  { alphabet: 'f', isAnswer: true },
                  { alphabet: 'i', isAnswer: true ,isPink:true},
                  { alphabet: 'x', isAnswer: true ,isPink:true},
                  { alphabet: 'm', isAnswer: false },
                ],
                answer: 'fix',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_six.png',
                spellings: [
                  { alphabet: 'h', isAnswer: false },
                  { alphabet: 'a', isAnswer: false },
                  { alphabet: 'g', isAnswer: false },
                  { alphabet: 's', isAnswer: true },
                  { alphabet: 'i', isAnswer: true ,isPink:true},
                  { alphabet: 'x', isAnswer: true ,isPink:true},
                ],
                answer: 'six',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_lid.png',
                spellings: [
                  { alphabet: 'f', isAnswer: false },
                  { alphabet: 'l', isAnswer: true },
                  { alphabet: 'i', isAnswer: true ,isPink:true},
                  { alphabet: 'd', isAnswer: true ,isPink:true},
                  { alphabet: 'a', isAnswer: false },
                  { alphabet: 'n', isAnswer: false },
                ],
                answer: 'lid',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_kid.png',
                spellings: [
                  { alphabet: 'j', isAnswer: false },
                  { alphabet: 'a', isAnswer: false },
                  { alphabet: 'k', isAnswer: true },
                  { alphabet: 'i', isAnswer: true ,isPink:true},
                  { alphabet: 'd', isAnswer: true ,isPink:true},
                  { alphabet: 'm', isAnswer: false },
                ],
                answer: 'kid',
              },
            ],
          },
          8: {
            title: 'Listen and repeat. Then record your voice.',
            shortDescption: 'Story',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S8_Dir.mp3',
            type: 'record',
            customData: {
              bgImage: 'https://cdn.cloubot.com/PM/image/l2_u4_bg_wide.jpg',
              sound: 'Lv2_U4_reading',
              sentences: ['Jam in a can.', '##Six## ham into the can.', '##Mix## ##six## ham and jam.', '“##Mix##! ##Mix##! ##Mix##!”', 'A ##lid##.', '##Sit## and ##hit## the ##lid##.', '“##Hit##! ##Hit##! ##Hit##!”', '##Six## ##kids## in a can!'],
            },
          },
          9: {
            title: 'Choose and type the word.',
            shortDescption: 'Words in the Story',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S9_Dir.mp3',
            type: 'write',
            customData: {
              type: 'imgSoundWrite',
              problem: [
                {
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_six_ham_into_the_can.png',
                  sentenceFormat: '##word## ham into the can.',
                  correctWord: 'Six',
                  wrongWord: 'Mix',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_mix_six_ham_and_jam.png',
                  sentenceFormat: '##word## six ham and jam.',
                  correctWord: 'Mix',
                  wrongWord: 'Hit',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_sit_and_hit_the_lid.png',
                  sentenceFormat: 'Sit and ##word## the lid.',
                  correctWord: 'hit',
                  wrongWord: 'fix',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_six_kids_in_a_can.png',
                  sentenceFormat: 'Six ##word##s in a can.',
                  correctWord: 'kid',
                  wrongWord: 'lid',
                },
              ],
            },
          },
          10: {
            title: 'Story Chant',
            shortDescption: 'Story Chant',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S10_Dir.mp3',
            url: 'https://player.vimeo.com/video/381458561',
            type: 'video',
          },
          11: {
            title: 'Read. Then drag and drop.',
            shortDescption: 'Story Activity',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S11_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imgDnd3',
              problem: {
                question: [
                  { image: 'https://cdn.cloubot.com/PM/image/level2_unit04_SB_01.png', sentence: ['One ##kid## wears a bib.'] },
                  { image: 'https://cdn.cloubot.com/PM/image/level2_unit04_SB_02.png', sentence: ['Two ##kids## ##sit## on a mat.'] },
                  { image: 'https://cdn.cloubot.com/PM/image/level2_unit04_SB_03.png', sentence: ['Three ##kids## ##mix## colors.'] },
                  { image: 'https://cdn.cloubot.com/PM/image/level2_unit04_SB_04.png', sentence: ['Four ##kids## ##fix## a rib.'] },
                ],
              },
              totalCount: 4,
            },
          },
          12: {
            title: 'Listen and type the word.',
            shortDescption: 'Quiz',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S12_Dir.mp3',
            type: 'unitTest',
            problem: [
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_kit.png', word: 'kit' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_sit.png', word: 'sit' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_hit.png', word: 'hit' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_mix.png', word: 'mix' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_fix.png', word: 'fix' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_six.png', word: 'six' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_lid.png', word: 'lid' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_kid.png', word: 'kid' },
            ],
          },
        },
      },
      5: {
        title: 'Review - Unit 1 ~ Unit 4',
        stage: {
          1: {
            title: 'Listen. Then drag and drop.',
            shortDescption: 'Review the Sounds Ⅰ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_Review_S1_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imgDnd2',
              hasSoundBtn: true,
              answerAbsolute: true,
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_dad.png',
                  question: 'd',
                  answer: 'ad',
                  candidate: { 0: ['c', 'g', 'l', 'b', 't', 'd'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_tag.png',
                  question: 't',
                  answer: 'ag',
                  candidate: { 0: ['c', 'g', 'l', 'b', 't', 'd'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_ram.png',
                  question: 'r',
                  answer: 'am',
                  candidate: { 0: ['c', 'r', 'l', 'b', 't', 'd'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_man.png',
                  question: 'm',
                  answer: 'an',
                  candidate: { 0: ['m', 'g', 'l', 'b', 't', 'd'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_map.png',
                  question: 'm',
                  answer: 'ap',
                  candidate: { 0: ['c', 'm', 'l', 'b', 't', 'd'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_cat.png',
                  question: 'c',
                  answer: 'at',
                  candidate: { 0: ['c', 'g', 'l', 'b', 't', 'd'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_pig.png',
                  question: 'p',
                  answer: 'ig',
                  candidate: { 0: ['p', 'g', 'l', 'b', 't', 'd'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_win.png',
                  question: 'w',
                  answer: 'in',
                  candidate: { 0: ['w', 'g', 'l', 'b', 't', 'v'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_hip.png',
                  question: 'h',
                  answer: 'ip',
                  candidate: { 0: ['c', 'g', 'h', 'b', 't', 'd'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_sit.png',
                  question: 's',
                  answer: 'it',
                  candidate: { 0: ['c', 's', 'l', 'b', 't', 'd'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_fix.png',
                  question: 'f',
                  answer: 'ix',
                  candidate: { 0: ['f', 'g', 'l', 'b', 't', 'd'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_lid.png',
                  question: 'l',
                  answer: 'id',
                  candidate: { 0: ['c', 'g', 'l', 'b', 't', 'd'] },
                },
              ],
              totalCount: 1,
            },
          },
          2: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Review the Words Ⅰ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_Review05_S2_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imgDnd4',

              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_bad.png',
                  question: 'b',
                  answer: 'ad',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level2/image_bad.png',
                    'https://cdn.cloubot.com/PM/image/level2/image_tag.png',
                    'https://cdn.cloubot.com/PM/image/level2/image_jam.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_tag.png',
                  question: 't',
                  answer: 'ag',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level2/image_pin.png',
                    'https://cdn.cloubot.com/PM/image/level2/image_tag.png',
                    'https://cdn.cloubot.com/PM/image/level2/image_ham.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_ram.png',
                  question: 'r',
                  answer: 'am',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level2/image_ram.png',
                    'https://cdn.cloubot.com/PM/image/level2/image_man.png',
                    'https://cdn.cloubot.com/PM/image/level2/image_wag.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_can.png',
                  question: 'c',
                  answer: 'an',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level2/image_cap.png',
                    'https://cdn.cloubot.com/PM/image/level2/image_can.png',
                    'https://cdn.cloubot.com/PM/image/level2/image_wag.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_cap.png',
                  question: 'c',
                  answer: 'ap',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level2/image_ham.png',
                    'https://cdn.cloubot.com/PM/image/level2/image_cap.png',
                    'https://cdn.cloubot.com/PM/image/level2/image_bag.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_hat.png',
                  question: 'h',
                  answer: 'at',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level2/image_ram.png',
                    'https://cdn.cloubot.com/PM/image/level2/image_ham.png',
                    'https://cdn.cloubot.com/PM/image/level2/image_hat.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_big.png',
                  question: 'b',
                  answer: 'ig',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level2/image_big.png',
                    'https://cdn.cloubot.com/PM/image/level2/image_kid.png',
                    'https://cdn.cloubot.com/PM/image/level2/image_pin.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_pin.png',
                  question: 'p',
                  answer: 'in',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level2/image_kid.png',
                    'https://cdn.cloubot.com/PM/image/level2/image_pin.png',
                    'https://cdn.cloubot.com/PM/image/level2/image_big.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_lip.png',
                  question: 'l',
                  answer: 'ip',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level2/image_lip.png',
                    'https://cdn.cloubot.com/PM/image/level2/image_lid.png',
                    'https://cdn.cloubot.com/PM/image/level2/image_win.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_hit.png',
                  question: 'h',
                  answer: 'it',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level2/image_fix.png',
                    'https://cdn.cloubot.com/PM/image/level2/image_kid.png',
                    'https://cdn.cloubot.com/PM/image/level2/image_hit.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_mix.png',
                  question: 'm',
                  answer: 'ix',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level2/image_mix.png',
                    'https://cdn.cloubot.com/PM/image/level2/image_hit.png',
                    'https://cdn.cloubot.com/PM/image/level2/image_lip.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_kid.png',
                  question: 'k',
                  answer: 'id',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level2/image_fix.png',
                    'https://cdn.cloubot.com/PM/image/level2/image_kid.png',
                    'https://cdn.cloubot.com/PM/image/level2/image_lip.png',
                  ],
                },
              ],
              totalCount: 1,
            },
          },
          3: {
            title: 'Look and listen. Then choose.',
            shortDescption: 'Review the Words Ⅱ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_Review05_S3_Dir.mp3',
            type: 'imgSelect',
            customData: {
              type: 'imgSelectImg', //imgSelectImg, imgSelectWord

              problem: [
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level2/image_sad.png',
                  qWord: 'sad',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_man.png', word: 'man', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_dad.png', word: 'dad', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_ham.png', word: 'ham', isAnswer: false },
                  ],
                },
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level2/image_tap.png',
                  qWord: 'tap',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_map.png', word: 'map', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_tag.png', word: 'tag', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_bad.png', word: 'bad', isAnswer: false },
                  ],
                },
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level2/image_bin.png',
                  qWord: 'bin',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_pin.png', word: 'pin', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_mix.png', word: 'mix', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_lip.png', word: 'lip', isAnswer: false },
                  ],
                },
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level2/image_six.png',
                  qWord: 'six',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_win.png', word: 'win', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_sit.png', word: 'sit', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_fix.png', word: 'fix', isAnswer: true },
                  ],
                },
              ],
            },
          },
          4: {
            title: 'Look and choose.',
            shortDescption: 'Word Puzzle',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_Review_S4_Dir.mp3',
            type: 'crossWords',
            customData: {
              imageBaseUrl: 'https://cdn.cloubot.com/PM/image/level2/',
              problem: [
                {
                  words: [
                    {
                      name: 'dad',
                      xy: [
                        [0, 1],
                        [1, 1],
                        [2, 1],
                      ],
                    },
                    {
                      name: 'tag',
                      xy: [
                        [1, 0],
                        [1, 1],
                        [1, 2],
                      ],
                    },
                    {
                      name: 'ram',
                      xy: [
                        [2, 5],
                        [3, 5],
                        [4, 5],
                      ],
                    },
                    {
                      name: 'fan',
                      xy: [
                        [5, 2],
                        [5, 3],
                        [5, 4],
                      ],
                    },
                    {
                      name: 'pig',
                      xy: [
                        [4, 0],
                        [4, 1],
                        [4, 2],
                      ],
                    },
                    {
                      name: 'lip',
                      xy: [
                        [6, 5],
                        [6, 6],
                        [6, 7],
                      ],
                    },
                    {
                      name: 'hit',
                      xy: [
                        [6, 0],
                        [6, 1],
                        [6, 2],
                      ],
                    },
                    {
                      name: 'lid',
                      xy: [
                        [0, 4],
                        [0, 5],
                        [0, 6],
                      ],
                    },
                  ],
                  coords: [
                    ['a', 't', 'v', 'g', 'p', 't', 'h'],
                    ['d', 'a', 'd', 'e', 'i', 'e', 'i'],
                    ['a', 'g', 'a', 't', 'g', 'f', 't'],
                    ['s', 'e', 'n', 'e', 'e', 'a', 'u'],
                    ['l', 'c', 'd', 'k', 'd', 'n', 'e'],
                    ['i', 'd', 'r', 'a', 'm', 'e', 'l'],
                    ['d', 'p', 'e', 'c', 's', 'n', 'i'],
                    ['t', 'a', 'd', 'e', 'c', 'e', 'p'],
                  ],
                },
                {
                  words: [
                    {
                      name: 'bad',
                      xy: [
                        [1, 0],
                        [2, 0],
                        [3, 0],
                      ],
                    },
                    {
                      name: 'wag',
                      xy: [
                        [0, 1],
                        [0, 2],
                        [0, 3],
                      ],
                    },
                    {
                      name: 'man',
                      xy: [
                        [1, 4],
                        [1, 5],
                        [1, 6],
                      ],
                    },
                    {
                      name: 'hat',
                      xy: [
                        [5, 2],
                        [5, 3],
                        [5, 4],
                      ],
                    },
                    {
                      name: 'wig',
                      xy: [
                        [2, 5],
                        [3, 5],
                        [4, 5],
                      ],
                    },
                    {
                      name: 'bin',
                      xy: [
                        [3, 4],
                        [3, 5],
                        [3, 6],
                      ],
                    },
                    {
                      name: 'hip',
                      xy: [
                        [4, 7],
                        [5, 7],
                        [6, 7],
                      ],
                    },
                    {
                      name: 'rip',
                      xy: [
                        [6, 5],
                        [6, 6],
                        [6, 7],
                      ],
                    },
                  ],
                  coords: [
                    ['a', 'b', 'a', 'd', 'i', 't', 'e'],
                    ['w', 'g', 'v', 'e', 'i', 'e', 'r'],
                    ['a', 'a', 'a', 't', 't', 'h', 't'],
                    ['g', 't', 'n', 'e', 's', 'a', 'a'],
                    ['p', 'm', 'e', 'b', 'a', 't', 'u'],
                    ['g', 'a', 'w', 'i', 'g', 'o', 'r'],
                    ['a', 'n', 'e', 'n', 'e', 'p', 'i'],
                    ['w', 'd', 'v', 'q', 'h', 'i', 'p'],
                  ],
                },
              ],
            },
          },
          5: {
            title: 'Look at the picture. Choose the picture that has different sound. (Rhyme)',
            shortDescption: 'Review the Sounds Ⅱ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_Review_S5_Dir.mp3',
            type: 'imgSelect',
            customData: {
              type: 'imgSelectWord', //imgSelectImg, imgSelectWord
              problem: [
                {
                  qWord: 'am',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_ram.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_ham.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_map.png', isAnswer: true },
                  ],
                },
                {
                  qWord: 'ap',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_cat.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_cap.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_tap.png', isAnswer: false },
                  ],
                },
                {
                  qWord: 'id',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_fix.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_lid.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_kid.png', isAnswer: false },
                  ],
                },
                {
                  qWord: 'ix',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_sit.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_mix.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_six.png', isAnswer: false },
                  ],
                },
              ],
            },
          },
          6: {
            title: 'Read and choose.',
            shortDescption: 'Review the Words Ⅲ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_Review_S6_Dir.mp3',
            type: 'imgSelect',
            customData: {
              type: 'imgSelectSentence', //imgSelectImg, imgSelectWord, imgSelectSound
              problem: [
                {
                  qSentence: 'jam and ham',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_hat_and_ham.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_jam_and_ham.png', isAnswer: true },
                  ],
                },
                {
                  qSentence: 'a sad dad',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_a_sad_dad.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_a_happy_dad.png', isAnswer: false },
                  ],
                },
                {
                  qSentence: 'a map and a cap',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_a_map_and_a_cap.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_a_map_and_a_can.png', isAnswer: false },
                  ],
                },
                {
                  qSentence: 'a pig in a bin',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_a_wig_in_a_bin.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_a_pig_in_a_bin.png', isAnswer: true },
                  ],
                },
              ],
            },
          },
          7: {
            title: 'Look and choose. Then type the word.',
            shortDescption: 'Review the Words Ⅳ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_Review_S7_Dir.mp3',
            type: 'write',
            customData: {
              type: 'multiReview',
              problem: [
                {
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_dad.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_wig.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_pin.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_ham.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_kit.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_cap.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_fat.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_fix.png', isAnswer: false },
                  ],
                  exVowel: ['Short', 'a'],
                },
                {
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_bad.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_pig.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_bin.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_hat.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_sit.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_ram.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_tap.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_kid.png', isAnswer: true },
                  ],
                  exVowel: ['Short', 'i'],
                },
              ],
              totalCount: 4,
            },
          },
          8: {
            title: 'Choose the word to create your sentence.',
            shortDescption: 'Make Your Sentence',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_Review_S8_Dir.mp3',
            type: 'write',
            customData: {
              type: 'singleReview',
              problem: [
                {
                  imageBaseUrl: 'https://cdn.cloubot.com/PM/image/level2/',
                  sentenceFormat: 'Dad wants ##word##.',
                  words: ['ham', 'jam'],
                },
                {
                  imageBaseUrl: 'https://cdn.cloubot.com/PM/image/level2/',
                  sentenceFormat: 'The man sees the ##word##.',
                  words: ['fan', 'can'],
                },
                {
                  imageBaseUrl: 'https://cdn.cloubot.com/PM/image/level2/',
                  sentenceFormat: 'The pig wears a ##word##.',
                  words: ['wig', 'hat'],
                },
                {
                  imageBaseUrl: 'https://cdn.cloubot.com/PM/image/level2/',
                  sentenceFormat: 'The ##word## sits on a mat.',
                  words: ['kid', 'man'],
                },
              ],
            },
          },
        },
      },
    },
  },
  {
    bookName: '2b',
    bookCode: 'PM-L4V1',
    validation: {
      purchaseCode: 'someRandomCode',
      accessToken: 'short-lived-access-token',
    },
    unit: {
      6: {
        title: 'Short Vowel - ug · un · ut',
        stage: {
          1: {
            title: 'Sound Animation',
            shortDescption: 'Sound Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S1_Dir.mp3',
            url: 'https://player.vimeo.com/video/381457998',
            type: 'video',
          },
          2: {
            title: 'Drag and drop.',
            shortDescption: 'Learn the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S2_Dir.mp3',
            type: 'dnd',
            hasStep: false,
            customData: {
              isShortVowel: true,
              soundBaseUrl: 'https://cdn.cloubot.com/PM/audio/sounds/level2/',
              problem: [
                { 1: ['u'], 2: ['u', 'g'], type: 'dndType1', exampleWord: 'bug', exSound: 'b_ug_bug' },
                { 1: ['u'], 2: ['u', 'n'], type: 'dndType1', exampleWord: 'sun', exSound: 's_un_sun' },
                { 1: ['u'], 2: ['u', 't'], type: 'dndType1', exampleWord: 'nut', exSound: 'n_ut_nut' },
              ],
              totalCount: 3,
              type: 'alphabetDnd',
            },
          },
          3: {
            title: ['Listen and choose.', 'Drag and drop. Then type.'],
            shortDescption: 'Practice the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S3_Dir.mp3',
            type: 'phonemeSelectAndDnd',
            hasStep: true,
            problem: [
              {
                type: 'select',
                answer: { name: 'ug', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/ug.mp3' },
                word: {
                  name: 'bug',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/bug.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_bug.png',
                },
                candidate: ['ug', 'un'],
              },
              {
                type: 'select',
                answer: { name: 'un', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/un.mp3' },
                word: {
                  name: 'sun',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/sun.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_sun.png',
                },
                candidate: ['un', 'ut'],
              },
              {
                type: 'select',
                answer: { name: 'ut', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/ut.mp3' },
                word: {
                  name: 'nut',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/nut.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_nut.png',
                },
                candidate: ['ut', 'ug'],
              },
              {
                type: 'dnd',
                answer: { name: 'ug', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/ug.mp3' },
                word: {
                  name: 'bug',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/bug.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_bug.png',
                },
                candidate: ['ug', 'un', 'ut'],
              },
              {
                type: 'dnd',
                answer: { name: 'un', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/un.mp3' },
                word: {
                  name: 'sun',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/sun.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_sun.png',
                },
                candidate: ['ug', 'un', 'ut'],
              },
              {
                type: 'dnd',
                answer: { name: 'ut', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/ut.mp3' },
                word: {
                  name: 'nut',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/nut.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_nut.png',
                },
                candidate: ['ug', 'un', 'ut'],
              },
            ],
            customData: {},
          },
          4: {
            title: 'Word Animation',
            shortDescption: 'Word Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S4_Dir.mp3',
            url: 'https://player.vimeo.com/video/381458368',
            type: 'video',
          },
          5: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Practice the Words Ⅰ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S5_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_bug.png',
                  question: 'b',
                  answer: 'ug',
                  candidate: ['ug', 'un', 'ut'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_hug.png',
                  question: 'h',
                  answer: 'ug',
                  candidate: ['ug', 'un', 'ut'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_rug.png',
                  question: 'r',
                  answer: 'ug',
                  candidate: ['ug', 'un', 'ut'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_sun.png',
                  question: 's',
                  answer: 'un',
                  candidate: ['ug', 'un', 'ut'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_bun.png',
                  question: 'b',
                  answer: 'un',
                  candidate: ['ug', 'un', 'ut'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_run.png',
                  question: 'r',
                  answer: 'un',
                  candidate: ['ug', 'un', 'ut'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_nut.png',
                  question: 'n',
                  answer: 'ut',
                  candidate: ['ug', 'un', 'ut'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_hut.png',
                  question: 'h',
                  answer: 'ut',
                  candidate: ['ug', 'un', 'ut'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_cut.png',
                  question: 'c',
                  answer: 'ut',
                  candidate: ['ug', 'un', 'ut'],
                },
              ],
              type: 'imgDnd',
              totalCount: 1,
            },
          },
          6: {
            title: 'Look and choose.',
            shortDescption: 'Practice the Words Ⅱ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S6_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'multiWordDnd',
              problem: [
                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_bug.png', word: 'bug' },
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_hug.png', word: 'hug' },
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_rug.png', word: 'rug' },
                  ],
                  words: ['bug', 'hug', 'rug'],
                },
                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_sun.png', word: 'sun' },
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_bun.png', word: 'bun' },
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_run.png', word: 'run' },
                  ],
                  words: ['sun', 'bun', 'run'],
                },
                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_nut.png', word: 'nut' },
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_hut.png', word: 'hut' },
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_cut.png', word: 'cut' },
                  ],
                  words: ['nut', 'hut', 'cut'],
                },
              ],
              totalCount: 0, //사용하지 않음
            },
          },
          7: {
            title: 'Choose and type the word.',
            shortDescption: 'Practice the Words Ⅲ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S7_Dir.mp3',
            type: 'lineWord',
            problem: [
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_bug.png',
                spellings: [
                  { alphabet: 's', isAnswer: false },
                  { alphabet: 'b', isAnswer: false },
                  { alphabet: 'u', isAnswer: true ,isPink:true},
                  { alphabet: 'g', isAnswer: true ,isPink:true},
                  { alphabet: 'h', isAnswer: true },
                  { alphabet: 'f', isAnswer: false },
                ],
                answer: 'bug',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_hug.png',
                spellings: [
                  { alphabet: 'c', isAnswer: false },
                  { alphabet: 'a', isAnswer: false },
                  { alphabet: 'u', isAnswer: false },
                  { alphabet: 'h', isAnswer: true },
                  { alphabet: 'u', isAnswer: true ,isPink:true},
                  { alphabet: 'g', isAnswer: true ,isPink:true},
                ],
                answer: 'hug',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_rug.png',
                spellings: [
                  { alphabet: 'k', isAnswer: false },
                  { alphabet: 'j', isAnswer: false },
                  { alphabet: 's', isAnswer: false },
                  { alphabet: 'r', isAnswer: true },
                  { alphabet: 'u', isAnswer: true ,isPink:true},
                  { alphabet: 'g', isAnswer: true ,isPink:true },
                ],
                answer: 'rug',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_sun.png',
                spellings: [
                  { alphabet: 'g', isAnswer: true },
                  { alphabet: 'h', isAnswer: true },
                  { alphabet: 's', isAnswer: true },
                  { alphabet: 'u', isAnswer: false ,isPink:true},
                  { alphabet: 'n', isAnswer: false ,isPink:true},
                  { alphabet: 'k', isAnswer: false },
                ],
                answer: 'sun',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_bun.png',
                spellings: [
                  { alphabet: 'b', isAnswer: false },
                  { alphabet: 'u', isAnswer: false ,isPink:true},
                  { alphabet: 'n', isAnswer: true ,isPink:true },
                  { alphabet: 'q', isAnswer: true },
                  { alphabet: 'r', isAnswer: true },
                  { alphabet: 'w', isAnswer: false },
                ],
                answer: 'bun',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_run.png',
                spellings: [
                  { alphabet: 'i', isAnswer: false },
                  { alphabet: 'r', isAnswer: false },
                  { alphabet: 'u', isAnswer: false ,isPink:true},
                  { alphabet: 'n', isAnswer: true ,isPink:true},
                  { alphabet: 'k', isAnswer: true },
                  { alphabet: 'z', isAnswer: true },
                ],
                answer: 'run',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_nut.png',
                spellings: [
                  { alphabet: 'n', isAnswer: false },
                  { alphabet: 'u', isAnswer: true ,isPink:true},
                  { alphabet: 't', isAnswer: true ,isPink:true},
                  { alphabet: 'y', isAnswer: true },
                  { alphabet: 'g', isAnswer: false },
                  { alphabet: 'h', isAnswer: false },
                ],
                answer: 'nut',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_hut.png',
                spellings: [
                  { alphabet: 'b', isAnswer: false },
                  { alphabet: 'z', isAnswer: false },
                  { alphabet: 'c', isAnswer: true },
                  { alphabet: 'h', isAnswer: true ,isPink:true},
                  { alphabet: 'u', isAnswer: true ,isPink:true},
                  { alphabet: 't', isAnswer: false },
                ],
                answer: 'hut',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_cut.png',
                spellings: [
                  { alphabet: 'd', isAnswer: false },
                  { alphabet: 'k', isAnswer: true },
                  { alphabet: 'c', isAnswer: true },
                  { alphabet: 'u', isAnswer: true ,isPink:true},
                  { alphabet: 't', isAnswer: false ,isPink:true},
                  { alphabet: 'v', isAnswer: false },
                ],
                answer: 'cut',
              },
            ],
          },
          8: {
            title: 'Listen and repeat. Then record your voice.',
            shortDescption: 'Story',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S8_Dir.mp3',
            type: 'record',
            customData: {
              bgImage: 'https://cdn.cloubot.com/PM/image/l2_u6_bg_wide.jpg',
              sound: 'Lv2_U6_reading',
              sentences: ['A ##bug## in the ##sun##.', 'A ##hut##!', 'A ##nut## in the ##hut##?', 'A ##bun##?', 'A ##nut## in the ##bun##?', '##Cut## the ##bun##.', 'A ##nut## in the ##bun##!', 'My ##nut##! ##Hug## the ##nut##.'],
            },
          },
          9: {
            title: 'Choose and type the word.',
            shortDescption: 'Words in the Story',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S9_Dir.mp3',
            type: 'write',
            customData: {
              type: 'imgSoundWrite',
              problem: [
                {
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_a_bug_in_the_sun.png',
                  sentenceFormat: 'A bug in the ##word##.',
                  correctWord: 'sun',
                  wrongWord: 'bun',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_a_nut_in_the_hut.png',
                  sentenceFormat: 'A nut in the ##word##.',
                  correctWord: 'hut',
                  wrongWord: 'cut',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_a_nut_in_the_bun.png',
                  sentenceFormat: 'A ##word## in the bun.',
                  correctWord: 'nut',
                  wrongWord: 'rug',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_hug_the_nut.png',
                  sentenceFormat: '##word## the nut.',
                  correctWord: 'Hug',
                  wrongWord: 'Run',
                },
              ],
            },
          },
          10: {
            title: 'Story Chant',
            shortDescption: 'Story Chant',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S10_Dir.mp3',
            url: 'https://player.vimeo.com/video/381458574',
            type: 'video',
          },
          11: {
            title: 'Read. Then drag and drop.',
            shortDescption: 'Story Activity',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S11_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imgDnd3',
              problem: {
                question: [
                  { image: 'https://cdn.cloubot.com/PM/image/level2_unit06_SB_01.png', sentence: ['##Bug## lives in a ##hut##.'] },
                  { image: 'https://cdn.cloubot.com/PM/image/level2_unit06_SB_02.png', sentence: ['The ##hut## is in a ##nut##.'] },
                  { image: 'https://cdn.cloubot.com/PM/image/level2_unit06_SB_03.png', sentence: ['The ##nut## is under a ##rug##.'] },
                  { image: 'https://cdn.cloubot.com/PM/image/level2_unit06_SB_04.png', sentence: ['But where is ##Bug##?'] },
                ],
              },
              totalCount: 4,
            },
          },
          12: {
            title: 'Listen and type the word.',
            shortDescption: 'Quiz',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S12_Dir.mp3',
            type: 'unitTest',
            problem: [
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_bug.png', word: 'bug' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_hug.png', word: 'hug' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_rug.png', word: 'rug' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_sun.png', word: 'sun' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_bun.png', word: 'bun' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_run.png', word: 'run' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_nut.png', word: 'nut' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_hut.png', word: 'hut' },
            ],
          },
        },
      },
      7: {
        title: 'Short Vowel - ub · ud · um · up',
        stage: {
          1: {
            title: 'Sound Animation',
            shortDescption: 'Sound Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S1_Dir.mp3',
            url: 'https://player.vimeo.com/video/381458009',
            type: 'video',
          },
          2: {
            title: 'Drag and drop.',
            shortDescption: 'Learn the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S2_Dir.mp3',
            type: 'dnd',
            hasStep: false,
            customData: {
              isShortVowel: true,
              soundBaseUrl: 'https://cdn.cloubot.com/PM/audio/sounds/level2/',
              problem: [
                { 1: ['u'], 2: ['u', 'b'], type: 'dndType1', exampleWord: 'tub', exSound: 't_ub_tub' },
                { 1: ['u'], 2: ['u', 'd'], type: 'dndType1', exampleWord: 'mud', exSound: 'm_ud_mud' },
                { 1: ['u'], 2: ['u', 'm'], type: 'dndType1', exampleWord: 'gum', exSound: 'g_um_gum' },
                { 1: ['u'], 2: ['u', 'p'], type: 'dndType1', exampleWord: 'cup', exSound: 'c_up_cup' },
              ],
              totalCount: 3, //이게 뭐지?
              type: 'alphabetDnd',
            },
          },
          3: {
            title: ['Listen and choose.', 'Drag and drop. Then type.'],
            shortDescption: 'Practice the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S3_Dir.mp3',
            type: 'phonemeSelectAndDnd',
            hasStep: true,
            problem: [
              {
                type: 'select',
                answer: { name: 'ub', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/ub.mp3' },
                word: {
                  name: 'tub',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/tub.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_tub.png',
                },
                candidate: ['ub', 'ud'],
              },
              {
                type: 'select',
                answer: { name: 'ud', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/ud.mp3' },
                word: {
                  name: 'mud',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/mud.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_mud.png',
                },
                candidate: ['ud', 'um'],
              },
              {
                type: 'select',
                answer: { name: 'um', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/um.mp3' },
                word: {
                  name: 'gum',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/gum.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_gum.png',
                },
                candidate: ['um', 'up'],
              },
              {
                type: 'select',
                answer: { name: 'up', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/up.mp3' },
                word: {
                  name: 'cup',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/cup.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_cup.png',
                },
                candidate: ['up', 'ub'],
              },
              {
                type: 'dnd',
                answer: { name: 'ub', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/ub.mp3' },
                word: {
                  name: 'tub',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/tub.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_tub.png',
                },
                candidate: ['ub', 'ud', 'um', 'up'],
              },
              {
                type: 'dnd',
                answer: { name: 'ud', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/ud.mp3' },
                word: {
                  name: 'mud',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/mud.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_mud.png',
                },
                candidate: ['ub', 'ud', 'um', 'up'],
              },
              {
                type: 'dnd',
                answer: { name: 'um', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/um.mp3' },
                word: {
                  name: 'gum',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/gum.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_gum.png',
                },
                candidate: ['ub', 'ud', 'um', 'up'],
              },
              {
                type: 'dnd',
                answer: { name: 'up', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/up.mp3' },
                word: {
                  name: 'cup',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/cup.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_cup.png',
                },
                candidate: ['ub', 'ud', 'um', 'up'],
              },
            ],
          },
          4: {
            title: 'Word Animation',
            shortDescption: 'Word Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S4_Dir.mp3',
            url: 'https://player.vimeo.com/video/381458378',
            type: 'video',
          },
          5: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Practice the Words Ⅰ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S5_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_tub.png',
                  question: 't',
                  answer: 'ub',
                  candidate: ['ub', 'ud', 'up'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_cub.png',
                  question: 'c',
                  answer: 'ub',
                  candidate: ['ub', 'ud', 'up'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_mud.png',
                  question: 'm',
                  answer: 'ud',
                  candidate: ['ub', 'ud', 'up'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_bud.png',
                  question: 'b',
                  answer: 'ud',
                  candidate: ['ub', 'ud', 'up'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_gum.png',
                  question: 'g',
                  answer: 'um',
                  candidate: ['ub', 'um', 'up'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_hum.png',
                  question: 'h',
                  answer: 'um',
                  candidate: ['ub', 'um', 'up'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_cup.png',
                  question: 'c',
                  answer: 'up',
                  candidate: ['ub', 'um', 'up'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_pup.png',
                  question: 'p',
                  answer: 'up',
                  candidate: ['ub', 'um', 'up'],
                },
              ],
              type: 'imgDnd',
              totalCount: 1,
            },
          },
          6: {
            title: 'Look and choose.',
            shortDescption: 'Practice the Words Ⅱ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S6_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'multiWordDnd',
              problem: [
                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_tub.png', word: 'tub' },
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_cub.png', word: 'cub' },
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_mud.png', word: 'mud' },
                  ],
                  words: ['tub', 'cub', 'mud'],
                },
                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_bud.png', word: 'bud' },
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_gum.png', word: 'gum' },
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_hum.png', word: 'hum' },
                  ],
                  words: ['bud', 'gum', 'hum'],
                },
                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_cup.png', word: 'cup' },
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_pup.png', word: 'pup' },
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_mud.png', word: 'mud' },
                  ],
                  words: ['cup', 'pup', 'mud'],
                },
              ],
              totalCount: 0, //사용하지 않음
            },
          },
          7: {
            title: 'Choose and type the word.',
            shortDescption: 'Practice the Words Ⅲ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S7_Dir.mp3',
            type: 'lineWord',
            problem: [
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_tub.png',
                spellings: [
                  { alphabet: 's', isAnswer: false },
                  { alphabet: 't', isAnswer: false },
                  { alphabet: 'u', isAnswer: true ,isPink:true},
                  { alphabet: 'b', isAnswer: true ,isPink:true},
                  { alphabet: 'h', isAnswer: true },
                  { alphabet: 'f', isAnswer: false },
                ],
                answer: 'tub',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_cub.png',
                spellings: [
                  { alphabet: 'o', isAnswer: false },
                  { alphabet: 'a', isAnswer: false },
                  { alphabet: 'd', isAnswer: false },
                  { alphabet: 'c', isAnswer: true },
                  { alphabet: 'u', isAnswer: true ,isPink:true},
                  { alphabet: 'b', isAnswer: true ,isPink:true},
                ],
                answer: 'cub',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_mud.png',
                spellings: [
                  { alphabet: 'k', isAnswer: false },
                  { alphabet: 'j', isAnswer: false },
                  { alphabet: 'r', isAnswer: false },
                  { alphabet: 'm', isAnswer: true },
                  { alphabet: 'u', isAnswer: true ,isPink:true},
                  { alphabet: 'd', isAnswer: true ,isPink:true},
                ],
                answer: 'mud',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_bud.png',
                spellings: [
                  { alphabet: 'b', isAnswer: true },
                  { alphabet: 'u', isAnswer: true ,isPink:true},
                  { alphabet: 'd', isAnswer: true ,isPink:true},
                  { alphabet: 'a', isAnswer: false },
                  { alphabet: 'c', isAnswer: false },
                  { alphabet: 'k', isAnswer: false },
                ],
                answer: 'bud',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_gum.png',
                spellings: [
                  { alphabet: 'b', isAnswer: false },
                  { alphabet: 'd', isAnswer: false },
                  { alphabet: 'g', isAnswer: true },
                  { alphabet: 'u', isAnswer: true ,isPink:true},
                  { alphabet: 'm', isAnswer: true ,isPink:true},
                  { alphabet: 'w', isAnswer: false },
                ],
                answer: 'gum',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_hum.png',
                spellings: [
                  { alphabet: 'i', isAnswer: false },
                  { alphabet: 'r', isAnswer: false },
                  { alphabet: 'w', isAnswer: false },
                  { alphabet: 'h', isAnswer: true },
                  { alphabet: 'u', isAnswer: true ,isPink:true},
                  { alphabet: 'm', isAnswer: true ,isPink:true},
                ],
                answer: 'hum',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_cup.png',
                spellings: [
                  { alphabet: 'c', isAnswer: false },
                  { alphabet: 'u', isAnswer: true ,isPink:true},
                  { alphabet: 'p', isAnswer: true ,isPink:true},
                  { alphabet: 'y', isAnswer: true },
                  { alphabet: 'g', isAnswer: false },
                  { alphabet: 'n', isAnswer: false },
                ],
                answer: 'cup',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_pup.png',
                spellings: [
                  { alphabet: 'b', isAnswer: false },
                  { alphabet: 'z', isAnswer: false },
                  { alphabet: 'p', isAnswer: true },
                  { alphabet: 'u', isAnswer: true ,isPink:true},
                  { alphabet: 'p', isAnswer: true ,isPink:true},
                  { alphabet: 't', isAnswer: false },
                ],
                answer: 'pup',
              },
            ],
          },
          8: {
            title: 'Listen and repeat. Then record your voice.',
            shortDescption: 'Story',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S8_Dir.mp3',
            type: 'record',
            customData: {
              bgImage: 'https://cdn.cloubot.com/PM/image/l2_u7_bg_wide.jpg',
              sound: 'Lv2_U7_reading',
              sentences: ['A ##cub## and a ##pup##.', 'Fun in the ##mud##.', 'Fun, fun, fun!', 'A ##cub## and a ##pup## in a ##mud## ##tub##.', 'The ##cub## and the ##pup## rub.', 'The ##cub## and the ##pup## ##hum##.', '##Hum##, ##hum##, ##hum##!'],
            },
          },
          9: {
            title: 'Choose and type the word.',
            shortDescption: 'Words in the Story',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S9_Dir.mp3',
            type: 'write',
            customData: {
              type: 'imgSoundWrite',
              problem: [
                {
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_fun_in_the_mud.png',
                  sentenceFormat: 'Fun in the ##word##.',
                  correctWord: 'mud',
                  wrongWord: 'tub',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_a_cub_and_a_pup_in_a_tub.png',
                  sentenceFormat: 'A cub and a pup in a ##word##.',
                  correctWord: 'tub',
                  wrongWord: 'cup',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_the_cub_and_the_pup_rub.png',
                  sentenceFormat: 'The cub and the ##word## rub.',
                  correctWord: 'pup',
                  wrongWord: 'bud',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_the_cub_and_the_pup_hum.png',
                  sentenceFormat: 'The cub and the pup ##word##.',
                  correctWord: 'hum',
                  wrongWord: 'gum',
                },
              ],
            },
          },
          10: {
            title: 'Story Chant',
            shortDescption: 'Story Chant',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S10_Dir.mp3',
            url: 'https://player.vimeo.com/video/381458578',
            type: 'video',
          },
          11: {
            title: 'Read. Then drag and drop.',
            shortDescption: 'Story Activity',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S11_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imgDnd3',
              problem: {
                question: [
                  { image: 'https://cdn.cloubot.com/PM/image/level2_unit07_SB_01.png', sentence: ['The ##pup## is in the ##cup##.'] },
                  { image: 'https://cdn.cloubot.com/PM/image/level2_unit07_SB_02.png', sentence: ['The ##cub## is in the ##mud##.'] },
                  { image: 'https://cdn.cloubot.com/PM/image/level2_unit07_SB_03.png', sentence: ['The ##bug## is in the ##gum##.'] },
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level2_unit07_SB_04.png',
                    sentence: ['Dad picks them ##up##.', 'They go in the ##tub##.'],
                  },
                ],
              },
              totalCount: 4,
            },
          },
          12: {
            title: 'Listen and type the word.',
            shortDescption: 'Quiz',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S12_Dir.mp3',
            type: 'unitTest',
            problem: [
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_tub.png', word: 'tub' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_cub.png', word: 'cub' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_mud.png', word: 'mud' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_bud.png', word: 'bud' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_gum.png', word: 'gum' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_hum.png', word: 'hum' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_cup.png', word: 'cup' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_pup.png', word: 'pup' },
            ],
          },
        },
      },
      8: {
        title: 'Short Vowel - ed · en · et',
        stage: {
          1: {
            title: 'Sound Animation',
            shortDescption: 'Sound Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S1_Dir.mp3',
            url: 'https://player.vimeo.com/video/381458020',
            type: 'video',
          },
          2: {
            title: 'Drag and drop.',
            shortDescption: 'Learn the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S2_Dir.mp3',
            type: 'dnd',
            hasStep: false,
            customData: {
              isShortVowel: true,
              soundBaseUrl: 'https://cdn.cloubot.com/PM/audio/sounds/level2/',
              problem: [
                { 1: ['e'], 2: ['e', 'd'], type: 'dndType1', exampleWord: 'red', exSound: 'r_ed_red' },
                { 1: ['e'], 2: ['e', 'n'], type: 'dndType1', exampleWord: 'hen', exSound: 'h_en_hen' },
                { 1: ['e'], 2: ['e', 't'], type: 'dndType1', exampleWord: 'jet', exSound: 'j_et_jet' },
              ],
              totalCount: 3,
              type: 'alphabetDnd',
            },
          },
          3: {
            title: ['Listen and choose.', 'Drag and drop. Then type.'],
            shortDescption: 'Practice the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S3_Dir.mp3',
            type: 'phonemeSelectAndDnd',
            hasStep: true,
            problem: [
              {
                type: 'select',
                answer: { name: 'ed', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/ed.mp3' },
                word: {
                  name: 'red',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/red.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_red.png',
                },
                candidate: ['ed', 'en'],
              },
              {
                type: 'select',
                answer: { name: 'en', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/en.mp3' },
                word: {
                  name: 'hen',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/hen.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_hen.png',
                },
                candidate: ['en', 'et'],
              },
              {
                type: 'select',
                answer: { name: 'et', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/et.mp3' },
                word: {
                  name: 'jet',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/jet.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_jet.png',
                },
                candidate: ['et', 'ed'],
              },
              {
                type: 'dnd',
                answer: { name: 'ed', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/ed.mp3' },
                word: {
                  name: 'red',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/red.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_red.png',
                },
                candidate: ['ed', 'en', 'et'],
              },
              {
                type: 'dnd',
                answer: { name: 'en', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/en.mp3' },
                word: {
                  name: 'hen',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/hen.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_hen.png',
                },
                candidate: ['ed', 'en', 'et'],
              },
              {
                type: 'dnd',
                answer: { name: 'et', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/et.mp3' },
                word: {
                  name: 'jet',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/jet.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_jet.png',
                },
                candidate: ['ed', 'en', 'et'],
              },
            ],
          },
          4: {
            title: 'Word Animation',
            shortDescption: 'Word Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S4_Dir.mp3',
            url: 'https://player.vimeo.com/video/381458384',
            type: 'video',
          },
          5: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Practice the Words Ⅰ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S5_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_red.png',
                  question: 'r',
                  answer: 'ed',
                  candidate: ['ed', 'en', 'et'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_bed.png',
                  question: 'b',
                  answer: 'ed',
                  candidate: ['ed', 'en', 'et'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_Ted.png',
                  question: 'T',
                  answer: 'ed',
                  candidate: ['ed', 'en', 'et'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_hen.png',
                  question: 'h',
                  answer: 'en',
                  candidate: ['ed', 'en', 'et'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_ten.png',
                  question: 't',
                  answer: 'en',
                  candidate: ['ed', 'en', 'et'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_pen.png',
                  question: 'p',
                  answer: 'en',
                  candidate: ['ed', 'en', 'et'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_jet.png',
                  question: 'j',
                  answer: 'et',
                  candidate: ['ed', 'en', 'et'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_wet.png',
                  question: 'w',
                  answer: 'et',
                  candidate: ['ed', 'en', 'et'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_pet.png',
                  question: 'p',
                  answer: 'et',
                  candidate: ['ed', 'en', 'et'],
                },
              ],
              type: 'imgDnd',
              totalCount: 1,
            },
          },
          6: {
            title: 'Look and choose.',
            shortDescption: 'Practice the Words Ⅱ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S6_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'multiWordDnd',
              problem: [
                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_red.png', word: 'red' },
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_bed.png', word: 'bed' },
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_Ted.png', word: 'Ted' },
                  ],
                  words: ['red', 'bed', 'Ted'],
                },
                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_hen.png', word: 'hen' },
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_ten.png', word: 'ten' },
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_pen.png', word: 'pen' },
                  ],
                  words: ['hen', 'ten', 'pen'],
                },
                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_jet.png', word: 'jet' },
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_wet.png', word: 'wet' },
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_pet.png', word: 'pet' },
                  ],
                  words: ['jet', 'wet', 'pet'],
                },
              ],
              totalCount: 0, //사용하지 않음
            },
          },
          7: {
            title: 'Choose and type the word.',
            shortDescption: 'Practice the Words Ⅲ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S7_Dir.mp3',
            type: 'lineWord',
            problem: [
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_red.png',
                spellings: [
                  { alphabet: 'c', isAnswer: false },
                  { alphabet: 'd', isAnswer: false },
                  { alphabet: 'r', isAnswer: true },
                  { alphabet: 'e', isAnswer: true ,isPink:true},
                  { alphabet: 'd', isAnswer: true ,isPink:true},
                  { alphabet: 'g', isAnswer: false },
                ],
                answer: 'red',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_bed.png',
                spellings: [
                  { alphabet: 'u', isAnswer: false },
                  { alphabet: 'b', isAnswer: false },
                  { alphabet: 'e', isAnswer: false ,isPink:true},
                  { alphabet: 'd', isAnswer: true ,isPink:true},
                  { alphabet: 'f', isAnswer: true },
                  { alphabet: 'c', isAnswer: true },
                ],
                answer: 'bed',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_Ted.png',
                spellings: [
                  { alphabet: 'T', isAnswer: false },
                  { alphabet: 'e', isAnswer: false ,isPink:true},
                  { alphabet: 'd', isAnswer: false ,isPink:true},
                  { alphabet: 's', isAnswer: true },
                  { alphabet: 'e', isAnswer: true },
                  { alphabet: 'i', isAnswer: true },
                ],
                answer: 'Ted',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_hen.png',
                spellings: [
                  { alphabet: 'g', isAnswer: true },
                  { alphabet: 'h', isAnswer: true },
                  { alphabet: 'e', isAnswer: true ,isPink:true},
                  { alphabet: 'n', isAnswer: false ,isPink:true },
                  { alphabet: 'p', isAnswer: false },
                  { alphabet: 'k', isAnswer: false },
                ],
                answer: 'hen',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_ten.png',
                spellings: [
                  { alphabet: 's', isAnswer: false },
                  { alphabet: 't', isAnswer: false },
                  { alphabet: 'e', isAnswer: true ,isPink:true},
                  { alphabet: 'n', isAnswer: true ,isPink:true},
                  { alphabet: 'j', isAnswer: true },
                  { alphabet: 'h', isAnswer: false },
                ],
                answer: 'ten',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_pen.png',
                spellings: [
                  { alphabet: 'b', isAnswer: false },
                  { alphabet: 'p', isAnswer: false },
                  { alphabet: 's', isAnswer: false },
                  { alphabet: 'p', isAnswer: true },
                  { alphabet: 'e', isAnswer: true ,isPink:true},
                  { alphabet: 'n', isAnswer: true ,isPink:true},
                ],
                answer: 'pen',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_jet.png',
                spellings: [
                  { alphabet: 'n', isAnswer: false },
                  { alphabet: 'j', isAnswer: true },
                  { alphabet: 'e', isAnswer: true ,isPink:true},
                  { alphabet: 't', isAnswer: true ,isPink:true},
                  { alphabet: 'g', isAnswer: false },
                  { alphabet: 'n', isAnswer: false },
                ],
                answer: 'jet',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_wet.png',
                spellings: [
                  { alphabet: 'b', isAnswer: false },
                  { alphabet: 'o', isAnswer: false },
                  { alphabet: 'p', isAnswer: true },
                  { alphabet: 'w', isAnswer: true },
                  { alphabet: 'e', isAnswer: true ,isPink:true},
                  { alphabet: 't', isAnswer: false ,isPink:true},
                ],
                answer: 'wet',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_pet.png',
                spellings: [
                  { alphabet: 'b', isAnswer: false },
                  { alphabet: 'q', isAnswer: true },
                  { alphabet: 'p', isAnswer: true },
                  { alphabet: 'e', isAnswer: true ,isPink:true},
                  { alphabet: 't', isAnswer: false ,isPink:true},
                  { alphabet: 's', isAnswer: false },
                ],
                answer: 'pet',
              },
            ],
          },
          8: {
            title: 'Listen and repeat. Then record your voice.',
            shortDescption: 'Story',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S8_Dir.mp3',
            type: 'record',
            customData: {
              bgImage: 'https://cdn.cloubot.com/PM/image/l2_u8_bg_wide.jpg',
              sound: 'Lv2_U8_reading',
              sentences: ['##Ten## ##red## ##hens##.', '##Ten## ##wet## ##red## ##hens##', 'on the ##bed##.', 'Get the net, ##Ted##.', 'Get ##ten## ##wet## ##red## ##hens##.', '##Ten## ##red## ##hens##', 'in the net.'],
            },
          },
          9: {
            title: 'Choose and type the word.',
            shortDescption: 'Words in the Story',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S9_Dir.mp3',
            type: 'write',
            customData: {
              type: 'imgSoundWrite',
              problem: [
                {
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_ten_red_hens.png',
                  sentenceFormat: '##word## red hens.',
                  correctWord: 'Ten',
                  wrongWord: 'Ted',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_ten_wet_hens_on_the_bed.png',
                  sentenceFormat: 'Ten wet hens on the ##word##.',
                  correctWord: 'bed',
                  wrongWord: 'jet',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_get_the_net_ted.png',
                  sentenceFormat: 'Get the net, ##word##.',
                  correctWord: 'Ted',
                  wrongWord: 'Hen',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_ten_red_hens_in_the_net.png',
                  sentenceFormat: 'Ten ##word## hens in the net.',
                  correctWord: 'red',
                  wrongWord: 'pen',
                },
              ],
            },
          },
          10: {
            title: 'Story Chant',
            shortDescption: 'Story Chant',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S10_Dir.mp3',
            url: 'https://player.vimeo.com/video/381458586',
            type: 'video',
          },
          11: {
            title: 'Read. Then drag and drop.',
            shortDescption: 'Story Activity',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S11_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imgDnd3',
              problem: {
                question: [
                  { image: 'https://cdn.cloubot.com/PM/image/level2_unit08_SB_01.png', sentence: ['The ##hen## is ##red##.'] },
                  { image: 'https://cdn.cloubot.com/PM/image/level2_unit08_SB_02.png', sentence: ['The ##hen## is ##wet##.'] },
                  { image: 'https://cdn.cloubot.com/PM/image/level2_unit08_SB_03.png', sentence: ['The ##hen## is on the ##bed##.'] },
                  { image: 'https://cdn.cloubot.com/PM/image/level2_unit08_SB_04.png', sentence: ['Here is the ##vet##.'] },
                ],
              },
              totalCount: 4,
            },
          },
          12: {
            title: 'Listen and type the word.',
            shortDescption: 'Quiz',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S12_Dir.mp3',
            type: 'unitTest',
            problem: [
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_red.png', word: 'red' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_bed.png', word: 'bed' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_Ted.png', word: 'Ted' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_hen.png', word: 'hen' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_ten.png', word: 'ten' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_pen.png', word: 'pen' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_jet.png', word: 'jet' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_wet.png', word: 'wet' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_pet.png', word: 'pet' },
            ],
          },
        },
      },
      9: {
        title: 'Short Vowel - og · ot · ox',
        stage: {
          1: {
            title: 'Sound Animation',
            shortDescption: 'Sound Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S1_Dir.mp3',
            url: 'https://player.vimeo.com/video/381458029',
            type: 'video',
          },
          2: {
            title: 'Drag and drop.',
            shortDescption: 'Learn the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S2_Dir.mp3',
            type: 'dnd',
            hasStep: false,
            customData: {
              isShortVowel: true,
              soundBaseUrl: 'https://cdn.cloubot.com/PM/audio/sounds/level2/',
              problem: [
                { 1: ['o'], 2: ['o', 'g'], type: 'dndType1', exampleWord: 'dog', exSound: 'd_og_dog' },
                { 1: ['o'], 2: ['o', 't'], type: 'dndType1', exampleWord: 'pot', exSound: 'p_ot_pot' },
                { 1: ['o'], 2: ['o', 'x'], type: 'dndType1', exampleWord: 'fox', exSound: 'f_ox_fox' },
              ],
              totalCount: 3,
              type: 'alphabetDnd',
            },
          },
          3: {
            title: ['Listen and choose.', 'Drag and drop. Then type.'],
            shortDescption: 'Practice the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S3_Dir.mp3',
            type: 'phonemeSelectAndDnd',
            hasStep: true,
            problem: [
              {
                type: 'select',
                answer: { name: 'og', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/og.mp3' },
                word: {
                  name: 'dog',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/dog.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_dog.png',
                },
                candidate: ['og', 'ot'],
              },
              {
                type: 'select',
                answer: { name: 'ot', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/ot.mp3' },
                word: {
                  name: 'pot',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/pot.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_pot.png',
                },
                candidate: ['ot', 'ox'],
              },
              {
                type: 'select',
                answer: { name: 'ox', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/ox.mp3' },
                word: {
                  name: 'fox',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/fox.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_fox.png',
                },
                candidate: ['ox', 'og'],
              },
              {
                type: 'dnd',
                answer: { name: 'og', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/og.mp3' },
                word: {
                  name: 'dog',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/dog.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_dog.png',
                },
                candidate: ['og', 'ot', 'ox'],
              },
              {
                type: 'dnd',
                answer: { name: 'ot', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/ot.mp3' },
                word: {
                  name: 'pot',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/pot.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_pot.png',
                },
                candidate: ['og', 'ot', 'ox'],
              },
              {
                type: 'dnd',
                answer: { name: 'ox', url: 'https://cdn.cloubot.com/PM/audio/sounds/level2/ox.mp3' },
                word: {
                  name: 'fox',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/fox.mp3',
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_fox.png',
                },
                candidate: ['og', 'ot', 'ox'],
              },
            ],
          },
          4: {
            title: 'Word Animation',
            shortDescption: 'Word Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S4_Dir.mp3',
            url: 'https://player.vimeo.com/video/381458396',
            type: 'video',
          },
          5: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Practice the Words Ⅰ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S5_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_dog.png',
                  question: 'd',
                  answer: 'og',
                  candidate: ['og', 'ot', 'ox'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_log.png',
                  question: 'l',
                  answer: 'og',
                  candidate: ['og', 'ot', 'ox'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_jog.png',
                  question: 'j',
                  answer: 'og',
                  candidate: ['og', 'ot', 'ox'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_pot.png',
                  question: 'p',
                  answer: 'ot',
                  candidate: ['og', 'ot', 'ox'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_hot.png',
                  question: 'h',
                  answer: 'ot',
                  candidate: ['og', 'ot', 'ox'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_dot.png',
                  question: 'd',
                  answer: 'ot',
                  candidate: ['og', 'ot', 'ox'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_fox.png',
                  question: 'f',
                  answer: 'ox',
                  candidate: ['og', 'ot', 'ox'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_box.png',
                  question: 'b',
                  answer: 'ox',
                  candidate: ['og', 'ot', 'ox'],
                },
              ],
              type: 'imgDnd',
              totalCount: 1,
            },
          },
          6: {
            title: 'Look and choose.',
            shortDescption: 'Practice the Words Ⅱ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S6_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'multiWordDnd',
              problem: [
                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_dog.png', word: 'dog' },
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_log.png', word: 'log' },
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_jog.png', word: 'jog' },
                  ],
                  words: ['dog', 'log', 'jog'],
                },
                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_pot.png', word: 'pot' },
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_hot.png', word: 'hot' },
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_dot.png', word: 'dot' },
                  ],
                  words: ['pot', 'hot', 'dot'],
                },
                {
                  images: [
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_fox.png', word: 'fox' },
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_box.png', word: 'box' },
                    { url: 'https://cdn.cloubot.com/PM/image/level2/image_ox.png', word: 'ox' },
                  ],
                  words: ['fox', 'box', 'ox'],
                },
              ],
              totalCount: 0, //사용하지 않음
            },
          },
          7: {
            title: 'Choose and type the word.',
            shortDescption: 'Practice the Words Ⅲ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S7_Dir.mp3',
            type: 'lineWord',
            problem: [
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_box.png',
                spellings: [
                  { alphabet: 'u', isAnswer: false },
                  { alphabet: 'g', isAnswer: false },
                  { alphabet: 'b', isAnswer: true },
                  { alphabet: 'o', isAnswer: true ,isPink:true},
                  { alphabet: 'x', isAnswer: true ,isPink:true},
                  { alphabet: 't', isAnswer: false },
                ],
                answer: 'box',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_pot.png',
                spellings: [
                  { alphabet: 'o', isAnswer: false },
                  { alphabet: 'x', isAnswer: false },
                  { alphabet: 'a', isAnswer: false },
                  { alphabet: 'p', isAnswer: true },
                  { alphabet: 'o', isAnswer: true ,isPink:true},
                  { alphabet: 't', isAnswer: true ,isPink:true},
                ],
                answer: 'pot',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_log.png',
                spellings: [
                  { alphabet: 'l', isAnswer: true },
                  { alphabet: 'o', isAnswer: true ,isPink:true},
                  { alphabet: 'g', isAnswer: true ,isPink:true},
                  { alphabet: 'p', isAnswer: false },
                  { alphabet: 'a', isAnswer: false },
                  { alphabet: 'n', isAnswer: false },
                ],
                answer: 'log',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level2/image_fox.png',
                spellings: [
                  { alphabet: 'u', isAnswer: false },
                  { alphabet: 'f', isAnswer: true },
                  { alphabet: 'o', isAnswer: true ,isPink:true},
                  { alphabet: 'x', isAnswer: true ,isPink:true},
                  { alphabet: 'a', isAnswer: false },
                  { alphabet: 'd', isAnswer: false },
                ],
                answer: 'fox',
              },
            ],
          },
          8: {
            title: 'Listen and repeat. Then record your voice.',
            shortDescption: 'Story',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S8_Dir.mp3',
            type: 'record',
            customData: {
              bgImage: 'https://cdn.cloubot.com/PM/image/l2_u9_bg_wide.jpg',
              sound: 'Lv2_U9_reading',
              sentences: ['A ##fox## has a ##box##.', 'A ##dog## has a ##hot## ##pot##.', 'The ##fox## and the ##dog## ##jog##.', '##Jog## on the ##log##.', 'Oh, it’s not a ##log##.', 'It’s an ##ox##!', 'Run!'],
            },
          },
          9: {
            title: 'Choose and type the word.',
            shortDescption: 'Words in the Story',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S9_Dir.mp3',
            type: 'write',
            customData: {
              type: 'imgSoundWrite',
              problem: [
                {
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_a_fox_has_a_box.png',
                  sentenceFormat: 'A fox has a ##word##.',
                  correctWord: 'box',
                  wrongWord: 'pot',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_a_dog_has_a_hot_pot.png',
                  sentenceFormat: 'A dog has a hot ##word##.',
                  correctWord: 'pot',
                  wrongWord: 'log',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_jog_on_the_log.png',
                  sentenceFormat: 'Jog on the ##word##.',
                  correctWord: 'log',
                  wrongWord: 'box',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_its_an_ox.png',
                  sentenceFormat: "It's an ##word##!",
                  correctWord: 'ox',
                  wrongWord: 'fox',
                },
              ],
            },
          },
          10: {
            title: 'Story Chant',
            shortDescption: 'Story Chant',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S10_Dir.mp3',
            url: 'https://player.vimeo.com/video/381458592',
            type: 'video',
          },
          11: {
            title: 'Read. Then drag and drop.',
            shortDescption: 'Story Activity',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S11_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imgDnd3',
              problem: {
                question: [
                  { image: 'https://cdn.cloubot.com/PM/image/level2_unit09_SB_01.png', sentence: ['The ##ox## is after the ##fox##.'] },
                  { image: 'https://cdn.cloubot.com/PM/image/level2_unit09_SB_02.png', sentence: ['The ##fox## is after the ##dog##.'] },
                  { image: 'https://cdn.cloubot.com/PM/image/level2_unit09_SB_03.png', sentence: ['The ##dog## hops on the ##log##.'] },
                  { image: 'https://cdn.cloubot.com/PM/image/level2_unit09_SB_04.png', sentence: ["Oh, no! It's not a ##log##!"] },
                ],
              },
              totalCount: 4,
            },
          },
          12: {
            title: 'Listen and type the word.',
            shortDescption: 'Quiz',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_S12_Dir.mp3',
            type: 'unitTest',
            problem: [
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_dog.png', word: 'dog' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_log.png', word: 'log' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_jog.png', word: 'jog' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_pot.png', word: 'pot' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_hot.png', word: 'hot' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_dot.png', word: 'dot' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_fox.png', word: 'fox' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_box.png', word: 'box' },
              { image: 'https://cdn.cloubot.com/PM/image/level2/image_ox.png', word: 'ox' },
            ],
          },
        },
      },
      10: {
        title: 'Review - Unit 6 ~ Unit 9',
        stage: {
          1: {
            title: 'Listen. Then drag and drop.',
            shortDescption: 'Review the Sounds Ⅰ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_Review_S1_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imgDnd2',
              hasSoundBtn: true,
              answerAbsolute: true,
              ignorePhoneme: true,
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_bug.png',
                  question: 'b',
                  answer: 'ug',
                  candidate: { 0: ['b', 'h', 'r', 's', 'n', 'c'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_sun.png',
                  question: 's',
                  answer: 'un',
                  candidate: { 0: ['b', 'h', 'r', 's', 'n', 'c'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_nut.png',
                  question: 'n',
                  answer: 'ut',
                  candidate: { 0: ['b', 'h', 'r', 's', 'n', 'c'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_tub.png',
                  question: 't',
                  answer: 'ub',
                  candidate: { 0: ['t', 'm', 'g', 'p', 'j', 'c'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_mud.png',
                  question: 'm',
                  answer: 'ud',
                  candidate: { 0: ['t', 'm', 'g', 'p', 'j', 'c'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_gum.png',
                  question: 'g',
                  answer: 'um',
                  candidate: { 0: ['t', 'm', 'g', 'p', 'j', 'c'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_cub.png',
                  question: 'c',
                  answer: 'ub',
                  candidate: { 0: ['c', 'g', 'l', 'b', 't', 'd'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_red.png',
                  question: 'r',
                  answer: 'ed',
                  candidate: { 0: ['c', 'r', 'j', 'b', 't', 'v'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_hen.png',
                  question: 'h',
                  answer: 'en',
                  candidate: { 0: ['b', 'h', 'r', 's', 'n', 'c'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_jet.png',
                  question: 'j',
                  answer: 'et',
                  candidate: { 0: ['t', 'm', 'g', 'p', 'j', 'c'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_dog.png',
                  question: 'd',
                  answer: 'og',
                  candidate: { 0: ['c', 'k', 'l', 'b', 't', 'd'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_pot.png',
                  question: 'p',
                  answer: 'ot',
                  candidate: { 0: ['c', 'p', 'l', 'b', 't', 'd'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_fox.png',
                  question: 'f',
                  answer: 'ox',
                  candidate: { 0: ['p', 'j', 'w', 'd', 'l', 'f'] },
                },
              ],
              totalCount: 1,
            },
          },
          2: {
            title: 'Listen. Then drag and drop.',
            shortDescption: 'Review the Words Ⅰ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_Review10_S2_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imgDnd4',

              hasSoundBtn: true,
              hideWordBox: true,
              problem: [
                {
                  //여기서는 이렇게 나눌 필요가 없는데, imgDnd4 을 사용하려다보니 어쩔 수 없이 문제형식을 똑같이 한다.
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_bug.png',
                  question: 'b',
                  answer: 'ug',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level2/image_sun.png',
                    'https://cdn.cloubot.com/PM/image/level2/image_nut.png',
                    'https://cdn.cloubot.com/PM/image/level2/image_bug.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_tub.png',
                  question: 't',
                  answer: 'ub',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level2/image_tub.png',
                    'https://cdn.cloubot.com/PM/image/level2/image_mud.png',
                    'https://cdn.cloubot.com/PM/image/level2/image_gum.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_cup.png',
                  question: 'c',
                  answer: 'up',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level2/image_red.png',
                    'https://cdn.cloubot.com/PM/image/level2/image_cup.png',
                    'https://cdn.cloubot.com/PM/image/level2/image_hen.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_jet.png',
                  question: 'j',
                  answer: 'et',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level2/image_dog.png',
                    'https://cdn.cloubot.com/PM/image/level2/image_pot.png',
                    'https://cdn.cloubot.com/PM/image/level2/image_jet.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level2/image_fox.png',
                  question: 'f',
                  answer: 'ox',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level2/image_fox.png',
                    'https://cdn.cloubot.com/PM/image/level2/image_hen.png',
                    'https://cdn.cloubot.com/PM/image/level2/image_dog.png',
                  ],
                },
              ],
              totalCount: 1,
            },
          },
          3: {
            title: 'Look and choose.',
            shortDescption: 'Review the Words Ⅱ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_Review10_S3_Dir.mp3',
            type: 'imgSelect',
            customData: {
              type: 'selectWord',
              problem: [
                {
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_bug.png',
                  words: [
                    { name: 'bug', isAnswer: true },
                    { name: 'hug', isAnswer: false },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_sun.png',
                  words: [
                    { name: 'bun', isAnswer: false },
                    { name: 'sun', isAnswer: true },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_tub.png',
                  words: [
                    { name: 'cub', isAnswer: false },
                    { name: 'tub', isAnswer: true },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_nut.png',
                  words: [
                    { name: 'nut', isAnswer: true },
                    { name: 'hut', isAnswer: false },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_bud.png',
                  words: [
                    { name: 'mud', isAnswer: false },
                    { name: 'bud', isAnswer: true },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_red.png',
                  words: [
                    { name: 'red', isAnswer: true },
                    { name: 'hen', isAnswer: false },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_pup.png',
                  words: [
                    { name: 'cup', isAnswer: false },
                    { name: 'pup', isAnswer: true },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_bed.png',
                  words: [
                    { name: 'bed', isAnswer: true },
                    { name: 'red', isAnswer: false },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_hen.png',
                  words: [
                    { name: 'hen', isAnswer: true },
                    { name: 'pen', isAnswer: false },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_wet.png',
                  words: [
                    { name: 'jet', isAnswer: false },
                    { name: 'wet', isAnswer: true },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_log.png',
                  words: [
                    { name: 'jog', isAnswer: false },
                    { name: 'log', isAnswer: true },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_dot.png',
                  words: [
                    { name: 'hot', isAnswer: false },
                    { name: 'dot', isAnswer: true },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level2/image_ox.png',
                  words: [
                    { name: 'fox', isAnswer: false },
                    { name: 'ox', isAnswer: true },
                  ],
                },
              ],
            },
          },
          4: {
            title: 'Look and choose.',
            shortDescption: 'Word Puzzle',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_Review_S4_Dir.mp3',
            type: 'crossWords',
            customData: {
              imageBaseUrl: 'https://cdn.cloubot.com/PM/image/level2/',
              problem: [
                {
                  words: [
                    {
                      name: 'hen',
                      xy: [
                        [0, 7],
                        [1, 7],
                        [2, 7],
                      ],
                    },
                    {
                      name: 'bug',
                      xy: [
                        [0, 2],
                        [0, 3],
                        [0, 4],
                      ],
                    },
                    {
                      name: 'red',
                      xy: [
                        [4, 4],
                        [4, 5],
                        [4, 6],
                      ],
                    },
                    {
                      name: 'rug',
                      xy: [
                        [6, 1],
                        [6, 2],
                        [6, 3],
                      ],
                    },
                    {
                      name: 'cut',
                      xy: [
                        [3, 5],
                        [3, 6],
                        [3, 7],
                      ],
                    },
                    {
                      name: 'hot',
                      xy: [
                        [2, 2],
                        [3, 2],
                        [4, 2],
                      ],
                    },
                    {
                      name: 'box',
                      xy: [
                        [2, 1],
                        [3, 1],
                        [4, 1],
                      ],
                    },
                    {
                      name: 'pot',
                      xy: [
                        [6, 4],
                        [6, 5],
                        [6, 6],
                      ],
                    },
                  ],
                  coords: [
                    ['a', 'b', 'h', 'f', 'o', 'p', 'h'],
                    ['c', 'c', 'b', 'o', 'x', 'e', 'r'],
                    ['b', 'a', 'h', 'o', 't', 'e', 'u'],
                    ['u', 't', 'e', 'e', 's', 'i', 'g'],
                    ['g', 'e', 'z', 't', 'r', 'k', 'p'],
                    ['g', 'a', 'n', 'c', 'e', 'i', 'o'],
                    ['a', 'p', 'e', 'u', 'd', 't', 't'],
                    ['h', 'e', 'n', 't', 'c', 'e', 's'],
                  ],
                },
                {
                  words: [
                    {
                      name: 'ten',
                      xy: [
                        [0, 2],
                        [1, 2],
                        [2, 2],
                      ],
                    },
                    {
                      name: 'hug',
                      xy: [
                        [0, 4],
                        [0, 5],
                        [0, 6],
                      ],
                    },
                    {
                      name: 'bed',
                      xy: [
                        [4, 0],
                        [5, 0],
                        [6, 0],
                      ],
                    },
                    {
                      name: 'run',
                      xy: [
                        [2, 0],
                        [2, 1],
                        [2, 2],
                      ],
                    },
                    {
                      name: 'nut',
                      xy: [
                        [4, 2],
                        [4, 3],
                        [4, 4],
                      ],
                    },
                    {
                      name: 'cub',
                      xy: [
                        [3, 3],
                        [4, 3],
                        [5, 3],
                      ],
                    },
                    {
                      name: 'gum',
                      xy: [
                        [0, 6],
                        [1, 6],
                        [2, 6],
                      ],
                    },
                    {
                      name: 'fox',
                      xy: [
                        [3, 7],
                        [4, 7],
                        [5, 7],
                      ],
                    },
                  ],
                  coords: [
                    ['a', 'e', 'r', 'o', 'b', 'e', 'd'],
                    ['c', 'd', 'u', 'h', 'k', 'q', 'o'],
                    ['t', 'e', 'n', 'o', 'n', 's', 'p'],
                    ['g', 'm', 'i', 'c', 'u', 'b', 'l'],
                    ['h', 'e', 'k', 'r', 't', 'c', 'm'],
                    ['u', 'h', 's', 'o', 'v', 'u', 't'],
                    ['g', 'u', 'm', 'f', 'w', 'o', 'v'],
                    ['b', 'q', 'n', 'f', 'o', 'x', 'z'],
                  ],
                },
              ],
            },
          },
          5: {
            title: 'Look at the picture. Choose the picture that has different sound. (Rhyme)',
            shortDescption: 'Review the Sounds Ⅱ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_Review_S5_Dir.mp3',
            type: 'imgSelect',
            customData: {
              type: 'imgSelectWord', //imgSelectImg, imgSelectWord
              problem: [
                {
                  qWord: 'ug',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_rug.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_bug.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_bun.png', isAnswer: true },
                  ],
                },
                {
                  qWord: 'um',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_run.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_gum.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_hum.png', isAnswer: false },
                  ],
                },
                {
                  qWord: 'en',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_hot.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_hen.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_ten.png', isAnswer: false },
                  ],
                },
                {
                  qWord: 'ot',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_dog.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_dot.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_pot.png', isAnswer: false },
                  ],
                },
              ],
            },
          },
          6: {
            title: 'Read and choose.',
            shortDescption: 'Review the Words Ⅲ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_Review_S6_Dir.mp3',
            type: 'imgSelect',
            customData: {
              type: 'imgSelectSentence', //imgSelectImg, imgSelectWord
              problem: [
                {
                  qSentence: 'a red bed',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_a_blue_bed.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_a_red_bed.png', isAnswer: true },
                  ],
                },
                {
                  qSentence: 'a cub in a tub',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_a_cub_in_a_tub.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_a_dog_in_a_tub.png', isAnswer: false },
                  ],
                },
                {
                  qSentence: 'a bug on a rug',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_a_bug_on_a_rug.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_a_bug_on_a_leg.png', isAnswer: false },
                  ],
                },
                {
                  qSentence: 'a fox in a box',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_a_dog_in_a_box.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_a_fox_in_a_box.png', isAnswer: true },
                  ],
                },
              ],
            },
          },
          7: {
            title: 'Look and choose. Then type the word.',
            shortDescption: 'Review the Words Ⅳ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_Review_S7_Dir.mp3',
            type: 'write',
            customData: {
              type: 'multiReview',
              problem: [
                {
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_bun.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_ox.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_red.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_hum.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_hen.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_bud.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_log.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_hut.png', isAnswer: true },
                  ],
                  exVowel: ['Short', 'u'],
                },
                {
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_red.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_cub.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_run.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_wet.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_rug.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_ten.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_gum.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_pet.png', isAnswer: true },
                  ],
                  exVowel: ['Short', 'e'],
                },
                {
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_dog.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_bed.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_hum.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_fox.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_pen.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_hot.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_pup.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level2/image_ox.png', isAnswer: true },
                  ],
                  exVowel: ['Short', 'o'],
                },
              ],
              totalCount: 4,
            },
          },
          8: {
            title: 'Choose the word to create your sentence.',
            shortDescption: 'Make Your Sentence',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_Review_S8_Dir.mp3',
            type: 'write',
            customData: {
              type: 'singleReview',
              problem: [
                {
                  sentenceFormat: 'Bug lives in a ##word##.',
                  words: ['hut', 'nut'],
                  imageBaseUrl: 'https://cdn.cloubot.com/PM/image/level2/',
                },
                {
                  sentenceFormat: 'The cub is in the ##word##.',
                  words: ['tub', 'mud'],
                  imageBaseUrl: 'https://cdn.cloubot.com/PM/image/level2/',
                },
                {
                  sentenceFormat: 'The dog hops on the ##word##.',
                  words: ['log', 'ox'],
                  imageBaseUrl: 'https://cdn.cloubot.com/PM/image/level2/',
                },
              ],
            },
          },
        },
      },
    },
  },
  {
    bookName: '3a',
    bookCode: 'PM-L5V1',
    validation: {
      purchaseCode: 'someRandomCode',
      accessToken: 'short-lived-access-token',
    },
    unit: {
      1: {
        title: 'Long Vowel - ake · ase · ate',
        stage: {
          1: {
            title: 'Sound Animation',
            shortDescption: 'Sound Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S1_Dir.mp3',
            url: 'https://player.vimeo.com/video/376987765',
            type: 'video',
          },
          2: {
            title: 'Drag and drop.',
            shortDescption: 'Learn the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S2_Dir.mp3',
            type: 'dnd',
            hasStep: true,
            customData: {
              problem: [
                { 1: ['a', 't'], 2: ['a', 't', 'e'], type: 'dndType1' },
                { 1: ['a', 's'], 2: ['a', 's', 'e'], type: 'dndType1' },
                { 1: ['a', 'k'], 2: ['a', 'k', 'e'], type: 'dndType1' },
                { candidate: ['d', 'g', 'l'], question: 'ate', type: 'dndType2' },
                { candidate: ['c', 'b', 'v'], question: 'ase', type: 'dndType2' },
                { candidate: ['b', 'c', 'l'], question: 'ake', type: 'dndType2' },
              ],
              totalCount: 5,
              type: 'alphabetDnd',
            },
          },
          3: {
            title: 'Word Animation',
            shortDescption: 'Word Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S3_Dir.mp3',
            url: 'https://player.vimeo.com/video/376134035',
            type: 'video',
          },
          4: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Practice the Sounds I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S4_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_bake.png',
                  question: 'b',
                  answer: 'ake',
                  candidate: ['ate', 'ase', 'ake'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_case.png',
                  question: 'c',
                  answer: 'ase',
                  candidate: ['ate', 'ase', 'ake'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_date.png',
                  question: 'd',
                  answer: 'ate',
                  candidate: ['ate', 'ase', 'ake'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_cake.png',
                  question: 'c',
                  answer: 'ake',
                  candidate: ['ate', 'ase', 'ake'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_base.png',
                  question: 'b',
                  answer: 'ase',
                  candidate: ['ate', 'ase', 'ake'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_gate.png',
                  question: 'g',
                  answer: 'ate',
                  candidate: ['ate', 'ase', 'ake'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_lake.png',
                  question: 'l',
                  answer: 'ake',
                  candidate: ['ate', 'ase', 'ake'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_vase.png',
                  question: 'v',
                  answer: 'ase',
                  candidate: ['ate', 'ase', 'ake'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_late.png',
                  question: 'l',
                  answer: 'ate',
                  candidate: ['ate', 'ase', 'ake'],
                },
              ],
              type: 'imgDnd',
              totalCount: 1,
            },
          },
          5: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Practice the Sounds II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S5_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_bake.png',
                  question: 'b',
                  answer: 'ake',
                  candidate: { 0: ['c', 'g', 'l', 'b', 't', 'd'], 1: ['ate', 'ase', 'ake'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_case.png',
                  question: 'c',
                  answer: 'ase',
                  candidate: { 0: ['c', 'g', 'l', 'b', 't', 'd'], 1: ['ate', 'ase', 'ake'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_date.png',
                  question: 'd',
                  answer: 'ate',
                  candidate: { 0: ['c', 'g', 'l', 'b', 't', 'd'], 1: ['ate', 'ase', 'ake'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_cake.png',
                  question: 'c',
                  answer: 'ake',
                  candidate: { 0: ['c', 'g', 'l', 'b', 't', 'd'], 1: ['ate', 'ase', 'ake'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_base.png',
                  question: 'b',
                  answer: 'ase',
                  candidate: { 0: ['c', 'g', 'l', 'b', 't', 'd'], 1: ['ate', 'ase', 'ake'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_gate.png',
                  question: 'g',
                  answer: 'ate',
                  candidate: { 0: ['c', 'g', 'l', 'b', 't', 'd'], 1: ['ate', 'ase', 'ake'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_lake.png',
                  question: 'l',
                  answer: 'ake',
                  candidate: { 0: ['c', 'g', 'l', 'b', 't', 'd'], 1: ['ate', 'ase', 'ake'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_vase.png',
                  question: 'v',
                  answer: 'ase',
                  candidate: { 0: ['c', 'g', 'l', 'b', 't', 'v'], 1: ['ate', 'ase', 'ake'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_late.png',
                  question: 'l',
                  answer: 'ate',
                  candidate: { 0: ['c', 'g', 'l', 'b', 't', 'd'], 1: ['ate', 'ase', 'ake'] },
                },
              ],
              type: 'imgDnd2',
              hasSoundBtn: false,
              answerAbsolute: false,
              totalCount: 2,
            },
          },
          6: {
            title: 'Look and type the word.',
            shortDescption: 'Practice the Words I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S6_Dir.mp3',
            type: 'write',
            customData: {
              type: 'imgWrite',
              problem: [
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_bake.png', word: 'bake' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_cake.png', word: 'cake' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_lake.png', word: 'lake' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_case.png', word: 'case' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_base.png', word: 'base' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_vase.png', word: 'vase' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_date.png', word: 'date' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_gate.png', word: 'gate' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_late.png', word: 'late' },
              ],
            },
          },
          7: {
            title: 'Listen. Then drag and drop.',
            shortDescption: 'Practice the Words II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S7_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_bake.png',
                  question: 'b',
                  answer: 'ake',
                  candidate: { 0: ['c', 'g', 'l', 'b', 't', 'd'], 1: ['ate', 'ase', 'ake'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_case.png',
                  question: 'c',
                  answer: 'ase',
                  candidate: { 0: ['c', 'g', 'l', 'b', 't', 'd'], 1: ['ate', 'ase', 'ake'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_date.png',
                  question: 'd',
                  answer: 'ate',
                  candidate: { 0: ['c', 'g', 'l', 'b', 't', 'd'], 1: ['ate', 'ase', 'ake'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_cake.png',
                  question: 'c',
                  answer: 'ake',
                  candidate: { 0: ['c', 'g', 'l', 'b', 't', 'd'], 1: ['ate', 'ase', 'ake'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_base.png',
                  question: 'b',
                  answer: 'ase',
                  candidate: { 0: ['c', 'g', 'l', 'b', 't', 'd'], 1: ['ate', 'ase', 'ake'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_gate.png',
                  question: 'g',
                  answer: 'ate',
                  candidate: { 0: ['c', 'g', 'l', 'b', 't', 'd'], 1: ['ate', 'ase', 'ake'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_lake.png',
                  question: 'l',
                  answer: 'ake',
                  candidate: { 0: ['c', 'g', 'l', 'b', 't', 'd'], 1: ['ate', 'ase', 'ake'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_vase.png',
                  question: 'v',
                  answer: 'ase',
                  candidate: { 0: ['c', 'g', 'l', 'b', 't', 'v'], 1: ['ate', 'ase', 'ake'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_late.png',
                  question: 'l',
                  answer: 'ate',
                  candidate: { 0: ['c', 'g', 'l', 'b', 't', 'd'], 1: ['ate', 'ase', 'ake'] },
                },
              ],
              type: 'imgDnd2',
              hasSoundBtn: true,
              answerAbsolute: false,
              totalCount: 2,
            },
          },
          8: {
            title: 'Listen and repeat. Then record your voice.',
            shortDescption: 'Story',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S8_Dir.mp3',
            type: 'record',
            customData: {
              bgImage: 'https://cdn.cloubot.com/PM/image/l3_u1_bg_wide.jpg',
              sound: 'Lv3_U1_reading',
              sentences: ['It’s the ##date##.', '##Bake## a ##cake##.', '##Bake## a ##vase##.', 'Oh!', 'I’m ##late##!', 'It’s the ##lake##.', 'Here is the ##case##.', 'Here is the ##cake##.', 'Here is the ##vase##.', 'I’m not ##late##.'],
            },
          },
          9: {
            title: 'Choose and type the word.',
            shortDescption: 'Words in the Story',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S9_Dir.mp3',
            type: 'write',
            customData: {
              type: 'imgSoundWrite',
              problem: [
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_date.png',
                  sentenceFormat: "It's the ##word##.",
                  correctWord: 'date',
                  wrongWord: 'cake',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_bake.png',
                  sentenceFormat: '##word## a cake.',
                  correctWord: 'Bake',
                  wrongWord: 'Lake',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_late.png',
                  sentenceFormat: "I'm ##word##!",
                  correctWord: 'late',
                  wrongWord: 'case',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_cake.png',
                  sentenceFormat: 'Here is the ##word##.',
                  correctWord: 'cake',
                  wrongWord: 'vase',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_vase.png',
                  sentenceFormat: 'Here is the ##word##.',
                  correctWord: 'vase',
                  wrongWord: 'late',
                },
              ],
            },
          },
          10: {
            title: 'Story Chant',
            shortDescption: 'Story Chant',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S10_Dir.mp3',
            url: 'https://player.vimeo.com/video/376988948',
            type: 'video',
          },
          11: {
            title: 'Read. Then drag and drop.',
            shortDescption: 'Story Activity',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S11_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imgDnd3',
              problem: {
                question: [
                  { image: 'https://cdn.cloubot.com/PM/image/level3_unit01_SB_01.png', sentence: ['What is in the ##case##?'] },
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level3_unit01_SB_02.png',
                    sentence: ['I have a ##vase##.', 'I have a ##rake##.'],
                  },
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level3_unit01_SB_03.png',
                    sentence: ['I have a ##cake##.', 'I have a ##date##.'],
                  },
                  { image: 'https://cdn.cloubot.com/PM/image/level3_unit01_SB_04.png', sentence: ['Please open the ##gate##.'] },
                ],
              },
              totalCount: 4,
            },
          },
          12: {
            title: 'Listen and type the word.',
            shortDescption: 'Quiz',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S12_Dir.mp3',
            type: 'unitTest',
            problem: [
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_bake.png', word: 'bake' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_case.png', word: 'case' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_date.png', word: 'date' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_cake.png', word: 'cake' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_base.png', word: 'base' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_gate.png', word: 'gate' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_lake.png', word: 'lake' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_vase.png', word: 'vase' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_late.png', word: 'late' },
            ],
          },
        },
      },
      2: {
        title: 'Long Vowel - ame · ave · ape',
        stage: {
          1: {
            title: 'Sound Animation',
            shortDescption: 'Sound Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S1_Dir.mp3',
            url: 'https://player.vimeo.com/video/376987779',
            type: 'video',
          },
          2: {
            title: 'Drag and drop.',
            shortDescption: 'Learn the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S2_Dir.mp3',
            type: 'dnd',
            hasStep: true,
            customData: {
              problem: [
                { 1: ['a', 'm'], 2: ['a', 'm', 'e'], type: 'dndType1' },
                { 1: ['a', 'v'], 2: ['a', 'v', 'e'], type: 'dndType1' },
                { 1: ['a', 'p'], 2: ['a', 'p', 'e'], type: 'dndType1' },
                { candidate: ['g', 'n', 's'], question: 'ame', type: 'dndType2' },
                { candidate: ['w', 'c', 's'], question: 'ave', type: 'dndType2' },
                { candidate: ['t', 'c'], question: 'ape', type: 'dndType2' },
              ],
              totalCount: 5,
              type: 'alphabetDnd',
            },
          },
          3: {
            title: 'Word Animation',
            shortDescption: 'Word Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S3_Dir.mp3',
            url: 'https://player.vimeo.com/video/376134072',
            type: 'video',
          },
          4: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Practice the Sounds I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S4_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imgDnd',
              totalCount: 1,
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_game.png',
                  question: 'g',
                  answer: 'ame',
                  candidate: ['ame', 'ave', 'ape'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_wave.png',
                  question: 'w',
                  answer: 'ave',
                  candidate: ['ame', 'ave', 'ape'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_tape.png',
                  question: 't',
                  answer: 'ape',
                  candidate: ['ame', 'ave', 'ape'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_name.png',
                  question: 'n',
                  answer: 'ame',
                  candidate: ['ame', 'ave', 'ape'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_cave.png',
                  question: 'c',
                  answer: 'ave',
                  candidate: ['ame', 'ave', 'ape'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_cape.png',
                  question: 'c',
                  answer: 'ape',
                  candidate: ['ame', 'ave', 'ape'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_same.png',
                  question: 's',
                  answer: 'ame',
                  candidate: ['ame', 'ave', 'ape'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_save.png',
                  question: 's',
                  answer: 'ave',
                  candidate: ['ame', 'ave', 'ape'],
                },
              ],
            },
          },
          5: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Practice the Sounds II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S5_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_game.png',
                  question: 'g',
                  answer: 'ame',
                  candidate: { 0: ['g', 'w', 't', 'n', 'c', 's'], 1: ['ame', 'ave', 'ape'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_wave.png',
                  question: 'w',
                  answer: 'ave',
                  candidate: { 0: ['g', 'w', 't', 'n', 'c', 's'], 1: ['ame', 'ave', 'ape'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_tape.png',
                  question: 't',
                  answer: 'ape',
                  candidate: { 0: ['g', 'w', 't', 'n', 'c', 's'], 1: ['ame', 'ave', 'ape'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_name.png',
                  question: 'n',
                  answer: 'ame',
                  candidate: { 0: ['g', 'w', 't', 'n', 'c', 's'], 1: ['ame', 'ave', 'ape'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_cave.png',
                  question: 'c',
                  answer: 'ave',
                  candidate: { 0: ['g', 'w', 't', 'n', 'c', 's'], 1: ['ame', 'ave', 'ape'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_cape.png',
                  question: 'c',
                  answer: 'ape',
                  candidate: { 0: ['g', 'w', 't', 'n', 'c', 's'], 1: ['ame', 'ave', 'ape'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_same.png',
                  question: 's',
                  answer: 'ame',
                  candidate: { 0: ['g', 'w', 't', 'n', 'c', 's'], 1: ['ame', 'ave', 'ape'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_save.png',
                  question: 's',
                  answer: 'ave',
                  candidate: { 0: ['g', 'w', 't', 'n', 'c', 's'], 1: ['ame', 'ave', 'ape'] },
                },
              ],
              type: 'imgDnd2',
              hasSoundBtn: false,
              answerAbsolute: false,
              totalCount: 2,
            },
          },
          6: {
            title: 'Look and type the word.',
            shortDescption: 'Practice the Words I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S6_Dir.mp3',
            type: 'write',
            customData: {
              type: 'imgWrite',
              problem: [
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_game.png', word: 'game' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_wave.png', word: 'wave' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_tape.png', word: 'tape' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_name.png', word: 'name' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_cave.png', word: 'cave' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_cape.png', word: 'cape' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_same.png', word: 'same' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_save.png', word: 'save' },
              ],
            },
          },
          7: {
            title: 'Listen. Then drag and drop.',
            shortDescption: 'Practice the Words II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S7_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_game.png',
                  question: 'g',
                  answer: 'ame',
                  candidate: { 0: ['g', 'w', 't', 'n', 'c', 's'], 1: ['ame', 'ave', 'ape'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_wave.png',
                  question: 'w',
                  answer: 'ave',
                  candidate: { 0: ['g', 'w', 't', 'n', 'c', 's'], 1: ['ame', 'ave', 'ape'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_tape.png',
                  question: 't',
                  answer: 'ape',
                  candidate: { 0: ['g', 'w', 't', 'n', 'c', 's'], 1: ['ame', 'ave', 'ape'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_name.png',
                  question: 'n',
                  answer: 'ame',
                  candidate: { 0: ['g', 'w', 't', 'n', 'c', 's'], 1: ['ame', 'ave', 'ape'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_cave.png',
                  question: 'c',
                  answer: 'ave',
                  candidate: { 0: ['g', 'w', 't', 'n', 'c', 's'], 1: ['ame', 'ave', 'ape'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_cape.png',
                  question: 'c',
                  answer: 'ape',
                  candidate: { 0: ['g', 'w', 't', 'n', 'c', 's'], 1: ['ame', 'ave', 'ape'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_same.png',
                  question: 's',
                  answer: 'ame',
                  candidate: { 0: ['g', 'w', 't', 'n', 'c', 's'], 1: ['ame', 'ave', 'ape'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_save.png',
                  question: 's',
                  answer: 'ave',
                  candidate: { 0: ['g', 'w', 't', 'n', 'c', 's'], 1: ['ame', 'ave', 'ape'] },
                },
              ],
              type: 'imgDnd2',
              hasSoundBtn: true,
              answerAbsolute: false,
              totalCount: 2,
            },
          },
          8: {
            title: 'Listen and repeat. Then record your voice.',
            shortDescption: 'Story',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S8_Dir.mp3',
            type: 'record',
            customData: {
              bgImage: 'https://cdn.cloubot.com/PM/image/l3_u2_bg_wide.jpg',
              sound: 'Lv3_U2_reading',
              sentences: ['I’m James.', 'I’m James!', 'We have the ##same## ##name##.', 'We have the ##same## ##cape##.', 'We play in the ##wave##.', 'We play in the ##cave##.', 'We play a ##game##.', 'Oh, no! ##Tape##! Cut the ##tape##.', '##Save## James!'],
            },
          },
          9: {
            title: 'Choose and type the word.',
            shortDescption: 'Words in the Story',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S9_Dir.mp3',
            type: 'write',
            customData: {
              type: 'imgSoundWrite',
              problem: [
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_same.png',
                  sentenceFormat: 'We have the ##word## name.',
                  correctWord: 'same',
                  wrongWord: 'game',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_cape.png',
                  sentenceFormat: 'We have the same ##word##.',
                  correctWord: 'cape',
                  wrongWord: 'wave',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_wave.png',
                  sentenceFormat: 'We play in the ##word##.',
                  correctWord: 'wave',
                  wrongWord: 'same',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_cave.png',
                  sentenceFormat: 'We play in the ##word##.',
                  correctWord: 'cave',
                  wrongWord: 'game',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_tape.png',
                  sentenceFormat: 'Cut the ##word##.',
                  correctWord: 'tape',
                  wrongWord: 'cape',
                },
              ],
            },
          },
          10: {
            title: 'Story Chant',
            shortDescption: 'Story Chant',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S10_Dir.mp3',
            url: 'https://player.vimeo.com/video/376989002',
            type: 'video',
          },
          11: {
            title: 'Read. Then drag and drop.',
            shortDescption: 'Story Activity',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S11_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imgDnd3',
              problem: {
                question: [
                  { image: 'https://cdn.cloubot.com/PM/image/level3_unit02_SB_01.png', sentence: ['The lion lives in a ##cave##.'] },
                  { image: 'https://cdn.cloubot.com/PM/image/level3_unit02_SB_02.png', sentence: ['The lion has a ##mane##.'] },
                  { image: 'https://cdn.cloubot.com/PM/image/level3_unit02_SB_03.png', sentence: ['The lion hops a ##wave##.'] },
                  { image: 'https://cdn.cloubot.com/PM/image/level3_unit02_SB_04.png', sentence: ['The lion needs a ##cane##.'] },
                ],
              },
              totalCount: 4,
            },
          },
          12: {
            title: 'Listen and type the word.',
            shortDescption: 'Quiz',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S12_Dir.mp3',
            type: 'unitTest',
            problem: [
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_game.png', word: 'game' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_wave.png', word: 'wave' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_tape.png', word: 'tape' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_name.png', word: 'name' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_cave.png', word: 'cave' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_cape.png', word: 'cape' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_same.png', word: 'same' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_save.png', word: 'save' },
            ],
          },
        },
      },
      3: {
        title: 'Long Vowel - ice · ike · ine',
        stage: {
          1: {
            title: 'Sound Animation',
            shortDescption: 'Sound Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S1_Dir.mp3',
            url: 'https://player.vimeo.com/video/376987798',
            type: 'video',
          },
          2: {
            title: 'Drag and drop.',
            shortDescption: 'Learn the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S2_Dir.mp3',
            type: 'dnd',
            hasStep: true,
            customData: {
              problem: [
                { 1: ['i', 'c'], 2: ['i', 'c', 'e'], type: 'dndType1' },
                { 1: ['i', 'k'], 2: ['i', 'k', 'e'], type: 'dndType1' },
                { 1: ['i', 'n'], 2: ['i', 'n', 'e'], type: 'dndType1' },
                { candidate: ['d', 'm', 'r'], question: 'ice', type: 'dndType2' },
                { candidate: ['b', 'h', 'm'], question: 'ike', type: 'dndType2' },
                { candidate: ['l', 'n', 'p'], question: 'ine', type: 'dndType2' },
              ],
              totalCount: 5,
              type: 'alphabetDnd',
            },
          },
          3: {
            title: 'Word Animation',
            shortDescption: 'Word Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S3_Dir.mp3',
            url: 'https://player.vimeo.com/video/376134101',
            type: 'video',
          },
          4: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Practice the Sounds I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S4_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imgDnd',
              totalCount: 1,
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_dice.png',
                  question: 'd',
                  answer: 'ice',
                  candidate: ['ice', 'ike', 'ine'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_bike.png',
                  question: 'b',
                  answer: 'ike',
                  candidate: ['ice', 'ike', 'ine'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_line.png',
                  question: 'l',
                  answer: 'ine',
                  candidate: ['ice', 'ike', 'ine'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_mice.png',
                  question: 'm',
                  answer: 'ice',
                  candidate: ['ice', 'ike', 'ine'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_hike.png',
                  question: 'h',
                  answer: 'ike',
                  candidate: ['ice', 'ike', 'ine'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_nine.png',
                  question: 'n',
                  answer: 'ine',
                  candidate: ['ice', 'ike', 'ine'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_rice.png',
                  question: 'r',
                  answer: 'ice',
                  candidate: ['ice', 'ike', 'ine'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_mike.png',
                  question: 'm',
                  answer: 'ike',
                  candidate: ['ice', 'ike', 'ine'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_pine.png',
                  question: 'p',
                  answer: 'ine',
                  candidate: ['ice', 'ike', 'ine'],
                },
              ],
            },
          },
          5: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Practice the Sounds II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S5_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_dice.png',
                  question: 'd',
                  answer: 'ice',
                  candidate: { 0: ['b', 'd', 'h', 'l', 'm', 'n', 'r', 'p'], 1: ['ice', 'ike', 'ine'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_bike.png',
                  question: 'b',
                  answer: 'ike',
                  candidate: { 0: ['b', 'd', 'h', 'l', 'm', 'n', 'r', 'p'], 1: ['ice', 'ike', 'ine'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_line.png',
                  question: 'l',
                  answer: 'ine',
                  candidate: { 0: ['b', 'd', 'h', 'l', 'm', 'n', 'r', 'p'], 1: ['ice', 'ike', 'ine'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_mice.png',
                  question: 'm',
                  answer: 'ice',
                  candidate: { 0: ['b', 'd', 'h', 'l', 'm', 'n', 'r', 'p'], 1: ['ice', 'ike', 'ine'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_hike.png',
                  question: 'h',
                  answer: 'ike',
                  candidate: { 0: ['b', 'd', 'h', 'l', 'm', 'n', 'r', 'p'], 1: ['ice', 'ike', 'ine'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_nine.png',
                  question: 'n',
                  answer: 'ine',
                  candidate: { 0: ['b', 'd', 'h', 'l', 'm', 'n', 'r', 'p'], 1: ['ice', 'ike', 'ine'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_rice.png',
                  question: 'r',
                  answer: 'ice',
                  candidate: { 0: ['b', 'd', 'h', 'l', 'm', 'n', 'r', 'p'], 1: ['ice', 'ike', 'ine'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_mike.png',
                  question: 'm',
                  answer: 'ike',
                  candidate: { 0: ['b', 'd', 'h', 'l', 'm', 'n', 'r', 'p'], 1: ['ice', 'ike', 'ine'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_pine.png',
                  question: 'p',
                  answer: 'ine',
                  candidate: { 0: ['b', 'd', 'h', 'l', 'm', 'n', 'r', 'p'], 1: ['ice', 'ike', 'ine'] },
                },
              ],
              type: 'imgDnd2',
              hasSoundBtn: false,
              answerAbsolute: false,
              totalCount: 2,
            },
          },
          6: {
            title: 'Look and type the word.',
            shortDescption: 'Practice the Words I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S6_Dir.mp3',
            type: 'write',
            customData: {
              type: 'imgWrite',
              problem: [
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_dice.png', word: 'dice' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_bike.png', word: 'bike' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_line.png', word: 'line' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_mice.png', word: 'mice' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_hike.png', word: 'hike' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_nine.png', word: 'nine' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_rice.png', word: 'rice' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_mike.png', word: 'mike' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_pine.png', word: 'pine' },
              ],
            },
          },
          7: {
            title: 'Listen. Then drag and drop.',
            shortDescption: 'Practice the Words II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S7_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_dice.png',
                  question: 'd',
                  answer: 'ice',
                  candidate: { 0: ['b', 'd', 'h', 'l', 'm', 'n', 'r', 'p'], 1: ['ice', 'ike', 'ine'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_bike.png',
                  question: 'b',
                  answer: 'ike',
                  candidate: { 0: ['b', 'd', 'h', 'l', 'm', 'n', 'r', 'p'], 1: ['ice', 'ike', 'ine'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_line.png',
                  question: 'l',
                  answer: 'ine',
                  candidate: { 0: ['b', 'd', 'h', 'l', 'm', 'n', 'r', 'p'], 1: ['ice', 'ike', 'ine'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_mice.png',
                  question: 'm',
                  answer: 'ice',
                  candidate: { 0: ['b', 'd', 'h', 'l', 'm', 'n', 'r', 'p'], 1: ['ice', 'ike', 'ine'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_hike.png',
                  question: 'h',
                  answer: 'ike',
                  candidate: { 0: ['b', 'd', 'h', 'l', 'm', 'n', 'r', 'p'], 1: ['ice', 'ike', 'ine'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_nine.png',
                  question: 'n',
                  answer: 'ine',
                  candidate: { 0: ['b', 'd', 'h', 'l', 'm', 'n', 'r', 'p'], 1: ['ice', 'ike', 'ine'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_rice.png',
                  question: 'r',
                  answer: 'ice',
                  candidate: { 0: ['b', 'd', 'h', 'l', 'm', 'n', 'r', 'p'], 1: ['ice', 'ike', 'ine'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_mike.png',
                  question: 'm',
                  answer: 'ike',
                  candidate: { 0: ['b', 'd', 'h', 'l', 'm', 'n', 'r', 'p'], 1: ['ice', 'ike', 'ine'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_pine.png',
                  question: 'p',
                  answer: 'ine',
                  candidate: { 0: ['b', 'd', 'h', 'l', 'm', 'n', 'r', 'p'], 1: ['ice', 'ike', 'ine'] },
                },
              ],
              type: 'imgDnd2',
              hasSoundBtn: true,
              answerAbsolute: false,
              totalCount: 2,
            },
          },
          8: {
            title: 'Listen and repeat. Then record your voice.',
            shortDescption: 'Story',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S8_Dir.mp3',
            type: 'record',
            customData: {
              bgImage: 'https://cdn.cloubot.com/PM/image/l3_u3_bg_wide.jpg',
              sound: 'Lv3_U3_reading',
              sentences: ['##Nine## ##mice## like ##rice##.', 'Oh, no! No ##rice##.', '##Nine## ##mice## are on ##bikes##.', 'Look!', 'Nice ##rice## is on a ##pine##!', '##Nine## ##mice## ##line## up.', '##Nine## ##mice## ##hike##.', 'Up, up, up!'],
            },
          },
          9: {
            title: 'Choose and type the word.',
            shortDescption: 'Words in the Story',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S9_Dir.mp3',
            type: 'write',
            customData: {
              type: 'imgSoundWrite',
              problem: [
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_mice.png',
                  sentenceFormat: 'Nine ##word## like rice.',
                  correctWord: 'mice',
                  wrongWord: 'line',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_bike.png',
                  sentenceFormat: 'The mice are on ##word##s.',
                  correctWord: 'bike',
                  wrongWord: 'nine',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_rice.png',
                  sentenceFormat: 'Nice ##word## is on a pine.',
                  correctWord: 'rice',
                  wrongWord: 'dice',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_line.png',
                  sentenceFormat: 'The mice ##word## up.',
                  correctWord: 'line',
                  wrongWord: 'rice',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_hike.png',
                  sentenceFormat: 'The mice ##word##.',
                  correctWord: 'hike',
                  wrongWord: 'nine',
                },
              ],
            },
          },
          10: {
            title: 'Story Chant',
            shortDescption: 'Story Chant',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S10_Dir.mp3',
            url: 'https://player.vimeo.com/video/376989015',
            type: 'video',
          },
          11: {
            title: 'Read. Then drag and drop.',
            shortDescption: 'Story Activity',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S11_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imgDnd3',
              problem: {
                question: [
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level3_unit03_SB_01.png',
                    sentence: ['Two ##mice## want a ##pine##.', 'They ##hike##, ##hike##, ##hike##.'],
                  },
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level3_unit03_SB_02.png',
                    sentence: ['Three ##mice## want a ##bike##.', 'They roll the ##dice##.'],
                  },
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level3_unit03_SB_03.png',
                    sentence: ['Four ##mice## want some ##rice##.', 'They wait in ##line##.'],
                  },
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level3_unit03_SB_04.png',
                    sentence: ['Now, there are ##nine## ##mice##.', 'Run, run. Run from ##Mike##!'],
                  },
                ],
              },
              totalCount: 4,
            },
          },
          12: {
            title: 'Listen and type the word.',
            shortDescption: 'Quiz',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S12_Dir.mp3',
            type: 'unitTest',
            problem: [
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_dice.png', word: 'dice' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_bike.png', word: 'bike' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_line.png', word: 'line' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_mice.png', word: 'mice' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_hike.png', word: 'hike' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_nine.png', word: 'nine' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_rice.png', word: 'rice' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_mike.png', word: 'mike' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_pine.png', word: 'pine' },
            ],
          },
        },
      },
      4: {
        title: 'Long Vowel - ide · ive · ite',
        stage: {
          1: {
            title: 'Sound Animation',
            shortDescption: 'Sound Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S1_Dir.mp3',
            url: 'https://player.vimeo.com/video/376987808',
            type: 'video',
          },
          2: {
            title: 'Drag and drop.',
            shortDescption: 'Learn the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S2_Dir.mp3',
            type: 'dnd',
            hasStep: true,
            customData: {
              problem: [
                { 1: ['i', 'd'], 2: ['i', 'd', 'e'], type: 'dndType1' },
                { 1: ['i', 'v'], 2: ['i', 'v', 'e'], type: 'dndType1' },
                { 1: ['i', 't'], 2: ['i', 't', 'e'], type: 'dndType1' },
                { candidate: ['h', 'r', 'w'], question: 'ide', type: 'dndType2' },
                { candidate: ['d', 'f', 'h'], question: 'ive', type: 'dndType2' },
                { candidate: ['b', 'k'], question: 'ite', type: 'dndType2' },
              ],
              totalCount: 5,
              type: 'alphabetDnd',
            },
          },
          3: {
            title: 'Word Animation',
            shortDescption: 'Word Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S3_Dir.mp3',
            url: 'https://player.vimeo.com/video/376134140',
            type: 'video',
          },
          4: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Practice the Sounds I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S4_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imgDnd',
              totalCount: 1,
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_hide.png',
                  question: 'h',
                  answer: 'ide',
                  candidate: ['ide', 'ive', 'ite'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_dive.png',
                  question: 'd',
                  answer: 'ive',
                  candidate: ['ide', 'ive', 'ite'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_bite.png',
                  question: 'b',
                  answer: 'ite',
                  candidate: ['ide', 'ive', 'ite'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_ride.png',
                  question: 'r',
                  answer: 'ide',
                  candidate: ['ide', 'ive', 'ite'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_five.png',
                  question: 'f',
                  answer: 'ive',
                  candidate: ['ide', 'ive', 'ite'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_kite.png',
                  question: 'k',
                  answer: 'ite',
                  candidate: ['ide', 'ive', 'ite'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_wide.png',
                  question: 'w',
                  answer: 'ide',
                  candidate: ['ide', 'ive', 'ite'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_hive.png',
                  question: 'h',
                  answer: 'ive',
                  candidate: ['ide', 'ive', 'ite'],
                },
              ],
            },
          },
          5: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Practice the Sounds II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S5_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_hide.png',
                  question: 'h',
                  answer: 'ide',
                  candidate: { 0: ['r', 'w', 'd', 'f', 'h', 'k', 'b'], 1: ['ide', 'ive', 'ite'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_dive.png',
                  question: 'd',
                  answer: 'ive',
                  candidate: { 0: ['r', 'w', 'd', 'f', 'h', 'k', 'b'], 1: ['ide', 'ive', 'ite'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_bite.png',
                  question: 'b',
                  answer: 'ite',
                  candidate: { 0: ['r', 'w', 'd', 'f', 'h', 'k', 'b'], 1: ['ide', 'ive', 'ite'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_ride.png',
                  question: 'r',
                  answer: 'ide',
                  candidate: { 0: ['r', 'w', 'd', 'f', 'h', 'k', 'b'], 1: ['ide', 'ive', 'ite'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_five.png',
                  question: 'f',
                  answer: 'ive',
                  candidate: { 0: ['r', 'w', 'd', 'f', 'h', 'k', 'b'], 1: ['ide', 'ive', 'ite'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_kite.png',
                  question: 'k',
                  answer: 'ite',
                  candidate: { 0: ['r', 'w', 'd', 'f', 'h', 'k', 'b'], 1: ['ide', 'ive', 'ite'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_wide.png',
                  question: 'w',
                  answer: 'ide',
                  candidate: { 0: ['r', 'w', 'd', 'f', 'h', 'k', 'b'], 1: ['ide', 'ive', 'ite'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_hive.png',
                  question: 'h',
                  answer: 'ive',
                  candidate: { 0: ['r', 'w', 'd', 'f', 'h', 'k', 'b'], 1: ['ide', 'ive', 'ite'] },
                },
              ],
              type: 'imgDnd2',
              hasSoundBtn: false,
              answerAbsolute: false,
              totalCount: 2,
            },
          },
          6: {
            title: 'Look and type the word.',
            shortDescption: 'Practice the Words I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S6_Dir.mp3',
            type: 'write',
            customData: {
              type: 'imgWrite',
              problem: [
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_hide.png', word: 'hide' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_dive.png', word: 'dive' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_bite.png', word: 'bite' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_ride.png', word: 'ride' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_five.png', word: 'five' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_kite.png', word: 'kite' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_wide.png', word: 'wide' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_hive.png', word: 'hive' },
              ],
            },
          },
          7: {
            title: 'Listen. Then drag and drop.',
            shortDescption: 'Practice the Words II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S7_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_hide.png',
                  question: 'h',
                  answer: 'ide',
                  candidate: { 0: ['r', 'w', 'd', 'f', 'h', 'k', 'b'], 1: ['ide', 'ive', 'ite'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_dive.png',
                  question: 'd',
                  answer: 'ive',
                  candidate: { 0: ['r', 'w', 'd', 'f', 'h', 'k', 'b'], 1: ['ide', 'ive', 'ite'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_bite.png',
                  question: 'b',
                  answer: 'ite',
                  candidate: { 0: ['r', 'w', 'd', 'f', 'h', 'k', 'b'], 1: ['ide', 'ive', 'ite'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_ride.png',
                  question: 'r',
                  answer: 'ide',
                  candidate: { 0: ['r', 'w', 'd', 'f', 'h', 'k', 'b'], 1: ['ide', 'ive', 'ite'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_five.png',
                  question: 'f',
                  answer: 'ive',
                  candidate: { 0: ['r', 'w', 'd', 'f', 'h', 'k', 'b'], 1: ['ide', 'ive', 'ite'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_kite.png',
                  question: 'k',
                  answer: 'ite',
                  candidate: { 0: ['r', 'w', 'd', 'f', 'h', 'k', 'b'], 1: ['ide', 'ive', 'ite'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_wide.png',
                  question: 'w',
                  answer: 'ide',
                  candidate: { 0: ['r', 'w', 'd', 'f', 'h', 'k', 'b'], 1: ['ide', 'ive', 'ite'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_hive.png',
                  question: 'h',
                  answer: 'ive',
                  candidate: { 0: ['r', 'w', 'd', 'f', 'h', 'k', 'b'], 1: ['ide', 'ive', 'ite'] },
                },
              ],
              type: 'imgDnd2',
              hasSoundBtn: true,
              answerAbsolute: false,
              totalCount: 2,
            },
          },
          8: {
            title: 'Listen and repeat. Then record your voice.',
            shortDescption: 'Story',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S8_Dir.mp3',
            type: 'record',
            customData: {
              bgImage: 'https://cdn.cloubot.com/PM/image/l3_u4_bg_wide.jpg',
              sound: 'Lv3_U4_reading',
              sentences: ['##Five## mice like rice.', '##Five## mice ##ride## a ##wide## ##kite##.', 'Is it rice?', '##Five## mice ##bite## it.', 'No, it’s not rice.', 'It’s a ##hive##!', '##Five## mice ##dive##.', '##Five## mice ##hide## in a pine.'],
            },
          },
          9: {
            title: 'Choose and type the word.',
            shortDescption: 'Words in the Story',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S9_Dir.mp3',
            type: 'write',
            customData: {
              type: 'imgSoundWrite',
              problem: [
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_kite.png',
                  sentenceFormat: 'Five mice ride a wide ##word##.',
                  correctWord: 'kite',
                  wrongWord: 'hide',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_bite.png',
                  sentenceFormat: 'Five mice ##word## it.',
                  correctWord: 'bite',
                  wrongWord: 'kite',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_hive.png',
                  sentenceFormat: "It's not rice. It's a ##word##!",
                  correctWord: 'hive',
                  wrongWord: 'bite',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_dive.png',
                  sentenceFormat: 'Five mice ##word##.',
                  correctWord: 'dive',
                  wrongWord: 'hive',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_hide.png',
                  sentenceFormat: 'Five mice ##word## in a pine.',
                  correctWord: 'hide',
                  wrongWord: 'kite',
                },
              ],
            },
          },
          10: {
            title: 'Story Chant',
            shortDescption: 'Story Chant',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S10_Dir.mp3',
            url: 'https://player.vimeo.com/video/376989045',
            type: 'video',
          },
          11: {
            title: 'Read. Then drag and drop.',
            shortDescption: 'Story Activity',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S11_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imgDnd3',
              problem: {
                question: [
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level3_unit04_SB_01.png',
                    sentence: ['Here comes ##five## bad ##kites##.', 'The ##kites## ##ride## a bike.'],
                  },
                  { image: 'https://cdn.cloubot.com/PM/image/level3_unit04_SB_02.png', sentence: ['The ##kites## play with a ##hive##.'] },
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level3_unit04_SB_03.png',
                    sentence: ['The ##kites## run to ##hide##.', 'The ##kites## ##dive## into the lake.'],
                  },
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level3_unit04_SB_04.png',
                    sentence: ['Oh, no! The fish ##bite## the ##kites##!'],
                  },
                ],
              },
              totalCount: 4,
            },
          },
          12: {
            title: 'Listen and type the word.',
            shortDescption: 'Quiz',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S12_Dir.mp3',
            type: 'unitTest',
            problem: [
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_hide.png', word: 'hide' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_dive.png', word: 'dive' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_bite.png', word: 'bite' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_ride.png', word: 'ride' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_five.png', word: 'five' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_kite.png', word: 'kite' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_wide.png', word: 'wide' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_hive.png', word: 'hive' },
            ],
          },
        },
      },
      5: {
        title: 'Review : UNIT.1 ~ UNIT.4',
        stage: {
          1: {
            title: 'Listen. Then drag and drop.',
            shortDescption: 'Review the Sounds I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_Review_S1_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imgDnd2',
              hasSoundBtn: true,
              answerAbsolute: true,
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_bake.png',
                  question: 'b',
                  answer: 'ake',
                  candidate: { 0: ['c', 'g', 'l', 'b', 't', 'd'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_case.png',
                  question: 'c',
                  answer: 'ase',
                  candidate: { 0: ['c', 'g', 'l', 'b', 't', 'd'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_date.png',
                  question: 'd',
                  answer: 'ate',
                  candidate: { 0: ['c', 'g', 'l', 'b', 't', 'd'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_cake.png',
                  question: 'c',
                  answer: 'ake',
                  candidate: { 0: ['c', 'g', 'l', 'b', 't', 'd'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_base.png',
                  question: 'b',
                  answer: 'ase',
                  candidate: { 0: ['c', 'g', 'l', 'b', 't', 'd'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_gate.png',
                  question: 'g',
                  answer: 'ate',
                  candidate: { 0: ['c', 'g', 'l', 'b', 't', 'd'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_lake.png',
                  question: 'l',
                  answer: 'ake',
                  candidate: { 0: ['c', 'g', 'l', 'b', 't', 'd'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_vase.png',
                  question: 'v',
                  answer: 'ase',
                  candidate: { 0: ['c', 'g', 'l', 'b', 't', 'v'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_late.png',
                  question: 'l',
                  answer: 'ate',
                  candidate: { 0: ['c', 'g', 'l', 'b', 't', 'd'] },
                },
              ],
              totalCount: 1,
            },
          },
          2: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Review the Words I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_Review05_S2_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imgDnd4',

              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_bake.png',
                  question: 'b',
                  answer: 'ake',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level3/image_bake.png',
                    'https://cdn.cloubot.com/PM/image/level3/image_case.png',
                    'https://cdn.cloubot.com/PM/image/level3/image_date.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_case.png',
                  question: 'c',
                  answer: 'ase',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level3/image_bake.png',
                    'https://cdn.cloubot.com/PM/image/level3/image_case.png',
                    'https://cdn.cloubot.com/PM/image/level3/image_date.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_date.png',
                  question: 'd',
                  answer: 'ate',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level3/image_bake.png',
                    'https://cdn.cloubot.com/PM/image/level3/image_case.png',
                    'https://cdn.cloubot.com/PM/image/level3/image_date.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_nine.png',
                  question: 'n',
                  answer: 'ine',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level3/image_game.png',
                    'https://cdn.cloubot.com/PM/image/level3/image_rice.png',
                    'https://cdn.cloubot.com/PM/image/level3/image_nine.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_rice.png',
                  question: 'r',
                  answer: 'ice',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level3/image_game.png',
                    'https://cdn.cloubot.com/PM/image/level3/image_rice.png',
                    'https://cdn.cloubot.com/PM/image/level3/image_nine.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_game.png',
                  question: 'g',
                  answer: 'ame',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level3/image_game.png',
                    'https://cdn.cloubot.com/PM/image/level3/image_rice.png',
                    'https://cdn.cloubot.com/PM/image/level3/image_nine.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_hike.png',
                  question: 'h',
                  answer: 'ike',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level3/image_kite.png',
                    'https://cdn.cloubot.com/PM/image/level3/image_hive.png',
                    'https://cdn.cloubot.com/PM/image/level3/image_hike.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_hive.png',
                  question: 'h',
                  answer: 'ive',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level3/image_kite.png',
                    'https://cdn.cloubot.com/PM/image/level3/image_hive.png',
                    'https://cdn.cloubot.com/PM/image/level3/image_hike.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_kite.png',
                  question: 'k',
                  answer: 'ite',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level3/image_kite.png',
                    'https://cdn.cloubot.com/PM/image/level3/image_hive.png',
                    'https://cdn.cloubot.com/PM/image/level3/image_hike.png',
                  ],
                },
              ],
              totalCount: 1,
            },
          },
          3: {
            title: 'Look and listen. Then choose.',
            shortDescption: 'Review the Words II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_Review05_S3_Dir.mp3',
            type: 'imgSelect',
            customData: {
              type: 'imgSelectImg', //imgSelectImg, imgSelectWord

              problem: [
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level3/image_bike.png',
                  qWord: 'bike',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_date.png', word: 'date', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_hike.png', word: 'hike', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_cone.png', word: 'cone', isAnswer: false },
                  ],
                },
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level3/image_lake.png',
                  qWord: 'lake',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_cake.png', word: 'cake', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_hike.png', word: 'hike', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_cone.png', word: 'cone', isAnswer: false },
                  ],
                },
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level3/image_case.png',
                  qWord: 'case',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_vase.png', word: 'vase', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_hike.png', word: 'hike', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_cone.png', word: 'cone', isAnswer: false },
                  ],
                },
              ],
            },
          },
          4: {
            title: 'Look and choose.',
            shortDescption: 'Word Puzzle',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_Review_S4_Dir.mp3',
            type: 'crossWords',
            customData: {
              imageBaseUrl: 'https://cdn.cloubot.com/PM/image/level3/',
              problem: [
                {
                  words: [
                    {
                      name: 'cave',
                      xy: [
                        [0, 1],
                        [1, 1],
                        [2, 1],
                        [3, 1],
                      ],
                    },
                    {
                      name: 'case',
                      xy: [
                        [0, 1],
                        [0, 2],
                        [0, 3],
                        [0, 4],
                      ],
                    },
                    {
                      name: 'five',
                      xy: [
                        [2, 5],
                        [3, 5],
                        [4, 5],
                        [5, 5],
                      ],
                    },
                    {
                      name: 'hive',
                      xy: [
                        [5, 2],
                        [5, 3],
                        [5, 4],
                        [5, 5],
                      ],
                    },
                    {
                      name: 'bite',
                      xy: [
                        [4, 0],
                        [4, 1],
                        [4, 2],
                        [4, 3],
                      ],
                    },
                    {
                      name: 'ride',
                      xy: [
                        [6, 1],
                        [6, 2],
                        [6, 3],
                        [6, 4],
                      ],
                    },
                    {
                      name: 'dice',
                      xy: [
                        [3, 4],
                        [3, 5],
                        [3, 6],
                        [3, 7],
                      ],
                    },
                    {
                      name: 'game',
                      xy: [
                        [1, 0],
                        [1, 1],
                        [1, 2],
                        [1, 3],
                      ],
                    },
                  ],
                  coords: [
                    ['a', 'g', 'v', 'g', 'b', 't', 'e'],
                    ['c', 'a', 'v', 'e', 'i', 'e', 'r'],
                    ['a', 'm', 'a', 't', 't', 'h', 'i'],
                    ['s', 'e', 'n', 'e', 'e', 'i', 'd'],
                    ['e', 'c', 'e', 'd', 'k', 'v', 'e'],
                    ['g', 'a', 'f', 'i', 'v', 'e', 'v'],
                    ['a', 'p', 'e', 'c', 's', 'n', 'e'],
                    ['t', 'a', 'd', 'e', 'c', 'e', 's'],
                  ],
                },
                {
                  words: [
                    {
                      name: 'bake',
                      xy: [
                        [1, 0],
                        [2, 0],
                        [3, 0],
                        [4, 0],
                      ],
                    },
                    {
                      name: 'cake',
                      xy: [
                        [0, 1],
                        [0, 2],
                        [0, 3],
                        [0, 4],
                      ],
                    },
                    {
                      name: 'gate',
                      xy: [
                        [1, 1],
                        [1, 2],
                        [1, 3],
                        [1, 4],
                      ],
                    },
                    {
                      name: 'late',
                      xy: [
                        [5, 2],
                        [5, 3],
                        [5, 4],
                        [5, 5],
                      ],
                    },
                    {
                      name: 'name',
                      xy: [
                        [2, 5],
                        [3, 5],
                        [4, 5],
                        [5, 5],
                      ],
                    },
                    {
                      name: 'same',
                      xy: [
                        [4, 3],
                        [4, 4],
                        [4, 5],
                        [4, 6],
                      ],
                    },
                    {
                      name: 'tape',
                      xy: [
                        [6, 2],
                        [6, 3],
                        [6, 4],
                        [6, 5],
                      ],
                    },
                    {
                      name: 'wave',
                      xy: [
                        [0, 7],
                        [1, 7],
                        [2, 7],
                        [3, 7],
                      ],
                    },
                  ],
                  coords: [
                    ['a', 'b', 'a', 'k', 'e', 't', 'e'],
                    ['c', 'g', 'v', 'e', 'i', 'e', 'r'],
                    ['a', 'a', 'a', 't', 't', 'l', 't'],
                    ['k', 't', 'n', 'e', 's', 'a', 'a'],
                    ['e', 'e', 'e', 'd', 'a', 't', 'p'],
                    ['g', 'a', 'n', 'a', 'm', 'e', 'e'],
                    ['a', 'p', 'e', 'c', 'e', 'p', 'e'],
                    ['w', 'a', 'v', 'e', 'c', 'e', 's'],
                  ],
                },
              ],
            },
          },
          5: {
            title: 'Look at the picture. Choose the picture that has the same sound. (Rhyme)',
            shortDescption: 'Review the Sounds II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_Review05_S5_Dir.mp3',
            type: 'imgSelect',
            customData: {
              type: 'imgSelectWord', //imgSelectImg, imgSelectWord
              problem: [
                {
                  qWord: 'ike',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_mike.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_vase.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_rice.png', isAnswer: false },
                  ],
                },
                {
                  qWord: 'ase',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_date.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_hike.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_case.png', isAnswer: true },
                  ],
                },
              ],
            },
          },
          6: {
            title: 'Read and choose.',
            shortDescption: 'Review the Words III',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_Review05_S6_Dir.mp3',
            type: 'imgSelect',
            customData: {
              type: 'imgSelectSentence', //imgSelectImg, imgSelectWord
              problem: [
                {
                  qSentence: 'a cape on a base',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_a_cap_on_a_base.jpg', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_a_cape_on_a_base.jpg', isAnswer: true },
                  ],
                },
                {
                  qSentence: 'a kite in a cave',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_a_kite_in_a_cave.jpg', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_a_kit_in_a_cave.jpg', isAnswer: false },
                  ],
                },
                {
                  qSentence: 'five cases',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_five_cases.jpg', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_five_cans.jpg', isAnswer: false },
                  ],
                },
                {
                  qSentence: 'nine pines',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_nine_pins.jpg', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_nine_pines.jpg', isAnswer: true },
                  ],
                },
              ],
            },
          },
          7: {
            title: 'Look and choose. Then type the word.',
            shortDescption: 'Review the Words IV',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_Review_S7_Dir.mp3',
            type: 'write',
            customData: {
              type: 'multiReview',
              problem: [
                {
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_bake.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_mike.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_hide.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_date.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_hive.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_vase.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_wave.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_dive.png', isAnswer: false },
                  ],
                  exVowel: ['Long', 'a'],
                },
                {
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_bake.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_mike.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_hide.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_date.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_hive.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_vase.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_wave.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_dive.png', isAnswer: true },
                  ],
                  exVowel: ['Long', 'i'],
                },
              ],
              totalCount: 4,
            },
          },
          8: {
            title: 'Choose the word to create your sentence.',
            shortDescption: 'Make Your Sentence',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_Review_S8_Dir.mp3',
            type: 'write',
            customData: {
              type: 'singleReview',
              problem: [
                {
                  imageBaseUrl: 'https://cdn.cloubot.com/PM/image/level3/',
                  sentenceFormat: 'James bakes a ##word##.',
                  words: ['cake', 'vase'],
                },
                {
                  imageBaseUrl: 'https://cdn.cloubot.com/PM/image/level3/',
                  sentenceFormat: 'He hides in a ##word##.',
                  words: ['cave', 'pine'],
                },
                {
                  imageBaseUrl: 'https://cdn.cloubot.com/PM/image/level3/',
                  sentenceFormat: 'He rides in a ##word##.',
                  words: ['kite', 'bike'],
                },
              ],
            },
          },
        },
      },
    },
  },
  {
    bookName: '3b',
    bookCode: 'PM-L6V1',
    validation: {
      purchaseCode: 'someRandomCode',
      accessToken: 'short-lived-access-token',
    },
    unit: {
      6: {
        title: 'Long Vowel - ole · ome · one',
        stage: {
          1: {
            title: 'Sound Animation',
            shortDescption: 'Sound Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S1_Dir.mp3',
            url: 'https://player.vimeo.com/video/376987822',
            type: 'video',
          },
          2: {
            title: 'Drag and drop.',
            shortDescption: 'Learn the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S2_Dir.mp3',
            type: 'dnd',
            hasStep: true,
            customData: {
              problem: [
                { 1: ['o', 'l'], 2: ['o', 'l', 'e'], type: 'dndType1' },
                { 1: ['o', 'm'], 2: ['o', 'm', 'e'], type: 'dndType1' },
                { 1: ['o', 'n'], 2: ['o', 'n', 'e'], type: 'dndType1' },
                { candidate: ['h', 'p', 'm'], question: 'ole', type: 'dndType2' },
                { candidate: ['h', 'd'], question: 'ome', type: 'dndType2' },
                { candidate: ['b', 'c'], question: 'one', type: 'dndType2' },
              ],
              totalCount: 5,
              type: 'alphabetDnd',
            },
          },
          3: {
            title: 'Word Animation',
            shortDescption: 'Word Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S3_Dir.mp3',
            url: 'https://player.vimeo.com/video/376134169',
            type: 'video',
          },
          4: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Practice the Sounds I',
            type: 'dnd',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S4_Dir.mp3',
            customData: {
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_hole.png',
                  question: 'h',
                  answer: 'ole',
                  candidate: ['one', 'ome', 'ole'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_dome.png',
                  question: 'd',
                  answer: 'ome',
                  candidate: ['one', 'ome', 'ole'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_bone.png',
                  question: 'b',
                  answer: 'one',
                  candidate: ['one', 'ome', 'ole'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_pole.png',
                  question: 'p',
                  answer: 'ole',
                  candidate: ['one', 'ome', 'ole'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_home.png',
                  question: 'h',
                  answer: 'ome',
                  candidate: ['one', 'ome', 'ole'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_cone.png',
                  question: 'c',
                  answer: 'one',
                  candidate: ['one', 'ome', 'ole'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_mole.png',
                  question: 'm',
                  answer: 'ole',
                  candidate: ['one', 'ome', 'ole'],
                },
              ],
              type: 'imgDnd',
              totalCount: 1,
            },
          },
          5: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Practice the Sounds II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S5_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_hole.png',
                  question: 'h',
                  answer: 'ole',
                  candidate: { 0: ['h', 'd', 'b', 'p', 'c', 'm'], 1: ['one', 'ome', 'ole'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_dome.png',
                  question: 'd',
                  answer: 'ome',
                  candidate: { 0: ['h', 'd', 'b', 'p', 'c', 'm'], 1: ['one', 'ome', 'ole'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_bone.png',
                  question: 'b',
                  answer: 'one',
                  candidate: { 0: ['h', 'd', 'b', 'p', 'c', 'm'], 1: ['one', 'ome', 'ole'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_pole.png',
                  question: 'p',
                  answer: 'ole',
                  candidate: { 0: ['h', 'd', 'b', 'p', 'c', 'm'], 1: ['one', 'ome', 'ole'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_home.png',
                  question: 'h',
                  answer: 'ome',
                  candidate: { 0: ['h', 'd', 'b', 'p', 'c', 'm'], 1: ['one', 'ome', 'ole'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_cone.png',
                  question: 'c',
                  answer: 'one',
                  candidate: { 0: ['h', 'd', 'b', 'p', 'c', 'm'], 1: ['one', 'ome', 'ole'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_mole.png',
                  question: 'm',
                  answer: 'ole',
                  candidate: { 0: ['h', 'd', 'b', 'p', 'c', 'm'], 1: ['one', 'ome', 'ole'] },
                },
              ],
              type: 'imgDnd2',
              hasSoundBtn: false,
              answerAbsolute: false,
              totalCount: 2,
            },
          },
          6: {
            title: 'Look and type the word.',
            shortDescption: 'Practice the Words I',
            type: 'write',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S6_Dir.mp3',
            customData: {
              type: 'imgWrite',
              problem: [
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_hole.png', word: 'hole' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_dome.png', word: 'dome' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_bone.png', word: 'bone' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_pole.png', word: 'pole' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_home.png', word: 'home' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_cone.png', word: 'cone' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_mole.png', word: 'mole' },
              ],
            },
          },
          7: {
            title: 'Listen. Then drag and drop.',
            shortDescption: 'Practice the Words II',
            type: 'dnd',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S7_Dir.mp3',
            customData: {
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_hole.png',
                  question: 'h',
                  answer: 'ole',
                  candidate: { 0: ['h', 'd', 'b', 'p', 'c', 'm'], 1: ['one', 'ome', 'ole'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_dome.png',
                  question: 'd',
                  answer: 'ome',
                  candidate: { 0: ['h', 'd', 'b', 'p', 'c', 'm'], 1: ['one', 'ome', 'ole'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_bone.png',
                  question: 'b',
                  answer: 'one',
                  candidate: { 0: ['h', 'd', 'b', 'p', 'c', 'm'], 1: ['one', 'ome', 'ole'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_pole.png',
                  question: 'p',
                  answer: 'ole',
                  candidate: { 0: ['h', 'd', 'b', 'p', 'c', 'm'], 1: ['one', 'ome', 'ole'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_home.png',
                  question: 'h',
                  answer: 'ome',
                  candidate: { 0: ['h', 'd', 'b', 'p', 'c', 'm'], 1: ['one', 'ome', 'ole'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_cone.png',
                  question: 'c',
                  answer: 'one',
                  candidate: { 0: ['h', 'd', 'b', 'p', 'c', 'm'], 1: ['one', 'ome', 'ole'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_mole.png',
                  question: 'm',
                  answer: 'ole',
                  candidate: { 0: ['h', 'd', 'b', 'p', 'c', 'm'], 1: ['one', 'ome', 'ole'] },
                },
              ],
              type: 'imgDnd2',
              hasSoundBtn: true,
              answerAbsolute: false,
              totalCount: 2,
            },
          },
          8: {
            title: 'Listen and repeat. Then record your voice.',
            shortDescption: 'Story',
            type: 'record',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S8_Dir.mp3',
            customData: {
              bgImage: 'https://cdn.cloubot.com/PM/image/l3_u6_bg_wide.jpg',
              sound: 'Lv3_U6_reading',
              sentences: ['Five mice come ##home##.', 'The ##home## is a ##dome##.', 'No rice is in the ##dome##.', 'Five mice go to the ##mole##’s ##home##.', 'The ##mole## has a ##pole##, a ##bone##, and a ##cone##.', 'But the ##mole## has no rice.'],
            },
          },
          9: {
            title: 'Choose and type the word.',
            shortDescption: 'Words in the Story',
            type: 'write',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S9_Dir.mp3',
            customData: {
              type: 'imgSoundWrite',
              problem: [
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_dome.png',
                  sentenceFormat: 'The home is a ##word##.',
                  correctWord: 'dome',
                  wrongWord: 'mole',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_pole.png',
                  sentenceFormat: 'The mole has a ##word##.',
                  correctWord: 'pole',
                  wrongWord: 'home',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_bone.png',
                  sentenceFormat: 'The mole has a ##word##.',
                  correctWord: 'bone',
                  wrongWord: 'dome',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_cone.png',
                  sentenceFormat: 'The mole has a ##word##.',
                  correctWord: 'cone',
                  wrongWord: 'hole',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_mole.png',
                  sentenceFormat: 'The ##word## has no rice.',
                  correctWord: 'mole',
                  wrongWord: 'hole',
                },
              ],
            },
          },
          10: {
            title: 'Story Chant',
            shortDescption: 'Story Chant',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S10_Dir.mp3',
            url: 'https://player.vimeo.com/video/376989069',
            type: 'video',
          },
          11: {
            title: 'Read. Then drag and drop.',
            shortDescption: 'Story Activity',
            type: 'dnd',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S11_Dir.mp3',
            customData: {
              type: 'imgDnd3',
              problem: {
                question: [
                  { image: 'https://cdn.cloubot.com/PM/image/level3_unit06_SB_01.png', sentence: ['##Mole## needs a ##home##.'] },
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level3_unit06_SB_02.png',
                    sentence: ['He does not want a ##dome##.', 'He does not want a ##cone##.'],
                  },
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level3_unit06_SB_03.png',
                    sentence: ['##Mole## finds a ##pole##.', 'He slips into the ##hole##.'],
                  },
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level3_unit06_SB_04.png',
                    sentence: ['Now, ##Mole## is ##alone## in his dark new ##home##.'],
                  },
                ],
              },
              totalCount: 4,
            },
          },
          12: {
            title: 'Listen and type the word.',
            shortDescption: 'Quiz',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S12_Dir.mp3',
            type: 'unitTest',
            problem: [
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_hole.png', word: 'hole' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_dome.png', word: 'dome' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_bone.png', word: 'bone' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_pole.png', word: 'pole' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_home.png', word: 'home' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_cone.png', word: 'cone' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_mole.png', word: 'mole' },
            ],
          },
        },
      },
      7: {
        title: 'Long Vowel - ose · ope · ote',
        stage: {
          1: {
            title: 'Sound Animation',
            shortDescption: 'Sound Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S1_Dir.mp3',
            url: 'https://player.vimeo.com/video/376987841',
            type: 'video',
          },
          2: {
            title: 'Drag and drop.',
            shortDescption: 'Learn the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S2_Dir.mp3',
            type: 'dnd',
            hasStep: true,
            customData: {
              problem: [
                { 1: ['o', 's'], 2: ['o', 's', 'e'], type: 'dndType1' },
                { 1: ['o', 'p'], 2: ['o', 'p', 'e'], type: 'dndType1' },
                { 1: ['o', 't'], 2: ['o', 't', 'e'], type: 'dndType1' },
                { candidate: ['r', 'n', 'h'], question: 'ose', type: 'dndType2' },
                { candidate: ['h', 'r'], question: 'ope', type: 'dndType2' },
                { candidate: ['n', 'v'], question: 'ote', type: 'dndType2' },
              ],
              totalCount: 5,
              type: 'alphabetDnd',
            },
          },
          3: {
            title: 'Word Animation',
            shortDescption: 'Word Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S3_Dir.mp3',
            url: 'https://player.vimeo.com/video/376134203',
            type: 'video',
          },
          4: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Practice the Sounds I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S4_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_rose.png',
                  question: 'r',
                  answer: 'ose',
                  candidate: ['ote', 'ope', 'ose'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_hope.png',
                  question: 'h',
                  answer: 'ope',
                  candidate: ['ote', 'ope', 'ose'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_note.png',
                  question: 'n',
                  answer: 'ote',
                  candidate: ['ote', 'ope', 'ose'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_nose.png',
                  question: 'n',
                  answer: 'ose',
                  candidate: ['ote', 'ope', 'ose'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_rope.png',
                  question: 'r',
                  answer: 'ope',
                  candidate: ['ote', 'ope', 'ose'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_vote.png',
                  question: 'v',
                  answer: 'ote',
                  candidate: ['ote', 'ope', 'ose'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_hose.png',
                  question: 'h',
                  answer: 'ose',
                  candidate: ['ote', 'ope', 'ose'],
                },
              ],
              type: 'imgDnd',
              totalCount: 1,
            },
          },
          5: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Practice the Sounds II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S5_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_rose.png',
                  question: 'r',
                  answer: 'ose',
                  candidate: { 0: ['r', 'n', 'h', 'v'], 1: ['ose', 'ope', 'ote'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_hope.png',
                  question: 'h',
                  answer: 'ope',
                  candidate: { 0: ['r', 'n', 'h', 'v'], 1: ['ose', 'ope', 'ote'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_note.png',
                  question: 'n',
                  answer: 'ote',
                  candidate: { 0: ['r', 'n', 'h', 'v'], 1: ['ose', 'ope', 'ote'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_nose.png',
                  question: 'n',
                  answer: 'ose',
                  candidate: { 0: ['r', 'n', 'h', 'v'], 1: ['ose', 'ope', 'ote'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_rope.png',
                  question: 'r',
                  answer: 'ope',
                  candidate: { 0: ['r', 'n', 'h', 'v'], 1: ['ose', 'ope', 'ote'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_vote.png',
                  question: 'v',
                  answer: 'ote',
                  candidate: { 0: ['r', 'n', 'h', 'v'], 1: ['ose', 'ope', 'ote'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_hose.png',
                  question: 'h',
                  answer: 'ose',
                  candidate: { 0: ['r', 'n', 'h', 'v'], 1: ['ose', 'ope', 'ote'] },
                },
              ],
              type: 'imgDnd2',
              hasSoundBtn: false,
              answerAbsolute: false,
              totalCount: 2,
            },
          },
          6: {
            title: 'Look and type the word.',
            shortDescption: 'Practice the Words I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S6_Dir.mp3',
            type: 'write',
            customData: {
              type: 'imgWrite',
              problem: [
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_rose.png', word: 'rose' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_hope.png', word: 'hope' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_note.png', word: 'note' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_nose.png', word: 'nose' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_rope.png', word: 'rope' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_vote.png', word: 'vote' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_hose.png', word: 'hose' },
              ],
            },
          },
          7: {
            title: 'Listen. Then drag and drop.',
            shortDescption: 'Practice the Words II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S7_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_rose.png',
                  question: 'r',
                  answer: 'ose',
                  candidate: { 0: ['r', 'n', 'h', 'v'], 1: ['ose', 'ope', 'ote'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_hope.png',
                  question: 'h',
                  answer: 'ope',
                  candidate: { 0: ['r', 'n', 'h', 'v'], 1: ['ose', 'ope', 'ote'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_note.png',
                  question: 'n',
                  answer: 'ote',
                  candidate: { 0: ['r', 'n', 'h', 'v'], 1: ['ose', 'ope', 'ote'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_nose.png',
                  question: 'n',
                  answer: 'ose',
                  candidate: { 0: ['r', 'n', 'h', 'v'], 1: ['ose', 'ope', 'ote'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_rope.png',
                  question: 'r',
                  answer: 'ope',
                  candidate: { 0: ['r', 'n', 'h', 'v'], 1: ['ose', 'ope', 'ote'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_vote.png',
                  question: 'v',
                  answer: 'ote',
                  candidate: { 0: ['r', 'n', 'h', 'v'], 1: ['ose', 'ope', 'ote'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_hose.png',
                  question: 'h',
                  answer: 'ose',
                  candidate: { 0: ['r', 'n', 'h', 'v'], 1: ['ose', 'ope', 'ote'] },
                },
              ],
              type: 'imgDnd2',
              hasSoundBtn: true,
              answerAbsolute: false,
              totalCount: 2,
            },
          },
          8: {
            title: 'Listen and repeat. Then record your voice.',
            shortDescption: 'Story',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S8_Dir.mp3',
            type: 'record',
            customData: {
              bgImage: 'https://cdn.cloubot.com/PM/image/l3_u7_bg_wide.jpg',
              sound: 'Lv3_U7_reading',
              sentences: ['Kate has ##hope##.', 'Kate likes five mice.', 'Kate gets a ##note##.', 'Kate gets a ##rose##.', 'Kate runs to five mice’s home.', 'Kate gets a ##rope##.', 'Oh, it’s not a ##rope##.', 'It’s a ##hose##.', 'Kate gets a wet ##nose##.'],
            },
          },
          9: {
            title: 'Choose and type the word.',
            shortDescption: 'Words in the Story',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S9_Dir.mp3',
            type: 'write',
            customData: {
              type: 'imgSoundWrite',
              problem: [
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_hope.png',
                  sentenceFormat: 'Kate has ##word##.',
                  correctWord: 'hope',
                  wrongWord: 'rose',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_note.png',
                  sentenceFormat: 'Kate gets a ##word##.',
                  correctWord: 'note',
                  wrongWord: 'nose',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_rose.png',
                  sentenceFormat: 'Kate gets a ##word##.',
                  correctWord: 'rose',
                  wrongWord: 'hope',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_hose.png',
                  sentenceFormat: "It's not a rope. It's a ##word##.",
                  correctWord: 'hose',
                  wrongWord: 'note',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_nose.png',
                  sentenceFormat: 'Kate gets a wet ##word##.',
                  correctWord: 'nose',
                  wrongWord: 'hope',
                },
              ],
            },
          },
          10: {
            title: 'Story Chant',
            shortDescption: 'Story Chant',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S10_Dir.mp3',
            url: 'https://player.vimeo.com/video/376989098',
            type: 'video',
          },
          11: {
            title: 'Read. Then drag and drop.',
            shortDescption: 'Story Activity',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S11_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imgDnd3',
              problem: {
                question: [
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level3_unit07_SB_01.png',
                    sentence: ['This is my hero.', 'He has a ##rope##.'],
                  },
                  { image: 'https://cdn.cloubot.com/PM/image/level3_unit07_SB_02.png', sentence: ['He has a ##hose##.'] },
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level3_unit07_SB_03.png',
                    sentence: ['I write him a ##note##.', 'I give him a ##rose##.'],
                  },
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level3_unit07_SB_04.png',
                    sentence: ['I ##hope## he likes it.', 'Dad, I love you!'],
                  },
                ],
              },
              totalCount: 4,
            },
          },
          12: {
            title: 'Listen and type the word.',
            shortDescption: 'Quiz',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S12_Dir.mp3',
            type: 'unitTest',
            problem: [
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_rose.png', word: 'rose' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_hope.png', word: 'hope' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_note.png', word: 'note' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_nose.png', word: 'nose' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_rope.png', word: 'rope' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_vote.png', word: 'vote' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_hose.png', word: 'hose' },
            ],
          },
        },
      },
      8: {
        title: 'Long Vowel - ube · une · ute',
        stage: {
          1: {
            title: 'Sound Animation',
            shortDescption: 'Sound Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S1_Dir.mp3',
            url: 'https://player.vimeo.com/video/376987860',
            type: 'video',
          },
          2: {
            title: 'Drag and drop.',
            shortDescption: 'Learn the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S2_Dir.mp3',
            type: 'dnd',
            hasStep: true,
            customData: {
              problem: [
                { 1: ['u', 'b'], 2: ['u', 'b', 'e'], type: 'dndType1' },
                { 1: ['u', 'n'], 2: ['u', 'n', 'e'], type: 'dndType1' },
                { 1: ['u', 't'], 2: ['u', 't', 'e'], type: 'dndType1' },
                { candidate: ['c', 't'], question: 'ube', type: 'dndType2' },
                { candidate: ['j', 't'], question: 'une', type: 'dndType2' },
                { candidate: ['c', 'm'], question: 'ute', type: 'dndType2' },
              ],
              totalCount: 5,
              type: 'alphabetDnd',
            },
          },
          3: {
            title: 'Word Animation',
            shortDescption: 'Word Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S3_Dir.mp3',
            url: 'https://player.vimeo.com/video/376134242',
            type: 'video',
          },
          4: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Practice the Sounds I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S4_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_cube.png',
                  question: 'c',
                  answer: 'ube',
                  candidate: ['ute', 'une', 'ube'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_june.png',
                  question: 'J',
                  answer: 'une',
                  candidate: ['ute', 'une', 'ube'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_cute.png',
                  question: 'c',
                  answer: 'ute',
                  candidate: ['ute', 'une', 'ube'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_tube.png',
                  question: 't',
                  answer: 'ube',
                  candidate: ['ute', 'une', 'ube'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_tune.png',
                  question: 't',
                  answer: 'une',
                  candidate: ['ute', 'une', 'ube'],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_mute.png',
                  question: 'm',
                  answer: 'ute',
                  candidate: ['ute', 'une', 'ube'],
                },
              ],
              type: 'imgDnd',
              totalCount: 1,
            },
          },
          5: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Practice the Sounds II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S5_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_cube.png',
                  question: 'c',
                  answer: 'ube',
                  candidate: { 0: ['c', 't', 'j', 'm'], 1: ['une', 'ute', 'ube'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_june.png',
                  sound: 'J_une_June',
                  question: 'j',
                  answer: 'une',
                  candidate: { 0: ['c', 't', 'j', 'm'], 1: ['une', 'ute', 'ube'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_cute.png',
                  question: 'c',
                  answer: 'ute',
                  candidate: { 0: ['c', 't', 'j', 'm'], 1: ['une', 'ute', 'ube'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_tube.png',
                  question: 't',
                  answer: 'ube',
                  candidate: { 0: ['c', 't', 'j', 'm'], 1: ['une', 'ute', 'ube'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_tune.png',
                  question: 't',
                  answer: 'une',
                  candidate: { 0: ['c', 't', 'j', 'm'], 1: ['une', 'ute', 'ube'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_mute.png',
                  question: 'm',
                  answer: 'ute',
                  candidate: { 0: ['c', 't', 'j', 'm'], 1: ['une', 'ute', 'ube'] },
                },
              ],
              type: 'imgDnd2',
              hasSoundBtn: false,
              answerAbsolute: false,
              totalCount: 2,
            },
          },
          6: {
            title: 'Look and type the word.',
            shortDescption: 'Practice the Words I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S6_Dir.mp3',
            type: 'write',
            customData: {
              type: 'imgWrite',
              problem: [
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_cube.png', word: 'cube' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_june.png', word: 'june' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_cute.png', word: 'cute' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_tube.png', word: 'tube' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_tune.png', word: 'tune' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_mute.png', word: 'mute' },
              ],
            },
          },
          7: {
            title: 'Listen. Then drag and drop.',
            shortDescption: 'Practice the Words II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S7_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_cube.png',
                  question: 'c',
                  answer: 'ube',
                  candidate: { 0: ['c', 't', 'j', 'm'], 1: ['une', 'ute', 'ube'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_june.png',
                  question: 'j',
                  answer: 'une',
                  sound: 'J_une_June',
                  candidate: { 0: ['c', 't', 'j', 'm'], 1: ['une', 'ute', 'ube'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_cute.png',
                  question: 'c',
                  answer: 'ute',
                  candidate: { 0: ['c', 't', 'j', 'm'], 1: ['une', 'ute', 'ube'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_tube.png',
                  question: 't',
                  answer: 'ube',
                  candidate: { 0: ['c', 't', 'j', 'm'], 1: ['une', 'ute', 'ube'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_tune.png',
                  question: 't',
                  answer: 'une',
                  candidate: { 0: ['c', 't', 'j', 'm'], 1: ['une', 'ute', 'ube'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_mute.png',
                  question: 'm',
                  answer: 'ute',
                  candidate: { 0: ['c', 't', 'j', 'm'], 1: ['une', 'ute', 'ube'] },
                },
              ],
              type: 'imgDnd2',
              hasSoundBtn: true,
              answerAbsolute: false,
              totalCount: 2,
            },
          },
          8: {
            title: 'Listen and repeat. Then record your voice.',
            shortDescption: 'Story',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S8_Dir.mp3',
            type: 'record',
            customData: {
              bgImage: 'https://cdn.cloubot.com/PM/image/l3_u8_bg_wide.jpg',
              sound: 'Lv3_U8_reading',
              sentences: ['James and ##June## like to hum.', 'James and ##June## hum a ##tune##.', 'James gets a ##tube##.', '##June## gets in ##tune##.', 'Mute the ##tune##!', 'Cut the ##cube##.', 'Five ##cute## cubs hide in the ##cube##.'],
            },
          },
          9: {
            title: 'Choose and type the word.',
            shortDescption: 'Words in the Story',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S9_Dir.mp3',
            type: 'write',
            customData: {
              type: 'imgSoundWrite',
              problem: [
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_cube.png',
                  sentenceFormat: "It's the ##word##.",
                  correctWord: 'cube',
                  wrongWord: 'June',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_june.png',
                  sentenceFormat: "It's the ##word##.",
                  correctWord: 'june',
                  wrongWord: 'cube',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_cute.png',
                  sentenceFormat: 'I am ##word##.',
                  correctWord: 'cute',
                  wrongWord: 'cube',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_tube.png',
                  sentenceFormat: "It's the ##word##.",
                  correctWord: 'tube',
                  wrongWord: 'cube',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_tune.png',
                  sentenceFormat: 'Mute the ##word##!',
                  correctWord: 'tune',
                  wrongWord: 'cube',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_mute.png',
                  sentenceFormat: "It's the ##word## dog.",
                  correctWord: 'mute',
                  wrongWord: 'cube',
                },
              ],
            },
          },
          10: {
            title: 'Story Chant',
            shortDescption: 'Story Chant',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S10_Dir.mp3',
            url: 'https://player.vimeo.com/video/376989166',
            type: 'video',
          },
          11: {
            title: 'Read. Then drag and drop.',
            shortDescption: 'Story Activity',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S11_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imgDnd3',
              problem: {
                question: [
                  { image: 'https://cdn.cloubot.com/PM/image/level3_unit08_SB_01.png', sentence: ['The ##cute## pig is ##mute##.'] },
                  { image: 'https://cdn.cloubot.com/PM/image/level3_unit08_SB_02.png', sentence: ['I give her an ice ##cube##.'] },
                  { image: 'https://cdn.cloubot.com/PM/image/level3_unit08_SB_03.png', sentence: ['I give her a candy ##tube##.'] },
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level3_unit08_SB_04.png',
                    sentence: ['The pig is happy.', 'She can sing a ##tune##.'],
                  },
                ],
              },
              totalCount: 4,
            },
          },
          12: {
            title: 'Listen and type the word.',
            shortDescption: 'Quiz',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S12_Dir.mp3',
            type: 'unitTest',
            problem: [
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_cube.png', word: 'cube' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_june.png', word: 'june' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_cute.png', word: 'cute' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_tube.png', word: 'tube' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_tune.png', word: 'tune' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_mute.png', word: 'mute' },
            ],
          },
        },
      },
      9: {
        title: 'Magic e',
        stage: {
          1: {
            title: 'Sound Animation',
            shortDescption: 'Sound Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S1_Dir.mp3',
            url: 'https://player.vimeo.com/video/376987875',
            type: 'video',
          },
          2: {
            title: 'Listen. Then drag and drop.',
            shortDescption: 'Learn the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S2_Dir.mp3',
            type: 'dnd',
            hasStep: true,
            customData: {
              isMagic: true,
              problem: [
                { 1: ['can'], 2: ['can', 'e'], type: 'dndType1' },
                { 1: ['kit'], 2: ['kit', 'e'], type: 'dndType1' },
                { 1: ['hop'], 2: ['hop', 'e'], type: 'dndType1' },
                { 1: ['tub'], 2: ['tub', 'e'], type: 'dndType1' },
                {
                  answer: ['can', 'cane', 'kit', 'kite', 'hop', 'hope', 'tub', 'tube'],
                  type: 'dndType3',
                },
              ],
              totalCount: 3,
              type: 'alphabetDnd',
            },
          },
          3: {
            title: 'Word Animation',
            shortDescption: 'Word Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S3_Dir.mp3',
            url: 'https://player.vimeo.com/video/376134266',
            type: 'video',
          },
          4: {
            title: 'Drag and drop.',
            shortDescption: 'Practice the Sounds I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S4_Dir_U9.mp3',
            type: 'dnd',
            customData: {
              type: 'wordDnd',

              problem: [
                { answer: 'can', type: 'short' },
                { answer: 'kit', type: 'short' },
                { answer: 'tub', type: 'short' },
                { answer: 'hop', type: 'short' },
                { answer: 'cane', type: 'long' },
                { answer: 'kite', type: 'long' },
                { answer: 'tube', type: 'long' },
                { answer: 'hope', type: 'long' },
              ],
            },
          },
          5: {
            title: 'Type the correct word below each image.',
            shortDescption: 'Practice the Sounds II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S5_Dir_U9.mp3',
            type: 'write',
            customData: {
              type: 'magicWrite',

              problem: [
                { short: 'can', long: 'cane' },
                { short: 'tap', long: 'tape' },
                { short: 'kit', long: 'kite' },
                { short: 'pin', long: 'pine' },
                { short: 'hop', long: 'hope' },
                { short: 'pop', long: 'pope' },
                { short: 'tub', long: 'tube' },
                { short: 'cub', long: 'cube' },
              ],
              totalCount: 8,
            },
          },
          6: {
            title: 'Look and type the word.',
            shortDescption: 'Practice the Words I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S6_Dir.mp3',
            type: 'write',
            customData: {
              type: 'imgWrite',
              hint: true,
              problem: [
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_cane.png', word: 'cane' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_tape.png', word: 'tape' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_kite.png', word: 'kite' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_pine.png', word: 'pine' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_hope.png', word: 'hope' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_pope.png', word: 'pope' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_tube.png', word: 'tube' },
                { image: 'https://cdn.cloubot.com/PM/image/level3/image_cube.png', word: 'cube' },
              ],
            },
          },
          7: {
            title: 'Listen. Then drag and drop.',
            shortDescption: 'Practice the Words II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S7_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_can.png',
                  question: 'c',
                  answer: 'an',
                  candidate: { 0: ['c', 't', 'p', 'h'], 1: ['an', 'ane'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_tap.png',
                  question: 't',
                  answer: 'ap',
                  candidate: { 0: ['c', 't', 'k', 'h'], 1: ['ap', 'ape'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_kite.png',
                  question: 'k',
                  answer: 'ite',
                  candidate: { 0: ['c', 't', 'k', 'h'], 1: ['it', 'ite'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_pin.png',
                  question: 'p',
                  answer: 'in',
                  candidate: { 0: ['t', 'k', 'p', 'h'], 1: ['in', 'ine'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_hope.png',
                  question: 'h',
                  answer: 'ope',
                  candidate: { 0: ['p', 'h', 't', 'c'], 1: ['op', 'ope'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_pop.png',
                  question: 'p',
                  answer: 'op',
                  candidate: { 0: ['p', 'h', 'c', 't'], 1: ['op', 'ope'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_tube.png',
                  question: 't',
                  answer: 'ube',
                  candidate: { 0: ['p', 'h', 'c', 't'], 1: ['ub', 'ube'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_cub.png',
                  question: 'c',
                  answer: 'ub',
                  candidate: { 0: ['p', 'h', 'c', 't'], 1: ['ub', 'ube'] },
                },
              ],
              type: 'imgDnd2',
              hasSoundBtn: true,
              answerAbsolute: false,
              totalCount: 2,
              ignorePhoneme: true, //음원이 없어서 일단 추가함. 나중엔 빼야 할 수도..
            },
          },
          8: {
            title: 'Listen and repeat. Then record your voice.',
            shortDescption: 'Story',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S8_Dir.mp3',
            type: 'record',
            customData: {
              bgImage: 'https://cdn.cloubot.com/PM/image/l3_u9_bg_wide.jpg',
              sound: 'Lv3_U9_reading',
              sentences: ['Five ##cubs## are in the ##cube##.', 'One has a cap and a cape.', 'One has a ##can## and a ##cane##.', 'One has a ##pin## and a ##pine## cone.', 'One has a fine fin.', 'One has a ##kite## in a ##kit##.', 'Five ##cubs## ##hop## out the ##cube##.'],
            },
          },
          9: {
            title: 'Choose and type the word.',
            shortDescption: 'Words in the Story',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S9_Dir.mp3',
            type: 'write',
            customData: {
              type: 'imgSoundWrite',
              problem: [
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_kit.png',
                  sentenceFormat: "It's the ##word##.",
                  correctWord: 'kit',
                  wrongWord: 'kite',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_tub.png',
                  sentenceFormat: "It's the ##word##.",
                  correctWord: 'tub',
                  wrongWord: 'tube',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_tape.png',
                  sentenceFormat: "It's the ##word##.",
                  correctWord: 'tape',
                  wrongWord: 'tap',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_hop.png',
                  sentenceFormat: 'I ##word##.',
                  correctWord: 'hop',
                  wrongWord: 'hope',
                },
              ],
            },
          },
          10: {
            title: 'Story Chant',
            shortDescption: 'Story Chant',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S10_Dir.mp3',
            url: 'https://player.vimeo.com/video/376989179',
            type: 'video',
          },
          11: {
            title: 'Read. Then drag and drop.',
            shortDescption: 'Story Activity',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S11_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imgDnd3',
              problem: {
                question: [
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level3_unit09_SB_01.png',
                    sentence: ['##Can## becomes ##cane##.', '##Man## becomes ##mane##.'],
                  },
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level3_unit09_SB_02.png',
                    sentence: ['##Cap## becomes ##cape##.', '##Tap## becomes ##tape##.'],
                  },
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level3_unit09_SB_03.png',
                    sentence: ['##Cub## becomes ##cube##.', '##Tub## becomes ##tube##.'],
                  },
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level3_unit09_SB_04.png',
                    sentence: ["Just add the magic '##e##'.", "It's easy and fun!"],
                  },
                ],
              },
              totalCount: 4,
            },
          },
          12: {
            title: 'Listen and type the word.',
            shortDescption: 'Quiz',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_S12_Dir.mp3',
            type: 'unitTest',
            problem: [
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_can.png', word: 'can' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_tape.png', word: 'tape' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_kite.png', word: 'kite' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_pin.png', word: 'pin' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_hop.png', word: 'hop' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_pope.png', word: 'pope' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_tube.png', word: 'tube' },
              { image: 'https://cdn.cloubot.com/PM/image/level3/image_cub.png', word: 'cub' },
            ],
          },
        },
      },
      10: {
        title: 'Review : Unit.6 ~ Unit.8',
        stage: {
          1: {
            title: 'Listen. Then drag and drop.',
            shortDescption: 'Review the Sounds I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_Review_S1_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imgDnd2',
              hasSoundBtn: true,
              answerAbsolute: true,
              ignorePhoneme: true,
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_hole.png',
                  question: 'h',
                  answer: 'ole',
                  candidate: { 0: ['h', 'g', 'l', 'b', 't', 'd'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_dome.png',
                  question: 'd',
                  answer: 'ome',
                  candidate: { 0: ['c', 'g', 'l', 'b', 't', 'd'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_bone.png',
                  question: 'b',
                  answer: 'one',
                  candidate: { 0: ['c', 'g', 'l', 'b', 't', 'd'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_rose.png',
                  question: 'r',
                  answer: 'ose',
                  candidate: { 0: ['r', 'g', 'l', 'b', 't', 'd'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_rope.png',
                  question: 'r',
                  answer: 'ope',
                  candidate: { 0: ['c', 'r', 'l', 'b', 't', 'd'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_vote.png',
                  question: 'v',
                  answer: 'ote',
                  candidate: { 0: ['c', 'v', 'l', 'b', 't', 'd'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_cube.png',
                  question: 'c',
                  answer: 'ube',
                  candidate: { 0: ['c', 'g', 'l', 'b', 't', 'd'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_june.png',
                  sound: 'J_une_June',
                  question: 'j',
                  answer: 'une',
                  candidate: { 0: ['c', 'g', 'j', 'b', 't', 'v'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_mute.png',
                  question: 'm',
                  answer: 'ute',
                  candidate: { 0: ['c', 'm', 'l', 'b', 't', 'd'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_tape.png',
                  question: 't',
                  answer: 'ape',
                  candidate: { 0: ['c', 'g', 'l', 'b', 't', 'd'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_kite.png',
                  question: 'k',
                  answer: 'ite',
                  candidate: { 0: ['c', 'k', 'l', 'b', 't', 'd'] },
                },
                //{url: "https://cdn.cloubot.com/PM/image/level3/image_pope.png", question: "p", answer: "ope", candidate: {0: ["c", "p", "l", "b", "t", "d"]}},
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_tube.png',
                  question: 't',
                  answer: 'ube',
                  candidate: { 0: ['c', 'g', 'l', 'b', 't', 'd'] },
                },
              ],
              totalCount: 1,
            },
          },
          2: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Review the Words I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_Review10_S2_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imgDnd4',

              hasSoundBtn: true,
              hideWordBox: true,
              problem: [
                {
                  //여기서는 이렇게 나눌 필요가 없는데, imgDnd4 을 사용하려다보니 어쩔 수 없이 문제형식을 똑같이 한다.
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_cube.png',
                  question: 'c',
                  answer: 'ube',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level3/image_cute.png',
                    'https://cdn.cloubot.com/PM/image/level3/image_mute.png',
                    'https://cdn.cloubot.com/PM/image/level3/image_cube.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_note.png',
                  question: 'n',
                  answer: 'ote',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level3/image_note.png',
                    'https://cdn.cloubot.com/PM/image/level3/image_nose.png',
                    'https://cdn.cloubot.com/PM/image/level3/image_rose.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_mole.png',
                  question: 'm',
                  answer: 'ole',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level3/image_home.png',
                    'https://cdn.cloubot.com/PM/image/level3/image_mole.png',
                    'https://cdn.cloubot.com/PM/image/level3/image_dome.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_bone.png',
                  question: 'b',
                  answer: 'one',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level3/image_tune.png',
                    'https://cdn.cloubot.com/PM/image/level3/image_june.png',
                    'https://cdn.cloubot.com/PM/image/level3/image_bone.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level3/image_hope.png',
                  question: 'h',
                  answer: 'ope',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level3/image_hope.png',
                    'https://cdn.cloubot.com/PM/image/level3/image_hole.png',
                    'https://cdn.cloubot.com/PM/image/level3/image_pole.png',
                  ],
                },
              ],
              totalCount: 1,
            },
          },
          3: {
            title: 'Look and choose.',
            shortDescption: 'Review the Words II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_Review10_S3_Dir.mp3',
            type: 'imgSelect',
            customData: {
              type: 'selectWord',
              problem: [
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_vote.png',
                  words: [
                    { name: 'vote', isAnswer: true },
                    { name: 'note', isAnswer: false },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_tube.png',
                  words: [
                    { name: 'tub', isAnswer: false },
                    { name: 'tube', isAnswer: true },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_pine.png',
                  words: [
                    { name: 'pin', isAnswer: false },
                    { name: 'pine', isAnswer: true },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_mute.png',
                  words: [
                    { name: 'mute', isAnswer: true },
                    { name: 'cute', isAnswer: false },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_tune.png',
                  words: [
                    { name: 'June', isAnswer: false },
                    { name: 'tune', isAnswer: true },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_cube.png',
                  words: [
                    { name: 'cube', isAnswer: true },
                    { name: 'tube', isAnswer: false },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_rope.png',
                  words: [
                    { name: 'hope', isAnswer: false },
                    { name: 'rope', isAnswer: true },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_can.png',
                  words: [
                    { name: 'can', isAnswer: true },
                    { name: 'cane', isAnswer: false },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_tap.png',
                  words: [
                    { name: 'tap', isAnswer: true },
                    { name: 'tape', isAnswer: false },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_cone.png',
                  words: [
                    { name: 'bone', isAnswer: false },
                    { name: 'cone', isAnswer: true },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_hose.png',
                  words: [
                    { name: 'rose', isAnswer: false },
                    { name: 'hose', isAnswer: true },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_dome.png',
                  words: [
                    { name: 'home', isAnswer: false },
                    { name: 'dome', isAnswer: true },
                  ],
                },
              ],
            },
          },
          4: {
            title: 'Look and choose.',
            shortDescption: 'Word Puzzle',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_Review_S4_Dir.mp3',
            type: 'crossWords',
            customData: {
              imageBaseUrl: 'https://cdn.cloubot.com/PM/image/level3/',
              problem: [
                {
                  words: [
                    {
                      name: 'cute',
                      xy: [
                        [0, 7],
                        [1, 7],
                        [2, 7],
                        [3, 7],
                      ],
                    },
                    {
                      name: 'cane',
                      xy: [
                        [0, 1],
                        [0, 2],
                        [0, 3],
                        [0, 4],
                      ],
                    },
                    {
                      name: 'kite',
                      xy: [
                        [5, 4],
                        [5, 5],
                        [5, 6],
                        [5, 7],
                      ],
                    },
                    {
                      name: 'hope',
                      xy: [
                        [6, 0],
                        [6, 1],
                        [6, 2],
                        [6, 3],
                      ],
                    },
                    {
                      name: 'tube',
                      xy: [
                        [3, 4],
                        [3, 5],
                        [3, 6],
                        [3, 7],
                      ],
                    },
                    {
                      name: 'mole',
                      xy: [
                        [2, 2],
                        [3, 2],
                        [4, 2],
                        [5, 2],
                      ],
                    },
                    {
                      name: 'home',
                      xy: [
                        [2, 0],
                        [2, 1],
                        [2, 2],
                        [2, 3],
                      ],
                    },
                    {
                      name: 'cone',
                      xy: [
                        [1, 1],
                        [2, 1],
                        [3, 1],
                        [4, 1],
                      ],
                    },
                  ],
                  coords: [
                    ['a', 'b', 'h', 'f', 'o', 'p', 'h'],
                    ['c', 'c', 'o', 'n', 'e', 'e', 'o'],
                    ['a', 'a', 'm', 'o', 'l', 'e', 'p'],
                    ['n', 't', 'e', 'e', 's', 'i', 'e'],
                    ['e', 'e', 'z', 't', 'a', 'k', 'p'],
                    ['g', 'a', 'n', 'u', 'm', 'i', 'e'],
                    ['a', 'p', 'e', 'b', 'e', 't', 'e'],
                    ['c', 'u', 't', 'e', 'c', 'e', 's'],
                  ],
                },
                {
                  words: [
                    {
                      name: 'hole',
                      xy: [
                        [0, 2],
                        [1, 2],
                        [2, 2],
                        [3, 2],
                      ],
                    },
                    {
                      name: 'dome',
                      xy: [
                        [1, 1],
                        [1, 2],
                        [1, 3],
                        [1, 4],
                      ],
                    },
                    {
                      name: 'bone',
                      xy: [
                        [0, 7],
                        [1, 7],
                        [2, 7],
                        [3, 7],
                      ],
                    },
                    {
                      name: 'rose',
                      xy: [
                        [3, 4],
                        [3, 5],
                        [3, 6],
                        [3, 7],
                      ],
                    },
                    {
                      name: 'rope',
                      xy: [
                        [6, 0],
                        [6, 1],
                        [6, 2],
                        [6, 3],
                      ],
                    },
                    {
                      name: 'note',
                      xy: [
                        [2, 0],
                        [3, 0],
                        [4, 0],
                        [5, 0],
                      ],
                    },
                    {
                      name: 'cube',
                      xy: [
                        [5, 4],
                        [5, 5],
                        [5, 6],
                        [5, 7],
                      ],
                    },
                    {
                      name: 'june',
                      xy: [
                        [3, 3],
                        [4, 3],
                        [5, 3],
                        [6, 3],
                      ],
                    },
                  ],
                  coords: [
                    ['a', 'e', 'n', 'o', 't', 'e', 'r'],
                    ['c', 'd', 'f', 'h', 'k', 'q', 'o'],
                    ['h', 'o', 'l', 'e', 'n', 's', 'p'],
                    ['g', 'm', 'i', 'j', 'u', 'n', 'e'],
                    ['j', 'e', 'k', 'r', 't', 'c', 'm'],
                    ['f', 'r', 's', 'o', 'v', 'u', 't'],
                    ['d', 'y', 'z', 's', 'w', 'b', 'v'],
                    ['b', 'o', 'n', 'e', 'x', 'e', 'z'],
                  ],
                },
              ],
            },
          },
          5: {
            title: 'Read and type.',
            shortDescption: 'Review the Sounds II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_Review10_S5_Dir.mp3',
            type: 'spellingChange',
            problem: [
              {
                before: {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_hop.png',
                  word: 'hop',
                },
                after: {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_hope.png',
                  word: 'hop##e##',
                },
                direction: 'Add ##e##.',
                answer: 'hope',
              },
              {
                before: {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_hope.png',
                  word: '##h##ope',
                },
                after: {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_rope.png',
                  word: '##r##ope',
                },
                direction: 'Change the ##h## to ##r##.',
                answer: 'rope',
              },
              {
                before: {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_rope.png',
                  word: 'ro##p##e',
                },
                after: {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_rose.png',
                  word: 'ro##s##e',
                },
                direction: 'Change the ##p## to ##s##.',
                answer: 'rose',
              },
              {
                before: {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_rose.png',
                  word: '##r##ose',
                },
                after: {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_nose.png',
                  word: '##n##ose',
                },
                direction: 'Change the ##r## to ##n##.',
                answer: 'nose',
              },
              {
                before: {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_nose.png',
                  word: 'no##s##e',
                },
                after: {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_note.png',
                  word: 'no##t##e',
                },
                direction: 'Change the ##s## to ##t##.',
                answer: 'note',
              },
              {
                before: {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_note.png',
                  word: '##n##ote',
                },
                after: {
                  image: 'https://cdn.cloubot.com/PM/image/level3/image_vote.png',
                  word: '##v##ote',
                },
                direction: 'Change the ##n## to ##v##.',
                answer: 'vote',
              },
            ],
          },
          6: {
            title: 'Look at the picture. Choose the picture that has different sound. (Rhyme)',
            shortDescption: 'Review the Words III',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_Review10_S6_Dir.mp3',
            type: 'imgSelect',
            customData: {
              type: 'imgSelectWord', //imgSelectImg, imgSelectWord
              problem: [
                {
                  qWord: 'one',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_bone.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_cone.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_dome.png', isAnswer: true },
                  ],
                },
                {
                  qWord: 'ope',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_vote.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_hope.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_rope.png', isAnswer: false },
                  ],
                },
              ],
            },
          },
          7: {
            title: 'Look and choose. Then type the word.',
            shortDescption: 'Review the Words IV',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L2_Review_S7_Dir.mp3',
            type: 'write',
            customData: {
              type: 'multiReview',
              problem: [
                {
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_dome.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_cute.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_cube.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_cone.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_mute.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_nose.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_tube.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_vote.png', isAnswer: true },
                  ],
                  exVowel: ['Long', 'o'],
                },
                {
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_cute.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_nose.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_rope.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_tune.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_pole.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_june.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_vote.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level3/image_cube.png', isAnswer: true },
                  ],
                  exVowel: ['Long', 'u'],
                },
              ],
              totalCount: 4,
            },
          },
          8: {
            title: 'Choose the word to create your sentence.',
            shortDescption: 'Make Your Sentence',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L3_Review_S8_Dir.mp3',
            type: 'write',
            customData: {
              type: 'singleReview',
              problem: [
                {
                  sentenceFormat: 'I have a ##word## dog.',
                  words: ['cute', 'mute'],
                  imageBaseUrl: 'https://cdn.cloubot.com/PM/image/level3/',
                },
                {
                  sentenceFormat: 'He has a nose like a ##word##.',
                  words: ['cone', 'dome'],
                  imageBaseUrl: 'https://cdn.cloubot.com/PM/image/level3/',
                },
                {
                  sentenceFormat: 'He likes ##word##s.',
                  words: ['kit', 'kite'],
                  imageBaseUrl: 'https://cdn.cloubot.com/PM/image/level3/',
                },
              ],
            },
          },
        },
      },
    },
  },
  {
    bookName: '4a',
    bookCode: 'PM-L7V1',
    validation: {
      purchaseCode: 'someRandomCode',
      accessToken: 'short-lived-access-token',
    },
    unit: {
      1: {
        title: 'Double Letters - bl · fl · gl · sl',
        stage: {
          1: {
            title: 'Sound Animation',
            shortDescption: 'Sound Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S1_Dir.mp3',
            url: 'https://player.vimeo.com/video/410082564',
            type: 'video',
          },
          2: {
            title: 'Drag and drop.',
            shortDescption: 'Learn the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S2_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  alphabets: ['b', 'l'],
                  double: ['bl'],
                  type: 'dndType4',
                  discImage: 'https://cdn.cloubot.com/PM/image/level4/drag_unit1_1.png',
                  wordImage: 'https://cdn.cloubot.com/PM/image/level4/image_black.png',
                  word: '##bl##ack',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/bl_black.mp3',
                },
                {
                  alphabets: ['f', 'l'],
                  double: ['fl'],
                  type: 'dndType4',
                  discImage: 'https://cdn.cloubot.com/PM/image/level4/drag_unit1_2.png',
                  wordImage: 'https://cdn.cloubot.com/PM/image/level4/image_flag.png',
                  word: '##fl##ag',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/fl_flag.mp3',
                },
                {
                  alphabets: ['g', 'l'],
                  double: ['gl'],
                  type: 'dndType4',
                  discImage: 'https://cdn.cloubot.com/PM/image/level4/drag_unit1_3.png',
                  wordImage: 'https://cdn.cloubot.com/PM/image/level4/image_globe.png',
                  word: '##gl##obe',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/gl_globe.mp3',
                },
                {
                  alphabets: ['s', 'l'],
                  double: ['sl'],
                  type: 'dndType4',
                  discImage: 'https://cdn.cloubot.com/PM/image/level4/drag_unit1_4.png',
                  wordImage: 'https://cdn.cloubot.com/PM/image/level4/image_slide.png',
                  word: '##sl##ide',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/sl_slide.mp3',
                },
              ],
              totalCount: 4,
              type: 'alphabetDnd',
              soundBaseUrl: 'https://cdn.cloubot.com/PM/audio/sounds/level4/',
            },
          },
          3: {
            title: 'Listen and choose.',
            shortDescption: 'Practice the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S3_Dir.mp3',
            type: 'wordSelect',
            problem: [
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_black.png',
                word: 'black',
                exWords: ['##bl##ack', '##bl##ade', '##bl##ue', '##gl##obe'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_blade.png',
                word: 'blade',
                exWords: ['##bl##ack', '##bl##ade', '##bl##ue', '##gl##obe'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_globe.png',
                word: 'globe',
                exWords: ['##bl##ack', '##bl##ade', '##bl##ue', '##gl##obe'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_flame.png',
                word: 'flame',
                exWords: ['##bl##ack', '##bl##ade', '##fl##ame', '##gl##obe'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_slide.png',
                word: 'slide',
                exWords: ['##sl##ide', '##bl##ade', '##bl##ue', '##gl##obe'],
              },
            ],
          },
          4: {
            title: 'Learn the Words.',
            shortDescption: 'Learn the Words',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S4_Dir.mp3',
            type: 'phonemeImage',
            problem: [
              {
                phoneme: '##bl## -',
                word: '##bl##ack',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_black.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/bl_black.mp3',
              },
              {
                phoneme: '##bl## -',
                word: '##bl##ade',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_blade.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/bl_blade.mp3',
              },
              {
                phoneme: '##bl## -',
                word: '##bl##ue',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_blue.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/bl_blue.mp3',
              },
              {
                phoneme: '##fl## -',
                word: '##fl##ag',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_flag.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/fl_flag.mp3',
              },
              {
                phoneme: '##fl## -',
                word: '##fl##ame',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_flame.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/fl_flame.mp3',
              },
              {
                phoneme: '##fl## -',
                word: '##fl##ute',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_flute.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/fl_flute.mp3',
              },
              {
                phoneme: '##gl## -',
                word: '##gl##obe',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_globe.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/gl_globe.mp3',
              },
              {
                phoneme: '##gl## -',
                word: '##gl##ass',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_glass.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/gl_glass.mp3',
              },
              {
                phoneme: '##gl## -',
                word: '##gl##ue',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_glue.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/gl_glue.mp3',
              },
              {
                phoneme: '##sl## -',
                word: '##sl##ide',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_slide.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/sl_slide.mp3',
              },
              {
                phoneme: '##sl## -',
                word: '##sl##ed',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_sled.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/sl_sled.mp3',
              },
              {
                phoneme: '##sl## -',
                word: '##sl##eep',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_sleep.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/sl_sleep.mp3',
              },
            ],
          },
          5: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Practice the WordsⅠ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S5_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_globe.png',
                  isBackEnd: true,
                  question: 'obe',
                  answer: 'gl',
                  candidate: ['gl', 'bl', 'fl'],
                  sound: 'level4/gl_globe',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_black.png',
                  isBackEnd: true,
                  question: 'ack',
                  answer: 'bl',
                  candidate: ['bl', 'fl', 'gl'],
                  sound: 'level4/bl_black',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_flame.png',
                  isBackEnd: true,
                  question: 'ame',
                  answer: 'fl',
                  candidate: ['fl', 'sl', 'bl'],
                  sound: 'level4/fl_flame',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_slide.png',
                  isBackEnd: true,
                  question: 'ide',
                  answer: 'sl',
                  candidate: ['sl', 'bl', 'gl'],
                  sound: 'level4/sl_slide',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_flag.png',
                  isBackEnd: true,
                  question: 'ag',
                  answer: 'fl',
                  candidate: ['fl', 'sl', 'bl'],
                  sound: 'level4/fl_flag',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_blade.png',
                  isBackEnd: true,
                  question: 'ade',
                  answer: 'bl',
                  candidate: ['bl', 'gl', 'fl'],
                  sound: 'level4/bl_blade',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_glass.png',
                  isBackEnd: true,
                  question: 'ass',
                  answer: 'gl',
                  candidate: ['gl', 'fl', 'sl'],
                  sound: 'level4/gl_glass',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_sleep.png',
                  isBackEnd: true,
                  question: 'eep',
                  answer: 'sl',
                  candidate: ['sl', 'bl', 'gl'],
                  sound: 'level4/sl_sleep',
                },
              ],
              type: 'imgDnd',
              totalCount: 1,
            },
          },
          6: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Practice the Words Ⅱ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S6_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_globe.png',
                  question: 'gl',
                  answer: 'obe',
                  candidate: { 0: ['bl', 'fl', 'gl', 'sl'], 1: ['ack', 'obe', 'ag', 'ide'] },
                  sound: 'globe',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_black.png',
                  question: 'bl',
                  answer: 'ack',
                  candidate: { 0: ['bl', 'fl', 'gl', 'sl'], 1: ['ack', 'ame', 'obe', 'ede'] },
                  sound: 'black',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_flame.png',
                  question: 'fl',
                  answer: 'ame',
                  candidate: { 0: ['bl', 'fl', 'gl', 'sl'], 1: ['ack', 'ame', 'obe', 'ede'] },
                  sound: 'flame',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_slide.png',
                  question: 'sl',
                  answer: 'ide',
                  candidate: { 0: ['bl', 'fl', 'gl', 'sl'], 1: ['ack', 'ame', 'obe', 'ide'] },
                  sound: 'slide',
                },
              ],
              type: 'imgDnd2',
              hasSoundBtn: false,
              answerAbsolute: false,
              totalCount: 2,
            },
          },
          7: {
            title: 'Look and choose.',
            shortDescption: 'Practice the Words Ⅲ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S7_Dir.mp3',
            type: 'phonemeSelectAndWrite',
            problem: [
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_black.png', word: '##bl##ack', exPhonemes: ['bl', 'fl', 'gl', 'sl'] },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_flag.png', word: '##fl##ag', exPhonemes: ['bl', 'fl', 'gl', 'sl'] },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_blade.png', word: '##bl##ade', exPhonemes: ['bl', 'fl', 'gl', 'sl'] },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_slide.png', word: '##sl##ide', exPhonemes: ['bl', 'fl', 'gl', 'sl'] },
            ],
          },
          8: {
            title: 'Listen and repeat. Then record your voice.',
            shortDescption: 'Story',
            type: 'record',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S8_Dir.mp3',
            customData: {
              bgImage: 'https://cdn.cloubot.com/PM/image/l4_u1_bg_wide.jpg',
              sound: 'Lv4_U1_reading',
              sentences: ['Glen rides a ##black## ##sled##.', 'The ##black## ##sled## has ##blue## ##blades##.', 'The ##black## ##sled## has ##flames##.', 'Glen on the ##sled## rides a ##slide##.', 'It is a ##glass## ##slide##.', 'Up, up, up!', 'The ##flames## stop.', 'Down, down, down!', 'Into the ##blue## lake!'],
            },
          },
          9: {
            title: 'Choose and type the word.',
            shortDescption: 'Words in the Story',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S9_Dir.mp3',
            type: 'write',
            customData: {
              type: 'imgSoundWrite',
              problem: [
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U1_S9_1.png',
                  sentenceFormat: 'Glen rides a black ##word##.',
                  correctWord: 'sled',
                  wrongWord: 'slide',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U1_S9_1.png',
                  sentenceFormat: 'The black sled has blue ##word##s.',
                  correctWord: 'blade',
                  wrongWord: 'slide',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U1_S9_1.png',
                  sentenceFormat: 'The black sled has ##word##.',
                  correctWord: 'flame',
                  wrongWord: 'flag',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U1_S9_4.png',
                  sentenceFormat: 'Glen on the sled rides a ##word##.',
                  correctWord: 'slide',
                  wrongWord: 'flute',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U1_S9_5.png',
                  sentenceFormat: 'Down, down, down! Into the ##word## lake!',
                  correctWord: 'blue',
                  wrongWord: 'black',
                },
              ],
            },
          },
          10: {
            title: 'Story Chant',
            shortDescption: 'Story Chant',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S10_Dir.mp3',
            url: 'https://player.vimeo.com/video/410082262',
            type: 'video',
          },
          11: {
            title: 'Read. Then drag and drop.',
            shortDescption: 'Story Activity',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S11_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imgDnd3',
              problem: {
                question: [
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level4_unit01_SB_01.png',
                    sentence: ['Here is a ##slim## pirate.', 'He has a ##black## ##blade##.'],
                  },
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level4_unit01_SB_02.png',
                    sentence: ['He is on a cliff.', 'His ##flag## is ##black##.'],
                  },
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level4_unit01_SB_03.png',
                    sentence: ['This is his ##sled##.', 'This is his ##slide##!'],
                  },
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level4_unit01_SB_04.png',
                    sentence: ['A ##glass## of hot cocoa!', 'A warm ##flame##!', 'The pirate is ##glad##!'],
                  },
                ],
              },
              totalCount: 4,
            },
          },
          12: {
            title: 'Listen and type the word.',
            shortDescption: 'Quiz',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S12_Dir.mp3',
            type: 'unitTest',
            problem: [
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_black.png', word: 'black' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_blade.png', word: 'blade' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_blue.png', word: 'blue' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_flag.png', word: 'flag' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_flame.png', word: 'flame' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_flute.png', word: 'flute' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_globe.png', word: 'globe' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_glass.png', word: 'glass' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_glue.png', word: 'glue' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_slide.png', word: 'slide' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_sled.png', word: 'sled' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_sleep.png', word: 'sleep' },
            ],
          },
        },
      },
      2: {
        title: 'Double Letters - br · cr · dr · gr',
        stage: {
          1: {
            title: 'Sound Animation',
            shortDescption: 'Sound Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S1_Dir.mp3',
            url: 'https://player.vimeo.com/video/410082579',
            type: 'video',
          },
          2: {
            title: 'Drag and drop.',
            shortDescption: 'Learn the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S2_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  alphabets: ['b', 'r'],
                  double: ['br'],
                  type: 'dndType4',
                  discImage: 'https://cdn.cloubot.com/PM/image/level4/drag_unit2_1.png',
                  wordImage: 'https://cdn.cloubot.com/PM/image/level4/image_brick.png',
                  word: '##br##ick',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/br_brick.mp3',
                },
                {
                  alphabets: ['c', 'r'],
                  double: ['cr'],
                  type: 'dndType4',
                  discImage: 'https://cdn.cloubot.com/PM/image/level4/drag_unit2_2.png',
                  wordImage: 'https://cdn.cloubot.com/PM/image/level4/image_crab.png',
                  word: '##cr##ab',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/cr_crab.mp3',
                },
                {
                  alphabets: ['d', 'r'],
                  double: ['dr'],
                  type: 'dndType4',
                  discImage: 'https://cdn.cloubot.com/PM/image/level4/drag_unit2_3.png',
                  wordImage: 'https://cdn.cloubot.com/PM/image/level4/image_dress.png',
                  word: '##dr##ess',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/dr_dress.mp3',
                },
                {
                  alphabets: ['g', 'r'],
                  double: ['gr'],
                  type: 'dndType4',
                  discImage: 'https://cdn.cloubot.com/PM/image/level4/drag_unit2_4.png',
                  wordImage: 'https://cdn.cloubot.com/PM/image/level4/image_grape.png',
                  word: '##gr##ape',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/gr_grape.mp3',
                },
              ],
              totalCount: 4,
              type: 'alphabetDnd',
              soundBaseUrl: 'https://cdn.cloubot.com/PM/audio/sounds/level4/',
            },
          },
          3: {
            title: 'Listen and choose.',
            shortDescption: 'Practice the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S3_Dir.mp3',
            type: 'wordSelect',
            problem: [
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_brick.png',
                word: 'brick',
                exWords: ['##br##ick', '##cr##ab', '##dr##ess', '##gr##ape'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_crab.png',
                word: 'crab',
                exWords: ['##br##ick', '##cr##ab', '##dr##ess', '##gr##ape'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_dress.png',
                word: 'dress',
                exWords: ['##br##ick', '##cr##ab', '##dr##ess', '##gr##ape'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_grape.png',
                word: 'grape',
                exWords: ['##br##ick', '##cr##ab', '##dr##ess', '##gr##ape'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_cross.png',
                word: 'cross',
                exWords: ['##cr##oss', '##cr##ab', '##dr##ess', '##gr##ape'],
              },
            ],
          },
          4: {
            title: 'Look and listen.',
            shortDescption: 'Learn the Words',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S4_Dir.mp3',
            type: 'phonemeImage',
            problem: [
              {
                phoneme: '##br## -',
                word: '##br##ick',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_brick.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/br_brick.mp3',
              },
              {
                phoneme: '##br## -',
                word: '##br##ide',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_bride.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/br_bride.mp3',
              },
              {
                phoneme: '##br## -',
                word: '##br##ave',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_brave.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/br_brave.mp3',
              },
              {
                phoneme: '##cr## -',
                word: '##cr##ab',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_crab.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/cr_crab.mp3',
              },
              {
                phoneme: '##cr## -',
                word: '##cr##oss',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_cross.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/cr_cross.mp3',
              },
              {
                phoneme: '##cr## -',
                word: '##cr##ow',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_crow.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/cr_crow.mp3',
              },
              {
                phoneme: '##dr## -',
                word: '##dr##ess',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_dress.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/dr_dress.mp3',
              },
              {
                phoneme: '##dr## -',
                word: '##dr##um',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_drum.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/dr_drum.mp3',
              },
              {
                phoneme: '##dr## -',
                word: '##dr##agon',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_dragon.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/dr_dragon.mp3',
              },
              {
                phoneme: '##gr## -',
                word: '##gr##ape',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_grape.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/gr_grape.mp3',
              },
              {
                phoneme: '##gr## -',
                word: '##gr##ass',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_grass.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/gr_grass.mp3',
              },
              {
                phoneme: '##gr## -',
                word: '##gr##een',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_green.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/gr_green.mp3',
              },
            ],
          },
          5: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Practice the Words I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S5_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_brave.png',
                  isBackEnd: true,
                  question: 'ave',
                  answer: 'br',
                  candidate: ['br', 'cr', 'dr'],
                  sound: 'level4/br_brave',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_crow.png',
                  isBackEnd: true,
                  question: 'ow',
                  answer: 'cr',
                  candidate: ['dr', 'br', 'cr'],
                  sound: 'level4/cr_crow',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_drum.png',
                  isBackEnd: true,
                  question: 'um',
                  answer: 'dr',
                  candidate: ['gr', 'dr', 'cr'],
                  sound: 'level4/dr_drum',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_green.png',
                  isBackEnd: true,
                  question: 'een',
                  answer: 'gr',
                  candidate: ['cr', 'br', 'gr'],
                  sound: 'level4/gr_green',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_bride.png',
                  isBackEnd: true,
                  question: 'ide',
                  answer: 'br',
                  candidate: ['br', 'dr', 'gr'],
                  sound: 'level4/br_bride',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_cross.png',
                  isBackEnd: true,
                  question: 'oss',
                  answer: 'cr',
                  candidate: ['br', 'cr', 'dr'],
                  sound: 'level4/cr_cross',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_dragon.png',
                  isBackEnd: true,
                  question: 'agon',
                  answer: 'dr',
                  candidate: ['dr', 'br', 'cr'],
                  sound: 'level4/dr_dragon',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_grape.png',
                  isBackEnd: true,
                  question: 'ape',
                  answer: 'gr',
                  candidate: ['br', 'dr', 'gr'],
                  sound: 'level4/gr_grape',
                },
              ],
              type: 'imgDnd',
              totalCount: 1,
            },
          },
          6: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Practice the Words II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S6_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_bride.png',
                  question: 'br',
                  answer: 'ide',
                  candidate: { 0: ['br', 'cr', 'dr', 'gr'], 1: ['ide', 'oss', 'ess', 'een'] },
                  sound: 'bride',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_cross.png',
                  question: 'cr',
                  answer: 'oss',
                  candidate: { 0: ['br', 'cr', 'dr', 'gr'], 1: ['ide', 'oss', 'ess', 'een'] },
                  sound: 'cross',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_dress.png',
                  question: 'dr',
                  answer: 'ess',
                  candidate: { 0: ['br', 'cr', 'dr', 'gr'], 1: ['ide', 'oss', 'ess', 'een'] },
                  sound: 'dress',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_green.png',
                  question: 'gr',
                  answer: 'een',
                  candidate: { 0: ['br', 'cr', 'dr', 'gr'], 1: ['ide', 'oss', 'ess', 'een'] },
                  sound: 'green',
                },
              ],
              type: 'imgDnd2',
              hasSoundBtn: false,
              answerAbsolute: false,
              totalCount: 2,
            },
          },
          7: {
            title: 'Look and choose.',
            shortDescption: 'Practice the Words Ⅲ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S7_Dir.mp3',
            type: 'phonemeSelectAndWrite',
            problem: [
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_brave.png', word: '##br##ave', exPhonemes: ['br', 'cr', 'dr', 'gr'] },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_crab.png', word: '##cr##ab', exPhonemes: ['br', 'cr', 'dr', 'gr'] },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_drum.png', word: '##dr##um', exPhonemes: ['br', 'cr', 'dr', 'gr'] },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_grass.png', word: '##gr##ass', exPhonemes: ['br', 'cr', 'dr', 'gr'] },
            ],
          },
          8: {
            title: 'Listen and repeat. Then record your voice.',
            shortDescption: 'Story',
            type: 'record',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S8_Dir.mp3',
            customData: {
              bgImage: 'https://cdn.cloubot.com/PM/image/l4_u2_bg_wide.jpg',
              sound: 'Lv4_U2_reading',
              sentences: ['Who is the ##bride##?', 'She is the ##dragon## in the blue ##dress##.', 'She is on the ##green## ##grass##.', 'Who is the groom?', 'The ##crow## in the brown hat? No.', 'The ##crab## in the gray hat? No.', 'The ##dragon## in the ##green## hat?', 'Yes! He is the groom.'],
            },
          },
          9: {
            title: 'Choose and type the word.',
            shortDescption: 'Words in the Story',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S9_Dir.mp3',
            type: 'write',
            customData: {
              type: 'imgSoundWrite',
              problem: [
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U2_S9_1.png',
                  sentenceFormat: 'She is the dragon in blue ##word##.',
                  correctWord: 'dress',
                  wrongWord: 'brick',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U2_S9_1.png',
                  sentenceFormat: 'She is on the green ##word##.',
                  correctWord: 'grass',
                  wrongWord: 'brave',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U2_S9_3.png',
                  sentenceFormat: 'The ##word## in the brown hat? No.',
                  correctWord: 'crow',
                  wrongWord: 'bride',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U2_S9_4.png',
                  sentenceFormat: 'The ##word## in the gray hat? No.',
                  correctWord: 'crab',
                  wrongWord: 'grape',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U2_S9_5.png',
                  sentenceFormat: 'The ##word## in the green hat? Yes!',
                  correctWord: 'dragon',
                  wrongWord: 'brick',
                },
              ],
            },
          },
          10: {
            title: 'Story Chant',
            shortDescption: 'Story Chant',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S10_Dir.mp3',
            url: 'https://player.vimeo.com/video/410082322',
            type: 'video',
          },
          11: {
            title: 'Read. Then drag and drop.',
            shortDescption: 'Story Activity',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S11_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imgDnd3',
              problem: {
                question: [
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level4_unit02_SB_01.png',
                    sentence: ['The ##crab## is a ##bride##.', 'The frog marries the ##bride##.'],
                  },
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level4_unit02_SB_02.png',
                    sentence: ["The ##crab##'s ##dress## is ##grass##."],
                  },
                  { image: 'https://cdn.cloubot.com/PM/image/level4_unit02_SB_03.png', sentence: ["The frog's hat is a ##grape##."] },
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level4_unit02_SB_04.png',
                    sentence: ['The ##crow## plays a ##drum##.', 'The wedding is done!'],
                  },
                ],
              },
              totalCount: 4,
            },
          },
          12: {
            title: 'Listen and type the word.',
            shortDescption: 'Quiz',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S12_Dir.mp3',
            type: 'unitTest',
            problem: [
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_brick.png', word: 'brick' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_bride.png', word: 'bride' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_brave.png', word: 'brave' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_crab.png', word: 'crab' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_cross.png', word: 'cross' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_crow.png', word: 'crow' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_dress.png', word: 'dress' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_drum.png', word: 'drum' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_dragon.png', word: 'dragon' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_grape.png', word: 'grape' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_grass.png', word: 'grass' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_green.png', word: 'green' },
            ],
          },
        },
      },
      3: {
        title: 'Double Letters - ch · sh',
        stage: {
          1: {
            title: 'Sound Animation',
            shortDescption: 'Sound Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S1_Dir.mp3',
            url: 'https://player.vimeo.com/video/410082609',
            type: 'video',
          },
          2: {
            title: 'Drag and drop.',
            shortDescption: 'Learn the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S2_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  double: ['ch'],
                  type: 'dndType5',
                  discImage: 'https://cdn.cloubot.com/PM/image/level4/drag_unit3_1.png',
                  words: [
                    {
                      word: '##ch##in',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level4/image_chin.png',
                      sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ch_chin.mp3',
                    },
                    {
                      word: 'lun##ch##',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level4/image_lunch.png',
                      sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ch_lunch.mp3',
                    },
                  ],
                },
                {
                  double: ['sh'],
                  type: 'dndType5',
                  discImage: 'https://cdn.cloubot.com/PM/image/level4/drag_unit3_2.png',
                  words: [
                    {
                      word: '##sh##ip',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level4/image_ship.png',
                      sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/sh_ship.mp3',
                    },
                    {
                      word: 'bru##sh##',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level4/image_brush.png',
                      sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/sh_brush.mp3',
                    },
                  ],
                },
              ],

              totalCount: 4,
              type: 'alphabetDnd',
              soundBaseUrl: 'https://cdn.cloubot.com/PM/audio/sounds/level4/',
            },
          },
          3: {
            title: 'Listen and choose.',
            shortDescption: 'Practice the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S3_Dir.mp3',
            type: 'wordSelect',
            problem: [
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_chin.png',
                word: 'chin',
                exWords: ['##ch##in', '##ch##ip', 'ostri##ch##', '##ch##op'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_ship.png',
                word: 'ship',
                exWords: ['wat##ch##', '##sh##ip', '##sh##ark', 'bru##sh##'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_fish.png',
                word: 'fish',
                exWords: ['wa##sh##', 'ostri##ch##', 'fi##sh##', '##ch##op'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_lunch.png',
                word: 'lunch',
                exWords: ['##ch##ip', '##sh##ark', 'bru##sh##', 'lun##ch##'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_shap.png',
                word: 'shape',
                exWords: ['##sh##ape', 'wa##sh##', 'wat##ch##', 'lun##ch##'],
              },
            ],
          },
          4: {
            title: 'Look and listen.',
            shortDescption: 'Learn the Words',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S4_Dir.mp3',
            type: 'phonemeImage',
            problem: [
              {
                phoneme: '##ch## -',
                word: '##ch##in',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_chin.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ch_chin.mp3',
              },
              {
                phoneme: '##ch## -',
                word: '##ch##ip',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_chip.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ch_chip.mp3',
              },
              {
                phoneme: '##ch## -',
                word: '##ch##op',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_chop.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ch_chop.mp3',
              },
              {
                phoneme: '- ##ch##',
                word: 'lun##ch##',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_lunch.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ch_lunch.mp3',
              },
              {
                phoneme: '- ##ch##',
                word: 'ostri##ch##',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_ostrich.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ch_ostrich.mp3',
              },
              {
                phoneme: '- ##ch##',
                word: 'wat##ch##',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_watch.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ch_watch.mp3',
              },
              {
                phoneme: '##sh## -',
                word: '##sh##ip',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_ship.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/sh_ship.mp3',
              },
              {
                phoneme: '##sh## -',
                word: '##sh##ape',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_shape.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/sh_shape.mp3',
              },
              {
                phoneme: '##sh## -',
                word: '##sh##ark',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_shark.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/sh_shark.mp3',
              },
              {
                phoneme: '- ##sh##',
                word: 'bru##sh##',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_brush.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/sh_brush.mp3',
              },
              {
                phoneme: '- ##sh##',
                word: 'fi##sh##',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_fish.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/sh_fish.mp3',
              },
              {
                phoneme: '- ##sh##',
                word: 'wa##sh##',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_wash.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/sh_wash.mp3',
              },
            ],
          },
          5: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Practice the Words I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S5_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_chop.png',
                  isBackEnd: true,
                  question: 'op',
                  answer: 'ch',
                  candidate: ['th', 'ch', 'sh'],
                  sound: 'level4/ch_chop',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_lunch.png',
                  question: 'lun',
                  answer: 'ch',
                  candidate: ['sh', 'ch', 'th'],
                  sound: 'level4/ch_lunch',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_ship.png',
                  isBackEnd: true,
                  question: 'ip',
                  answer: 'sh',
                  candidate: ['ch', 'sh', 'th'],
                  sound: 'level4/sh_ship',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_brush.png',
                  question: 'bru',
                  answer: 'sh',
                  candidate: ['sh', 'ch', 'th'],
                  sound: 'level4/sh_brush',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_chip.png',
                  isBackEnd: true,
                  question: 'ip',
                  answer: 'ch',
                  candidate: ['ch', 'th', 'sh'],
                  sound: 'level4/ch_chip',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_ostrich.png',
                  question: 'ostri',
                  answer: 'ch',
                  candidate: ['ch', 'sh', 'th'],
                  sound: 'level4/ch_ostrich',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_shape.png',
                  isBackEnd: true,
                  question: 'ape',
                  answer: 'sh',
                  candidate: ['th', 'ch', 'sh'],
                  sound: 'level4/sh_shape',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_wash.png',
                  question: 'wa',
                  answer: 'sh',
                  candidate: ['sh', 'ch', 'th'],
                  sound: 'level4/sh_wash',
                },
              ],
              type: 'imgDnd',
              totalCount: 1,
            },
          },
          6: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Practice the Words II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S6_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_chip.png',
                  question: 'ch',
                  answer: 'ip',
                  candidate: { 0: ['ch', 'sh'], 1: ['ip', 'in', 'it'] },
                  sound: 'chip',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_lunch.png',
                  question: 'lun',
                  answer: 'ch',
                  candidate: { 0: ['lun', 'pun', 'tun'], 1: ['ch', 'sh'] },
                  sound: 'lunch',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_shape.png',
                  question: 'sh',
                  answer: 'ape',
                  candidate: { 0: ['ch', 'sh'], 1: ['ape', 'in', 'ark'] },
                  sound: 'shape',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_fish.png',
                  question: 'fi',
                  answer: 'sh',
                  candidate: { 0: ['fi', 'di', 'da'], 1: ['ch', 'sh'] },
                  sound: 'fish',
                },
              ],
              type: 'imgDnd2',
              hasSoundBtn: false,
              answerAbsolute: false,
              totalCount: 2,
            },
          },
          7: {
            title: 'Look and choose.',
            shortDescption: 'Practice the Words Ⅲ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S7_Dir.mp3',
            type: 'phonemeSelectAndWrite',
            problem: [
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_lunch.png', word: 'lun##ch##', exPhonemes: ['ch', 'sh'] },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_watch.png', word: 'wat##ch##', exPhonemes: ['ch', 'sh'] },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_shark.png', word: '##sh##ark', exPhonemes: ['ch', 'sh'] },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_brush.png', word: 'bru##sh##', exPhonemes: ['ch', 'sh'] },
            ],
          },
          8: {
            title: 'Listen and repeat. Then record your voice.',
            shortDescption: 'Story',
            type: 'record',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S8_Dir.mp3',
            customData: {
              bgImage: 'https://cdn.cloubot.com/PM/image/l4_u3_bg_wide.jpg',
              sound: 'Lv4_U3_reading',
              sentences: ['An ##ostrich## sits on a ##ship##.', 'She has ##lunch##.', 'Mmm … ##Fish## and ##chips##.', '##Chop## the ##chips##.', '##Chop##. ##Chop##. ##Chop##.', '##Wash## the ##fish##.', '##Wash##. ##Wash##. ##Wash##.', 'The ##fish## jumps. Splash!', 'Catch the ##ostrich##’s ##lunch##!', 'A ##fish## ##shape##?', 'It’s not the ##fish##!', 'It’s a ##shark##!'],
            },
          },
          9: {
            title: 'Choose and type the word.',
            shortDescption: 'Words in the Story',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S9_Dir.mp3',
            type: 'write',
            customData: {
              type: 'imgSoundWrite',
              problem: [
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U3_S9_1.png',
                  sentenceFormat: 'An ostrich sits on a ##word##.',
                  correctWord: 'ship',
                  wrongWord: 'fish',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U3_S9_2.png',
                  sentenceFormat: 'Chop the ##word## s.',
                  correctWord: 'chip',
                  wrongWord: 'shark',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U3_S9_3.png',
                  sentenceFormat: 'Wash the ##word##.',
                  correctWord: 'fish',
                  wrongWord: 'ship',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U3_S9_4.png',
                  sentenceFormat: "Catch the ostrich's ##word##.",
                  correctWord: 'lunch',
                  wrongWord: 'shape',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U3_S9_5.png',
                  sentenceFormat: "It's not the fish! It's a ##word##.",
                  correctWord: 'shark',
                  wrongWord: 'fish',
                },
              ],
            },
          },
          10: {
            title: 'Story Chant',
            shortDescption: 'Story Chant',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S10_Dir.mp3',
            url: 'https://player.vimeo.com/video/410082350',
            type: 'video',
          },
          11: {
            title: 'Read. Then drag and drop.',
            shortDescption: 'Story Activity',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S11_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imgDnd3',
              problem: {
                question: [
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level4_unit03_SB_01.png',
                    sentence: ['I have an old ##fish##!', 'I have a smelly ##shoe##!'],
                  },
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level4_unit03_SB_02.png',
                    sentence: ['I have a ##chocolate## ##cherry##.', 'This is my ##lunch##!'],
                  },
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level4_unit03_SB_03.png',
                    sentence: ['##Munch##, ##munch##!', '##Munch##, ##munch##!', '##Munch##! ##Munch##!'],
                  },
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level4_unit03_SB_04.png',
                    sentence: ["I ##wash## my ##chin##.", 'Oh, no! My ##shirt##!'],
                  },
                ],
              },
              totalCount: 4,
            },
          },
          12: {
            title: 'Listen and type the word.',
            shortDescption: 'Quiz',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S12_Dir.mp3',
            type: 'unitTest',
            problem: [
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_chin.png', word: 'chin' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_chip.png', word: 'chip' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_chop.png', word: 'chop' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_lunch.png', word: 'lunch' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_ostrich.png', word: 'ostrich' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_watch.png', word: 'watch' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_ship.png', word: 'ship' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_shape.png', word: 'shape' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_shark.png', word: 'shark' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_brush.png', word: 'brush' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_fish.png', word: 'fish' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_wash.png', word: 'wash' },
            ],
          },
        },
      },
      4: {
        title: 'Double Letters - th · wh',
        stage: {
          1: {
            title: 'Sound Animation',
            shortDescption: 'Sound Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S1_Dir.mp3',
            url: 'https://player.vimeo.com/video/410082636',
            type: 'video',
          },
          2: {
            title: 'Drag and drop.',
            shortDescption: 'Learn the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S2_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  double: ['th'],
                  type: 'dndType5',
                  discImage: 'https://cdn.cloubot.com/PM/image/level4/drag_unit4_1.png',
                  words: [
                    {
                      word: '##th##ick',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level4/image_thick.png',
                      sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/th_thick.mp3',
                    },
                    {
                      word: 'ba##th##',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level4/image_bath.png',
                      sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/th_bath.mp3',
                    },
                  ],
                },
                {
                  double: ['wh'],
                  type: 'dndType5',
                  discImage: 'https://cdn.cloubot.com/PM/image/level4/drag_unit4_2.png',
                  words: [
                    {
                      word: '##wh##ite',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level4/image_white.png',
                      sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/wh_white.mp3',
                    },
                    {
                      word: '##wh##ip',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level4/image_whip.png',
                      sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/wh_whip.mp3',
                    },
                  ],
                },
              ],

              totalCount: 4,
              type: 'alphabetDnd',
              soundBaseUrl: 'https://cdn.cloubot.com/PM/audio/sounds/level4/',
            },
          },
          3: {
            title: 'Listen and choose.',
            shortDescption: 'Practice the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S3_Dir.mp3',
            type: 'wordSelect',
            problem: [
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_thick.png',
                word: 'thick',
                exWords: ['##th##ick', '##th##in', 'ba##th##', 'tee##th##'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_three.png',
                word: 'three',
                exWords: ['##wh##ale', '##th##ree', '##wh##ip', '##wh##isk'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_mouth.png',
                word: 'mouth',
                exWords: ['wa##sh##', 'mou##th##', 'ba##th##', '##wh##ale'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_white.png',
                word: 'white',
                exWords: ['##wh##isper', '##th##ick', '##wh##ite', '##wh##isk'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_wheel.png',
                word: 'wheel',
                exWords: ['tee##th##', '##wh##ip', '##wh##isper', '##wh##eel'],
              },
            ],
          },
          4: {
            title: 'Look and listen.',
            shortDescption: 'Learn the Words',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S4_Dir.mp3',
            type: 'phonemeImage',
            problem: [
              {
                phoneme: '##th## -',
                word: '##th##ick',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_thick.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/th_thick.mp3',
              },
              {
                phoneme: '##th## -',
                word: '##th##in',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_thin.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/th_thin.mp3',
              },
              {
                phoneme: '##th## -',
                word: '##th##ree',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_three.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/th_three.mp3',
              },
              {
                phoneme: '- ##th##',
                word: 'ba##th##',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_bath.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/th_bath.mp3',
              },
              {
                phoneme: '- ##th##',
                word: 'tee##th##',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_teeth.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/th_teeth.mp3',
              },
              {
                phoneme: '- ##th##',
                word: 'mou##th##',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_mouth.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/th_mouth.mp3',
              },
              {
                phoneme: '##wh## -',
                word: '##wh##ite',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_white.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/wh_white.mp3',
              },
              {
                phoneme: '##wh## -',
                word: '##wh##ale',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_whale.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/wh_whale.mp3',
              },
              {
                phoneme: '##wh## -',
                word: '##wh##eel',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_wheel.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/wh_wheel.mp3',
              },
              {
                phoneme: '##wh## -',
                word: '##wh##ip',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_whip.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/wh_whip.mp3',
              },
              {
                phoneme: '##wh## -',
                word: '##wh##isk',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_whisk.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/wh_whisk.mp3',
              },
              {
                phoneme: '##wh## -',
                word: '##wh##isper',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_whisper.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/wh_whisper.mp3',
              },
            ],
          },
          5: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Practice the Words I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S5_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_wheel.png',
                  isBackEnd: true,
                  question: 'eel',
                  answer: 'wh',
                  candidate: ['th', 'ch', 'wh'],
                  sound: 'level4/wh_wheel',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_mouth.png',
                  question: 'mou',
                  answer: 'th',
                  candidate: ['th', 'ch', 'wh'],
                  sound: 'level4/th_mouth',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_thick.png',
                  isBackEnd: true,
                  question: 'ick',
                  answer: 'th',
                  candidate: ['wh', 'sh', 'th'],
                  sound: 'level4/th_thick',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_whisk.png',
                  isBackEnd: true,
                  question: 'isk',
                  answer: 'wh',
                  candidate: ['th', 'ch', 'wh'],
                  sound: 'level4/wh_whisk',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_bath.png',
                  question: 'ba',
                  answer: 'th',
                  candidate: ['th', 'ch', 'wh'],
                  sound: 'level4/th_bath',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_three.png',
                  isBackEnd: true,
                  question: 'ree',
                  answer: 'th',
                  candidate: ['ch', 'th', 'wh'],
                  sound: 'level4/th_three',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_white.png',
                  isBackEnd: true,
                  question: 'ite',
                  answer: 'wh',
                  candidate: ['ch', 'wh', 'th'],
                  sound: 'level4/wh_white',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_whisper.png',
                  isBackEnd: true,
                  question: 'isper',
                  answer: 'wh',
                  candidate: ['th', 'wh', 'sh'],
                  sound: 'level4/wh_whisper',
                },
              ],
              type: 'imgDnd',
              totalCount: 1,
            },
          },
          6: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Practice the Words II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S6_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_thin.png',
                  question: 'th',
                  answer: 'in',
                  candidate: { 0: ['th', 'wh'], 1: ['in', 'ree', 'ale', 'ite'] },
                  sound: 'thin',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_three.png',
                  question: 'th',
                  answer: 'ree',
                  candidate: { 0: ['th', 'wh'], 1: ['in', 'ree', 'ale', 'ite'] },
                  sound: 'three',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_white.png',
                  question: 'wh',
                  answer: 'ite',
                  candidate: { 0: ['th', 'wh'], 1: ['in', 'ree', 'ale', 'ite'] },
                  sound: 'white',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_whale.png',
                  question: 'wh',
                  answer: 'ale',
                  candidate: { 0: ['th', 'wh'], 1: ['in', 'ree', 'ale', 'ite'] },
                  sound: 'whale',
                },
              ],
              type: 'imgDnd2',
              hasSoundBtn: false,
              answerAbsolute: false,
              totalCount: 2,
            },
          },
          7: {
            title: 'Look and choose.',
            shortDescption: 'Practice the Words Ⅲ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S7_Dir.mp3',
            type: 'phonemeSelectAndWrite',
            problem: [
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_teeth.png', word: 'tee##th##', exPhonemes: ['th', 'wh'] },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_mouth.png', word: 'mou##th##', exPhonemes: ['th', 'wh'] },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_wheel.png', word: '##wh##eel', exPhonemes: ['th', 'wh'] },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_whip.png', word: '##wh##ip', exPhonemes: ['th', 'wh'] },
            ],
          },
          8: {
            title: 'Listen and repeat. Then record your voice.',
            shortDescption: 'Story',
            type: 'record',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S8_Dir.mp3',
            customData: {
              bgImage: 'https://cdn.cloubot.com/PM/image/l4_u4_bg_wide.jpg',
              sound: 'Lv4_U4_reading',
              sentences: ['##Three## blue ##whales## are in a tub.', 'The ##whales## take a ##bath##.', '“I wash my ##thin## chin.”', '“I wash my ##mouth##.”', '“I wash my ##white## ##teeth##.”', 'Two blue ##whales## ##whisper##.', '“Blue ##whales## don’t have ##teeth##.”', 'He is not a blue ##whale##. He is a shark!'],
            },
          },
          9: {
            title: 'Choose and type the word.',
            shortDescption: 'Words in the Story',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S9_Dir.mp3',
            type: 'write',
            customData: {
              type: 'imgSoundWrite',
              problem: [
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U4_S9_1.png',
                  sentenceFormat: 'Three blue ##word##s are in a tub.',
                  correctWord: 'whale',
                  wrongWord: 'wheel',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U4_S9_1.png',
                  sentenceFormat: 'The whales take a ##word##.',
                  correctWord: 'bath',
                  wrongWord: 'whisk',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U4_S9_3.png',
                  sentenceFormat: 'I wash my ##word## chin.',
                  correctWord: 'thin',
                  wrongWord: 'thick',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U4_S9_4.png',
                  sentenceFormat: 'I wash my ##word## teeth.',
                  correctWord: 'white',
                  wrongWord: 'three',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U4_S9_5.png',
                  sentenceFormat: "Blue whales don't have ##word##.",
                  correctWord: 'teeth',
                  wrongWord: 'mouth',
                },
              ],
            },
          },
          10: {
            title: 'Story Chant',
            shortDescption: 'Story Chant',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S10_Dir.mp3',
            url: 'https://player.vimeo.com/video/410082376',
            type: 'video',
          },
          11: {
            title: 'Read. Then drag and drop.',
            shortDescption: 'Story Activity',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S11_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imgDnd3',
              problem: {
                question: [
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level4_unit04_SB_01.png',
                    sentence: ['##Three## ##white## ##whales## paint their body black.'],
                  },
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level4_unit04_SB_02.png',
                    sentence: ['The ##teeth## turn black. “Too black!”', 'The ##mouth## turns black. “Too black!”'],
                  },
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level4_unit04_SB_03.png',
                    sentence: ['The ##white## ##whales## ##whisper##.', '“The paint is too ##thick##.”'],
                  },
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level4_unit04_SB_04.png',
                    sentence: [
                      '##Three## ##white## ##whales## take a ##bath##.',
                      'They brush their ##teeth##.',
                      'They are ##white## again.',
                    ],
                  },
                ],
              },
              totalCount: 4,
            },
          },
          12: {
            title: 'Listen and type the word.',
            shortDescption: 'Quiz',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S12_Dir.mp3',
            type: 'unitTest',
            problem: [
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_thick.png', word: 'thick' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_thin.png', word: 'thin' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_three.png', word: 'three' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_bath.png', word: 'bath' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_teeth.png', word: 'teeth' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_mouth.png', word: 'mouth' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_white.png', word: 'white' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_whale.png', word: 'whale' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_wheel.png', word: 'wheel' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_whip.png', word: 'whip' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_whisk.png', word: 'whisk' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_whisper.png', word: 'whisper' },
            ],
          },
        },
      },
      5: {
        title: 'hard/soft c · g',
        stage: {
          1: {
            title: 'Sound Animation',
            shortDescption: 'Sound Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S1_Dir.mp3',
            url: 'https://player.vimeo.com/video/410082659',
            type: 'video',
          },
          2: {
            title: 'Drag and drop.',
            shortDescption: 'Learn the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S2_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  letter: 'c',
                  hard: ['a', 'o', 'u'],
                  soft: ['e', 'i', 'y'],
                  type: 'dndType6',
                  words: [
                    {
                      word: '##c##andy',
                      discImage: 'https://cdn.cloubot.com/PM/image/level4/drag_unit5_1.png',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level4/image_candy.png',
                      sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/c_candy.mp3',
                    },
                    {
                      word: '##c##ity',
                      discImage: 'https://cdn.cloubot.com/PM/image/level4/drag_unit5_2.png',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level4/image_city.png',
                      sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/c_city.mp3',
                    },
                  ],
                },
                {
                  letter: 'g',
                  hard: ['a', 'o', 'u'],
                  soft: ['e', 'i', 'y'],
                  type: 'dndType6',
                  words: [
                    {
                      word: '##g##um',
                      discImage: 'https://cdn.cloubot.com/PM/image/level4/drag_unit5_3.png',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level4/image_gum.png',
                      sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/g_gum.mp3',
                    },
                    {
                      word: '##g##iraffe',
                      discImage: 'https://cdn.cloubot.com/PM/image/level4/drag_unit5_4.png',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level4/image_giraffe.png',
                      sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/g_giraffe.mp3',
                    },
                  ],
                },
              ],
              totalCount: 2,
              type: 'alphabetDnd',
              soundBaseUrl: 'https://cdn.cloubot.com/PM/audio/sounds/level4/',
            },
          },
          3: {
            title: 'Listen and choose.',
            shortDescption: 'Practice the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S3_Dir.mp3',
            type: 'wordSelect',
            problem: [
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_candy.png',
                word: 'candy',
                exWords: ['##c##andy', '##c##ut', 'fa##c##e', 'bi##c##ycle'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_cold.png',
                word: 'cold',
                exWords: ['##g##um', '##c##old', '##c##andy', 'bi##c##ycle'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_city.png',
                word: 'city',
                exWords: ['##c##ity', '##g##um', '##c##old', '##c##ut'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_gorilla.png',
                word: 'gorilla',
                exWords: ['alli##g##ator', '##c##andy', '##c##ity', '##g##orilla'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_gym.png',
                word: 'gym',
                exWords: ['##g##iraffe', '##c##age', '##g##ym', 'fa##c##e'],
              },
            ],
          },
          4: {
            title: 'Look and listen.',
            shortDescption: 'Learn the Words',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S4_Dir.mp3',
            type: 'phonemeImage',
            problem: [
              {
                phoneme: 'hard ##c##',
                word: '##c##andy',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_candy.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/c_candy.mp3',
              },
              {
                phoneme: 'hard ##c##',
                word: '##c##ut',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_cut.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/c_cut.mp3',
              },
              {
                phoneme: 'hard ##c##',
                word: '##c##old',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_cold.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/c_cold.mp3',
              },
              {
                phoneme: 'soft ##c##',
                word: '##c##ity',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_city.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/c_city.mp3',
              },
              {
                phoneme: 'soft ##c##',
                word: 'fa##c##e',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_face.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/c_face.mp3',
              },
              {
                phoneme: 'soft ##c##',
                word: 'bi##c##ycle',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_bicycle.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/c_bicycle.mp3',
              },
              {
                phoneme: 'hard ##g##',
                word: '##g##um',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_gum.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/g_gum.mp3',
              },
              {
                phoneme: 'hard ##g##',
                word: '##g##orilla',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_gorilla.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/g_gorilla.mp3',
              },
              {
                phoneme: 'hard ##g##',
                word: 'alli##g##ator',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_alligator.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/g_alligator.mp3',
              },
              {
                phoneme: 'soft ##g##',
                word: '##g##iraffe',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_giraffe.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/g_giraffe.mp3',
              },
              {
                phoneme: 'soft ##g##',
                word: '##g##ym',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_gym.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/g_gym.mp3',
              },
              {
                phoneme: 'soft ##g##',
                word: 'ca##g##e',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_cage.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/g_cage.mp3',
              },
            ],
          },
          5: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Practice the Words I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S5_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_cold.png',
                  isBackEnd: true,
                  question: 'old',
                  answer: 'hard-C',
                  candidate: ['hard-C', 'soft-C'],
                  sound: 'level4/c_cold',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_face.png',
                  question: 'fa',
                  question2: 'e',
                  answer: 'soft-C',
                  candidate: ['hard-C', 'soft-C'],
                  sound: 'level4/c_face',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_gum.png',
                  isBackEnd: true,
                  question: 'um',
                  answer: 'hard-G',
                  candidate: ['hard-G', 'soft-G'],
                  sound: 'level4/g_gum',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_alligator.png',
                  question: 'alli',
                  question2: 'ator',
                  answer: 'hard-G',
                  candidate: ['hard-G', 'soft-G'],
                  sound: 'level4/g_alligator',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_candy.png',
                  isBackEnd: true,
                  question: 'andy',
                  answer: 'hard-C',
                  candidate: ['hard-C', 'soft-C'],
                  sound: 'level4/c_candy',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_city.png',
                  isBackEnd: true,
                  question: 'ity',
                  answer: 'soft-C',
                  candidate: ['hard-C', 'soft-C'],
                  sound: 'level4/c_city',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_gorilla.png',
                  isBackEnd: true,
                  question: 'orilla',
                  answer: 'hard-G',
                  candidate: ['hard-G', 'soft-G'],
                  sound: 'level4/g_gorilla',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_gym.png',
                  isBackEnd: true,
                  question: 'ym',
                  answer: 'soft-G',
                  candidate: ['hard-G', 'soft-G'],
                  sound: 'level4/g_gym',
                },
              ],
              type: 'imgDnd',
              totalCount: 1,
            },
          },
          6: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Practice the Words II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S6_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_candy.png',
                  question: 'c',
                  answer: 'andy',
                  candidate: { 0: ['c', 'g'], 1: ['andy', 'ity'] },
                  sound: 'candy',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_city.png',
                  question: 'c',
                  answer: 'ity',
                  candidate: { 0: ['c', 'g'], 1: ['ity', 'ame'] },
                  sound: 'city',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_gum.png',
                  question: 'g',
                  answer: 'um',
                  candidate: { 0: ['c', 'g'], 1: ['ut', 'um'] },
                  sound: 'gum',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_gym.png',
                  question: 'g',
                  answer: 'ym',
                  candidate: { 0: ['c', 'g'], 1: ['ut', 'ym'] },
                  sound: 'gym',
                },
              ],
              type: 'imgDnd2',
              hasSoundBtn: false,
              answerAbsolute: false,
              totalCount: 2,
            },
          },
          7: {
            title: 'Look and choose.',
            shortDescption: 'Practice the Words Ⅲ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S7_Dir.mp3',
            type: 'phonemeSelectAndWrite',
            problem: [
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_cut.png',
                word: '##c##ut',
                exPhonemes: ['hard-c', 'soft-c'],
                answer: 'hard-c',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_face.png',
                word: 'fa##c##e',
                exPhonemes: ['hard-c', 'soft-c'],
                answer: 'soft-c',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_gum.png',
                word: '##g##um',
                exPhonemes: ['hard-g', 'soft-g'],
                answer: 'hard-g',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_cage.png',
                word: 'ca##g##e',
                exPhonemes: ['hard-g', 'soft-g'],
                answer: 'soft-g',
              },
            ],
          },
          8: {
            title: 'Listen and repeat. Then record your voice.',
            shortDescption: 'Story',
            type: 'record',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S8_Dir.mp3',
            customData: {
              bgImage: 'https://cdn.cloubot.com/PM/image/l4_u5_bg_wide.jpg',
              sound: 'Lv4_U5_reading',
              sentences: ['A race in the ##city##!', '##Giraffe## rides a ##bicycle##.', 'Cat rides a car.', '##Gorilla## runs.', 'The ##city## is ##cold##.', 'Oh, no! Ice!', 'Cat and ##Giraffe## slide.', '##Gorilla## wins the race!', 'He wins the gold cup.', 'A girl gives cake and ##candy##.'],
            },
          },
          9: {
            title: 'Choose and type the word.',
            shortDescption: 'Words in the Story',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S9_Dir.mp3',
            type: 'write',
            customData: {
              type: 'imgSoundWrite',
              problem: [
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U5_S9_1.png',
                  sentenceFormat: 'A race in the ##word##.',
                  correctWord: 'city',
                  wrongWord: 'cage',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U5_S9_2.png',
                  sentenceFormat: 'Giraffe rides a ##word##.',
                  correctWord: 'bicycle',
                  wrongWord: 'candy',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U5_S9_3.png',
                  sentenceFormat: 'The city is ##word##.',
                  correctWord: 'cold',
                  wrongWord: 'candy',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U5_S9_4.png',
                  sentenceFormat: 'Oh, no! Ice! Cat and ##word## slide.',
                  correctWord: 'Giraffe',
                  wrongWord: 'Gorilla',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U5_S9_5.png',
                  sentenceFormat: 'A girl gives cake and ##word##.',
                  correctWord: 'candy',
                  wrongWord: 'gum',
                },
              ],
            },
          },
          10: {
            title: 'Story Chant',
            shortDescption: 'Story Chant',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S10_Dir.mp3',
            url: 'https://player.vimeo.com/video/410082402',
            type: 'video',
          },
          11: {
            title: 'Read. Then drag and drop.',
            shortDescption: 'Story Activity',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S11_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imgDnd3',
              problem: {
                question: [
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level4_unit05_SB_01.png',
                    sentence: ['The ##cage## is ##cold##.', 'The ##alligator## is ##cold##.'],
                  },
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level4_unit05_SB_02.png',
                    sentence: ['Here is a ##girl##.', 'The ##alligator## is ##glad##!'],
                  },
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level4_unit05_SB_03.png',
                    sentence: ['They go on a ##bicycle##!', 'They go in a ##cab##!'],
                  },
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level4_unit05_SB_04.png',
                    sentence: ['This is a ##city## ##garden##.', 'They eat ##candy## and ##cake##!'],
                  },
                ],
              },
              totalCount: 4,
            },
          },
          12: {
            title: 'Listen and type the word.',
            shortDescption: 'Quiz',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S12_Dir.mp3',
            type: 'unitTest',
            problem: [
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_candy.png', word: 'candy' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_cut.png', word: 'cut' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_cold.png', word: 'cold' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_city.png', word: 'city' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_face.png', word: 'face' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_bicycle.png', word: 'bicycle' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_gum.png', word: 'gum' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_gorilla.png', word: 'gorilla' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_alligator.png', word: 'alligator' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_giraffe.png', word: 'giraffe' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_gym.png', word: 'gym' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_cage.png', word: 'cage' },
            ],
          },
        },
      },
      R: {
        title: 'Review unit1~unit5',
        stage: {
          1: {
            title: 'Listen and choose.',
            shortDescption: 'Review the Sounds I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_Review_S1_Dir.mp3',
            type: 'wordSelect',
            problem: [
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_globe.png',
                word: 'globe',
                questionFormat: '##gl##obe',
                answer: 'gl',
                exWords: ['bl', 'fl', 'gl', 'sl'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_crow.png',
                word: 'crow',
                questionFormat: '##cr##ow',
                answer: 'cr',
                exWords: ['br', 'cr', 'dr', 'gr'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_drum.png',
                word: 'drum',
                questionFormat: '##dr##um',
                answer: 'dr',
                exWords: ['br', 'cr', 'dr', 'gr'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_blade.png',
                word: 'blade',
                questionFormat: '##bl##ade',
                answer: 'bl',
                exWords: ['bl', 'fl', 'gl', 'sl'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_brush.png',
                word: 'brush',
                questionFormat: 'bru##sh##',
                answer: 'sh',
                exWords: ['ch', 'sh', 'th', 'wh'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_ostrich.png',
                word: 'ostrich',
                questionFormat: 'ostri##ch##',
                answer: 'ch',
                exWords: ['ch', 'sh', 'th', 'wh'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_bath.png',
                word: 'bath',
                questionFormat: 'ba##th##',
                answer: 'th',
                exWords: ['ch', 'sh', 'th', 'wh'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_wheel.png',
                word: 'wheel',
                questionFormat: '##wh##eel',
                answer: 'wh',
                exWords: ['ch', 'sh', 'th', 'wh'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_cold.png',
                word: 'cold',
                questionFormat: '@c@old',
                answer: 'hard c',
                exWords: ['hard c', 'soft c', 'hard g', 'soft g'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_gym.png',
                word: 'gym',
                questionFormat: '@g@ym',
                answer: 'soft g',
                exWords: ['hard c', 'soft c', 'hard g', 'soft g'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_face.png',
                word: 'face',
                questionFormat: 'fa@c@e',
                answer: 'soft c',
                exWords: ['hard c', 'soft c', 'hard g', 'soft g'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_gorilla.png',
                word: 'gorilla',
                questionFormat: '@g@orilla',
                answer: 'hard g',
                exWords: ['hard c', 'soft c', 'hard g', 'soft g'],
              },
            ],
          },
          2: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Review the Words I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_Review_S2_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imgDnd4',
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_sleep.png',
                  questionFormat: 'sl##eep##',
                  rightWord: 'sleep',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level4/image_flute.png',
                    'https://cdn.cloubot.com/PM/image/level4/image_sleep.png',
                    'https://cdn.cloubot.com/PM/image/level4/image_grass.png',
                  ],
                },

                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_grape.png',
                  questionFormat: 'gr##ape##',
                  rightWord: 'grape',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level4/image_grape.png',
                    'https://cdn.cloubot.com/PM/image/level4/image_brick.png',
                    'https://cdn.cloubot.com/PM/image/level4/image_cross.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_flag.png',
                  questionFormat: 'fl##ag##',
                  rightWord: 'flag',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level4/image_blue.png',
                    'https://cdn.cloubot.com/PM/image/level4/image_sled.png',
                    'https://cdn.cloubot.com/PM/image/level4/image_flag.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_crab.png',
                  questionFormat: 'cr##ab##',
                  rightWord: 'crab',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level4/image_dragon.png',
                    'https://cdn.cloubot.com/PM/image/level4/image_brave.png',
                    'https://cdn.cloubot.com/PM/image/level4/image_crab.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_shark.png',
                  questionFormat: 'sh##ark##',
                  rightWord: 'shark',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level4/image_chip.png',
                    'https://cdn.cloubot.com/PM/image/level4/image_shark.png',
                    'https://cdn.cloubot.com/PM/image/level4/image_thick.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_chop.png',
                  questionFormat: 'ch##op##',
                  rightWord: 'chop',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level4/image_chop.png',
                    'https://cdn.cloubot.com/PM/image/level4/image_ship.png',
                    'https://cdn.cloubot.com/PM/image/level4/image_whip.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_mouth.png',
                  questionFormat: '##mou##th',
                  rightWord: 'mouth',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level4/image_watch.png',
                    'https://cdn.cloubot.com/PM/image/level4/image_mouth.png',
                    'https://cdn.cloubot.com/PM/image/level4/image_fish.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_whisper.png',
                  questionFormat: 'wh##isper##',
                  rightWord: 'whisper',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level4/image_chin.png',
                    'https://cdn.cloubot.com/PM/image/level4/image_three.png',
                    'https://cdn.cloubot.com/PM/image/level4/image_whisper.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_giraffe.png',
                  questionFormat: 'soft g',
                  rightWord: 'giraffe',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level4/image_gorilla.png',
                    'https://cdn.cloubot.com/PM/image/level4/image_giraffe.png',
                    'https://cdn.cloubot.com/PM/image/level4/image_cold.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_bicycle.png',
                  questionFormat: 'soft c',
                  rightWord: 'bicycle',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level4/image_bicycle.png',
                    'https://cdn.cloubot.com/PM/image/level4/image_alligator.png',
                    'https://cdn.cloubot.com/PM/image/level4/image_cage.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_gum.png',
                  questionFormat: 'hard g',
                  rightWord: 'gum',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level4/image_cut.png',
                    'https://cdn.cloubot.com/PM/image/level4/image_gym.png',
                    'https://cdn.cloubot.com/PM/image/level4/image_gum.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_candy.png',
                  questionFormat: 'hard c',
                  rightWord: 'candy',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level4/image_giraffe.png',
                    'https://cdn.cloubot.com/PM/image/level4/image_city.png',
                    'https://cdn.cloubot.com/PM/image/level4/image_candy.png',
                  ],
                },
              ],
              totalCount: 1,
            },
          },
          3: {
            title: 'Look and listen. Then choose.',
            shortDescption: ' Review the Words II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_Review_S3_Dir_a.mp3',
            type: 'imgSelect',
            customData: {
              type: 'imgSelectImg',
              problem: [
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level4/image_slide.png',
                  qWord: 'slide',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_black.png', word: 'black', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_glue.png', word: 'glue', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_sleep.png', word: 'sleep', isAnswer: true },
                  ],
                },
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level4/image_bride.png',
                  qWord: 'bride',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_grape.png', word: 'grape', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_brick.png', word: 'brick', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_cross.png', word: 'cross', isAnswer: false },
                  ],
                },
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level4/image_flame.png',
                  qWord: 'flame',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_blue.png', word: 'blue', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_sled.png', word: 'sled', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_flag.png', word: 'flag', isAnswer: true },
                  ],
                },
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level4/image_grass.png',
                  qWord: 'grass',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_green.png', word: 'green', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_brave.png', word: 'brave', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_crow.png', word: 'crow', isAnswer: false },
                  ],
                },
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level4/image_lunch.png',
                  qWord: 'lunch',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_brush.png', word: 'brush', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_watch.png', word: 'watch', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_wash.png', word: 'wash', isAnswer: false },
                  ],
                },
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level4/image_ship.png',
                  qWord: 'ship',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_chip.png', word: 'chip', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_shape.png', word: 'shape', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_thin.png', word: 'thin', isAnswer: false },
                  ],
                },
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level4/image_thin.png',
                  qWord: 'thin',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_three.png', word: 'three', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_whisk.png', word: 'whisk', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_chin.png', word: 'chin', isAnswer: false },
                  ],
                },
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level4/image_white.png',
                  qWord: 'white',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_shape.png', word: 'shape', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_thick.png', word: 'thick', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_whale.png', word: 'whale', isAnswer: true },
                  ],
                },
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level4/image_gym.png',
                  qWord: 'gym',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_gorilla.png', word: 'gorilla', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_cold.png', word: 'cold', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_giraffe.png', word: 'giraffe', isAnswer: true },
                  ],
                },
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level4/image_cut.png',
                  qWord: 'cut',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_face.png', word: 'face', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_candy.png', word: 'candy', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_gym.png', word: 'gym', isAnswer: false },
                  ],
                },
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level4/image_face.png',
                  qWord: 'face',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_cage.png', word: 'cage', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_bicycle.png', word: 'bicycle', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_giraffe.png', word: 'giraffe', isAnswer: false },
                  ],
                },
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level4/image_gorilla.png',
                  qWord: 'gorilla',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_gum.png', word: 'gum', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_cut.png', word: 'cut', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_city.png', word: 'city', isAnswer: false },
                  ],
                },
              ],
            },
          },
          4: {
            title: 'Look and choose.',
            shortDescption: 'Word Puzzle',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_Review_S4_Dir.mp3',
            type: 'crossWords',
            customData: {
              imageBaseUrl: 'https://cdn.cloubot.com/PM/image/level4/',
              problem: [
                {
                  words: [
                    {
                      name: 'dress',
                      xy: [
                        [2, 1],
                        [2, 2],
                        [2, 3],
                        [2, 4],
                        [2, 5],
                      ],
                    },
                    {
                      name: 'bride',
                      xy: [
                        [6, 1],
                        [6, 2],
                        [6, 3],
                        [6, 4],
                        [6, 5],
                      ],
                    },
                    {
                      name: 'crow',
                      xy: [
                        [1, 2],
                        [2, 2],
                        [3, 2],
                        [4, 2],
                      ],
                    },
                    {
                      name: 'bicycle',
                      xy: [
                        [0, 0],
                        [0, 1],
                        [0, 2],
                        [0, 3],
                        [0, 4],
                        [0, 5],
                        [0, 6],
                      ],
                    },
                    {
                      name: 'slide',
                      xy: [
                        [2, 5],
                        [3, 5],
                        [4, 5],
                        [5, 5],
                        [6, 5],
                      ],
                    },
                    {
                      name: 'bath',
                      xy: [
                        [0, 0],
                        [1, 0],
                        [2, 0],
                        [3, 0],
                      ],
                    },
                    {
                      name: 'cage',
                      xy: [
                        [3, 7],
                        [4, 7],
                        [5, 7],
                        [6, 7],
                      ],
                    },
                    {
                      name: 'ship',
                      xy: [
                        [2, 4],
                        [3, 4],
                        [4, 4],
                        [5, 4],
                      ],
                    },
                  ],
                  coords: [
                    ['b', 'a', 't', 'h', 'b', 't', 'e'],
                    ['i', 's', 'd', 'o', 'f', 'm', 'b'],
                    ['c', 'c', 'r', 'o', 'w', 'i', 'r'],
                    ['y', 'b', 'e', 'n', 'c', 'h', 'i'],
                    ['c', 'n', 's', 'h', 'i', 'p', 'd'],
                    ['l', 'p', 's', 'l', 'i', 'd', 'e'],
                    ['e', 'd', 'e', 'c', 's', 'n', 'f'],
                    ['t', 'a', 'd', 'c', 'a', 'g', 'e'],
                  ],
                },
                {
                  words: [
                    {
                      name: 'drum',
                      xy: [
                        [1, 5],
                        [2, 5],
                        [3, 5],
                        [4, 5],
                      ],
                    },
                    {
                      name: 'flame',
                      xy: [
                        [0, 0],
                        [0, 1],
                        [0, 2],
                        [0, 3],
                        [0, 4],
                      ],
                    },
                    {
                      name: 'sleep',
                      xy: [
                        [1, 1],
                        [2, 1],
                        [3, 1],
                        [4, 1],
                        [5, 1],
                      ],
                    },
                    {
                      name: 'crab',
                      xy: [
                        [1, 2],
                        [2, 2],
                        [3, 2],
                        [4, 2],
                      ],
                    },
                    {
                      name: 'fish',
                      xy: [
                        [0, 0],
                        [1, 0],
                        [2, 0],
                        [3, 0],
                      ],
                    },
                    {
                      name: 'teeth',
                      xy: [
                        [0, 6],
                        [1, 6],
                        [2, 6],
                        [3, 6],
                        [4, 6],
                      ],
                    },
                    {
                      name: 'chin',
                      xy: [
                        [6, 0],
                        [6, 1],
                        [6, 2],
                        [6, 3],
                      ],
                    },
                    {
                      name: 'whip',
                      xy: [
                        [5, 3],
                        [5, 4],
                        [5, 5],
                        [5, 6],
                      ],
                    },
                  ],
                  coords: [
                    ['f', 'i', 's', 'h', 'c', 'h', 'c'],
                    ['l', 's', 'l', 'e', 'e', 'p', 'h'],
                    ['a', 'c', 'r', 'a', 'b', 'b', 'i'],
                    ['m', 'b', 'f', 'd', 'c', 'w', 'n'],
                    ['e', 'n', 's', 'h', 'i', 'h', 'k'],
                    ['a', 'd', 'r', 'u', 'm', 'i', 'e'],
                    ['t', 'e', 'e', 't', 'h', 'p', 's'],
                    ['e', 'a', 'd', 'c', 'a', 'g', 'e'],
                  ],
                },
              ],
            },
          },
          5: {
            title: 'Look at the picture. Choose the picture that has different sound. (Rhyme)',
            shortDescption: ' Review the Sounds II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_Review_S5_Dir.mp3',
            type: 'imgSelect',
            customData: {
              type: 'imgSelectWord', //imgSelectImg, imgSelectWord
              problem: [
                {
                  qWord: 'bl',
                  direction: 'right',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_black.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_blade.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_brave.png', isAnswer: true },
                  ],
                },
                {
                  qWord: 'gr',
                  direction: 'right',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_grape.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_cross.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_grass.png', isAnswer: false },
                  ],
                },
                {
                  qWord: 'th',
                  direction: 'left',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_bath.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_mouth.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_watch.png', isAnswer: true },
                  ],
                },
                {
                  qWord: 'wh',
                  direction: 'right',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_drum.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_whisk.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_wheel.png', isAnswer: false },
                  ],
                },
                {
                  qWord: 'ch',
                  direction: 'none',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_chop.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_fish.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_chin.png', isAnswer: false },
                  ],
                },
                {
                  qWord: 'hard C',
                  direction: 'none',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_cut.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_face.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_candy.png', isAnswer: false },
                  ],
                },
                {
                  qWord: 'soft G',
                  direction: 'none',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_alligator.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_gym.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_giraffe.png', isAnswer: false },
                  ],
                },
                {
                  qWord: 'fl',
                  direction: 'right',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_flute.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_flame.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_green.png', isAnswer: true },
                  ],
                },
                {
                  qWord: 'cr',
                  direction: 'right',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_cross.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_crab.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_sleep.png', isAnswer: true },
                  ],
                },
                {
                  qWord: 'sh',
                  direction: 'none',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_brush.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_lunch.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_shape.png', isAnswer: false },
                  ],
                },
                {
                  qWord: 'soft C',
                  direction: 'none',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_bicycle.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_cold.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_city.png', isAnswer: false },
                  ],
                },
                {
                  qWord: 'hard G',
                  direction: 'none',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_cage.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_gorilla.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_gum.png', isAnswer: false },
                  ],
                },
              ],
            },
          },
          6: {
            title: 'Listen and choose the beginning sound.',
            shortDescption: 'Review the Words III',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_Review1_S6_Dir.mp3',
            type: 'imgSelect',
            customData: {
              type: 'selectWord',
              problem: [
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/image_grape.png',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/grape.mp3',
                  words: [
                    { name: 'gl', isAnswer: false },
                    { name: 'gr', isAnswer: true },
                    { name: 'dr', isAnswer: false },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/image_chin.png',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/chin.mp3',
                  words: [
                    { name: 'sh', isAnswer: false },
                    { name: 'ch', isAnswer: true },
                    { name: 'sl', isAnswer: false },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/image_blade.png',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/blade.mp3',
                  words: [
                    { name: 'bl', isAnswer: true },
                    { name: 'br', isAnswer: false },
                    { name: 'gl', isAnswer: false },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/image_whale.png',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/whale.mp3',
                  words: [
                    { name: 'th', isAnswer: false },
                    { name: 'wh', isAnswer: true },
                    { name: 'ch', isAnswer: false },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/image_crab.png',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/crab.mp3',
                  words: [
                    { name: 'cr', isAnswer: true },
                    { name: 'cl', isAnswer: false },
                    { name: 'ch', isAnswer: false },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/image_city.png',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/city.mp3',
                  words: [
                    { name: 'hard C', isAnswer: false },
                    { name: 'soft C', isAnswer: true },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/image_flag.png',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/flag.mp3',
                  words: [
                    { name: 'fr', isAnswer: false },
                    { name: 'gl', isAnswer: false },
                    { name: 'fl', isAnswer: true },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/image_candy.png',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/candy.mp3',
                  words: [
                    { name: 'hard C', isAnswer: true },
                    { name: 'soft C', isAnswer: false },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/image_three.png',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/three.mp3',
                  words: [
                    { name: 'th', isAnswer: true },
                    { name: 'wh', isAnswer: false },
                    { name: 'sh', isAnswer: false },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/image_giraffe.png',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/giraffe.mp3',
                  words: [
                    { name: 'hard G', isAnswer: false },
                    { name: 'soft G', isAnswer: true },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/image_brick.png',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/brick.mp3',
                  words: [
                    { name: 'bl', isAnswer: false },
                    { name: 'br', isAnswer: true },
                    { name: 'fr', isAnswer: false },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/image_globe.png',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/globe.mp3',
                  words: [
                    { name: 'gr', isAnswer: false },
                    { name: 'gl', isAnswer: true },
                    { name: 'cl', isAnswer: false },
                  ],
                },
              ],
            },
          },
          7: {
            title: 'Look, choose, and type.',
            shortDescption: 'Review the Sounds III',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_Review_S7_Dir.mp3',
            type: 'write',
            customData: {
              type: 'imageWordSelectWrite',
              problem: [
                { image: 'https://cdn.cloubot.com/PM/image/level4/image_brick.png', answer: 'brick', exWords: ['brick', 'black'] },
                { image: 'https://cdn.cloubot.com/PM/image/level4/image_glass.png', answer: 'glass', exWords: ['glass', 'grass'] },
                { image: 'https://cdn.cloubot.com/PM/image/level4/image_chip.png', answer: 'chip', exWords: ['chip', 'ship'] },
                { image: 'https://cdn.cloubot.com/PM/image/level4/image_crab.png', answer: 'crab', exWords: ['crab', 'flag'] },
                { image: 'https://cdn.cloubot.com/PM/image/level4/image_shape.png', answer: 'shape', exWords: ['shape', 'chip'] },
                { image: 'https://cdn.cloubot.com/PM/image/level4/image_bath.png', answer: 'bath', exWords: ['bath', 'bat'] },
                { image: 'https://cdn.cloubot.com/PM/image/level4/image_cut.png', answer: 'cut', exWords: ['cut', 'city'] },
                { image: 'https://cdn.cloubot.com/PM/image/level4/image_gym.png', answer: 'gym', exWords: ['gym', 'gum'] },
                { image: 'https://cdn.cloubot.com/PM/image/level4/image_three.png', answer: 'three', exWords: ['three', 'wheel'] },
                { image: 'https://cdn.cloubot.com/PM/image/level4/image_crow.png', answer: 'crow', exWords: ['crow', 'crown'] },
                { image: 'https://cdn.cloubot.com/PM/image/level4/image_glue.png', answer: 'glue', exWords: ['glue', 'blue'] },
                { image: 'https://cdn.cloubot.com/PM/image/level4/image_watch.png', answer: 'watch', exWords: ['watch', 'wash'] },
              ],
            },
          },
          8: {
            title: 'Look and type.',
            shortDescption: 'Review the Words IV',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_Review_S8_Dir.mp3',
            type: 'write',
            customData: {
              type: 'phonemeImageWrite',
              problem: [
                { phoneme: 'cr-', imageBaseUrl: 'https://cdn.cloubot.com/PM/image/level4/', words: ['crab', 'cross'] },
                { phoneme: 'wh-', imageBaseUrl: 'https://cdn.cloubot.com/PM/image/level4/', words: ['whisk', 'whisper'] },
                { phoneme: 'th-', imageBaseUrl: 'https://cdn.cloubot.com/PM/image/level4/', words: ['thin', 'thick'] },
                { phoneme: 'fl-', imageBaseUrl: 'https://cdn.cloubot.com/PM/image/level4/', words: ['flag', 'flute'] },
                { phoneme: 'br-', imageBaseUrl: 'https://cdn.cloubot.com/PM/image/level4/', words: ['bride', 'brick'] },
                { phoneme: '-sh', imageBaseUrl: 'https://cdn.cloubot.com/PM/image/level4/', words: ['fish', 'wash'] },
              ],
            },
          },
        },
      },
    },
  },
  {
    bookName: '4b',
    bookCode: 'PM-L8V1',
    validation: {
      purchaseCode: 'someRandomCode',
      accessToken: 'short-lived-access-token',
    },
    unit: {
      6: {
        title: 'Double Letters - ai · ay',
        stage: {
          1: {
            title: 'Sound Animation',
            shortDescption: 'Sound Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S1_Dir.mp3',
            url: 'https://player.vimeo.com/video/410082701',
            type: 'video',
          },
          2: {
            title: 'Drag and drop.',
            shortDescption: 'Learn the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S2_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  double: ['ai'],
                  type: 'dndType5',
                  discImage: 'https://cdn.cloubot.com/PM/image/level4/drag_unit6_1.png',
                  words: [
                    {
                      word: 'm##ai##l',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level4/image_mail.png',
                      sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ai_mail.mp3',
                    },
                    {
                      word: 'sn##ai##l',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level4/image_snail.png',
                      sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ai_snail.mp3',
                    },
                  ],
                },
                {
                  double: ['ay'],
                  type: 'dndType5',
                  discImage: 'https://cdn.cloubot.com/PM/image/level4/drag_unit6_2.png',
                  words: [
                    {
                      word: 'h##ay##',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level4/image_hay.png',
                      sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ay_hay.mp3',
                    },
                    {
                      word: 'cr##ay##on',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level4/image_crayon.png',
                      sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ay_crayon.mp3',
                    },
                  ],
                },
              ],
              totalCount: 4,
              type: 'alphabetDnd',
              soundBaseUrl: 'https://cdn.cloubot.com/PM/audio/sounds/level4/',
            },
          },
          3: {
            title: 'Listen and choose.',
            shortDescption: 'Practice the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S3_Dir.mp3',
            type: 'wordSelect',

            problem: [
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_mail.png',
                word: 'mail',
                exWords: ['m##ai##l', 'sn##ai##l', 'r##ai##n', 't##ai##l'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_snail.png',
                word: 'snail',
                exWords: ['tr##ai##n', 'sn##ai##l', 'w##ai##t', 'r##ai##n'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_rain.png',
                word: 'rain',
                exWords: ['t##ai##l', 'r##ai##n', 'tr##ai##l', 'm##ai##l'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_hay.png',
                word: 'hay',
                exWords: ['gr##ay##', 'pl##ay##', 'h##ay##', 'cl##ay##'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_crayon.png',
                word: 'crayon',
                exWords: ['spr##ay##', 'pl##ay##', 'cr##ay##on', 'sn##ai##l'],
              },
            ],
          },
          4: {
            title: 'Look and listen.',
            shortDescption: 'Learn the Words',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S4_Dir.mp3',
            type: 'phonemeImage',
            problem: [
              {
                phoneme: '- ##ai## -',
                word: 'm##ai##l',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_mail.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ai_mail.mp3',
              },
              {
                phoneme: '- ##ai## -',
                word: 'sn##ai##l',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_snail.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ai_snail.mp3',
              },
              {
                phoneme: '- ##ai## -',
                word: 'r##ai##n',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_rain.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ai_rain.mp3',
              },
              {
                phoneme: '- ##ai## -',
                word: 't##ai##l',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_tail.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ai_tail.mp3',
              },
              {
                phoneme: '- ##ai## -',
                word: 'tr##ai##n',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_train.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ai_train.mp3',
              },
              {
                phoneme: '- ##ai## -',
                word: 'w##ai##t',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_wait.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ai_wait.mp3',
              },
              {
                phoneme: '- ##ay## -',
                word: 'h##ay##',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_hay.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ay_hay.mp3',
              },
              {
                phoneme: '- ##ay## -',
                word: 'cr##ay##on',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_crayon.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ay_crayon.mp3',
              },
              {
                phoneme: '- ##ay## -',
                word: 'gr##ay##',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_gray.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ay_gray.mp3',
              },
              {
                phoneme: '- ##ay## -',
                word: 'cl##ay##',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_clay.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ay_clay.mp3',
              },
              {
                phoneme: '- ##ay## -',
                word: 'spr##ay##',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_spray.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ay_spray.mp3',
              },
              {
                phoneme: '- ##ay## -',
                word: 'pl##ay##',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_play.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ay_play.mp3',
              },
            ],
          },
          5: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Practice the WordsⅠ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S5_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_snail.png',
                  question: 'sn',
                  question2: 'l',
                  answer: 'ai',
                  candidate: ['ai', 'ay'],
                  sound: 'level4/ai_snail',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_gray.png',
                  question: 'gr',
                  answer: 'ay',
                  candidate: ['ai', 'ay'],
                  sound: 'level4/ay_gray',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_rain.png',
                  question: 'r',
                  question2: 'n',
                  answer: 'ai',
                  candidate: ['ai', 'ay'],
                  sound: 'level4/ai_rain',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_play.png',
                  question: 'pl',
                  answer: 'ay',
                  candidate: ['ai', 'ay'],
                  sound: 'level4/ay_play',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_wait.png',
                  question: 'w',
                  question2: 't',
                  answer: 'ai',
                  candidate: ['ai', 'ay'],
                  sound: 'level4/ai_wait',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_crayon.png',
                  question: 'cr',
                  question2: 'on',
                  answer: 'ay',
                  candidate: ['ai', 'ay'],
                  sound: 'level4/ay_crayon',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_mail.png',
                  question: 'm',
                  question2: 'l',
                  answer: 'ai',
                  candidate: ['ai', 'ay'],
                  sound: 'level4/ai_mail',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_spray.png',
                  question: 'spr',
                  answer: 'ay',
                  candidate: ['ai', 'ay'],
                  sound: 'level4/ay_spray',
                },
              ],
              type: 'imgDnd',
              totalCount: 1,
            },
          },
          6: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Practice the Words II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S6_Dir_2.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_tail.png',
                  question: 't',
                  answer: 'ai',
                  answer2: 'l',
                  candidate: { 0: ['t', 'n', 'k'], 1: ['ai', 'ay'], 2: ['l', 'r', 'b'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_train.png',
                  question: 'tr',
                  answer: 'ai',
                  answer2: 'n',
                  candidate: { 0: ['tr', 'dr', 'br'], 1: ['ai', 'ay'], 2: ['n', 'm', 'l'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_clay.png',
                  question: 'cl',
                  answer: 'ay',
                  candidate: { 0: ['cl', 'gr', 'tr'], 1: ['ai', 'ay'] },
                  sound: 'clay',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_spray.png',
                  question: 'spr',
                  answer: 'ay',
                  candidate: { 0: ['spr', 'str', 'spl'], 1: ['ai', 'ay'] },
                  sound: 'spray',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_play.png',
                  question: 'pl',
                  answer: 'ay',
                  candidate: { 0: ['pl', 'gr', 'tr'], 1: ['ai', 'ay'] },
                  sound: 'play',
                },
              ],
              type: 'imgDnd2',
              hasSoundBtn: false,
              answerAbsolute: false,
              totalCount: 2,
            },
          },
          7: {
            title: 'Look and choose.',
            shortDescption: 'Practice the Words Ⅲ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S7_Dir.mp3',
            type: 'phonemeSelectAndWrite',
            problem: [
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_rain.png', word: 'r##ai##n', exPhonemes: ['ai', 'ay'] },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_wait.png', word: 'w##ai##t', exPhonemes: ['ai', 'ay'] },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_clay.png', word: 'cl##ay##', exPhonemes: ['ai', 'ay'] },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_play.png', word: 'pl##ay##', exPhonemes: ['ai', 'ay'] },
            ],
          },
          8: {
            title: 'Listen and repeat. Then record your voice.',
            shortDescption: 'Story',
            type: 'record',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S8_Dir.mp3',
            customData: {
              bgImage: 'https://cdn.cloubot.com/PM/image/l4_u6_bg_wide.jpg',
              sound: 'Lv4_U6_reading',
              sentences: ['It is a ##rainy## day.', 'Kay ##waits## for ##mail##.', 'The ##mail## man!', 'Kay gets ##crayons##, paints, and ##clay##!', 'With paints and ##crayons##,', 'Kay makes a rail.', 'With ##gray## ##clay##,', 'Kay makes a ##gray## ##train##.', 'A ##gray## ##train## on the rail!', 'A happy ##rainy## day!'],
            },
          },
          9: {
            title: 'Choose and type the word.',
            shortDescption: 'Words in the Story',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S9_Dir.mp3',
            type: 'write',
            customData: {
              type: 'imgSoundWrite',
              problem: [
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U6_S9_1.png',
                  sentenceFormat: 'Kay waits for ##word##.',
                  correctWord: 'mail',
                  wrongWord: 'tail',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U6_S9_2.png',
                  sentenceFormat: 'Kay gets crayons, paints, and ##word##.',
                  correctWord: 'clay',
                  wrongWord: 'snail',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U6_S9_3.png',
                  sentenceFormat: 'With paints and ##word##s, Kay makes a rail.',
                  correctWord: 'crayon',
                  wrongWord: 'train',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U6_S9_4.png',
                  sentenceFormat: 'With gray clay, Kay makes a ##word## train.',
                  correctWord: 'gray',
                  wrongWord: 'spray',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U6_S9_5.png',
                  sentenceFormat: 'A gray ##word## on the rail.',
                  correctWord: 'train',
                  wrongWord: 'rain',
                },
              ],
            },
          },
          10: {
            title: 'Story Chant',
            shortDescption: 'Story Chant',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S10_Dir.mp3',
            url: 'https://player.vimeo.com/video/410082429',
            type: 'video',
          },
          11: {
            title: 'Read. Then drag and drop.',
            shortDescption: 'Story Activity',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S11_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imgDnd3',
              problem: {
                question: [
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level4_unit06_SB_01.png',
                    sentence: ['A ##crayon##! Some ##clay##!', 'The children ##play##.'],
                  },
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level4_unit06_SB_02.png',
                    sentence: ['This is the ##gray## ##snail##.', 'He ##waits## all ##day##.'],
                  },
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level4_unit06_SB_03.png',
                    sentence: ['The ##rain## is wet.', 'Where is the ##train##?'],
                  },
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level4_unit06_SB_04.png',
                    sentence: ['Here is the ##tray##!', 'The ##snail## has ##mail##!', 'What a long ##day##!'],
                  },
                ],
              },
              totalCount: 4,
            },
          },
          12: {
            title: 'Listen and type the word.',
            shortDescption: 'Quiz',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S12_Dir.mp3',
            type: 'unitTest',
            problem: [
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_mail.png', word: 'mail' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_snail.png', word: 'snail' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_rain.png', word: 'rain' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_tail.png', word: 'tail' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_train.png', word: 'train' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_wait.png', word: 'wait' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_hay.png', word: 'hay' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_crayon.png', word: 'crayon' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_gray.png', word: 'gray' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_clay.png', word: 'clay' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_spray.png', word: 'spray' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_play.png', word: 'play' },
            ],
          },
        },
      },
      7: {
        title: 'Double Letters - ea · ee',
        stage: {
          1: {
            title: 'Sound Animation',
            shortDescption: 'Sound Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S1_Dir.mp3',
            url: 'https://player.vimeo.com/video/410082723',
            type: 'video',
          },
          2: {
            title: 'Drag and drop.',
            shortDescption: 'Learn the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S2_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  double: ['ea'],
                  type: 'dndType5',
                  discImage: 'https://cdn.cloubot.com/PM/image/level4/drag_unit7_1.png',
                  words: [
                    {
                      word: '##ea##t',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level4/image_eat.png',
                      sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ea_eat.mp3',
                    },
                    {
                      word: 'b##ea##ch',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level4/image_beach.png',
                      sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ea_beach.mp3',
                    },
                  ],
                },
                {
                  double: ['ee'],
                  type: 'dndType5',
                  discImage: 'https://cdn.cloubot.com/PM/image/level4/drag_unit7_2.png',
                  words: [
                    {
                      word: 'b##ee##',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level4/image_bee.png',
                      sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ee_bee.mp3',
                    },
                    {
                      word: 'sh##ee##p',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level4/image_sheep.png',
                      sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ee_sheep.mp3',
                    },
                  ],
                },
              ],
              totalCount: 2,
              type: 'alphabetDnd',
              soundBaseUrl: 'https://cdn.cloubot.com/PM/audio/sounds/level4/',
            },
          },
          3: {
            title: 'Listen and choose.',
            shortDescption: 'Practice the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S3_Dir.mp3',
            type: 'wordSelect',
            problem: [
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_eat.png',
                word: 'eat',
                exWords: ['##ea##t', 'm##ea##t', 'l##ea##f', 's##ee##d'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_beach.png',
                word: 'beach',
                exWords: ['b##ea##ch', 'cl##ea##n', 'f##ee##t', 'tr##ee##'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_sea.png',
                word: 'sea',
                exWords: ['s##ea##', 's##ee##d', 'l##ea##f', 'm##ea##t'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_bee.png',
                word: 'bee',
                exWords: ['tr##ee##', 'b##ee##', 'cl##ea##n', 's##ea##'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_sheep.png',
                word: 'sheep',
                exWords: ['gr##ee##n', 'f##ee##t', '##ea##t', 'sh##ee##p'],
              },
            ],
          },
          4: {
            title: 'Look and listen.',
            shortDescption: 'Learn the Words',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S4_Dir.mp3',
            type: 'phonemeImage',
            problem: [
              {
                phoneme: '- ##ea## -',
                word: '##ea##t',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_eat.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ea_eat.mp3',
              },
              {
                phoneme: '- ##ea## -',
                word: 'b##ea##ch',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_beach.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ea_beach.mp3',
              },
              {
                phoneme: '- ##ea## -',
                word: 's##ea##',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_sea.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ea_sea.mp3',
              },
              {
                phoneme: '- ##ea## -',
                word: 'm##ea##t',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_meat.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ea_meat.mp3',
              },
              {
                phoneme: '- ##ea## -',
                word: 'l##ea##f',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_leaf.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ea_leaf.mp3',
              },
              {
                phoneme: '- ##ea## -',
                word: 'cl##ea##n',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_clean.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ea_clean.mp3',
              },
              {
                phoneme: '- ##ee## -',
                word: 'b##ee##',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_bee.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ee_bee.mp3',
              },
              {
                phoneme: '- ##ee## -',
                word: 'sh##ee##p',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_sheep.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ee_sheep.mp3',
              },
              {
                phoneme: '- ##ee## -',
                word: 's##ee##d',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_seed.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ee_seed.mp3',
              },
              {
                phoneme: '- ##ee## -',
                word: 'tr##ee##',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_tree.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ee_tree.mp3',
              },
              {
                phoneme: '- ##ee## -',
                word: 'f##ee##t',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_feet.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ee_feet.mp3',
              },
              {
                phoneme: '- ##ee## -',
                word: 'gr##ee##n',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_green.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ee_green.mp3',
              },
            ],
          },
          5: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Practice the Words I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S5_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_beach.png',
                  question: 'b',
                  question2: 'ch',
                  answer: 'ea',
                  candidate: ['ea', 'ee'],
                  sound: 'level4/ea_beach',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_sheep.png',
                  question: 'sh',
                  question2: 'p',
                  answer: 'ee',
                  candidate: ['ea', 'ee'],
                  sound: 'level4/ee_sheep',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_leaf.png',
                  question: 'l',
                  question2: 'f',
                  answer: 'ea',
                  candidate: ['ea', 'ee'],
                  sound: 'level4/ea_leaf',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_feet.png',
                  question: 'f',
                  question2: 't',
                  answer: 'ee',
                  candidate: ['ea', 'ee'],
                  sound: 'level4/ee_feet',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_eat.png',
                  isBackEnd: true,
                  question: 't',
                  answer: 'ea',
                  candidate: ['ea', 'ee'],
                  sound: 'level4/ea_eat',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_tree.png',
                  question: 'tr',
                  answer: 'ee',
                  candidate: ['ea', 'ee'],
                  sound: 'level4/ee_tree',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_clean.png',
                  question: 'cl',
                  question2: 'n',
                  answer: 'ea',
                  candidate: ['ea', 'ee'],
                  sound: 'level4/ea_clean',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_seed.png',
                  question: 's',
                  question2: 'd',
                  answer: 'ee',
                  candidate: ['ea', 'ee'],
                  sound: 'level4/ee_seed',
                },
              ],
              type: 'imgDnd',
              totalCount: 1,
            },
          },
          6: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Practice the Words II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S6_Dir_2.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_sea.png',
                  question: 's',
                  answer: 'ea',
                  candidate: { 0: ['s', 't', 'p'], 1: ['ea', 'ee'] },
                  sound: 'sea',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_meat.png',
                  question: 'm',
                  answer: 'ea',
                  answer2: 't',
                  candidate: { 0: ['m', 'n', 'k'], 1: ['ea', 'ee'], 2: ['t', 'l', 'f'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_leaf.png',
                  question: 'l',
                  answer: 'ea',
                  answer2: 'f',
                  candidate: { 0: ['l', 't', 'k'], 1: ['ea', 'ee'], 2: ['p', 'd', 'f'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_sheep.png',
                  question: 'sh',
                  answer: 'ee',
                  answer2: 'p',
                  candidate: { 0: ['sh', 'tr', 'sp'], 1: ['ea', 'ee'], 2: ['t', 'p', 'b'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_tree.png',
                  question: 'tr',
                  answer: 'ee',
                  candidate: { 0: ['sp', 'tr', 'st'], 1: ['ea', 'ee'] },
                  sound: 'tree',
                },
              ],
              type: 'imgDnd2',
              hasSoundBtn: false,
              answerAbsolute: false,
              totalCount: 2,
            },
          },
          7: {
            title: 'Look and choose.',
            shortDescption: 'Practice the Words Ⅲ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S7_Dir.mp3',
            type: 'phonemeSelectAndWrite',
            problem: [
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_sea.png', word: 's##ea##', exPhonemes: ['ea', 'ee'] },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_clean.png', word: 'cl##ea##n', exPhonemes: ['ea', 'ee'] },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_seed.png', word: 's##ee##d', exPhonemes: ['ea', 'ee'] },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_tree.png', word: 'tr##ee##', exPhonemes: ['ea', 'ee'] },
            ],
          },
          8: {
            title: 'Listen and repeat. Then record your voice.',
            shortDescption: 'Story',
            type: 'record',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S8_Dir.mp3',
            customData: {
              bgImage: 'https://cdn.cloubot.com/PM/image/l4_u7_bg_wide.jpg',
              sound: 'Lv4_U7_reading',
              sentences: ['The queen is ##green##!', 'Her teeth are ##green##.', 'Her ##feet## are ##green##.', 'She ##eats## ##meat##.', 'Still ##green##.', 'She ##eats## ##seeds##.', 'Still ##green##.', 'She ##eats## a ##tree##.', 'Still ##green## like a ##leaf##.', 'A ##bee##! ##Eat## a ##bee##?', 'Buzzzzz…', 'The queen is red now.'],
            },
          },
          9: {
            title: 'Choose and type the word.',
            shortDescption: 'Words in the Story',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S9_Dir.mp3',
            type: 'write',
            customData: {
              type: 'imgSoundWrite',
              problem: [
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U7_S9_1.png',
                  sentenceFormat: 'The queen is ##word##.',
                  correctWord: 'green',
                  wrongWord: 'bee',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U7_S9_1.png',
                  sentenceFormat: 'Her ##word## are green.',
                  correctWord: 'feet',
                  wrongWord: 'leaf',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U7_S9_3.png',
                  sentenceFormat: 'She eats ##word##. Still green.',
                  correctWord: 'meat',
                  wrongWord: 'feet',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U7_S9_4.png',
                  sentenceFormat: 'She eats a tree. Still green like a ##word##.',
                  correctWord: 'leaf',
                  wrongWord: 'meat',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U7_S9_5.png',
                  sentenceFormat: 'A bee! Eat a ##word##? Buzzzzz…',
                  correctWord: 'bee',
                  wrongWord: 'tree',
                },
              ],
            },
          },
          10: {
            title: 'Story Chant',
            shortDescption: 'Story Chant',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S10_Dir.mp3',
            url: 'https://player.vimeo.com/video/410082462',
            type: 'video',
          },
          11: {
            title: 'Read. Then drag and drop.',
            shortDescption: 'Story Activity',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S11_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imgDnd3',
              problem: {
                question: [
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level4_unit07_SB_01.png',
                    sentence: ['My ##feet## are not ##clean##.', 'I ##see## a ##seed##.'],
                  },
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level4_unit07_SB_02.png',
                    sentence: ['Oh, no! A ##leaf##!', 'Is that a ##bean##?'],
                  },
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level4_unit07_SB_03.png',
                    sentence: ['No, it is a ##tree##.', 'A ##bee## has tea.'],
                  },
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level4_unit07_SB_04.png',
                    sentence: ['The ##bee## is ##asleep##.', '##Asleep## on my ##knee##.'],
                  },
                ],
              },
              totalCount: 4,
            },
          },
          12: {
            title: 'Listen and type the word.',
            shortDescption: 'Quiz',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S12_Dir.mp3',
            type: 'unitTest',
            problem: [
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_eat.png', word: 'eat' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_beach.png', word: 'beach' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_sea.png', word: 'sea' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_meat.png', word: 'meat' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_leaf.png', word: 'leaf' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_clean.png', word: 'clean' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_bee.png', word: 'bee' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_sheep.png', word: 'sheep' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_seed.png', word: 'seed' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_tree.png', word: 'tree' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_feet.png', word: 'feet' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_green.png', word: 'green' },
            ],
          },
        },
      },
      8: {
        title: 'Double Letters - oa · ow',
        stage: {
          1: {
            title: 'Sound Animation',
            shortDescption: 'Sound Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S1_Dir.mp3',
            url: 'https://player.vimeo.com/video/410082746',
            type: 'video',
          },
          2: {
            title: 'Drag and drop.',
            shortDescption: 'Learn the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S2_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  double: ['oa'],
                  type: 'dndType5',
                  discImage: 'https://cdn.cloubot.com/PM/image/level4/drag_unit8_1.png',
                  words: [
                    {
                      word: 'b##oa##t',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level4/image_boat.png',
                      sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/oa_boat.mp3',
                    },
                    {
                      word: 't##oa##st',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level4/image_toast.png',
                      sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/oa_toast.mp3',
                    },
                  ],
                },
                {
                  double: ['ow'],
                  type: 'dndType5',
                  discImage: 'https://cdn.cloubot.com/PM/image/level4/drag_unit8_2.png',
                  words: [
                    {
                      word: 'b##ow##l',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level4/image_bowl.png',
                      sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ow_bowl.mp3',
                    },
                    {
                      word: 'cr##ow##',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level4/image_crow.png',
                      sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ow_crow.mp3',
                    },
                  ],
                },
              ],

              totalCount: 4,
              type: 'alphabetDnd',
              soundBaseUrl: 'https://cdn.cloubot.com/PM/audio/sounds/level4/',
            },
          },
          3: {
            title: 'Listen and choose.',
            shortDescption: 'Practice the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S3_Dir.mp3',
            type: 'wordSelect',
            problem: [
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_boat.png',
                word: 'boat',
                exWords: ['b##oa##t', 'c##oa##t', 's##oa##p', 'r##oa##d'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_toast.png',
                word: 'toast',
                exWords: ['wind##ow##', 'g##oa##t', 't##oa##st', 'yell##ow##'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_soap.png',
                word: 'soap',
                exWords: ['sn##ow##', 's##oa##p', 'yell##ow##', 'cr##ow##'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_bowl.png',
                word: 'bowl',
                exWords: ['c##oa##t', 'bl##ow##', 't##oa##st', 'b##ow##l'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_crow.png',
                word: 'crow',
                exWords: ['cr##ow##', 'bl##ow##', 'wind##ow##', 'g##oa##t'],
              },
            ],
          },
          4: {
            title: 'Look and listen.',
            shortDescption: 'Learn the Words',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S4_Dir.mp3',
            type: 'phonemeImage',
            problem: [
              {
                phoneme: '- ##oa## -',
                word: 'b##oa##t',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_boat.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/oa_boat.mp3',
              },
              {
                phoneme: '- ##oa## -',
                word: 't##oa##st',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_toast.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/oa_toast.mp3',
              },
              {
                phoneme: '- ##oa## -',
                word: 's##oa##p',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_soap.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/oa_soap.mp3',
              },
              {
                phoneme: '- ##oa## -',
                word: 'c##oa##t',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_coat.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/oa_coat.mp3',
              },
              {
                phoneme: '- ##oa## -',
                word: 'g##oa##t',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_goat.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/oa_goat.mp3',
              },
              {
                phoneme: '- ##oa## -',
                word: 'r##oa##d',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_road.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/oa_road.mp3',
              },
              {
                phoneme: '- ##ow## -',
                word: 'b##ow##l',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_bowl.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ow_bowl.mp3',
              },
              {
                phoneme: '- ##ow## -',
                word: 'cr##ow##',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_crow.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ow_crow.mp3',
              },
              {
                phoneme: '- ##ow## -',
                word: 'sn##ow##',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_snow.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ow_snow.mp3',
              },
              {
                phoneme: '- ##ow## -',
                word: 'bl##ow##',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_blow.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ow_blow.mp3',
              },
              {
                phoneme: '- ##ow## -',
                word: 'wind##ow##',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_window.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ow_window.mp3',
              },
              {
                phoneme: '- ##ow## -',
                word: 'yell##ow##',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_yellow.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ow_yellow.mp3',
              },
            ],
          },
          5: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Practice the Words I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S5_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_toast.png',
                  question: 't',
                  question2: 'st',
                  answer: 'oa',
                  candidate: ['oa', 'ow'],
                  sound: 'level4/oa_toast',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_bowl.png',
                  question: 'b',
                  question2: 'l',
                  answer: 'ow',
                  candidate: ['oa', 'ow'],
                  sound: 'level4/ow_bowl',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_boat.png',
                  question: 'b',
                  question2: 't',
                  answer: 'oa',
                  candidate: ['oa', 'ow'],
                  sound: 'level4/oa_boat',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_snow.png',
                  question: 'sn',
                  answer: 'ow',
                  candidate: ['oa', 'ow'],
                  sound: 'level4/ow_snow',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_coat.png',
                  question: 'c',
                  question2: 't',
                  answer: 'oa',
                  candidate: ['oa', 'ow'],
                  sound: 'level4/oa_coat',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_blow.png',
                  question: 'bl',
                  answer: 'ow',
                  candidate: ['oa', 'ow'],
                  sound: 'level4/ow_blow',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_goat.png',
                  question: 'g',
                  question2: 't',
                  answer: 'oa',
                  candidate: ['oa', 'ow'],
                  sound: 'level4/oa_goat',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_yellow.png',
                  question: 'yell',
                  answer: 'ow',
                  candidate: ['oa', 'ow'],
                  sound: 'level4/ow_yellow',
                },
              ],
              type: 'imgDnd',
              totalCount: 1,
            },
          },
          6: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Practice the Words II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S6_Dir_2.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_coat.png',
                  question: 'c',
                  answer: 'oa',
                  answer2: 't',
                  candidate: { 0: ['c', 'b', 'p'], 1: ['oa', 'ow'], 2: ['t', 'd', 'k'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_goat.png',
                  question: 'g',
                  answer: 'oa',
                  answer2: 't',
                  candidate: { 0: ['c', 'b', 'g'], 1: ['oa', 'ow'], 2: ['t', 'd', 'k'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_road.png',
                  question: 'r',
                  answer: 'oa',
                  answer2: 'd',
                  candidate: { 0: ['r', 'b', 'p'], 1: ['oa', 'ow'], 2: ['t', 'd', 'k'] },
                },

                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_snow.png',
                  question: 'sn',
                  answer: 'ow',
                  candidate: { 0: ['sp', 'sn', 'st'], 1: ['oa', 'ow'] },
                  sound: 'snow',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_blow.png',
                  question: 'bl',
                  answer: 'ow',
                  candidate: { 0: ['sp', 'bl', 'pl'], 1: ['oa', 'ow'] },
                  sound: 'blow',
                },
              ],
              type: 'imgDnd2',
              hasSoundBtn: false,
              answerAbsolute: false,
              totalCount: 2,
            },
          },
          7: {
            title: 'Look and choose.',
            shortDescption: 'Practice the Words Ⅲ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S7_Dir.mp3',
            type: 'phonemeSelectAndWrite',
            problem: [
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_soap.png', word: 's##oa##p', exPhonemes: ['oa', 'ow'] },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_coat.png', word: 'c##oa##t', exPhonemes: ['oa', 'ow'] },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_bowl.png', word: 'b##ow##l', exPhonemes: ['oa', 'ow'] },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_snow.png', word: 'sn##ow##', exPhonemes: ['oa', 'ow'] },
            ],
          },
          8: {
            title: 'Listen and repeat. Then record your voice.',
            shortDescption: 'Story',
            type: 'record',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S8_Dir.mp3',
            customData: {
              bgImage: 'https://cdn.cloubot.com/PM/image/l4_u8_bg_wide.jpg',
              sound: 'Lv4_U8_reading',
              sentences: ['See the ##snow## on the ##road##. The ##goat## has no ##coat##.', 'The ##snow## ##blows##. The ##goat## is cold.', 'Look! A ##yellow## house with ##yellow## ##windows##.', 'The toad gives the ##goat## ##toast##.', 'The ##snow## ##blows## on the ##yellow## ##windows##.', 'The ##goat## is not cold in the ##yellow## house.'],
            },
          },
          9: {
            title: 'Choose and type the word.',
            shortDescption: 'Words in the Story',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S9_Dir.mp3',
            type: 'write',
            customData: {
              type: 'imgSoundWrite',
              problem: [
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U8_S9_1.png',
                  sentenceFormat: 'See the snow on the ##word##.',
                  correctWord: 'road',
                  wrongWord: 'soap',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U8_S9_1.png',
                  sentenceFormat: 'The goat has no ##word##.',
                  correctWord: 'coat',
                  wrongWord: 'boat',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U8_S9_1.png',
                  sentenceFormat: 'The snow ##word## s.',
                  correctWord: 'blow',
                  wrongWord: 'boat',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U8_S9_4.png',
                  sentenceFormat: 'The toad gives the goat ##word##.',
                  correctWord: 'toast',
                  wrongWord: 'soap',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U8_S9_4.png',
                  sentenceFormat: 'The snow blows on the yellow ##word## s.',
                  correctWord: 'window',
                  wrongWord: 'road',
                },
              ],
            },
          },
          10: {
            title: 'Story Chant',
            shortDescption: 'Story Chant',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S10_Dir.mp3',
            url: 'https://player.vimeo.com/video/410082492',
            type: 'video',
          },
          11: {
            title: 'Read. Then drag and drop.',
            shortDescption: 'Story Activity',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S11_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imgDnd3',
              problem: {
                question: [
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level4_unit08_SB_01.png',
                    sentence: ['My ##goat## looks out the ##window##.', '“OK, let’s go out!”'],
                  },
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level4_unit08_SB_02.png',
                    sentence: ['The cold wind ##blows##.', '“Put on your ##coat##.”'],
                  },
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level4_unit08_SB_03.png',
                    sentence: ['There is ##snow## on the ##road##.', '“Let’s go home!”'],
                  },
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level4_unit08_SB_04.png',
                    sentence: ['My ##goat## sleeps in a ##boat##.', 'He has a ##yellow## ##pillow##.', '“Sweet dreams!”'],
                  },
                ],
              },
              totalCount: 4,
            },
          },

          12: {
            title: 'Listen and type the word.',
            shortDescption: 'Quiz',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S12_Dir.mp3',
            type: 'unitTest',
            problem: [
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_boat.png', word: 'boat' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_toast.png', word: 'toast' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_soap.png', word: 'soap' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_coat.png', word: 'coat' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_goat.png', word: 'goat' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_road.png', word: 'road' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_bowl.png', word: 'bowl' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_crow.png', word: 'crow' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_snow.png', word: 'snow' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_blow.png', word: 'blow' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_window.png', word: 'window' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_yellow.png', word: 'yellow' },
            ],
          },
        },
      },
      9: {
        title: 'Double Letters - ou · ow',
        stage: {
          1: {
            title: 'Sound Animation',
            shortDescption: 'Sound Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S1_Dir.mp3',
            url: 'https://player.vimeo.com/video/410082769',
            type: 'video',
          },
          2: {
            title: 'Drag and drop.',
            shortDescption: 'Learn the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S2_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  double: ['ou'],
                  type: 'dndType5',
                  discImage: 'https://cdn.cloubot.com/PM/image/level4/drag_unit9_1.png',
                  words: [
                    {
                      word: 'h##ou##se',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level4/image_house.png',
                      sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ou_house.mp3',
                    },
                    {
                      word: 'c##ou##ch',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level4/image_couch.png',
                      sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ou_couch.mp3',
                    },
                  ],
                },
                {
                  double: ['ow'],
                  type: 'dndType5',
                  discImage: 'https://cdn.cloubot.com/PM/image/level4/drag_unit9_2.png',
                  words: [
                    {
                      word: '##ow##l',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level4/image_owl.png',
                      sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ow_owl.mp3',
                    },
                    {
                      word: 'br##ow##n',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level4/image_brown.png',
                      sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ow_brown.mp3',
                    },
                  ],
                },
              ],

              totalCount: 4,
              type: 'alphabetDnd',
              soundBaseUrl: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ou_ow/',
            },
          },
          3: {
            title: 'Listen and choose.',
            shortDescption: 'Practice the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S3_Dir.mp3',
            type: 'wordSelect',
            problem: [
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_house.png',
                word: 'house',
                exWords: ['h##ou##se', 'c##ow##', 'br##ow##n', 'm##ou##se'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_couch.png',
                word: 'couch',
                exWords: ['##ow##l', 'c##ou##ch', 'bl##ou##se', 'cr##ow##n'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_cloud.png',
                word: 'cloud',
                exWords: ['d##ow##n', 'c##ou##nt', 'c##ow##', 'cl##ou##d'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_owl.png',
                word: 'owl',
                exWords: ['cr##ow##n', '##ow##l', 'bl##ou##se', 'c##ou##ch'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_down.png',
                word: 'down',
                exWords: ['d##ow##n', 'br##ow##n', 'm##ou##se', 'cl##ow##n'],
              },
            ],
          },
          4: {
            title: 'Look and listen.',
            shortDescption: 'Learn the Words',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S4_Dir.mp3',
            type: 'phonemeImage',
            problem: [
              {
                phoneme: '- ##ou## -',
                word: 'h##ou##se',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_house.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ou_house.mp3',
              },
              {
                phoneme: '- ##ou## -',
                word: 'c##ou##ch',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_couch.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ou_couch.mp3',
              },
              {
                phoneme: '- ##ou## -',
                word: 'cl##ou##d',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_cloud.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ou_cloud.mp3',
              },
              {
                phoneme: '- ##ou## -',
                word: 'm##ou##se',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_mouse.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ou_mouse.mp3',
              },
              {
                phoneme: '- ##ou## -',
                word: 'bl##ou##se',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_blouse.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ou_blouse.mp3',
              },
              {
                phoneme: '- ##ou## -',
                word: 'c##ou##nt',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_count.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ou_count.mp3',
              },
              {
                phoneme: '- ##ow## -',
                word: '##ow##l',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_owl.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ow_owl.mp3',
              },
              {
                phoneme: '- ##ow## -',
                word: 'br##ow##n',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_brown.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ow_brown.mp3',
              },
              {
                phoneme: '- ##ow## -',
                word: 'c##ow##',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_cow.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ow_cow.mp3',
              },
              {
                phoneme: '- ##ow## -',
                word: 'cl##ow##n',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_clown.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ow_clown.mp3',
              },
              {
                phoneme: '- ##ow## -',
                word: 'cr##ow##n',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_crown.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ow_crown.mp3',
              },
              {
                phoneme: '- ##ow## -',
                word: 'd##ow##n',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_down.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/ow_down.mp3',
              },
            ],
          },
          5: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Practice the Words I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S5_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_blouse.png',
                  question: 'bl',
                  question2: 'se',
                  answer: 'ou',
                  candidate: ['ou', 'ow'],
                  sound: 'level4/ou_blouse',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_brown.png',
                  question: 'br',
                  question2: 'n',
                  answer: 'ow',
                  candidate: ['ou', 'ow'],
                  sound: 'level4/ow_brown',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_cloud.png',
                  question: 'cl',
                  question2: 'd',
                  answer: 'ou',
                  candidate: ['ou', 'ow'],
                  sound: 'level4/ou_cloud',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_cow.png',
                  question: 'c',
                  answer: 'ow',
                  candidate: ['ou', 'ow'],
                  sound: 'level4/ow_cow',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_mouse.png',
                  question: 'm',
                  question2: 'se',
                  answer: 'ou',
                  candidate: ['ou', 'ow'],
                  sound: 'level4/ou_mouse',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_clown.png',
                  question: 'cl',
                  question2: 'n',
                  answer: 'ow',
                  candidate: ['ou', 'ow'],
                  sound: 'level4/ow_clown',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_count.png',
                  question: 'c',
                  question2: 'nt',
                  answer: 'ou',
                  candidate: ['ou', 'ow'],
                  sound: 'level4/ou_count',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_down.png',
                  question: 'd',
                  question2: 'n',
                  answer: 'ow',
                  candidate: ['ou', 'ow'],
                  sound: 'level4/ow_down',
                },
              ],
              type: 'imgDnd',
              totalCount: 1,
            },
          },
          6: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Practice the Words II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S6_Dir_2.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_mouse.png',
                  question: 'm',
                  answer: 'ou',
                  answer2: 'se',
                  candidate: { 0: ['m', 'n', 'k'], 1: ['ou', 'ow'], 2: ['se', 'te', 'ce'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_blouse.png',
                  question: 'bl',
                  answer: 'ou',
                  answer2: 'se',
                  candidate: { 0: ['bl', 'cl', 'kl'], 1: ['ou', 'ow'], 2: ['se', 'ce', 'de'] },
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_count.png',
                  question: 'c',
                  answer: 'ou',
                  answer2: 'nt',
                  candidate: { 0: ['c', 'k', 'j'], 1: ['ou', 'ow'], 2: ['nt', 'mt', 'nd'] },
                },

                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_cow.png',
                  question: 'c',
                  answer: 'ow',
                  candidate: { 0: ['c', 'k', 'l'], 1: ['oa', 'ow'] },
                  sound: 'cow',
                },

                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_down.png',
                  question: 'd',
                  answer: 'ow',
                  answer2: 'n',
                  candidate: { 0: ['c', 'd', 't'], 1: ['ou', 'ow'], 2: ['n', 'm', 'b'] },
                },
              ],
              type: 'imgDnd2',
              hasSoundBtn: false,
              answerAbsolute: false,
              totalCount: 2,
            },
          },
          7: {
            title: 'Look and choose.',
            shortDescption: 'Practice the Words Ⅲ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S7_Dir.mp3',
            type: 'phonemeSelectAndWrite',
            problem: [
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_couch.png', word: 'c##ou##ch', exPhonemes: ['ou', 'ow'] },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_count.png', word: 'c##ou##nt', exPhonemes: ['ou', 'ow'] },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_owl.png', word: '##ow##l', exPhonemes: ['ou', 'ow'] },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_down.png', word: 'd##ow##n', exPhonemes: ['ou', 'ow'] },
            ],
          },
          8: {
            title: 'Listen and repeat. Then record your voice.',
            shortDescption: 'Story',
            type: 'record',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S8_Dir.mp3',
            customData: {
              bgImage: 'https://cdn.cloubot.com/PM/image/l4_u9_bg_wide.jpg',
              sound: 'Lv4_U9_reading',
              sentences: ['The ##owl## has a ##house##', 'in the ##clouds##.', 'All is ##cloud## in the ##owl## ##house##.', 'A ##cloud## ##blouse##.', 'A ##cloud## ##couch##.', 'A ##cloud## ##mouse##.', 'A ##brown## ##cow##.', 'Not a ##cloud## ##cow##!', '##Down##.', '##Down##.', '##Down##.', 'The ##brown## ##cow## is', 'on the ground now.'],
            },
          },
          9: {
            title: 'Choose and type the word.',
            shortDescption: 'Words in the Story',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S9_Dir.mp3',
            type: 'write',
            customData: {
              type: 'imgSoundWrite',
              problem: [
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U9_S9_1.png',
                  sentenceFormat: 'The owl has a house in the ##word## s.',
                  correctWord: 'cloud',
                  wrongWord: 'crown',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U9_S9_1.png',
                  sentenceFormat: 'All is cloud in the owl ##word##.',
                  correctWord: 'house',
                  wrongWord: 'blouse',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U9_S9_3.png',
                  sentenceFormat: 'A cloud couch. A cloud ##word##.',
                  correctWord: 'mouse',
                  wrongWord: 'crown',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U9_S9_3.png',
                  sentenceFormat: 'A brown cow. Not a ##word## cow!',
                  correctWord: 'cloud',
                  wrongWord: 'clown',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U9_S9_5.png',
                  sentenceFormat: 'The brown ##word## is on the ground now.',
                  correctWord: 'cow',
                  wrongWord: 'owl',
                },
              ],
            },
          },
          10: {
            title: 'Story Chant',
            shortDescption: 'Story Chant',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S10_Dir.mp3',
            url: 'https://player.vimeo.com/video/410082514',
            type: 'video',
          },
          11: {
            title: 'Read. Then drag and drop.',
            shortDescption: 'Story Activity',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S11_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imgDnd3',
              problem: {
                question: [
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level4_unit09_SB_01.png',
                    sentence: ['Your ##cow## is so ##loud##!', 'Get ##out## of my ##house##!'],
                  },
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level4_unit09_SB_02.png',
                    sentence: ['Here is a ##tower##!', '##Count## one, two, three.'],
                  },
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level4_unit09_SB_03.png',
                    sentence: ['There is a ##brown## ##mouse##.', 'There is an ##owl##.'],
                  },
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level4_unit09_SB_04.png',
                    sentence: ['There is an old ##couch##.', 'It is soft like a ##cloud##.'],
                  },
                ],
              },
              totalCount: 4,
            },
          },
          12: {
            title: 'Listen and type the word.',
            shortDescption: 'Quiz',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S12_Dir.mp3',
            type: 'unitTest',

            problem: [
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_house.png', word: 'house' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_couch.png', word: 'couch' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_cloud.png', word: 'cloud' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_mouse.png', word: 'mouse' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_blouse.png', word: 'blouse' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_count.png', word: 'count' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_owl.png', word: 'owl' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_brown.png', word: 'brown' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_cow.png', word: 'cow' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_clown.png', word: 'clown' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_crown.png', word: 'crown' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_down.png', word: 'down' },
            ],
          },
        },
      },
      10: {
        title: 'long/short oo',
        stage: {
          1: {
            title: 'Sound Animation',
            shortDescption: 'Sound Animation',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S1_Dir.mp3',
            url: 'https://player.vimeo.com/video/410082801',
            type: 'video',
          },
          2: {
            title: 'Drag and drop.',
            shortDescption: 'Learn the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S2_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  long: ['oo'],
                  short: ['oo'],
                  type: 'dndType7',
                  words: [
                    {
                      word: 'm##oo##n',
                      discImage: 'https://cdn.cloubot.com/PM/image/level4/drag_unit10_1.png',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level4/image_moon.png',
                      sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/oo_moon.mp3',
                    },
                    {
                      word: 'sp##oo##n',
                      discImage: 'https://cdn.cloubot.com/PM/image/level4/drag_unit10_1.png',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level4/image_spoon.png',
                      sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/oo_spoon.mp3',
                    },
                    {
                      word: 'b##oo##k',
                      discImage: 'https://cdn.cloubot.com/PM/image/level4/drag_unit10_2.png',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level4/image_book.png',
                      sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/oo_book.mp3',
                    },
                    {
                      word: 'f##oo##t',
                      discImage: 'https://cdn.cloubot.com/PM/image/level4/drag_unit10_2.png',
                      wordImage: 'https://cdn.cloubot.com/PM/image/level4/image_foot.png',
                      sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/oo_foot.mp3',
                    },
                  ],
                },
              ],

              totalCount: 2,
              type: 'alphabetDnd',
              soundBaseUrl: 'https://cdn.cloubot.com/PM/audio/sounds/level4/',
            },
          },
          3: {
            title: 'Listen and choose.',
            shortDescption: 'Practice the Sounds',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S3_Dir.mp3',
            type: 'wordSelect',
            problem: [
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_moon.png',
                word: 'moon',
                exWords: ['m##oo##n', 'sch##oo##l', 'm##oo##se', 'z##oo##'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_spoon.png',
                word: 'spoon',
                exWords: ['f##oo##t', 'b##oo##k', 'c##oo##k', 'sp##oo##n'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_food.png',
                word: 'food',
                exWords: ['w##oo##d', 'f##oo##d', 'c##oo##kie', 'sch##oo##l'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_hook.png',
                word: 'hook',
                exWords: ['h##oo##k', 'z##oo##', 'f##oo##t', 'm##oo##n'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_cookie.png',
                word: 'cookie',
                exWords: ['f##oo##d', 'm##oo##se', 'c##oo##kie', 'b##oo##k'],
              },
            ],
          },
          4: {
            title: 'Look and listen.',
            shortDescption: 'Learn the Words',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S4_Dir.mp3',
            type: 'phonemeImage',
            problem: [
              {
                phoneme: 'long|- ##oo## -',
                word: 'm##oo##n',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_moon.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/oo_moon.mp3',
              },
              {
                phoneme: 'long|- ##oo## -',
                word: 'sp##oo##n',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_spoon.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/oo_spoon.mp3',
              },
              {
                phoneme: 'long|- ##oo## -',
                word: 'f##oo##d',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_food.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/oo_food.mp3',
              },
              {
                phoneme: 'long|- ##oo## -',
                word: 'z##oo##',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_zoo.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/oo_zoo.mp3',
              },
              {
                phoneme: 'long|- ##oo## -',
                word: 'm##oo##se',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_moose.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/oo_moose.mp3',
              },
              {
                phoneme: 'long|- ##oo## -',
                word: 'sch##oo##l',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_school.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/oo_school.mp3',
              },
              {
                phoneme: 'short|- ##oo## -',
                word: 'b##oo##k',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_book.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/oo_book.mp3',
              },
              {
                phoneme: 'short|- ##oo## -',
                word: 'f##oo##t',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_foot.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/oo_foot.mp3',
              },
              {
                phoneme: 'short|- ##oo## -',
                word: 'c##oo##k',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_cook.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/oo_cook.mp3',
              },
              {
                phoneme: 'short|- ##oo## -',
                word: 'w##oo##d',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_wood.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/oo_wood.mp3',
              },
              {
                phoneme: 'short|- ##oo## -',
                word: 'h##oo##k',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_hook.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/oo_hook.mp3',
              },
              {
                phoneme: 'short|- ##oo## -',
                word: 'c##oo##kie',
                image: 'https://cdn.cloubot.com/PM/image/level4/image_cookie.png',
                sound: 'https://cdn.cloubot.com/PM/audio/sounds/level4/oo_cookie.mp3',
              },
            ],
          },
          5: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Practice the Words I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S5_Dir.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_moose.png',
                  question: 'm',
                  question2: 'se',
                  answer: 'long-oo',
                  candidate: ['long-oo', 'short-oo'],
                  sound: 'level4/oo_moose',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_foot.png',
                  question: 'f',
                  question2: 't',
                  answer: 'short-oo',
                  candidate: ['long-oo', 'short-oo'],
                  sound: 'level4/oo_foot',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_spoon.png',
                  question: 'sp',
                  question2: 'n',
                  answer: 'long-oo',
                  candidate: ['long-oo', 'short-oo'],
                  sound: 'level4/oo_spoon',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_cook.png',
                  question: 'c',
                  question2: 'k',
                  answer: 'short-oo',
                  candidate: ['long-oo', 'short-oo'],
                  sound: 'level4/oo_cook',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_zoo.png',
                  question: 'z',
                  answer: 'long-oo',
                  candidate: ['long-oo', 'short-oo'],
                  sound: 'level4/oo_zoo',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_book.png',
                  question: 'b',
                  question2: 'k',
                  answer: 'short-oo',
                  candidate: ['long-oo', 'short-oo'],
                  sound: 'level4/oo_book',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_school.png',
                  question: 'sch',
                  question2: 'l',
                  answer: 'long-oo',
                  candidate: ['long-oo', 'short-oo'],
                  sound: 'level4/oo_school',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_wood.png',
                  question: 'w',
                  question2: 'd',
                  answer: 'short-oo',
                  candidate: ['long-oo', 'short-oo'],
                  sound: 'level4/oo_wood',
                },
              ],
              type: 'imgDnd',
              totalCount: 1,
            },
          },
          6: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Practice the Words II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S6_Dir_2.mp3',
            type: 'dnd',
            customData: {
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_moon.png',
                  question: 'm',
                  answer: 'long-oo',
                  answer2: 'n',
                  candidate: { 0: ['m', 't', 'k'], 1: ['long-oo', 'short-oo'], 2: ['n', 'l', 'b'] },
                  sound: 'moon',
                },

                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_zoo.png',
                  question: 'z',
                  answer: 'long-oo',
                  candidate: { 0: ['z', 'j', 'd'], 1: ['long-oo', 'short-oo'] },
                  sound: 'zoo',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_cook.png',
                  question: 'c',
                  answer: 'short-oo',
                  answer2: 'k',
                  candidate: { 0: ['c', 'd', 'p'], 1: ['long-oo', 'short-oo'], 2: ['k', 'g', 'b'] },
                  sound: 'cook',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_wood.png',
                  question: 'w',
                  answer: 'short-oo',
                  answer2: 'd',
                  candidate: { 0: ['w', 'u', 'v'], 1: ['long-oo', 'short-oo'], 2: ['d', 't', 'b'] },
                  sound: 'wood',
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_hook.png',
                  question: 'h',
                  answer: 'short-oo',
                  answer2: 'k',
                  candidate: { 0: ['h', 'y', 'p'], 1: ['long-oo', 'short-oo'], 2: ['k', 'g', 'x'] },
                  sound: 'hook',
                },
              ],
              type: 'imgDnd2',
              hasSoundBtn: false,
              answerAbsolute: false,
              totalCount: 2,
            },
          },
          7: {
            title: 'Look and choose.',
            shortDescption: 'Practice the Words Ⅲ',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S7_Dir.mp3',
            type: 'phonemeSelectAndWrite',
            problem: [
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_moon.png',
                word: 'm##oo##n',
                exPhonemes: ['long-oo', 'short-oo'],
                answer: 'long-oo',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_zoo.png',
                word: 'z##oo##',
                exPhonemes: ['long-oo', 'short-oo'],
                answer: 'long-oo',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_foot.png',
                word: 'f##oo##t',
                exPhonemes: ['long-oo', 'short-oo'],
                answer: 'short-oo',
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_cookie.png',
                word: 'c##oo##kie',
                exPhonemes: ['long-oo', 'short-oo'],
                answer: 'short-oo',
              },
            ],
          },
          8: {
            title: 'Listen and repeat. Then record your voice.',
            shortDescption: 'Story',
            type: 'record',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S8_Dir.mp3',
            customData: {
              bgImage: 'https://cdn.cloubot.com/PM/image/l4_u10_bg_wide.jpg',
              sound: 'Lv4_U10_reading',
              sentences: ['A ##moose## reads a ##book##.', 'It’s about the ##moon##.', 'Yes! To the ##moon##!', 'He takes ##food## and a ##spoon##.', 'The ##moose## goes to ##school##.', 'He goes to the ##zoo##.', 'He goes to the ##woods##.', 'But he can’t go to the ##moon##.', 'A ##hook##? Yes!', 'The ##moose## goes', 'to the ##moon##!'],
            },
          },
          9: {
            title: 'Choose and type the word.',
            shortDescption: 'Words in the Story',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S9_Dir.mp3',
            type: 'write',
            customData: {
              type: 'imgSoundWrite',
              problem: [
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U6_S10_1.png',
                  sentenceFormat: 'A mouse reads a ##word##.',
                  correctWord: 'book',
                  wrongWord: 'food',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U6_S10_2.png',
                  sentenceFormat: "It's about the ##word##.",
                  correctWord: 'moon',
                  wrongWord: 'hook',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U6_S10_3.png',
                  sentenceFormat: 'He takes food and a ##word##.',
                  correctWord: 'spoon',
                  wrongWord: 'book',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U6_S10_4.png',
                  sentenceFormat: 'The moose goes to ##word##.',
                  correctWord: 'school',
                  wrongWord: 'wood',
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/L4_U6_S10_5.png',
                  sentenceFormat: 'He goes to the ##word## s.',
                  correctWord: 'wood',
                  wrongWord: 'cook',
                },
              ],
            },
          },
          10: {
            title: 'Story Chant',
            shortDescption: 'Story Chant',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S10_Dir.mp3',
            url: 'https://player.vimeo.com/video/410082541',
            type: 'video',
          },
          11: {
            title: 'Read. Then drag and drop.',
            shortDescption: 'Story Activity',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S11_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imgDnd3',
              problem: {
                question: [
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level4_unit10_SB_01.png',
                    sentence: ['This ##moose## can ##cook##!', 'Here is his ##cook####book##!'],
                  },
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level4_unit10_SB_02.png',
                    sentence: ['The ##moon## is full.', 'The ##spoon## is full!'],
                  },
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level4_unit10_SB_03.png',
                    sentence: ['What is this ##food##?', 'It ##looks## so ##good##!'],
                  },
                  {
                    image: 'https://cdn.cloubot.com/PM/image/level4_unit10_SB_04.png',
                    sentence: ['##Goody##. Oh, ##goody##!', 'A big, big ##cookie##!'],
                  },
                ],
              },
              totalCount: 4,
            },
          },
          12: {
            title: 'Listen and type the word.',
            shortDescption: 'Quiz',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_S12_Dir.mp3',
            type: 'unitTest',
            problem: [
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_moon.png', word: 'moon' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_spoon.png', word: 'spoon' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_food.png', word: 'food' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_zoo.png', word: 'zoo' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_moose.png', word: 'moose' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_school.png', word: 'school' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_book.png', word: 'book' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_foot.png', word: 'foot' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_cook.png', word: 'cook' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_wood.png', word: 'wood' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_hook.png', word: 'hook' },
              { image: 'https://cdn.cloubot.com/PM/image/level4/image_cookie.png', word: 'cookie' },
            ],
          },
        },
      },
      R: {
        title: 'Review unit6~unit10',
        stage: {
          1: {
            title: 'Listen and choose.',
            shortDescption: 'Review the Sounds I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_Review_S1_Dir.mp3',
            type: 'wordSelect',
            problem: [
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_snail.png',
                word: 'snail',
                questionFormat: 'sn##ai##l',
                answer: 'ai',
                exWords: ['ai', 'ay', 'ea', 'ee'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_spray.png',
                word: 'spray',
                questionFormat: 'spr##ay##',
                answer: 'ay',
                exWords: ['ai', 'ay', 'ea', 'ee'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_beach.png',
                word: 'beach',
                questionFormat: 'b##ea##ch',
                answer: 'ea',
                exWords: ['ai', 'ay', 'ea', 'ee'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_sheep.png',
                word: 'sheep',
                questionFormat: 'sh##ee##p',
                answer: 'ee',
                exWords: ['ai', 'ay', 'ea', 'ee'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_boat.png',
                word: 'boat',
                questionFormat: 'b##oa##t',
                answer: 'oa',
                exWords: ['oa', 'ow', 'ou', 'ai'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_clown.png',
                word: 'clown',
                questionFormat: 'cl##ow##n',
                answer: 'ow',
                exWords: ['oa', 'ow', 'ou', 'ay'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_meat.png',
                word: 'meat',
                questionFormat: 'm##ea##t',
                answer: 'ea',
                exWords: ['oa', 'ow', 'ou', 'ea'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_window.png',
                word: 'window',
                questionFormat: 'wind##ow##',
                answer: 'ow',
                exWords: ['oa', 'ow', 'ou', 'ee'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_food.png',
                word: 'food',
                questionFormat: 'f@oo@d',
                answer: 'long oo',
                exWords: ['long oo', 'short oo'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_foot.png',
                word: 'foot',
                questionFormat: 'f@oo@t',
                answer: 'short oo',
                exWords: ['long oo', 'short oo'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_hook.png',
                word: 'hook',
                questionFormat: 'h@oo@k',
                answer: 'short oo',
                exWords: ['long oo', 'short oo'],
              },
              {
                image: 'https://cdn.cloubot.com/PM/image/level4/image_spoon.png',
                word: 'spoon',
                questionFormat: 'sp@oo@n',
                answer: 'long oo',
                exWords: ['long oo', 'short oo'],
              },
            ],
          },
          2: {
            title: 'Look. Then drag and drop.',
            shortDescption: 'Review the Words I',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_Review_S2_Dir.mp3',
            type: 'dnd',
            customData: {
              type: 'imgDnd4',
              problem: [
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_clean.png',
                  questionFormat: '##cl## ea ##n##',
                  rightWord: 'clean',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level4/image_green.png',
                    'https://cdn.cloubot.com/PM/image/level4/image_rain.png',
                    'https://cdn.cloubot.com/PM/image/level4/image_clean.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_toast.png',
                  questionFormat: '##t## oa ##st##',
                  rightWord: 'toast',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level4/image_blow.png',
                    'https://cdn.cloubot.com/PM/image/level4/image_toast.png',
                    'https://cdn.cloubot.com/PM/image/level4/image_house.png',
                  ],
                },

                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_down.png',
                  questionFormat: '##d## ow ##n##',
                  rightWord: 'down',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level4/image_mouse.png',
                    'https://cdn.cloubot.com/PM/image/level4/image_down.png',
                    'https://cdn.cloubot.com/PM/image/level4/image_feet.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_hay.png',
                  questionFormat: '##h##ay',
                  rightWord: 'hay',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level4/image_hay.png',
                    'https://cdn.cloubot.com/PM/image/level4/image_tree.png',
                    'https://cdn.cloubot.com/PM/image/level4/image_cow.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_train.png',
                  questionFormat: '##tr## ai ##n##',
                  rightWord: 'train',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level4/image_crayon.png',
                    'https://cdn.cloubot.com/PM/image/level4/image_seed.png',
                    'https://cdn.cloubot.com/PM/image/level4/image_train.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_leaf.png',
                  questionFormat: '##l## ea ##f##',
                  rightWord: 'leaf',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level4/image_cloud.png',
                    'https://cdn.cloubot.com/PM/image/level4/image_sheep.png',
                    'https://cdn.cloubot.com/PM/image/level4/image_leaf.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_meat.png',
                  questionFormat: '##m## ea ##t##',
                  rightWord: 'meat',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level4/image_green.png',
                    'https://cdn.cloubot.com/PM/image/level4/image_meat.png',
                    'https://cdn.cloubot.com/PM/image/level4/image_couch.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_snow.png',
                  questionFormat: '##sn##ow',
                  rightWord: 'snow',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level4/image_sea.png',
                    'https://cdn.cloubot.com/PM/image/level4/image_snow.png',
                    'https://cdn.cloubot.com/PM/image/level4/image_play.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_zoo.png',
                  questionFormat: '##z## oo',
                  rightWord: 'zoo',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level4/image_wood.png',
                    'https://cdn.cloubot.com/PM/image/level4/image_cook.png',
                    'https://cdn.cloubot.com/PM/image/level4/image_zoo.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_cookie.png',
                  questionFormat: '##c## oo ##kie##',
                  rightWord: 'cookie',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level4/image_spoon.png',
                    'https://cdn.cloubot.com/PM/image/level4/image_moon.png',
                    'https://cdn.cloubot.com/PM/image/level4/image_cookie.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_book.png',
                  questionFormat: '##b## oo ##k##',
                  rightWord: 'book',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level4/image_moon.png',
                    'https://cdn.cloubot.com/PM/image/level4/image_book.png',
                    'https://cdn.cloubot.com/PM/image/level4/image_zoo.png',
                  ],
                },
                {
                  url: 'https://cdn.cloubot.com/PM/image/level4/image_moose.png',
                  questionFormat: '##m## oo ##se##',
                  rightWord: 'moose',
                  candidate: [
                    'https://cdn.cloubot.com/PM/image/level4/image_moose.png',
                    'https://cdn.cloubot.com/PM/image/level4/image_hook.png',
                    'https://cdn.cloubot.com/PM/image/level4/image_foot.png',
                  ],
                },
              ],
            },
          },
          3: {
            title: 'Look and listen. Then choose.',
            shortDescption: ' Review the Words II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_Review_S3_Dir_b.mp3',
            type: 'imgSelect',
            customData: {
              type: 'imgSelectImg',
              problem: [
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level4/image_leaf.png',
                  qWord: 'leaf',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_meat.png', word: 'meat', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_rain.png', word: 'rain', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_clay.png', word: 'clay', isAnswer: false },
                  ],
                },
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level4/image_soap.png',
                  qWord: 'soap',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_couch.png', word: 'couch', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_goat.png', word: 'goat', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_spray.png', word: 'spray', isAnswer: false },
                  ],
                },
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level4/image_crown.png',
                  qWord: 'crown',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_wait.png', word: 'wait', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_sheep.png', word: 'sheep', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_brown.png', word: 'brown', isAnswer: true },
                  ],
                },
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level4/image_tail.png',
                  qWord: 'tail',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_bowl.png', word: 'bowl', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_sea.png', word: 'sea', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_snail.png', word: 'snail', isAnswer: true },
                  ],
                },
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level4/image_road.png',
                  qWord: 'road',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_clay.png', word: 'clay', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_coat.png', word: 'coat', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_house.png', word: 'house', isAnswer: false },
                  ],
                },
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level4/image_blow.png',
                  qWord: 'blow',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_mail.png', word: 'mail', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_sheep.png', word: 'sheep', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_crow.png', word: 'crow', isAnswer: true },
                  ],
                },
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level4/image_bee.png',
                  qWord: 'bee',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_play.png', word: 'play', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_feet.png', word: 'feet', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_count.png', word: 'count', isAnswer: false },
                  ],
                },
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level4/image_snow.png',
                  qWord: 'snow',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_blow.png', word: 'blow', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_clean.png', word: 'clean', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_blouse.png', word: 'blouse', isAnswer: false },
                  ],
                },
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level4/image_school.png',
                  qWord: 'school',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_zoo.png', word: 'zoo', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_cook.png', word: 'cook', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_book.png', word: 'book', isAnswer: false },
                  ],
                },
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level4/image_wood.png',
                  qWord: 'wood',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_moose.png', word: 'moose', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_moon.png', word: 'moon', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_foot.png', word: 'foot', isAnswer: true },
                  ],
                },
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level4/image_spoon.png',
                  qWord: 'spoon',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_cookie.png', word: 'cookie', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_book.png', word: 'book', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_moon.png', word: 'moon', isAnswer: true },
                  ],
                },
                {
                  qImage: 'https://cdn.cloubot.com/PM/image/level4/image_cook.png',
                  qWord: 'cook',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_moose.png', word: 'moose', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_hook.png', word: 'hook', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_zoo.png', word: 'zoo', isAnswer: false },
                  ],
                },
              ],
            },
          },
          4: {
            title: 'Look and choose.',
            shortDescption: 'Word Puzzle',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_Review_S4_Dir.mp3',
            type: 'crossWords',
            customData: {
              imageBaseUrl: 'https://cdn.cloubot.com/PM/image/level4/',
              problem: [
                {
                  words: [
                    {
                      name: 'couch',
                      xy: [
                        [1, 1],
                        [2, 1],
                        [3, 1],
                        [4, 1],
                        [5, 1],
                      ],
                    },
                    {
                      name: 'wood',
                      xy: [
                        [1, 2],
                        [2, 2],
                        [3, 2],
                        [4, 2],
                      ],
                    },
                    {
                      name: 'sheep',
                      xy: [
                        [5, 0],
                        [5, 1],
                        [5, 2],
                        [5, 3],
                        [5, 4],
                      ],
                    },
                    {
                      name: 'tail',
                      xy: [
                        [2, 6],
                        [3, 6],
                        [4, 6],
                        [5, 6],
                      ],
                    },
                    {
                      name: 'play',
                      xy: [
                        [0, 0],
                        [1, 0],
                        [2, 0],
                        [3, 0],
                      ],
                    },
                    {
                      name: 'meat',
                      xy: [
                        [6, 2],
                        [6, 3],
                        [6, 4],
                        [6, 5],
                      ],
                    },
                    {
                      name: 'goat',
                      xy: [
                        [0, 4],
                        [1, 4],
                        [2, 4],
                        [3, 4],
                      ],
                    },
                    {
                      name: 'crown',
                      xy: [
                        [0, 5],
                        [1, 5],
                        [2, 5],
                        [3, 5],
                        [4, 5],
                      ],
                    },
                  ],
                  coords: [
                    ['p', 'l', 'a', 'y', 'n', 's', 'l'],
                    ['c', 'c', 'o', 'u', 'c', 'h', 'c'],
                    ['e', 'w', 'o', 'o', 'd', 'e', 'm'],
                    ['a', 'i', 't', 'a', 'u', 'e', 'e'],
                    ['g', 'o', 'a', 't', 'o', 'p', 'a'],
                    ['c', 'r', 'o', 'w', 'n', 'b', 't'],
                    ['c', 'o', 't', 'a', 'i', 'l', 't'],
                    ['d', 'd', 'y', 's', 'z', 'o', 'e'],
                  ],
                },
                {
                  words: [
                    {
                      name: 'cloud',
                      xy: [
                        [1, 1],
                        [2, 1],
                        [3, 1],
                        [4, 1],
                        [5, 1],
                      ],
                    },
                    {
                      name: 'sea',
                      xy: [
                        [3, 3],
                        [3, 4],
                        [3, 5],
                      ],
                    },
                    {
                      name: 'rain',
                      xy: [
                        [1, 7],
                        [2, 7],
                        [3, 7],
                        [4, 7],
                      ],
                    },
                    {
                      name: 'owl',
                      xy: [
                        [0, 6],
                        [1, 6],
                        [2, 6],
                      ],
                    },
                    {
                      name: 'foot',
                      xy: [
                        [0, 0],
                        [0, 1],
                        [0, 2],
                        [0, 3],
                      ],
                    },
                    {
                      name: 'crow',
                      xy: [
                        [1, 1],
                        [1, 2],
                        [1, 3],
                        [1, 4],
                      ],
                    },
                    {
                      name: 'soap',
                      xy: [
                        [3, 3],
                        [4, 3],
                        [5, 3],
                        [6, 3],
                      ],
                    },
                    {
                      name: 'seed',
                      xy: [
                        [3, 0],
                        [4, 0],
                        [5, 0],
                        [6, 0],
                      ],
                    },
                  ],
                  coords: [
                    ['f', 's', 'a', 's', 'e', 'e', 'd'],
                    ['o', 'c', 'l', 'o', 'u', 'd', 't'],
                    ['o', 'r', 'r', 'w', 'l', 'k', 's'],
                    ['t', 'o', 'f', 's', 'o', 'a', 'p'],
                    ['y', 'w', 's', 'e', 't', 'l', 'o'],
                    ['c', 'm', 'd', 'a', 'n', 'r', 'u'],
                    ['o', 'w', 'l', 'k', 'l', 'e', 'n'],
                    ['a', 'r', 'a', 'i', 'n', 'a', 'p'],
                  ],
                },
              ],
            },
          },
          5: {
            title: 'Look at the picture. Choose the picture that has different sound. (Rhyme)',
            shortDescption: ' Review the Sounds II',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_Review_S5_Dir.mp3',
            type: 'imgSelect',
            customData: {
              type: 'imgSelectWord', //imgSelectImg, imgSelectWord
              problem: [
                {
                  qWord: 'ai',
                  direction: 'none',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_feet.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_tail.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_wait.png', isAnswer: false },
                  ],
                },
                {
                  qWord: 'ay',
                  direction: 'none',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_hay.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_green.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_clay.png', isAnswer: false },
                  ],
                },
                {
                  qWord: 'ea',
                  direction: 'none',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_eat.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_beach.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_mail.png', isAnswer: true },
                  ],
                },
                {
                  qWord: 'ee',
                  direction: 'none',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_tree.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_sheep.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_play.png', isAnswer: true },
                  ],
                },
                {
                  qWord: 'oa',
                  direction: 'both',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_toast.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_crown.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_road.png', isAnswer: false },
                  ],
                },
                {
                  qWord: 'ow',
                  direction: 'none',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_yellow.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_snow.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_house.png', isAnswer: true },
                  ],
                },
                {
                  qWord: 'ou',
                  direction: 'both',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_count.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_boat.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_couch.png', isAnswer: false },
                  ],
                },
                {
                  qWord: 'ow',
                  direction: 'none',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_meat.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_cow.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_down.png', isAnswer: false },
                  ],
                },
                {
                  qWord: 'long OO',
                  direction: 'none',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_hook.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_school.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_zoo.png', isAnswer: false },
                  ],
                },
                {
                  qWord: 'short OO',
                  direction: 'none',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_book.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_foot.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_zoo.png', isAnswer: true },
                  ],
                },
                {
                  qWord: 'long OO',
                  direction: 'none',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_food.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_moon.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_cook.png', isAnswer: true },
                  ],
                },
                {
                  qWord: 'short OO',
                  direction: 'none',
                  exImage: [
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_foot.png', isAnswer: false },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_spoon.png', isAnswer: true },
                    { image: 'https://cdn.cloubot.com/PM/image/level4/image_cookie.png', isAnswer: false },
                  ],
                },
              ],
            },
          },
          6: {
            title: 'Listen and choose the vowel sound.',
            shortDescption: 'Review the Words III',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_Review2_S6_Dir.mp3',
            type: 'imgSelect',
            customData: {
              type: 'selectWord',
              problem: [
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/image_house.png',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/house.mp3',
                  words: [
                    { name: 'oa', isAnswer: false },
                    { name: 'oo', isAnswer: false },
                    { name: 'ou', isAnswer: true },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/image_cloud.png',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/cloud.mp3',
                  words: [
                    { name: 'ai', isAnswer: false },
                    { name: 'ou', isAnswer: true },
                    { name: 'ei', isAnswer: false },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/image_coat.png',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/coat.mp3',
                  words: [
                    { name: 'oa', isAnswer: true },
                    { name: 'ai', isAnswer: false },
                    { name: 'oo', isAnswer: false },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/image_meat.png',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/meat.mp3',
                  words: [
                    { name: 'ea', isAnswer: true },
                    { name: 'ai', isAnswer: false },
                    { name: 'oa', isAnswer: false },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/image_sheep.png',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/sheep.mp3',
                  words: [
                    { name: 'ay', isAnswer: false },
                    { name: 'ee', isAnswer: true },
                    { name: 'ou', isAnswer: false },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/image_play.png',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/play.mp3',
                  words: [
                    { name: 'oa', isAnswer: false },
                    { name: 'ea', isAnswer: false },
                    { name: 'ay', isAnswer: true },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/image_bowl.png',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/bowl.mp3',
                  words: [
                    { name: 'ee', isAnswer: false },
                    { name: 'ay', isAnswer: false },
                    { name: 'ow', isAnswer: true },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/image_count.png',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/count.mp3',
                  words: [
                    { name: 'ou', isAnswer: true },
                    { name: 'ea', isAnswer: false },
                    { name: 'oo', isAnswer: false },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/image_brown.png',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/brown.mp3',
                  words: [
                    { name: 'ow', isAnswer: true },
                    { name: 'ay', isAnswer: false },
                    { name: 'ee', isAnswer: false },
                  ],
                },

                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/image_toast.png',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/toast.mp3',
                  words: [
                    { name: 'ou', isAnswer: false },
                    { name: 'ai', isAnswer: false },
                    { name: 'oa', isAnswer: true },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/image_school.png',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/school.mp3',
                  words: [
                    { name: 'long OO', isAnswer: true },
                    { name: 'short OO', isAnswer: false },
                  ],
                },
                {
                  image: 'https://cdn.cloubot.com/PM/image/level4/image_hook.png',
                  sound: 'https://cdn.cloubot.com/PM/audio/sounds/hook.mp3',
                  words: [
                    { name: 'long OO', isAnswer: false },
                    { name: 'short OO', isAnswer: true },
                  ],
                },
              ],
            },
          },
          7: {
            title: 'Look, choose, and type.',
            shortDescption: 'Review the Sounds III',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_Review_S7_Dir.mp3',
            type: 'write',
            customData: {
              type: 'imageWordSelectWrite',
              problem: [
                { image: 'https://cdn.cloubot.com/PM/image/level4/image_soap.png', answer: 'soap', exWords: ['soap', 'soup'] },
                { image: 'https://cdn.cloubot.com/PM/image/level4/image_moon.png', answer: 'moon', exWords: ['moon', 'mean'] },
                { image: 'https://cdn.cloubot.com/PM/image/level4/image_clown.png', answer: 'clown', exWords: ['clown', 'clean'] },
                { image: 'https://cdn.cloubot.com/PM/image/level4/image_couch.png', answer: 'couch', exWords: ['couch', 'coach'] },
                { image: 'https://cdn.cloubot.com/PM/image/level4/image_boat.png', answer: 'boat', exWords: ['boat', 'beat'] },
                { image: 'https://cdn.cloubot.com/PM/image/level4/image_bowl.png', answer: 'bowl', exWords: ['bowl', 'boat'] },
                { image: 'https://cdn.cloubot.com/PM/image/level4/image_tree.png', answer: 'tree', exWords: ['tree', 'tray'] },
                { image: 'https://cdn.cloubot.com/PM/image/level4/image_down.png', answer: 'down', exWords: ['down', 'brown'] },
                { image: 'https://cdn.cloubot.com/PM/image/level4/image_gray.png', answer: 'gray', exWords: ['gray', 'grow'] },
                { image: 'https://cdn.cloubot.com/PM/image/level4/image_foot.png', answer: 'foot', exWords: ['foot', 'food'] },
                { image: 'https://cdn.cloubot.com/PM/image/level4/image_leaf.png', answer: 'leaf', exWords: ['leaf', 'loaf'] },
                { image: 'https://cdn.cloubot.com/PM/image/level4/image_mail.png', answer: 'mail', exWords: ['mail', 'meal'] },
              ],
            },
          },
          8: {
            title: 'Look and type.',
            shortDescption: 'Review the Words IV',
            directionSound: 'https://cdn.cloubot.com/PM/audio/newsounds/L4_Review_S8_Dir.mp3',
            type: 'write',
            customData: {
              type: 'phonemeImageWrite',
              problem: [
                { phoneme: 'ai', imageBaseUrl: 'https://cdn.cloubot.com/PM/image/level4/', words: ['snail', 'tail'] },
                { phoneme: 'ee', imageBaseUrl: 'https://cdn.cloubot.com/PM/image/level4/', words: ['bee', 'feet'] },
                { phoneme: 'oa', imageBaseUrl: 'https://cdn.cloubot.com/PM/image/level4/', words: ['coat', 'goat'] },
                { phoneme: 'ow', imageBaseUrl: 'https://cdn.cloubot.com/PM/image/level4/', words: ['owl', 'clown'] },
                { phoneme: 'long oo', imageBaseUrl: 'https://cdn.cloubot.com/PM/image/level4/', words: ['moon', 'food'] },
                { phoneme: 'short oo', imageBaseUrl: 'https://cdn.cloubot.com/PM/image/level4/', words: ['cook', 'wood'] },
              ],
            },
          },
        },
      },
    },
  },
];

export default bookInfo;
